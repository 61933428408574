export const IncotermsInfo = {
    grupoC:{
        grupoTitle: 'Grupo C – Entrega Indirecta',
        grupoData: ` La parte vendedora contrata el vehículo para el transporte de los productos. Sin embargo, el
        exportador queda eximido de cualquier responsabilidad derivada de deterioros o costes adicionales.
        `,
        cfr: {
            title: 'Cost and Freight / Coste y Flete',
            data: `Usado en el transporte marítimo, la parte vendedora se
            responsabiliza del coste hasta que la mercancía llegue a su destino. Sin embargo, cuando se
            carga en el barco el riesgo para a ser competencia del comprador.`
        },
        cif: {
            title: 'Cost, Insurance and Freight / Coste, Seguro y Flete',
            data: `Cumple con los mismos parámetros
            que el CFR pero con el añadido de que el vendedor debe contratar un seguro. Y aquí entra en
            liza otra de las novedades de los Incoterms 2020, con una actualización de las coberturas
            que
            debe contraer y certificar el seguro contratado por el vendedor bajo las Cláusulas de Carga del
            Instituto (C), dejando un margen de negociación superior entre las partes de la operación.`
        },
        cpt: {
            title: 'Carriage Paid To / Transporte Pagado Hasta',
            data: `Se emplea en cualquier tipo de transporte.
            El vendedor asume los gastos hasta que la mercancía llegue a su destino final; mientras que el
            riesgo pasa a manos del comprador cuando la mercancía la posea el transportista.`
        },
        cip: {
            title: 'Carriage and Insurance Paid To / Transporte y Seguro Pagados Hasta',
            data: `Utilizable para
            cualquier tipo de transporte, el exportador afronta los gastos del transporte principal y del
            seguro durante todo el trayecto. De igual modo que con el CIF, los Incoterms 2020 amplían las
            diversas coberturas mínimas del seguro de la mercancía bajo las Cláusulas de Carga del
            Instituto (A), aunque será un aspecto a negociar en la compra-venta de los bienes.`
        }
    },
    grupoD:{
        grupoTitle: 'Grupo D – Entrega Directa',
        grupoData: ` Los costes y los riesgos corren a cuenta del vendedor desde la salida de la mercancía hasta su entrega
        en el lugar pactado.`,
        dpu: {
            title: 'Delivered Place Unloaded / Entrega y Descarga en Lugar Acordado)',
            data: `Otra de las actualizaciones pues sustituye al DAT anterior. El vendedor sigue asumiendo los costes y riesgos, pero con los Incoterms 2020 se amplía el lugar de descarga, ya que antes se realizaba exclusivamente en la terminal. Se usa para todo tipo de transporte.`
        },
        dap: {
            title: 'Delivered At Place / Entregado en un Punto',
            data: `El exportador asume gastos y riesgos hasta
            la entrega de los bienes, a excepción de los costes derivados de la importación de la mercancía
            que son atribuidos al comprador. Compatible con cualquier transporte.`
        },
        ddp: {
            title: 'Delivered Duty Paid / Entregado con Derechos Pagados',
            data: `Empleado para todo
            transporte, el vendedor asume absolutamente todos los costes y riesgos de la operación de
            exportación.`
        }
    },
    grupoE:{
        grupoTitle: 'Grupo E – Entrega directa a la salida',
        grupoData: ` La parte vendedora proporciona la mercancía al comprador en sus propias instalaciones. `,
        exw: {
            title: 'Ex-Works / En Fábrica',
            data:`El vendedor solo se ocupa de embalar los productos, ya que los pone a disposición del comprador en sus propios almacenes. Así, el comprador asume los gastos y las responsabilidades derivadas de cualquier maniobra, desde su carga hasta su llegada al destino final. La contratación del seguro, que no es obligatorio, también corre a cuenta del importador.`
        }
    },
    grupoF:{
        grupoTitle: 'Grupo F – Entrega Indirecta',
        grupoData: `El vendedor dispone los bienes a un transportista seleccionado y abonado por el comprador. `,
        fca: {
            title: 'Free Carrier / Libre Transportista',
            data:`El vendedor entrega la mercancía en un lugar
            acordado asumiendo los riesgos y los gastos -incluyendo los costes del despacho aduanero
            para la exportación- hasta dicho instante. El comprador, responsable desde dicha carga hasta
            la descarga final. Como
            actualización a los Incoterms 2020, el comprador puede pedir al
            transportista que expida un B/L
            (Bill of Landing ó Carta de Embarque) al vendedor bajo la
            especificación “on borad ó a bordo” que sirva para justificar la entrega de los productos. Así,
            se facilita el procedimiento con los créditos documentarios y con el pago del crédito al
            exportador.`
        },
        fas: {
            title: 'Free Alongside Ship / Franco al Costado del Buque',
            data:`Incoterm usado en el transporte
            marítimo para cargas a granel o con un volumen muy grande. El vendedor transmite los bienes
            en el muelle o puerto de embarque acordado junto al buque en el que viajará la mercancía. En
            dicho instante, el importador contrae todos los costes y deterioros.`
        },
        fob: {
            title: 'Free On Board / Libre a Bordo',
            data:` El Incoterm FOB, usado en el transporte marítimo,
            establece que el vendedor proporciona la mercancía en el barco establecido asumiendo toda
            responsabilidad hasta dicho momento. Entonces, el comprador hace frente a todo hasta su
            llegada al destino final.`
        }
    }
}