import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Popup } from "semantic-ui-react";
import "./styles/cards.scss";

const limit = 12;
const highLimit = 20;

export const CardIngresos = (props) => {
  let { element } = props;
  return (
    <>
      <Card className="_clickCard mySearchCard" id={Math.random()}>
        <CardBody>
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                {/* Icono & DO ID & Estatus */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================ICONO===================================== */}
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                Ingreso
                              </span>
                            </div>
                            <div className="mb-mail-subject">
                              <em
                                className={"fa-2x mr-2 fas fa-sort-amount-down"}
                                style={{
                                  color: "rgb(0, 20, 137)",
                                  marginLeft: "11px",
                                }}
                              ></em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ==========================cliente =========================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Cliente ? (
                                element.Cliente.length > limit ? (
                                  <Popup
                                    content={element.Cliente}
                                    key={element.Cliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Cliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Cliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================No. de Ingreso===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              No. de Ingreso
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              <Link
                                to={
                                  "/storage-entries-detail/" + element.IngresoId
                                }
                              >
                                {element.NoIngreso ? (
                                  element.NoIngreso.length > limit ? (
                                    <Popup
                                      content={element.NoIngreso}
                                      key={element.NoIngreso}
                                      position=""
                                      trigger={
                                        <span>
                                          {element.NoIngreso.substring(
                                            0,
                                            limit - 3
                                          )}
                                          ...
                                        </span>
                                      }
                                    />
                                  ) : (
                                    element.NoIngreso
                                  )
                                ) : (
                                  ""
                                )}
                              </Link>
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* MODO transporte & doc transporte */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================Estatus===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              <span>Estatus </span>
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <span style={{ fontSize: "12px" }}>
                            {element.Estatus ? (
                              element.Estatus.length > highLimit ? (
                                <Popup
                                  content={element.Estatus}
                                  key={element.Estatus}
                                  position=""
                                  trigger={
                                    <span>
                                      {element.Estatus.substring(0, limit - 3)}
                                      ...
                                    </span>
                                  }
                                />
                              ) : (
                                element.Estatus
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Ref. de Cliente===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Ref. de Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.RefCliente ? (
                                element.RefCliente.length > limit ? (
                                  <Popup
                                    content={element.RefCliente}
                                    key={element.RefCliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.RefCliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.RefCliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Doc. de Transporte===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Doc. de Transporte
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.DocTransporte ? (
                                element.DocTransporte.length > limit ? (
                                  <Popup
                                    content={element.DocTransporte}
                                    key={element.DocTransporte}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.DocTransporte.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.DocTransporte
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Aduana & País origen*/}
                <div className="col-md-2">
                  <div className="row text-center">
                    {/* ==========================DO de Aduana ===================================== */}
                    <div className="col-md-5">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              DO de Aduana
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.DoAduana ? (
                                element.DoAduana.length > limit ? (
                                  <Popup
                                    content={element.DoAduana}
                                    key={element.DoAduana}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.DoAduana.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.DoAduana
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Almacén===================================== */}
                    <div className="col-md-7">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Almacén
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Almacen ? (
                                element.Almacen.length > limit ? (
                                  <Popup
                                    content={element.Almacen}
                                    key={element.Almacen}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Almacen.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Almacen
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Instruccion & ATA */}
                <div className="col-md-4">
                  <div className="row text-center">
                    {/* ========================== Placas de Vehículo ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Placas de Vehículo
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.placa ? (
                                element.placa.length > limit ? (
                                  <Popup
                                    content={element.placa}
                                    key={element.placa}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.placa.substring(0, limit - 3)}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.placa
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Notificación de Ingreso ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Notificación de Ingreso
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaNotificacionIngreso ? (
                                element.FechaNotificacionIngreso.length >
                                limit ? (
                                  <Popup
                                    content={element.FechaNotificacionIngreso}
                                    key={element.FechaNotificacionIngreso}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaNotificacionIngreso.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaNotificacionIngreso
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Llegada a Almacén ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Llegada a Almacén
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaLlegadaAlmacen ? (
                                element.FechaLlegadaAlmacen.length > limit ? (
                                  <Popup
                                    content={element.FechaLlegadaAlmacen}
                                    key={element.FechaLlegadaAlmacen}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaLlegadaAlmacen.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaLlegadaAlmacen
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* END OF MAIN DIV */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
