import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Loader as Load, Icon } from 'semantic-ui-react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import Ingreso from './components/Ingresos';
import Despacho from './components/Despachos';

function DocumentManagerCustoms() {

  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend className="legendTitle">
          <Icon name='file alternate outline' />
          Gestor Documental Almacén
        </legend>
      </div>

      <Tab
        menu={{ secondary: true, className: 'wrapped' }}
        menuPosition="right"
        panes={[
          {
            menuItem: 'Ingresos',
            render: () => (
              <Suspense fallback={<Load />}><Ingreso></Ingreso></Suspense>
            )
          },
          {
            menuItem: 'Despachos',
            render: () => (
              <Suspense fallback={<Load />}><Despacho></Despacho></Suspense>
            )
          }
        ]}
      />

    </ContentWrapper>
  );
}

export default withRouter(DocumentManagerCustoms);
