import React from 'react';
import { init } from '../model';
import CreateContext from './CreateContext';
import { ProjectOutlined, UserOutlined, DollarOutlined } from '@ant-design/icons';

/**
 * Card info cretor
 */
const color = '#fff';
function CardInfo({name, client, logisticCost}){
  return [
    {
      icon: <ProjectOutlined style={{color: color, fontSize: '1.5em'}}/>,
      title: 'Nombre del Proyecto:',
      value: name
    },
    {
      icon: <UserOutlined style={{color: color, fontSize: '1.5em'}}/>,
      title: 'Cliente:',
      value: client
    },
    {
      icon: <DollarOutlined style={{color: color, fontSize: '1.5em'}}/>,
      title: 'Costo Logístico (COP):',
      value: logisticCost
    }
  ];
};


/**
 * Reducer function
 * @param {*} state - state
 * @param {*} action - action
 */
function reducer(state, action){
  switch(action.type){
  case 'IS_PROJECT_DETAIL':
    state.projectDetail = action.payload;
    return {
      ...state
    };
  case 'SET_PROJECT':
    let project= action.payload;
    state.project = {...project};
    return {
      ...state
    };
  default:
    return state;
  }
}
/**
 * Create provider function
 * @param {*} props - props to function
 */
function CreateProvider(props){
  const [state, dispatch] = React.useReducer(reducer, init(), init);

  return (
    <CreateContext.Provider
      value={{
        state,
        IsProjectDetail: (payload) => dispatch({ type: 'IS_PROJECT_DETAIL', payload}),
        SetProjectInfo: (payload) => dispatch({ type: 'SET_PROJECT', payload}),
        CardInfo: CardInfo
      }}
    >
      {props.children}
    </CreateContext.Provider>
  );
}

export default CreateProvider;