import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { Services } from '../../Services';
import { TableLoader } from '../Loader';
const Table = React.lazy(() => import('../../../Components/Table'));

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { label: 'Nombre', key: 'Nombre' },
        { label: 'Apellido', key: 'Apellido' },
        { label: 'Rol', key: 'Rol' },
        { label: 'Módulo', key: 'Module' },
        { label: 'Origen', key: 'Origin' },
        { label: 'Fecha', key: 'Date' },
      ],
      data: [],
      loaderTab: true,
    };
  }

  componentDidMount = () => {
    this.getDetails();
  };

  getDetails = () => {
    axios
      .get(`${Services.GetStatistics.path}/statistics/details`)
      .then((success) => {
        this.setState({ data: success.data.data, loaderTab: false });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Card className="myCard">
          <CardBody>
            {this.state.loaderTab ? (
              <TableLoader />
            ) : (
              <Table
                columns={this.state.columns}
                data={this.state.data}
                itemsByPage={10}
                nameXlsx={'Estadistica_Uso_Detalle.xlsx'}
                options={{
                  SelectZone: false,
                  downloads: {
                    pdf: false,
                    xlsx: true,
                  },
                  pagination: true,
                  search: true,
                }}
              />
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Details;
