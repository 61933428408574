import React, { Component } from 'react';
import {
  Button as ButtonSemantic,
  Input,
  Tab,
  Search,
  TransitionablePortal,
  Segment,
  Header,
} from 'semantic-ui-react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import Table from '../Components/Table';
import { Button, Fab, Checkbox } from '@material-ui/core/';
import { Card, CardBody } from 'reactstrap';
import AlertNotification from './AlertNotification';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { Popover } from 'antd';
import { Services } from './Services';
import './style.scss';

const config = [
  { label: 'Id', id: 'ClienteId', type: 'simple' },
  { label: 'Nombre del Cliente', id: 'Nombre', type: 'simple' },
  { label: 'Teléfono', id: 'Telefono', type: 'simple' },
  { label: 'NIT', id: 'NIT', type: 'simple' },
  { label: 'País', id: 'Pais', type: 'simple' },
];

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
      findCustomer: false,
      findAlert: false,
      alertSelected: {},
      rows: [],
      key: 0,
      openPortal: false,
      searchInput: '',
      customers: [],
      columns: [
        {
          label: 'ID',
          key: 'ClienteId',
          custom: (value) => (
            <em
              onClick={() =>
                this.setState({ customer: value, findCustomer: true }, () => {
                  this.getCustomerUsers();
                  this.buildRows();
                })
              }
            >
              {' '}
              <label style={{ color: '#349ced', cursor: 'pointer' }}>
                {value.ClienteId}
              </label>{' '}
            </em>
          ),
        },
        { label: 'Nombre Del Cliente', key: 'Nombre' },
        { label: 'NIT', key: 'NIT' },
        { label: 'Telefono', key: 'Telefono' },
        { label: 'Pais', key: 'Pais' },
        { label: 'Usuarios Asociados', key: 'UsuariosAsociados' },
      ],
      customer: {},
      allUsers: [],
      searchUsers: {
        columns: [
          {
            id: 'Nombre',
            label: 'Nombre',
          },
          {
            id: 'Apellido',
            label: 'Apellidos',
          },
          {
            id: 'Email',
            label: 'Email',
          },
          {
            id: 'Checked',
            label: '',
          },
        ],
        data: [],
        allData: [],
      },
      usersAdded: {
        columns: [
          {
            id: 'Nombre',
            label: 'Nombre',
          },
          {
            id: 'Apellido',
            label: 'Apellidos',
          },
          {
            id: 'Email',
            label: 'Email',
          },
          {
            id: 'Delete',
            label: '',
          },
        ],
        data: [],
      },
      mailList: {
        columns: [
          {
            id: 'Email',
            label: 'Lista de Correos',
          },
          {
            id: 'Delete',
            label: '',
          },
        ],
        data: [],
      },
      alertsData: [],
      notificationsData: [],
    };
  }

  componentDidMount = () => {
    this.getlist();
  };

  getlist = () => {
    axios
      .get(`${Services.GetListCustomer.path}/admin/listCustomer`)
      .then((success) => {
        this.setState({
          customers: success.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCustomerUsers = () => {
    axios
      .get(
        `${Services.GetInfoUser.path}/admin/infoCustomer/${this.state.customer.ClienteId}`
      )
      .then((success) => {
        let newSearch = this.state.searchUsers;
        newSearch.data = success.data.resp.array;
        newSearch.allData = success.data.resp.array;
        this.setState(
          {
            allUsers: success.data.resp.array,
            searchUsers: newSearch,
            findAlert: false,
          },
          () => {
            this.getAlertsCustomer();
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getAlertsCustomer = () => {
    axios
      .get(
        `${Services.Get_AlertsCustomer_Alert.path}/alerts/AlertsCustomer/${this.state.customer.ClienteId}`
      )
      .then((success) => {
        this.setState({
          alertsData: success.data.data.alerts,
          notificationsData: success.data.data.notifications,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  initUserData = () => {
    let { allUsers, alertSelected, usersAdded, searchUsers } = this.state;
    
    let str = alertSelected.UserIds;

    var usersIds = [];
    if (str) {
      usersIds = str.split(',');
    }
    usersIds = usersIds.filter(
      (item, index) => usersIds.indexOf(item) === index
    );
    usersIds = usersIds.filter((item) => item !== null || item !== '');
    let newUsersSearch = [];
    let newUsersAdded = [];
    allUsers.map((e) => {
      let findUserAdd = false;
      usersIds.map((f) => {
        if (f === e.UsuarioId.toString()) {
          findUserAdd = true;
          newUsersAdded.push(e);
        }
        return f;
      });
      if (!findUserAdd) {
        newUsersSearch.push(e);
      }
      return e;
    });

    newUsersSearch.map((e) => {
      e.Checked = false;
      return e;
    });

    usersAdded.data = newUsersAdded;
    searchUsers.data = newUsersSearch;
    searchUsers.allData = newUsersSearch;

    this.setState({ usersAdded: usersAdded, searchUsers: searchUsers });
  };

  initCopyData = () => {
    let { alertSelected, mailList } = this.state;
    let str = alertSelected.Emails;
    var usersIds = [];
    if (str) {
      usersIds = str.split(',');
    }
    usersIds = usersIds.filter(
      (item, index) => usersIds.indexOf(item) === index
    );
    usersIds = usersIds.filter((item) => item.length > 0);
    usersIds = usersIds.map((e) => {
      return { Email: e };
    });
    mailList.data = usersIds;
    this.setState({ mailList: mailList });
  };

  searchAll = (search) => {
    let { searchUsers } = this.state;
    let newSearch = searchUsers;
    newSearch.data = newSearch.allData;
    if (search.value.length > 0) {
      const newData = newSearch.allData.filter(function (item) {
        const nameData = item.Nombre
          ? item.Nombre.toUpperCase()
          : ''.toUpperCase();
        const lastData = item.Apellido
          ? item.Apellido.toUpperCase()
          : ''.toUpperCase();
        const emailData = item.Email
          ? item.Email.toUpperCase()
          : ''.toUpperCase();
        const textData = search.value.toUpperCase();
        return (
          nameData.indexOf(textData) > -1 ||
          lastData.indexOf(textData) > -1 ||
          emailData.indexOf(textData) > -1
        );
      });
      newSearch.data = newData;
    }
    this.setState({ searchUsers: newSearch, searchInput: search.value });
  };

  buildRows = () => {
    let { customer } = this.state;

    let rows = config.map((e) => {
      return (
        <div
          className="col-md-2 col-lg-2 col-sm-6"
          style={{ paddingTop: '1%' }}
        >
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span style={{ color: '#545350', fontWeight: 'bold' }}>
                {e.label}
              </span>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span>{customer[e.id] ? customer[e.id] : ''}</span>
            </div>
          </div>
        </div>
      );
    });
    this.setState({
      rows,
    });
  };

  addAllClick = () => {
    let { searchUsers, usersAdded } = this.state;
    let newUsers = usersAdded.data;
    searchUsers.data.map((e) => {
      newUsers.push(e);
      return e;
    });
    let newSearch = searchUsers;
    newSearch.data = [];
    newSearch.allData = [];
    let newAdd = usersAdded;
    newAdd.data = newUsers;
    this.setState({
      searchUsers: newSearch,
      usersAdded: newAdd,
      buttonDisabled: false,
    });
  };

  deleteAllClick = () => {
    let { searchUsers, usersAdded } = this.state;
    let newUsers = searchUsers.data;
    usersAdded.data.map((e) => {
      e.Checked = false;
      newUsers.push(e);
      return e;
    });
    let newSearch = searchUsers;
    newSearch.data = newUsers;
    newSearch.allData = newUsers;
    let newAdd = usersAdded;
    newAdd.data = [];
    this.setState({
      searchUsers: newSearch,
      usersAdded: newAdd,
      buttonDisabled: false,
    });
  };

  addClick = () => {
    let { searchUsers, usersAdded } = this.state;
    let newUsersAdded = usersAdded.data;
    let newUsersSearch = searchUsers.data.filter((e) => {
      if (e.Checked) {
        e.Checked = false;
        newUsersAdded.push(e);
      } else {
        return e;
      }
    });
    let newSearch = searchUsers;
    newSearch.data = newUsersSearch;
    newSearch.allData = newUsersSearch;

    newSearch.data.map((e) => {
      e.Checked = false;
    });

    let newAdd = usersAdded;
    newAdd.data = newUsersAdded;
    this.setState({
      searchUsers: newSearch,
      usersAdded: newAdd,
      buttonDisabled: false,
      key: Math.random(),
    });
  };

  deleteClick = (e, index) => {
    let { searchUsers, usersAdded } = this.state;
    let newUsersSearch = searchUsers.data;
    usersAdded.data.splice(index, 1);
    let newUsersAdded = usersAdded;
    e.Checked = false;
    newUsersSearch.push(e);
    let newSearch = searchUsers;
    newSearch.data = newUsersSearch;
    newSearch.allData = newUsersSearch;
    this.setState({
      searchUsers: newSearch,
      usersAdded: newUsersAdded,
      buttonDisabled: false,
    });
  };

  deleteMail = (index) => {
    let { mailList, indexSelected } = this.state;
    mailList.data.splice(indexSelected, 1);
    this.setState({
      mailList: mailList,
      openPortal: false,
      buttonDisabled: false,
    });
  };

  saveAlerts = () => {
    let { alertSelected, mailList, usersAdded } = this.state;
    var dataSave = alertSelected;
    dataSave.usersAdded = usersAdded.data;
    dataSave.emailAdded = mailList.data;

    axios
      .put(`${Services.Put_Update_Alert.path}/alerts/AlertsCustomer`, dataSave)
      .then((success) => {
        this.setState({ alertSelected: {}, findAlert: false });
        this.getAlertsCustomer();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  selectAlert = (alert) => {
    if (alert) {
      if (!alert.disabled) {
        this.setState({ alertSelected: {}, findAlert: false });
      } else {
        this.setState({ alertSelected: alert, findAlert: true }, () => {
          this.initUserData();
          this.initCopyData();
        });
      }
    } else {
      this.setState({ alertSelected: {}, findAlert: false });
    }
  };

  render() {
    let {
      alertsData,
      notificationsData,
      searchUsers,
      usersAdded,
      mailList,
      openPortal,
      customer,
      columns,
      customers,
      findCustomer,
      findAlert,
    } = this.state;

    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fa-1x mr-2 far fa-bell"></em>
              Alertas y Notificaciones
            </div>
          </div>
        </div>
        <Card>
          <CardBody className="myCard">
            <div className="col-md-12">
              <strong style={{ marginLeft: '-12px' }}>Clientes</strong>
            </div>
            <br></br>
            <div className="col-md-12">
              <Table
                columns={columns}
                data={customers}
                itemsByPage={10}
                options={{
                  pagination: true,
                  search: true,
                  downloads: {
                    xlsx: false,
                    pdf: false,
                  },
                }}
              />
            </div>
          </CardBody>
        </Card>
        {findCustomer ? (
          <div>
            <Card>
              <CardBody className="myCard">
                <div className="col-md-12">
                  <strong style={{ marginLeft: '-12px' }}>
                    Información del Cliente
                  </strong>
                </div>
                <br></br>
                <div
                  className="row"
                  style={{ width: '100%', marginLeft: '20px' }}
                >
                  <img
                    alt="Logo"
                    src={
                      customer.URLLogo
                        ? customer.URLLogo
                        : 'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png'
                    }
                    width="117"
                    height="117"
                    className="circule"
                  />
                  <div style={{ marginTop: '13px' }} className="col-md-10">
                    <div className="row">
                      {this.state.rows}
                      <div
                        class="col-lg-2 "
                        style={{ textAlign: 'center', marginBottom: 30 }}
                      >
                        <Button
                          style={{ color: '#ffff', background: '#ff0000c7' }}
                          variant="contained"
                          color="danger"
                          className="button"
                          onClick={() => {
                            this.setState({ findCustomer: false });
                          }}
                        >
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="myCard">
                <Tab
                  loading={true}
                  menu={{ secondary: true, className: 'myWrapped' }}
                  menuPosition="right"
                  panes={[
                    {
                      menuItem: 'Alertas',
                      render: () => (
                        <AlertNotification
                          data={alertsData}
                          getAlertsCustomer={this.getAlertsCustomer}
                          selectAlert={(alert) => {
                            this.selectAlert(alert);
                          }}
                          customer={customer}
                          type="1"
                        />
                      ),
                    },
                    {
                      menuItem: 'Notificaciones',
                      render: () => (
                        <AlertNotification
                          data={notificationsData}
                          customer={customer}
                          selectAlert={(alert) => {
                            this.selectAlert(alert);
                          }}
                          getAlertsCustomer={this.getAlertsCustomer}
                          type="2"
                        />
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
            {findAlert ? (
              <div>
                <Card>
                  <CardBody className="myCard">
                    <div className="col-md-12">
                      <strong style={{ marginLeft: '-12px' }}>
                        Asignación de Alertas
                      </strong>
                    </div>
                    <br></br>

                    <div key={this.state.key} className="col-12">
                      <div className="row">
                        <div
                          className="col-5"
                          style={{ backgroundColor: '#F5F5F5' }}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div
                                className="col-6"
                                style={{
                                  marginTop: 15,
                                  fontWeight: 'bold',
                                  fontSize: 18,
                                }}
                              >
                                Búsqueda de Usuarios
                              </div>
                              <div
                                className="col-6"
                                style={{
                                  textAlign: 'right',
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Search
                                  input={{
                                    icon: 'search',
                                    iconPosition: 'left',
                                    fluid: true,
                                  }}
                                  placeholder="Nombre Usuario"
                                  onSearchChange={(e, result) => {
                                    this.searchAll(result);
                                  }}
                                  value={this.state.searchInput}
                                  open={false}
                                  name="search"
                                  id="search"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 scrollUsers">
                            <table className="table" id="tableAbsoulte">
                              <tr>
                                {searchUsers.columns.map((e, index) => {
                                  return (
                                    <th id={e.id} key={index}>
                                      {e.label}
                                    </th>
                                  );
                                })}
                              </tr>
                              <tbody className="table-body">
                                {searchUsers.data.map((e, index) => {

                                  let td = searchUsers.columns.map((f, i) => {
                                    if (f.id === 'Checked') {
                                      return (
                                        <td key={i}>
                                          <Checkbox
                                            checked={e.Checked}
                                            onChange={() => {

                                              let newSearchUsers = searchUsers;

                                              newSearchUsers.data.map((g) => {
                                                if (
                                                  e.UsuarioId === g.UsuarioId
                                                ) {
                                                  let newcheck = !e.Checked;
                                                  e.Checked = newcheck;
                                                }
                                                return g;
                                              });

                                              // newSearchUsers.data[index].Checked = !newSearchUsers.data[index].Checked;
                                              this.setState(
                                                { searchUsers: newSearchUsers },
                                                () => {
                                                }
                                              );
                                            }}
                                            value={e[f.id]}
                                          />
                                        </td>
                                      );
                                    } else {
                                      return <td key={i}>{e[f.id]}</td>;
                                    }
                                  });
                                  return <tr>{td}</tr>;
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="col-2"
                          style={{
                            textAlign: 'center',
                            alignItems: 'center',
                            display: 'grid',
                          }}
                        >
                          <div className="buttonTable">
                            <Button onClick={() => this.addAllClick()}>
                              <p className="addAll">Agregar Todos</p>
                            </Button>
                          </div>
                          <div className="buttonTable">
                            <Button onClick={() => this.addClick()}>
                              <span
                                className="addCircle"
                                style={{
                                  backgroundColor: 'transparent !important',
                                }}
                              >
                                <div className="addCircleInside">Add</div>
                              </span>
                            </Button>
                          </div>
                          <div className="buttonTable">
                            <Button onClick={() => this.deleteAllClick()}>
                              <p className="deleteAll">Eliminar Todos</p>
                            </Button>
                          </div>
                        </div>
                        <div
                          className="col-5"
                          style={{ backgroundColor: '#F5F5F5' }}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div
                                className="col-12"
                                style={{
                                  marginTop: 15,
                                  fontWeight: 'bold',
                                  fontSize: 18,
                                  marginBottom: 15,
                                }}
                              >
                                Usuarios Agregados
                              </div>
                            </div>
                          </div>
                          <div className="col-12 scrollUsers">
                            <table
                              className="table scrollUsers"
                              id="tableAbsoulte"
                            >
                              <tr>
                                {usersAdded.columns.map((e, index) => {
                                  return (
                                    <th id={e.id} key={index}>
                                      {e.label}
                                    </th>
                                  );
                                })}
                              </tr>
                              <tbody className="table-body">
                                {usersAdded.data.map((e, index) => {
                                  let td = usersAdded.columns.map((f, i) => {
                                    if (f.id === 'Delete') {
                                      return (
                                        <td key={i}>
                                          <em
                                            onClick={() => {
                                              this.deleteClick(e, index);
                                            }}
                                            className="fa-2x mr-2 far fa-trash-alt"
                                            style={{ color: '#FC3737' }}
                                          ></em>
                                        </td>
                                      );
                                    } else {
                                      return <td key={i}>{e[f.id]}</td>;
                                    }
                                  });
                                  return <tr>{td}</tr>;
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="myCard">
                    <div className="col-md-12">
                      <strong style={{ marginLeft: '-12px' }}>Copiar a</strong>
                    </div>
                    <br></br>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-5">
                          Correo Electrónico
                          <div className="row">
                            <Input
                              placeholder="Correo Electrónico"
                              name="email"
                              className="emailInput"
                              id="email"
                              value={this.state.emailInput}
                              onChange={(ev, result) => {
                                this.setState({ emailInput: result.value });
                              }}
                            />
                            <Fab
                              color="lightBlue"
                              className="MuiFab-lightBlue customSmall"
                              aria-label="add"
                              onClick={() => {
                                let { emailInput } = this.state;
                                if (emailInput) {
                                  if (emailInput.length > 0) {
                                    mailList.data.push({ Email: emailInput });
                                    this.setState({
                                      mailList: mailList,
                                      emailInput: '',
                                      buttonDisabled: false,
                                    });
                                  }
                                }
                              }}
                            >
                              <AddIcon fontSize="small" />
                            </Fab>
                          </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-5">
                          <table className="table" id="tableAbsoulte">
                            <tr>
                              {mailList.columns.map((e, index) => {
                                return (
                                  <th id={e.id} key={index}>
                                    {e.label}
                                  </th>
                                );
                              })}
                            </tr>
                            <tbody className="table-body">
                              {mailList.data.map((e, index) => {
                                let td = mailList.columns.map((f, i) => {
                                  if (f.id === 'Delete') {
                                    return (
                                      <td
                                        style={{ textAlign: 'right' }}
                                        key={i}
                                      >
                                        <Popover
                                          placement="left"
                                          title={' Eliminar Correo Electrónico'}
                                          trigger="hover"
                                          overlayStyle={{
                                            width: "20vw"
                                          }}
                                          content={() => {
                                            return (
                                              <div className="row">
                                                <div
                                                  className="col-12"
                                                  style={{
                                                    marginBottom: '1em',
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  <p>
                                                    Al eliminar la cuenta de correo
                                                    electrónico, se elimina el envío
                                                    de correo de la alerta asignada.
                                                    ¿Está seguro de que desea eliminar
                                                    este correo electrónico?
                                                  </p>
                                                </div>
                                                <div
                                                  className="col-12"
                                                  style={{ textAlign: 'center' }}>
                                                  <ButtonSemantic
                                                    content="Aceptar"
                                                    negative={false}
                                                    style={{
                                                      backgroundColor: '#2B98F0',
                                                      color: 'white',
                                                    }}
                                                    onClick={() => {
                                                      this.setState({
                                                        openPortal: true,
                                                        indexSelected: index,
                                                        mailSelected: e,
                                                      }, () => this.deleteMail(index));
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }}>
                                          <em
                                            className="fa-2x mr-2 far fa-trash-alt"
                                            style={{ color: '#FC3737' }}
                                          ></em>
                                        </Popover>
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td style={{ textAlign: 'left' }} key={i}>
                                        {e[f.id]}
                                      </td>
                                    );
                                  }
                                });
                                return <tr>{td}</tr>;
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: 50,
                        marginBottom: 50,
                      }}
                    >
                      {this.state.buttonDisabled ? (
                        <Button
                          disabled={true}
                          variant="contained"
                          className="saveButton"
                          onClick={() => {
                            this.saveAlerts();
                          }}
                        >
                          <p>Guardar</p>
                        </Button>
                      ) : (
                        <Button
                          style={{
                            color: '#ffff',
                            background: '#3192ed',
                            fontWeight: 'bold',
                          }}
                          variant="contained"
                          color="danger"
                          className="button"
                          onClick={() => {
                            this.saveAlerts();
                          }}
                        >
                          Guardar
                        </Button>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </ContentWrapper>
    );
  }
}

export default Alerts;
