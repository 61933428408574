import React from 'react';
import { Button } from 'semantic-ui-react';

function ChatBotButtons(props) {
  const { buttons, botSteps } = props;

  const defaultStyles = {
    fontSize: '11px',
  };

  const defaultClassName = props.size ? props.size : 'col-md-6';

  const buttonFunction = (nextStep) => {

    if(botSteps){
      window.localStorage.setItem('currentChatbot', nextStep.label);
      botSteps.triggerNextStep({
        value: nextStep.label,
        trigger: nextStep.value,
      });
    }else{
      window.localStorage.setItem('currentChatbot', nextStep.label);
      props.triggerNextStep({
        value: nextStep.label,
        trigger: nextStep.value,
      });
    }
  };

  const createCols = () => {
    return buttons.map((item, element) =>
      element % 2 ? (
        <div className={defaultClassName}>
          <Button
            fluid
            compact
            size="tiny"
            style={defaultStyles}
            onClick={() => buttonFunction(item)}
          >
            {Array.isArray(item.label) ? item.label[0] : item.label}
          </Button>
          <div style={{ height: '10px' }}></div>
        </div>
      ) : (
        <div className={defaultClassName}>
          <Button
            fluid
            compact
            size="tiny"
            style={defaultStyles}
            onClick={() => buttonFunction(item)}
          >
            {Array.isArray(item.label) ? item.label[0] : item.label}
          </Button>
          <div style={{ height: '10px' }}></div>
        </div>
      )
    );
  };

  return (
    <div className="row" style={{ width: '100%' }}>
      {createCols()}
    </div>
  );
}

export default ChatBotButtons;
