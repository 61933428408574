import React from 'react';

const urlIcons = 'https://fsposeidon.blob.core.windows.net';

const Icons = {
  Internacional: {
    AEREA: (
      <img alt="" src={`${urlIcons}/roldan/icon_avion.png`} width="35"></img>
    ),
    MARITIMA: (
      <img alt="" src={`${urlIcons}/roldan/icon_barco.png`} width="32"></img>
    ),
    OTM: (
      <img alt="" src={`${urlIcons}/roldan/icon_camion.png`} width="35"></img>
    ),
    TERRESTRE: (
      <img alt="" src={`${urlIcons}/roldan/icon_camion.png`} width="35"></img>
    ),
  },
  Aduana: {
    Importacion: (
      <em
        className="fa-2x mr-2 fas fa-arrow-alt-circle-right"
        style={{ color: 'rgb(0, 20, 137)', width: '35' }}
      ></em>
    ),
    Exportacion: (
      <em
        className="fa-2x mr-2 fas fa-arrow-alt-circle-left"
        style={{ color: '#643296c2', marginLeft: '2px' }}
      ></em>
    ),
  },
  Integrada: {
    OTM: (
      <img alt="" src={`${urlIcons}/roldan/icon_camion.png`} width="35"></img>
    ),
    TERRESTRE: (
      <img alt="" src={`${urlIcons}/roldan/icon_camion.png`} width="35"></img>
    ),
    AEREO: (
      <img alt="" src={`${urlIcons}/roldan/icon_avion.png`} width="35"></img>
    ),
    AEREA: (
      <img alt="" src={`${urlIcons}/roldan/icon_avion.png`} width="35"></img>
    ),
    MARITIMO: (
      <img alt="" src={`${urlIcons}/roldan/icon_barco.png`} width="35"></img>
    ),
    MARITIMA: (
      <img alt="" src={`${urlIcons}/roldan/icon_barco.png`} width="35"></img>
    ),
    CARRETERO: (
      <img alt="" src={`${urlIcons}/roldan/icon_camion.png`} width="35"></img>
    ),
  },
  Almacen: {
    Ingreso: (
      <em
        className="fa-2x mr-2 fas fa-sort-amount-down"
        style={{ color: 'rgb(0, 20, 137)', width: '35' }}
      ></em>
    ),
    Despacho: (
      <em
        className="fa-2x mr-2 fas fa-sort-amount-up"
        style={{ color: 'rgb(88, 44, 131)', width: '35' }}
      ></em>
    ),
  },
};

export default Icons;
