import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.scss';
/*=============================================
=            Loader Component                 =
=============================================*/

export const TableLoader = () =>{
  let _rows = [];
  for (let index = 0; index < 5; index++) {      
    _rows.push(<Skeleton height={28}></Skeleton>);
  }

  return (
    <div className="_skeleton_loader">
      <React.Fragment>
        <Skeleton height={65}/>
      {
        _rows
      }
      <Skeleton height={24} width={"20%"}></Skeleton>
      </React.Fragment>
    </div>
  );
}

export const FullLoader = () => {
  return (
    <div className="_skeleton_loader">
      <React.Fragment>
        <Skeleton height={250}/>
        <Skeleton height={24} width={"100%"}></Skeleton>
      </React.Fragment>
    </div>
  );
};


export const TotalsCircle = () => (
  <Skeleton width="100%" height={120} className="_center_"/>
);