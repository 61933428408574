import React from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import MenuImageView from '../../../resources/Roldn-Logistics.png';
import ImagenROll from '../../../resources/Login_Logo2.png';


const BackgroundHead = {
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 'calc(100vw + 48px)',
  margin: -24,
  padding: 24,
};
/*=============================================
=            Login Component                  =
=============================================*/
function Terminos(props) {

  return (
    <div className="limiter" style={BackgroundHead}>
      <div className="">
        <br></br>
        <div className="col-12" style={{ marginBottom: '30px', textAlign: 'center' }}>
          <Col md={12}>
            <Row>
              <Col md={2}>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={4} style={{ textAlign: 'center' }}>
                    <img style={{ width: '18em', height: '9em', marginBottom:'1em' }} src={MenuImageView} alt='menu-view'/>
                  </Col>
                  <Col md={4} style={{ textAlign: 'center' }}>
                  </Col>
                  <Col md={4} style={{ textAlign: 'center' }}>
                    <img style={{ width: '18em', height: '9em' }} src={ImagenROll} alt='roll-4-all'/>
                  </Col>
                </Row>
              </Col>
              <Col md={2}>
              </Col>
            </Row>
          </Col>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div style={{ background: 'black', borderBottom: '1px', marginTop: '-2px' }} class="ui divider"></div>
              <br></br>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-2">
            </div>
            <div className="col-8">
              <h5 style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>TÉRMINOS Y CONDICIONES - ROL4ALL</h5>
              <div style={{ height: 40 }}></div>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El presente documento establece las condiciones mediante las cuales se regirá el uso de la aplicación móvil RoL4ll (en adelante la aplicación), operada por ROLDAN LOGISTICA y proveída sin costo alguna a los clientes de ROLDAN LOGISTICA. ROLDAN LOGISTICA es una compañía constituida en Colombia y domiciliada en la ciudad de Bogotá y que actúa en representación de las compañías AGENCIA DE ADUANAS ROLDAN SAS NIVEL 1, ROLDAN Y COMPAÑÍA SAS, LOGISTIC SERVICES FREE ZONE SAS, GLODEX SAS y ROLCO SHIPPING SAS (en adelante Roldán).
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                La aplicación funciona como un nuevo canal de información para los clientes sobre todas las operaciones que ROLDAN realiza a nombre del CLIENTE que usa los servicios ofrecidos por las Empresas Roldán.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El usuario se compromete a leer los términos y condiciones aquí establecidas, previamente a la descarga de la aplicación, por tanto, en caso de realizar la instalación se entiende que cuenta con el conocimiento integral de este documento y la consecuente aceptación de la totalidad de sus estipulaciones
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El Usuario reconoce que el ingreso de su información personal, y los datos que contiene la aplicación a su disposición respecto a los productos activos con las Empresas aliadas de Roldán en Colombia, la realizan de manera voluntaria, quienes optan por acceder a esta aplicación en Colombia o desde fuera del territorio nacional, lo hacen por iniciativa propia y son responsables del cumplimiento de las leyes locales, en la medida en que dichas leyes sean aplicables en su correspondiente país. Esta aplicación no está destinada a menores de edad y aunque no contiene ninguna información no relativa a sus operaciones, el acceso por parte de menores de edad está completamente prohibido. En caso de que se acceda por parte de menores de edad, deben contar con la supervisión de un adulto en todo momento desde la descarga y durante el uso de la aplicación, en el evento en que no se cumpla esta condición, le agradecemos no hacer uso de la aplicación.
             </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Alcance y Uso</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El usuario de la aplicación entiende y acepta que no obstante es operada por ROLDAN LOGISTICA., la información contenida en la misma será la referente a su vínculo comercial o contractual con las siguientes compañías: AGENCIA DE ADUANAS ROLDAN SAS NIVEL 1, ROLDAN Y COMPAÑÍA SAS, LOGISTIC SERVICES FREE ZONE SAS, GLODEX SAS y ROLCO SHIPPING SAS (en adelante Roldán), por tanto, las funcionalidades ofrecidas por la aplicación serán entregadas por cada compañía de acuerdo con su vinculación.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                En la aplicación se pondrá a disposición del CLIENTE información de las transacciones determinadas o habilitadas por Roldán para cada servicio en particular. Roldán podrá adicionar, modificar o eliminar las funcionalidades en cualquier momento, e inclusive eliminar la aplicación de forma unilateral, lo cual acepta el usuario mediante la instalación de la aplicación. En todo caso, al momento de realizar dichas modificaciones se notificarán al usuario a través de la misma aplicación móvil una vez inicie sesión.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Los tiempos de respuesta, tramites y demás solicitudes efectuadas por el usuario mediante la aplicación serán procesadas de conformidad con las especificaciones de cada servicio activo con Roldán.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El usuario acepta y autoriza que los registros electrónicos de las actividades mencionadas, que realice en la aplicación constituyen plena prueba de aceptación de los mismos
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Requisitos para Uso</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El usuario deberá contar con un dispositivo móvil inteligente (Smartphone) o Tableta con sistema operativo Android o IOS, cualquiera de estos con acceso a internet, ambos seguros y confiables. Roldán, no será responsable por la seguridad de los equipos Smartphone propiedad de los usuarios utilizados para el acceso al canal, ni por la disponibilidad del servicio en los dispositivos en los cuales se descargue la aplicación.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                En la forma permitida por la ley, los materiales de la aplicación se suministran sin garantía de ningún género, expresa o implícita, incluyendo sin limitación las garantías de calidad satisfactoria, comerciabilidad, adecuación para un fin particular o no infracción, por tanto, Roldán no garantiza el funcionamiento adecuado en los distintos sistemas operativos o dispositivos en los cuales se haga uso de la aplicación.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Para acceder al portal, EL CLIENTE contará con Usuario y Clave, que lo identifica en su relación con Roldán, los cuales serán los mismos utilizados en el portal web. Adicional a lo anterior se requerirá a EL CLIENTE, registrar preguntas de seguridad, las cuales serán solicitadas al momento de intentar ingresar el portal, sólo cuando el cliente ingrese desde un equipo registrado no se solicitará responder las preguntas definidas con anterioridad.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Obligaciones de los Usuarios</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El Usuario se obliga a usar la aplicación y los contenidos encontrados en ella de una manera diligente, correcta, lícita y en especial, se compromete a NO realizar las conductas descritas a continuación:
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                (a) Utilizar los contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público;
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                (b) Reproducir, copiar, representar, utilizar, distribuir, transformar o modificar los contenidos de la aplicación, por cualquier procedimiento o sobre cualquier soporte, total o parcial, o permitir el acceso del público a través de cualquier modalidad de comunicación pública;
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                (c) Utilizar los contenidos de cualquier manera que entrañen un riesgo de daño o inutilización de la aplicación o de los contenidos o de terceros;</p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                (d) Suprimir, eludir o manipular el derecho de autor y demás datos identificativos de los derechos de autor incorporados a los contenidos, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren tener los contenidos;
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                (e) Emplear los contenidos y, en particular, la información de cualquier clase obtenida a través de la aplicación para distribuir, transmitir, remitir, modificar, rehusar o reportar la publicidad o los contenidos de esta con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como comercializar o divulgar de cualquier modo dicha información;
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                (f) No permitir que terceros ajenos a usted usen la aplicación móvil con su clave;
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                (g) Utilizar la aplicación y los contenidos con fines lícitos y/o ilícitos, contrarios a lo establecido en estos Términos y Condiciones, o al uso mismo de la aplicación, que sean lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la aplicación y los contenidos o impedir la normal utilización o disfrute de esta y de los contenidos por parte de los usuarios.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Propiedad Intelectual</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Todo el material informático, gráfico, publicitario, fotográfico, de multimedia, audiovisual y de diseño, así como todos los contenidos, textos y bases de datos puestos a su disposición en esta aplicación están protegidos por derechos de autor y/o propiedad industrial cuyo titular es ROLDAN LOGISTICA o sus compañías filiales, vinculadas o subsidiarias, en algunos casos, de terceros que han autorizado su uso o explotación. Igualmente, el uso en la aplicación de algunos materiales de propiedad de terceros se encuentra expresamente autorizado por la ley o por dichos terceros. Todos los contenidos en la aplicación están protegidos por las normas sobre derecho de autor y por todas las normas nacionales e internacionales que le sean aplicables.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Exceptuando lo expresamente estipulado en estos Términos y Condiciones, queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los contenidos de esta aplicación, de manera o por medio alguno, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo y por escrito de Roldán o del titular de los respectivos derechos.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                En ningún caso estos Términos y Condiciones confieren derechos, licencias ni autorizaciones para realizar los actos anteriormente prohibidos. Cualquier uso no autorizado de los contenidos constituirá una violación del presente documento y a las normas vigentes sobre derechos de autor, a las normas vigentes nacionales e internacionales sobre Propiedad Industrial, y a cualquier otra que sea aplicable.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Licencia para Copiar para Uso Personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Usted podrá leer, visualizar, imprimir y descargar el material de sus operaciones.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Ninguna parte de la aplicación podrá ser reproducida o transmitida o almacenada en otro sitio web o en otra forma de sistema de recuperación electrónico.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Ya sea que se reconozca específicamente o no, las marcas comerciales, las marcas de servicio y los logos visualizados en esta aplicación pertenecen al grupo de compañías Roldán, sus socios promocionales u otros terceros.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán no interfiere, no toma decisiones, ni garantiza las relaciones que los usuarios lleguen a sostener o las vinculaciones con terceros que pauten y/o promocionen sus productos y servicios. Estas marcas de terceros se utilizan solamente para identificar los productos y servicios de sus respectivos propietarios y el patrocinio o el aval por parte de Roldán no se deben inferir con el uso de estas marcas comerciales.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Integración con Otras Aplicaciones</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Los links de Facebook®, Instagram®, twitter® en esta aplicación pueden mostrar contenido que no están bajo el control de Roldán.</p>
              <br></br>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Aunque esta aplicación de Roldán trata de suministrar links solamente a sitios y aplicaciones de terceros que cumplan con las leyes y regulaciones aplicables y las normas de Roldán, el Usuario debe entender que Roldán no tiene control sobre la naturaleza y el contenido de esos sitios y no está recomendando estos sitios, la información que contienen ni los productos o servicios de terceros.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán no acepta responsabilidad por el contenido del sitio de un tercero con el cual existe un link de hipertexto y no ofrece garantía (explícita o implícita) en cuanto al contenido de la información en esos sitios, ya que no recomienda estos sitios.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Usted debe verificar las secciones de términos y condiciones, política legal y de privacidad de algunos otros sitios de Roldán o de un tercero con los cuales se enlaza.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán no asume ninguna responsabilidad por pérdida directa, indirecta o consecuencial por el uso de un sitio de un tercero.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Uso de Información y Privacidad
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Con la descarga de la APP usted acepta y autoriza que Roldán Logística, utilice sus datos en calidad de responsable del tratamiento para fines derivados de la ejecución de la APP. Roldan Logística informa que podrá ejercer sus derechos a conocer, actualizar, rectificar y suprimir su información personal; así como el derecho a revocar el consentimiento otorgado para el tratamiento de datos personales previstos en la ley 1581 de 2012, observando nuestra política de tratamiento de información disponible en nuestra pagina web ; o a través de protecciondedatos@roldanlogistica.com o del teléfono (571) 404 2904 siendo voluntario responder preguntas sobre información sensible o de menores de edad.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldan Logística. podrá dar a conocer, transferir y/o trasmitir sus datos personales dentro y fuera del país a cualquier empresa miembro de Roldán, así como a terceros a consecuencia de un contrato, ley o vínculo lícito que así lo requiera, para todo lo anterior otorgo mi autorización expresa e inequívoca.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                De conformidad a lo anterior autoriza el tratamiento de su información en los términos señalados, y transfiere a Roldán de manera total, y sin limitación mis derechos de imagen y patrimoniales de autor, de manera voluntaria, previa, explicita, informada e inequívoca.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Responsabilidad de Roldán</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán procurará garantizar disponibilidad, continuidad o buen funcionamiento de la aplicación. Roldán podrá bloquear, interrumpir o restringir el acceso a esta cuando lo considere necesario para el mejoramiento de la aplicación o dar de baja la misma.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Se recomienda al Usuario tomar medidas adecuadas y actuar diligentemente al momento de acceder a la aplicación, como por ejemplo, contar con programas de protección, antivirus, para manejo de malware, spyware y herramientas similares.</p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán no será responsable por: a) Fuerza mayor o caso fortuito; b) Por la pérdida, extravío o hurto de su dispositivo móvil que implique el acceso de terceros a la aplicación móvil; c) Por errores en la digitación o accesos por parte del cliente; d) Por los perjuicios, lucro cesante, daño emergente, morales, y en general sumas a cargo de Roldán, por los retrasos, no procesamiento de información o suspensión del servicio del operador móvil o daños en los dispositivos móviles.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Denegación y Retirada del Acceso a la Aplicación</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                En el Evento en que un Usuario incumpla estos Términos y Condiciones, o cualesquiera otras disposiciones que resulten de aplicación, Roldán podrá suspender su acceso a la aplicación.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Términos y Condiciones</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                El Usuario acepta expresamente los Términos y Condiciones, siendo condición esencial para la utilización de la aplicación. En el evento en que se encuentre en desacuerdo con estos Términos y Condiciones, solicitamos abandonar la aplicación inmediatamente. Roldán podrá modificar los presentes términos y condiciones, avisando a los usuarios de la aplicación mediante publicación en la página web www.skandia.com.co o mediante la difusión de las modificación por algún medio electrónico, redes sociales, SMS y/o correo electrónico, lo cual se entenderá aceptado por el usuario si éste continua con el uso de la aplicación.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Jurisdicción</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Estos términos y condiciones y todo lo que tenga que ver con esta aplicación, se rigen por las leyes de Colombia.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Uso de Información no Personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán también recolecta información no personal en forma agregada para seguimiento de datos como el número total de descargas de la aplicación. Utilizamos esta información, que permanece en forma agregada, para entender el comportamiento de la aplicación.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Uso de Direcciones IP</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Una dirección de Protocolo de Internet (IP) es un conjunto de números que se asigna automáticamente a su o dispositivo móvil cuando usted accede a su proveedor de servicios de internet, o a través de la red de área local (LAN) de su organización o la red de área amplia (WAN). Los servidores web automáticamente identifican su dispositivo móvil por la dirección IP asignada a él durante su sesión en línea.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán podrán recolectar direcciones IP para propósitos de administración de sistemas y para auditar el uso de nuestro sitio, todo lo anterior de acuerdo con la autorización de protección de datos que se suscribe para tal efecto. Normalmente no vinculamos la dirección IP de un usuario con la información personal de ese usuario, lo que significa que cada sesión de usuario se registra, pero el usuario sigue siendo anónimo para nosotros. Sin embargo, podemos usar las direcciones IP para identificar a los usuarios de nuestro sitio cuando sea necesario con el objeto de exigir el cumplimiento de los términos de uso del sitio, o para proteger nuestro servicio, sitio u otros usuarios.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Seguridad</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Roldán está comprometido en la protección de la seguridad de su información personal. Roldán tiene implementados mecanismos de seguridad que aseguran la protección de la información personal, así como los accesos únicamente al personal y sistemas autorizados, también contra la pérdida, uso indebido y alteración de sus datos de usuario bajo nuestro control.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'center', textAlign: 'justify' }}>
                Excepto como se indica a continuación, sólo personal autorizado tiene acceso a la información que nos proporciona. Además, hemos impuesto reglas estrictas a los empleados de Roldán con acceso a las bases de datos que almacenan información del usuario o a los servidores que hospedan nuestros servicios.
              </p>
            </div>
            <div className="col-2">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Terminos);
