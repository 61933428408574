let nf = 8 ; 
export const getValuesOp = {
    Masa : function(value, fin, sin){
        var unitMeasurement = fin == 'oz' &&  sin === 'kg' ? Number((value / 35.274).toFixed(nf)) :
        fin == 'oz' &&  sin === 'g' ? Number((value * 28.3495).toFixed(nf)) :
        fin == 'oz' &&  sin === 'lb' ? Number((value / 16).toFixed(nf)) :
        fin == 'oz' &&  sin === 'short ton' ? Number((value / 32000).toFixed(10)) :
        fin == 'oz' &&  sin === 'T' ? Number((value / 35274).toFixed(10)) :
        
        fin == 'g' &&  sin === 'oz' ? Number((value / 28.3495).toFixed(nf)):
        //fin == 'g' &&  sin === 'g' ? (value * 28.35):
        fin == 'g' &&  sin === 'kg' ? Number((value / 1000).toFixed(nf)) :
        fin == 'g' &&  sin === 'lb' ? Number((value / 453.592).toFixed(nf)) :
        fin == 'g' &&  sin === 'short ton' ? Number((value / 907185).toFixed(nf)) :
        fin == 'g' &&  sin === 'T' ? Number((value / 1e+6).toFixed(nf)) :

        fin == 'kg' &&  sin === 'oz' ? Number((value * 35.274).toFixed(nf)):
        fin == 'kg' &&  sin === 'g' ? Number((value * 1000).toFixed(nf)):
        //fin == 'kg' &&  sin === 'kg' ? (value / 1000) :
        fin == 'kg' &&  sin === 'lb' ? Number((value * 2.20462).toFixed(nf)) :
        fin == 'kg' &&  sin === 'short ton' ? Number((value / 907.185).toFixed(nf)) :
        fin == 'kg' &&  sin === 'T' ? Number((value / 1000).toFixed(nf)) :

        fin == 'lb' &&  sin === 'oz' ? Number((value * 16).toFixed(nf)):
        fin == 'lb' &&  sin === 'g' ? Number((value * 453.592).toFixed(nf)):
        fin == 'lb' &&  sin === 'kg' ? Number((value / 2.20462).toFixed(nf)) :
        //fin == 'lb' &&  sin === 'lb' ? (value * 2.205) :
        fin == 'lb' &&  sin === 'short ton' ? Number((value / 2000).toFixed(nf)) :
        fin == 'lb' &&  sin === 'T' ? Number((value / 2204.62).toFixed(nf)) :

        // fin == 'lb' &&  sin === 'oz' ? Number((value * 16).toFixed(nf)):
        // fin == 'lb' &&  sin === 'g' ? Number((value * 454).toFixed(nf)):
        // fin == 'lb' &&  sin === 'kg' ? Number((value / 2.205).toFixed(nf)) :
        // //fin == 'lb' &&  sin === 'lb' ? (value * 2.205) :
        // fin == 'lb' &&  sin === 'short ton' ? Number((value / 2000).toFixed(nf)) :
        // fin == 'lb' &&  sin === 'T' ? Number((value / 2205).toFixed(nf)) :

        fin == 'short ton' &&  sin === 'oz' ? Number((value * 32000).toFixed(nf)):
        fin == 'short ton' &&  sin === 'g' ? Number((value * 907185).toFixed(nf)):
        fin == 'short ton' &&  sin === 'kg' ? Number((value * 907.185).toFixed(nf)) :
        fin == 'short ton' &&  sin === 'lb' ? Number((value * 2000).toFixed(nf)) :
        //fin == 'short ton' &&  sin === 'short ton' ? (value / 2000) :
        fin == 'short ton' &&  sin === 'T' ? Number((value / 1.10231).toFixed(nf)) :

        fin == 'T' &&  sin === 'oz' ? Number((value * 35274).toFixed(nf)):
        fin == 'T' &&  sin === 'g' ? Number((value * 1e+6).toFixed(nf)) :
        fin == 'T' &&  sin === 'kg' ? Number((value * 1000).toFixed(nf)) :
        fin == 'T' &&  sin === 'lb' ? Number((value * 2204.62).toFixed(nf)) :
        fin == 'T' &&  sin === 'short ton' ? Number((value * 1.10231).toFixed(nf)) :
        //fin == 'T' &&  sin === 'T' ? (value / 20205) :

        fin === sin  ? value :
            'Syntax Error';
            
        return unitMeasurement;
    },
    Longitud : function(value, fin, sin){
        var unitMeasurement = fin == 'm' &&  sin === 'mm' ? Number((value * 1000).toFixed(nf)) :
        fin == 'm' &&  sin === 'km' ? Number((value / 1000).toFixed(nf)) :
        fin == 'm' &&  sin === 'in' ? Number((value * 39.3701).toFixed(nf)) :
        fin == 'm' &&  sin === 'ft' ? Number((value * 3.28084).toFixed(nf)) :
        fin == 'm' &&  sin === 'yd' ? Number((value * 1.09361).toFixed(nf)) :
        fin == 'm' &&  sin === 'mi' ? Number((value / 1609.34).toFixed(nf)) :
        fin == 'm' &&  sin === 'min' ? Number((value / 1852).toFixed(nf)) :

        fin == 'mm' &&  sin === 'm' ? Number((value / 1000).toFixed(nf)) :
        //fin == 'mm' &&  sin === 'mm' ? (value / 35.274) :
        fin == 'mm' &&  sin === 'km' ? Number((value / 1e+6).toFixed(nf)) :
        fin == 'mm' &&  sin === 'in' ? Number((value / 25.4).toFixed(nf)) :
        fin == 'mm' &&  sin === 'ft' ? Number((value / 304.8).toFixed(nf)) :
        fin == 'mm' &&  sin === 'yd' ? Number((value / 914.4).toFixed(nf)) :
        fin == 'mm' &&  sin === 'mi' ? Number((value / 1.609e+6).toFixed(nf)) :
        fin == 'mm' &&  sin === 'min' ? Number((value / 1.852e+6).toFixed(nf)) :


        fin == 'km' &&  sin === 'm' ? Number((value * 1000).toFixed(nf)) :
        fin == 'km' &&  sin === 'mm' ? Number((value * 1e+6).toFixed(nf)) :
        //fin == 'km' &&  sin === 'km' ? (value * 28.3495) :
        fin == 'km' &&  sin === 'in' ? Number((value * 39370).toFixed(nf)) :
        fin == 'km' &&  sin === 'ft' ? Number((value * 3281).toFixed(nf)) :
        fin == 'km' &&  sin === 'yd' ? Number((value * 1094).toFixed(nf)) :
        fin == 'km' &&  sin === 'mi' ? Number((value / 1.609).toFixed(nf)) :
        fin == 'km' &&  sin === 'min' ? Number((value / 1.852).toFixed(nf)) :

        fin == 'in' &&  sin === 'm' ? Number(((value / 39.37).toFixed(nf))) :
        fin == 'in' &&  sin === 'mm' ? Number(((value * 25.4).toFixed(nf))) :
        fin == 'in' &&  sin === 'km' ? Number(((value / 39370).toFixed(nf))) :
        //fin == 'in' &&  sin === 'in' ? (value / 16) :
        fin == 'in' &&  sin === 'ft' ? Number(((value / 12).toFixed(nf))) :
        fin == 'in' &&  sin === 'yd' ? Number(((value / 36).toFixed(nf))) :
        fin == 'in' &&  sin === 'mi' ? Number(((value / 63360).toFixed(nf))) :
        fin == 'in' &&  sin === 'min' ? Number(((value / 72913).toFixed(nf))) :

        fin == 'ft' &&  sin === 'm' ? Number((value / 3.281).toFixed(nf)) :
        fin == 'ft' &&  sin === 'mm' ? Number((value / 305).toFixed(nf)) :
        fin == 'ft' &&  sin === 'km' ? Number((value / 3281).toFixed(nf)) :
        fin == 'ft' &&  sin === 'in' ? Number((value * 12).toFixed(nf)) :
        //fin == 'ft' &&  sin === 'ft' ? (value / 32000) :
        fin == 'ft' &&  sin === 'yd' ? Number((value / 3).toFixed(nf)) :
        fin == 'ft' &&  sin === 'mi' ? Number((value / 5280).toFixed(nf)) :
        fin == 'ft' &&  sin === 'min' ? Number((value / 6076).toFixed(nf)) :

        fin == 'yd' &&  sin === 'm' ? Number((value / 1.094).toFixed(nf)) :
        fin == 'yd' &&  sin === 'mm' ? Number((value * 914).toFixed(nf)) :
        fin == 'yd' &&  sin === 'km' ? Number((value / 1094).toFixed(nf)) :
        fin == 'yd' &&  sin === 'in' ? Number((value * 36).toFixed(nf)) :
        fin == 'yd' &&  sin === 'ft' ? Number((value * 3).toFixed(nf)) :
        //fin == 'yd' &&  sin === 'yd' ? (value / 35274) :
        fin == 'yd' &&  sin === 'mi' ? Number((value / 1760).toFixed(nf)) :
        fin == 'yd' &&  sin === 'min' ? Number((value / 2025).toFixed(nf)) :

        fin == 'mi' &&  sin === 'm' ? Number((value * 1609.34).toFixed(nf)) :
        fin == 'mi' &&  sin === 'mm' ? Number((value * 1.609e+6).toFixed(nf)) :
        fin == 'mi' &&  sin === 'km' ? Number((value * 1.609).toFixed(nf)) :
        fin == 'mi' &&  sin === 'in' ? Number((value * 63360).toFixed(nf)) :
        fin == 'mi' &&  sin === 'ft' ? Number((value * 5280).toFixed(nf)) :
        fin == 'mi' &&  sin === 'yd' ? Number((value * 1760).toFixed(nf)) :
        //fin == 'mi' &&  sin === 'mi' ? (value / 35274) :
        fin == 'mi' &&  sin === 'min' ? Number((value / 1.15078).toFixed(nf)) :
        
        fin == 'min' &&  sin === 'm' ? Number((value * 1852).toFixed(nf)) :
        fin == 'min' &&  sin === 'mm' ? Number((value * 1.852e+6).toFixed(nf)) :
        fin == 'min' &&  sin === 'km' ? Number((value * 1.852).toFixed(nf)) :
        fin == 'min' &&  sin === 'in' ? Number((value * 72913).toFixed(nf)) :
        fin == 'min' &&  sin === 'ft' ? Number((value * 6076).toFixed(nf)) :
        fin == 'min' &&  sin === 'yd' ? Number((value * 2025).toFixed(nf)) :
        fin == 'min' &&  sin === 'mi' ? Number((value * 1.15078).toFixed(nf)) :
        //fin == 'min' &&  sin === 'min' ? (value / 35274) :

        fin === sin  ? value : 'Syntax Error';
            
        return unitMeasurement;
    },
    Area : function(value, fin, sin){
        var unitMeasurement = fin == 'km2' &&  sin === 'm2' ? Number((value * 1e+6).toFixed(nf)) :
        fin == 'km2' &&  sin === 'yd2' ? Number((value * 1.196e+6).toFixed(nf)) :
        fin == 'km2' &&  sin === 'ft2' ? Number((value * 1.076e+7).toFixed(nf)) :
        fin == 'km2' &&  sin === 'in2' ? Number((value * 1.55e+9).toFixed(10)) :
        fin == 'km2' &&  sin === 'ha' ? Number((value * 100).toFixed(10)) :
        fin == 'km2' &&  sin === 'acre' ? Number((value * 247).toFixed(10)) :

        fin == 'm2' &&  sin === 'km2' ? Number((value / 1e+6).toFixed(nf)) :
        fin == 'm2' &&  sin === 'yd2' ? Number((value * 1.19599).toFixed(nf)) :
        fin == 'm2' &&  sin === 'ft2' ? Number((value * 10.7639).toFixed(nf)) :
        fin == 'm2' &&  sin === 'in2' ? Number((value * 1550).toFixed(10)) :
        fin == 'm2' &&  sin === 'ha' ? Number((value / 1000).toFixed(10)) :
        fin == 'm2' &&  sin === 'acre' ? Number((value / 4047).toFixed(10)) :

        fin == 'yd2' &&  sin === 'm2' ? Number((value / 1.19599 ).toFixed(nf)) :
        fin == 'yd2' &&  sin === 'km2' ? Number((value / 1.196e+6).toFixed(nf)) :
        fin == 'yd2' &&  sin === 'ft2' ? Number((value * 9).toFixed(nf)) :
        fin == 'yd2' &&  sin === 'in2' ? Number((value * 1296).toFixed(10)) :
        fin == 'yd2' &&  sin === 'ha' ? Number((value / 11959.9).toFixed(10)) :
        fin == 'yd2' &&  sin === 'acre' ? Number((value / 4840).toFixed(10)) :

        fin == 'ft2' &&  sin === 'm2' ? Number((value / 10.764).toFixed(nf)) :
        fin == 'ft2' &&  sin === 'km2' ? Number((value / 1.076e+7).toFixed(nf)) :
        fin == 'ft2' &&  sin === 'yd2' ? Number((value / 9).toFixed(nf)) :
        fin == 'ft2' &&  sin === 'in2' ? Number((value * 144).toFixed(10)) :
        fin == 'ft2' &&  sin === 'ha' ? Number((value / 107639).toFixed(10)) :
        fin == 'ft2' &&  sin === 'acre' ? Number((value / 43560).toFixed(10)) :

        fin == 'in2' &&  sin === 'm2' ? Number((value / 1550).toFixed(nf)) :
        fin == 'in2' &&  sin === 'km2' ? Number((value / 1.55e+9).toFixed(nf)) :
        fin == 'in2' &&  sin === 'yd2' ? Number((value / 1296).toFixed(nf)) :
        fin == 'in2' &&  sin === 'ft2' ? Number((value / 144).toFixed(10)) :
        fin == 'in2' &&  sin === 'ha' ? Number((value / 1.55e+7).toFixed(10)) :
        fin == 'in2' &&  sin === 'acre' ? Number((value / 6.273e+6).toFixed(10)) :

        fin == 'ha' &&  sin === 'm2' ? Number((value * 10000).toFixed(nf)) :
        fin == 'ha' &&  sin === 'km2' ? Number((value / 100).toFixed(nf)) :
        fin == 'ha' &&  sin === 'yd2' ? Number((value * 11959.9).toFixed(nf)) :
        fin == 'ha' &&  sin === 'ft2' ? Number((value * 107639).toFixed(10)) :
        fin == 'ha' &&  sin === 'in2' ? Number((value * 1.55e+7).toFixed(10)) :
        fin == 'ha' &&  sin === 'acre' ? Number((value * 2.471).toFixed(10)) :

        fin == 'acre' &&  sin === 'm2' ? Number((value * 4046.86).toFixed(nf)) :
        fin == 'acre' &&  sin === 'km2' ? Number((value / 247).toFixed(nf)) :
        fin == 'acre' &&  sin === 'yd2' ? Number((value * 4840).toFixed(nf)) :
        fin == 'acre' &&  sin === 'ft2' ? Number((value * 43560).toFixed(10)) :
        fin == 'acre' &&  sin === 'in2' ? Number((value * 6.273e+6).toFixed(10)) :
        fin == 'acre' &&  sin === 'ha' ? Number((value / 2.471).toFixed(10)) :
    

        fin === sin  ? value :
            'Syntax Error';
            
        return unitMeasurement;
    },
    Volumen : function(value, fin, sin){
        var unitMeasurement = 
        fin == 'l' &&  sin === 'ml' ? Number((value * 1000).toFixed(nf)) :
        fin == 'l' &&  sin === 'm3' ? Number((value / 1000).toFixed(nf)) :
        fin == 'l' &&  sin === 'in3' ? Number((value * 61.0237).toFixed(nf)) :
        fin == 'l' &&  sin === 'ft3' ? Number((value / 28.317).toFixed(10)) :
        //fin == 'l' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'l' &&  sin === 'room' ? Number((value * 1.05668744586969).toFixed(10)) :
        fin == 'l' &&  sin === 'TT' ? Number((value / 3.785).toFixed(10)) :
        fin == 'l' &&  sin === 'T' ? Number((value / 4.546).toFixed(10)) :

        fin == 'ml' &&  sin === 'l' ? Number((value / 1000).toFixed(nf)) :
        fin == 'ml' &&  sin === 'm3' ? Number((value / 1e+6).toFixed(nf)) :
        fin == 'ml' &&  sin === 'in3' ? Number((value / 16.387).toFixed(nf)) :
        fin == 'ml' &&  sin === 'ft3' ? Number((value / 28317).toFixed(10)) :
        //fin == 'ml' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'ml' &&  sin === 'room' ? Number((value / 946).toFixed(10)) :
        fin == 'ml' &&  sin === 'TT' ? Number((value / 3785).toFixed(10)) :
        fin == 'ml' &&  sin === 'T' ? Number((value / 4546).toFixed(10)) :

        fin == 'm3' &&  sin === 'l' ? Number((value * 1000).toFixed(nf)) :
        fin == 'm3' &&  sin === 'ml' ? Number((value * 1e+6).toFixed(nf)) :
        fin == 'm3' &&  sin === 'in3' ? Number((value * 61023.7).toFixed(nf)) :
        fin == 'm3' &&  sin === 'ft3' ? Number((value * 35.3146412031104).toFixed(10)) :
        //fin == 'm3' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'm3' &&  sin === 'room' ? Number((value * 1056.69).toFixed(10)) :
        fin == 'm3' &&  sin === 'TT' ? Number((value * 264.172).toFixed(10)) :
        fin == 'm3' &&  sin === 'T' ? Number((value * 219.969).toFixed(10)) :

        fin == 'in3' &&  sin === 'l' ? Number((value / 61.024).toFixed(nf)) :
        fin == 'in3' &&  sin === 'ml' ? Number((value * 16.3871).toFixed(nf)) :
        fin == 'in3' &&  sin === 'm3' ? Number((value / 61024).toFixed(nf)) :
        fin == 'in3' &&  sin === 'ft3' ? Number((value / 1728).toFixed(10)) :
        //fin == 'in3' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'in3' &&  sin === 'room' ? Number((value / 57.75).toFixed(10)) :
        fin == 'in3' &&  sin === 'TT' ? Number((value / 231).toFixed(10)) :
        fin == 'in3' &&  sin === 'T' ? Number((value / 277).toFixed(10)) :

        fin == 'ft3' &&  sin === 'l' ? Number((value * 28.3168).toFixed(10)) :
        fin == 'ft3' &&  sin === 'ml' ? Number((value * 28316.8).toFixed(nf)) :
        fin == 'ft3' &&  sin === 'm3' ? Number((value / 35.315).toFixed(nf)) :
        fin == 'ft3' &&  sin === 'in3' ? Number((value * 1728).toFixed(nf)) :
        //fin == 'ft3' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'ft3' &&  sin === 'room' ? Number((value * 29.922).toFixed(10)) :
        fin == 'ft3' &&  sin === 'TT' ? Number((value * 7.48052).toFixed(10)) :
        fin == 'ft3' &&  sin === 'T' ? Number((value * 6.22884).toFixed(10)) :
        
        // fin == 'yd3' &&  sin === 'l' ? Number((value ).toFixed(10)) :
        // fin == 'yd3' &&  sin === 'ml' ? Number((value ).toFixed(nf)) :
        // fin == 'yd3' &&  sin === 'm3' ? Number((value ).toFixed(nf)) :
        // fin == 'yd3' &&  sin === 'in3' ? Number((value ).toFixed(nf)) :
        // fin == 'yd3' &&  sin === 'ft3' ? Number((value ).toFixed(10)) :
        // fin == 'yd3' &&  sin === 'room' ? Number((value ).toFixed(10)) :
        // fin == 'yd3' &&  sin === 'TT' ? Number((value ).toFixed(10)) :
        // fin == 'yd3' &&  sin === 'T' ? Number((value ).toFixed(10)) :

        fin == 'room' &&  sin === 'l' ? Number((value / 1.057).toFixed(10)) :
        fin == 'room' &&  sin === 'ml' ? Number((value * 946.353).toFixed(nf)) :
        fin == 'room' &&  sin === 'm3' ? Number((value / 1057).toFixed(nf)) :
        fin == 'room' &&  sin === 'in3' ? Number((value * 57.75).toFixed(nf)) :
        fin == 'room' &&  sin === 'ft3' ? Number((value / 29.922).toFixed(10)) :
        //fin == 'room' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'room' &&  sin === 'TT' ? Number((value / 4).toFixed(10)) :
        fin == 'room' &&  sin === 'T' ? Number((value / 4.804).toFixed(10)) :

        fin == 'TT' &&  sin === 'l' ? Number((value * 3.78541).toFixed(10)) :
        fin == 'TT' &&  sin === 'ml' ? Number((value * 3785.41).toFixed(nf)) :
        fin == 'TT' &&  sin === 'm3' ? Number((value / 264).toFixed(nf)) :
        fin == 'TT' &&  sin === 'in3' ? Number((value * 231).toFixed(nf)) :
        fin == 'TT' &&  sin === 'ft3' ? Number((value / 7.481).toFixed(10)) :
        //fin == 'TT' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'TT' &&  sin === 'room' ? Number((value * 4).toFixed(10)) :
        fin == 'TT' &&  sin === 'T' ? Number((value / 1.201 ).toFixed(10)) :

        fin == 'T' &&  sin === 'l' ? Number((value * 4.54609).toFixed(10)) :
        fin == 'T' &&  sin === 'ml' ? Number((value * 4546.09).toFixed(nf)) :
        fin == 'T' &&  sin === 'm3' ? Number((value / 220).toFixed(nf)) :
        fin == 'T' &&  sin === 'in3' ? Number((value * 277.419).toFixed(nf)) :
        fin == 'T' &&  sin === 'ft3' ? Number((value / 6.229).toFixed(10)) :
        //fin == 'T' &&  sin === 'yd3' ? Number((value ).toFixed(10)) :
        fin == 'T' &&  sin === 'room' ? Number((value * 4.8038).toFixed(10)) :
        fin == 'T' &&  sin === 'TT' ? Number((value * 1.20095).toFixed(10)) :

        fin === sin  ? value :
            'Syntax Error';
            
        return unitMeasurement;
    }
}