import { EN, ES } from '../Etiquetas/Labels'

export const FormEnglish = {
  ModalInfo:
    [
      {
        label: `${EN.new_ticket_form.name.title}:`,
        type: 'info',
        id: 'UserName',
        name: 'UserName',
        class: '_align _name',
        col: 'col-xs-6 col-md-6 col-lg-6'
        // icon: 'user'
        // 'title': 'Nombre',
        // 'class': 'col-md-6',
        // 'type': 'text'
      },
      {
        label: `${EN.new_ticket_form.rol.title}:`,
        type: 'info',
        id: 'Rol',
        name: 'Rol',
        class: '_align _name',
        col: 'col-xs-6 col-md-6 col-lg-6'
        // 'title': 'Rol',
        // 'class': 'col-md-6',
        // 'type': 'text'
      },
      {
        label: `${EN.new_ticket_form.email.title}:`,
        type: 'info',
        id: 'Email',
        name: 'Email',
        class: '_align _name',
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6'
        // 'title': 'Correo electronico',
        // 'class': 'col-md-6',
        // 'type': 'text'
      },
      {
        label: `${EN.new_ticket_form.phone.title}:`,
        type: 'text',
        id: 'Phone',
        name: 'Phone',
        class: '_align _name',
        disabled: false,
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.phone.placeholder}`,
        // icon: 'user'

        // 'title': 'Telefono',
        // 'class': 'col-md-5',
        // 'type': 'input',
        // 'placeholder': 'Telefono'
      },
      // {
      //   label: 'CC To',
      //   type: 'cc',
      //   id: 'ccto',
      //   name: 'ccto',
      //   class: '_align _name',
      //   colm: 12,
      //   description: 'Puede agregar un correo adicional para recibir notificaciones'
      // },
    ],
  ModalCCto:
    [
      {
        label: `${EN.new_ticket_form.ccto.name}:`,
        type: 'text',
        id: 'NameCC',
        name: 'NameCC',
        class: '_align _name',
        disabled: false,
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.ccto.placeholderName}`,
      },
      {
        label: `${EN.new_ticket_form.ccto.email}:`,
        type: 'text',
        id: 'EmailCC',
        name: 'EmailCC',
        class: '_align _name',
        disabled: false,
        // colm: 5,+
        col: 'col-xs-5 col-md-5 col-lg-5',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.ccto.placeholderEmail}`,
      },
    ],
  ModalSelect:
    [
      {
        'type': 'div',
        'name': 'hr',
        'class': 'col-md-12',
      },
      // {
      //   'title': 'CC to',
      //   'name': 'Nombre',
      //   'email': 'Correo electronico',
      //   'class': 'col-md-6',
      // },
      {
        label: `${EN.new_ticket_form.type.title}:`,
        type: 'combo',
        id: 'TypeTicketId',
        name: 'TypeTicketId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.type.placeholder}`,
        description: `${EN.new_ticket_form.type.help}`,

        // icon: 'user'        
        // 'title': 'Tipo',
        // 'help': 'help de type',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Tipo de Error',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'TypeError'
      },
      {
        label: `${EN.new_ticket_form.module.title}:`,
        type: 'combo',
        id: 'RequestModuleId',
        name: 'RequestModuleId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.module.placeholder}`,
        description: `${EN.new_ticket_form.module.help}`,
        // icon: 'user'

        // 'title': 'Modulo',
        // 'help': 'help de modulo',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Modulo',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'Modulo'
      },
      {
        label: `${EN.new_ticket_form.sub_module.title}:`,
        type: 'combo',
        id: 'RequestTypeSubModuleId',
        name: 'RequestTypeSubModuleId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.sub_module.placeholder}`,
        description: `${EN.new_ticket_form.sub_module.help}`,
        // icon: 'user'

        // 'title': 'Sub modulo',
        // 'help': 'help de sub modulo',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Sub Modulo',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'subModulo'
      },
      {
        label: `${EN.new_ticket_form.severity.title}:`,
        type: 'combo',
        id: 'CatSeverityId',
        name: 'CatSeverityId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.severity.placeholder}`,
        description: `${EN.new_ticket_form.severity.help}`,
        // icon: 'user'

        // 'title': 'Severidad',
        // 'help': 'help de serveridad',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Severidad',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'Severity'
      },
      {
        label: `${EN.new_ticket_form.request.title}:`,
        type: 'combo',
        id: 'Request',
        name: 'Request',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.request.placeholder}`,
        description: `${EN.new_ticket_form.request.help}`,
        // icon: 'user'        
        // 'title': 'Request',
        // 'help': 'help de Request',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Request',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'TypeErrorRequest'
      },
      {
        label: `${EN.new_ticket_form.request_type.title}:`,
        type: 'combo',
        id: 'RequestType',
        name: 'RequestType',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.request_type.placeholder}`,
        description: `${EN.new_ticket_form.request_type.help}`,
        // icon: 'user'


        // 'title': 'Request Type',
        // 'help': 'help de Request Type',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Request Type',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'TypeErrorRequestType',
      },
      // {
      //   'type': 'div',
      //   'name': 'hr',
      //   'class': 'col-md-12',
      // },
      {
        label: `${EN.new_ticket_form.subject.title}:`,
        type: 'text',
        id: 'Subjet',
        name: 'Subject',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${EN.new_ticket_form.subject.placeholder}`,
        description: `${EN.new_ticket_form.subject.help}`,
        // icon: 'user'

        // 'title': 'Subject',
        // 'help': 'help de comentarios',
        // 'type': 'input',
        // 'class': 'col-md-11',
        // 'placeholder': 'Subject'
      },
      {
        label: `${EN.new_ticket_form.description.title}:`,
        type: 'text-area',
        id: 'Description',
        name: 'Description',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        placeholder: `${EN.new_ticket_form.description.placeholder}`,
        description: `${EN.new_ticket_form.description.help}`,
        // icon: 'user'

        // 'title': 'description',
        // 'help': 'help de description',
        // 'class': 'col-md-11',
        // 'type': 'description'
      },
    ],
  DetailUser:
    [
      {
        label: `${EN.details.user.info.name}`,
        type: 'info',
        id: 'UserProject',
        name: 'UserProject',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.user.info.role}`,
        type: 'info',
        id: 'RolUserProject',
        name: 'RolUserProject',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.user.info.email}`,
        type: 'info',
        id: 'Email',
        name: 'Email',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.user.info.phone}`,
        type: 'info',
        id: 'Phone',
        name: 'Phone',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
    ],
  DetailTicketErr:
    [
      {
        label: `${EN.details.ticket.info.type}`,
        type: 'info',
        id: 'TypeTicket',
        name: 'TypeTicket',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.ticket.info.module}`,
        type: 'info',
        id: 'RequestModule',
        name: 'RequestModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.ticket.info.sub_module}`,
        type: 'info',
        id: 'RequestTypeSubModule',
        name: 'RequestTypeSubModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.ticket.info.creation_date}`,
        type: 'info',
        id: 'CreateDate',
        name: 'CreateDate',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.ticket.info.assignet_to}`,
        type: 'info',
        id: 'UserFirstLevel',
        name: 'UserFirstLevel',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.ticket.info.severity}`,
        type: 'tag',
        id: 'Severity',
        name: 'Severity',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${EN.details.ticket.info.description}`,
        type: 'info',
        id: 'Description',
        name: 'Description',
        class: '_align _name',
        disabled: false,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: 'Descripción Del Error',
      },
    ],
  DetailTicketRequest:
    [
      {
        label: `${EN.details.ticket.info.type}`,
        type: 'info',
        id: 'TypeTicket',
        name: 'TypeTicket',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${EN.details.ticket.info.RequestModule}`,
        type: 'info',
        id: 'RequestModule',
        name: 'RequestModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${EN.details.ticket.info.RequestTypeSubModule}`,
        type: 'info',
        id: 'RequestTypeSubModule',
        name: 'RequestTypeSubModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${EN.details.ticket.info.creation_date}`,
        type: 'info',
        id: 'CreateDate',
        name: 'CreateDate',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${EN.details.ticket.info.assignet_to}`,
        type: 'info',
        id: 'UserFirstLevel',
        name: 'UserFirstLevel',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${EN.details.ticket.info.severity}`,
        type: 'tag',
        id: 'Severity',
        name: 'Severity',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${EN.details.ticket.info.description}`,
        type: 'info',
        id: 'Description',
        name: 'Description',
        class: '_align _name',
        disabled: false,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: 'Descripción Del Error',
      },
    ],
};

export const FormEsp = {
  ModalInfo:
    [
      {
        label: `${ES.new_ticket_form.name.title}:`,
        type: 'info',
        id: 'UserName',
        name: 'UserName',
        class: '_align _name',
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6'
        // icon: 'user'

        // 'title': 'Nombre',
        // 'class': 'col-md-6',
        // 'type': 'text'
      },
      {
        label: `${ES.new_ticket_form.rol.title}:`,
        type: 'info',
        id: 'Rol',
        name: 'Rol',
        class: '_align _name',
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6'

        // 'title': 'Rol',
        // 'class': 'col-md-6',
        // 'type': 'text'
      },
      {
        label: `${ES.new_ticket_form.email.title}:`,
        type: 'info',
        id: 'Email',
        name: 'Email',
        class: '_align _name',
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6'
        // 'title': 'Correo electronico',
        // 'class': 'col-md-6',
        // 'type': 'text'
      },
      {
        label: `${ES.new_ticket_form.phone.title}:`,
        type: 'text',
        id: 'Phone',
        name: 'Phone',
        class: '_align _name',
        disabled: false,
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.phone.placeholder}`,
        // icon: 'user'

        // 'title': 'Telefono',
        // 'class': 'col-md-5',
        // 'type': 'input',
        // 'placeholder': 'Telefono'
      },
      // {
      //   label: 'CC To',
      //   type: 'cc',
      //   id: 'ccto',
      //   name: 'ccto',
      //   class: '_align _name',
      //   colm: 12,
      //   description: 'Puede agregar un correo adicional para recibir notificaciones'
      // },
    ],
  ModalCCto:
    [
      {
        label: `${ES.new_ticket_form.ccto.name}:`,
        type: 'text',
        id: 'NameCC',
        name: 'NameCC',
        class: '_align _name',
        disabled: false,
        // colm: 6,
        col: 'col-xs-6 col-md-6 col-lg-6',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.ccto.placeholderName}`,
      },
      {
        label: `${EN.new_ticket_form.ccto.email}:`,
        type: 'text',
        id: 'EmailCC',
        name: 'EmailCC',
        class: '_align _name',
        disabled: false,
        col: 'col-xs-5 col-md-5 col-lg-5',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.ccto.placeholderEmail}`,
      },
    ],
  ModalSelect:
    [
      {
        'type': 'div',
        'name': 'hr',
        'class': 'col-md-12',
      },
      // {
      //   'title': 'CC to',
      //   'name': 'Nombre',
      //   'email': 'Correo electronico',
      //   'class': 'col-md-6',
      // },
      {
        label: `${ES.new_ticket_form.type.title}:`,
        type: 'combo',
        id: 'TypeTicketId',
        name: 'TypeTicketId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.type.placeholder}`,
        description: `${ES.new_ticket_form.type.help}`,

        // icon: 'user'        
        // 'title': 'Tipo',
        // 'help': 'help de type',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Tipo de Error',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'TypeError'
      },
      {
        label: `${ES.new_ticket_form.module.title}:`,
        type: 'combo',
        id: 'RequestModuleId',
        name: 'RequestModuleId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.module.placeholder}`,
        description: `${ES.new_ticket_form.module.help}`,
        // icon: 'user'

        // 'title': 'Modulo',
        // 'help': 'help de modulo',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Modulo',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'Modulo'
      },
      {
        label: `${ES.new_ticket_form.sub_module.title}:`,
        type: 'combo',
        id: 'RequestTypeSubModuleId',
        name: 'RequestTypeSubModuleId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.sub_module.placeholder}`,
        description: `${ES.new_ticket_form.sub_module.help}`,
        // icon: 'user'

        // 'title': 'Sub modulo',
        // 'help': 'help de sub modulo',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Sub Modulo',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'subModulo'
      },
      {
        label: `${ES.new_ticket_form.severity.title}:`,
        type: 'combo',
        id: 'CatSeverityId',
        name: 'CatSeverityId',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.severity.placeholder}`,
        description: `${ES.new_ticket_form.severity.help}`,
        // icon: 'user'

        // 'title': 'Severidad',
        // 'help': 'help de serveridad',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Severidad',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'Severity'
      },
      {
        label: `${ES.new_ticket_form.request.title}:`,
        type: 'combo',
        id: 'Request',
        name: 'Request',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.request.placeholder}`,
        description: `${ES.new_ticket_form.request.help}`,
        // icon: 'user'        
        // 'title': 'Request',
        // 'help': 'help de Request',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Request',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'TypeErrorRequest'
      },
      {
        label: `${ES.new_ticket_form.request_type.title}:`,
        type: 'combo',
        id: 'RequestType',
        name: 'RequestType',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.request_type.placeholder}`,
        description: `${ES.new_ticket_form.request_type.help}`,
        // icon: 'user'


        // 'title': 'Request Type',
        // 'help': 'help de Request Type',
        // 'class': 'col-md-12',
        // 'type': 'select',
        // 'placeholder': 'Request Type',
        // 'icon': 'fas fa-info-circle',
        // 'id': 'TypeErrorRequestType',
      },
      // {
      //   'type': 'div',
      //   'name': 'hr',
      //   'class': 'col-md-12',
      // },
      {
        label: `${ES.new_ticket_form.subject.title}:`,
        type: 'text',
        id: 'Subjet',
        name: 'Subject',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: `${ES.new_ticket_form.subject.placeholder}`,
        description: `${ES.new_ticket_form.subject.help}`,
        // icon: 'user'

        // 'title': 'Subject',
        // 'help': 'help de comentarios',
        // 'type': 'input',
        // 'class': 'col-md-11',
        // 'placeholder': 'Subject'
      },
      {
        label: `${ES.new_ticket_form.description.title}:`,
        type: 'text-area',
        id: 'Description',
        name: 'Description',
        class: '_align _name',
        disabled: false,
        // colm: 12,
        col: 'col-xs-12 col-md-12 col-lg-12',
        placeholder: `${ES.new_ticket_form.description.placeholder}`,
        description: `${ES.new_ticket_form.description.help}`,
        // icon: 'user'

        // 'title': 'description',
        // 'help': 'help de description',
        // 'class': 'col-md-11',
        // 'type': 'description'
      },
    ],
  DetailUser:
    [
      {
        label: `${ES.details.user.info.name}`,
        type: 'info',
        id: 'UserProject',
        name: 'UserProject',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
        // colm_xs: 6,
        // colm_md: 3,
        // colm_lg: 2,
      },
      {
        label: `${ES.details.user.info.role}`,
        type: 'info',
        id: 'RolUserProject',
        name: 'RolUserProject',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
        // colm_xs: 6,
        // colm_md: 3,
        // colm_lg: 2,
      },
      {
        label: `${ES.details.user.info.email}`,
        type: 'info',
        id: 'Email',
        name: 'Email',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'

        // colm_xs: 6,
        // colm_md: 3,
        // colm_lg: 2,
      },
      {
        label: `${ES.details.user.info.phone}`,
        type: 'info',
        id: 'Phone',
        name: 'Phone',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
        // colm_xs: 6,
        // colm_md: 3,
        // colm_lg: 2,
      },
    ],
  DetailTicketErr:
    [
      {
        label: `${ES.details.ticket.info.type}`,
        type: 'info',
        id: 'TypeTicket',
        name: 'TypeTicket',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${ES.details.ticket.info.module}`,
        type: 'info',
        id: 'RequestModule',
        name: 'RequestModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${ES.details.ticket.info.sub_module}`,
        type: 'info',
        id: 'RequestTypeSubModule',
        name: 'RequestTypeSubModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${ES.details.ticket.info.creation_date}`,
        type: 'info',
        id: 'CreateDate',
        name: 'CreateDate',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${ES.details.ticket.info.assignet_to}`,
        type: 'info',
        id: 'UserFirstLevel',
        name: 'UserFirstLevel',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${ES.details.ticket.info.severity}`,
        type: 'tag',
        id: 'Severity',
        name: 'Severity',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2'
      },
      {
        label: `${ES.details.ticket.info.description}`,
        type: 'info',
        id: 'Description',
        name: 'Description',
        class: '_align _name',
        disabled: false,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: 'Descripción Del Error',
      },
    ],
  DetailTicketRequest:
    [
      {
        label: `${ES.details.ticket.info.type}`,
        type: 'info',
        id: 'TypeTicket',
        name: 'TypeTicket',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${ES.details.ticket.info.module}`,
        type: 'info',
        id: 'RequestModule',
        name: 'RequestModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${ES.details.ticket.info.sub_module}`,
        type: 'info',
        id: 'RequestTypeSubModule',
        name: 'RequestTypeSubModule',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${ES.details.ticket.info.creation_date}`,
        type: 'info',
        id: 'CreateDate',
        name: 'CreateDate',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${ES.details.ticket.info.assignet_to}`,
        type: 'info',
        id: 'UserFirstLevel',
        name: 'UserFirstLevel',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${ES.details.ticket.info.severity}`,
        type: 'tag',
        id: 'Severity',
        name: 'Severity',
        class: '_align _name',
        col: 'col-xs-6 col-md-3 col-lg-2',
      },
      {
        label: `${ES.details.ticket.info.description}`,
        type: 'info',
        id: 'Description',
        name: 'Description',
        class: '_align _name',
        disabled: false,
        col: 'col-xs-12 col-md-12 col-lg-12',
        eval: 'text',
        placeholder: 'Descripción Del Error',
      },
    ],
};