import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const opStatusMenu = [
  { label: 'Internacional', value: 'currentLabel' },
  { label: 'Aduana', value: 'currentLabel' },
  { label: 'Almacén', value: 'currentLabel' },
  { label: 'Integrada', value: 'currentLabel' },
];

function OperationStatus(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <ChatbotButtons buttons={opStatusMenu} botSteps={props} />
    </div>
  );
}

export default OperationStatus;
