import React from 'react';
import propTypes from 'prop-types';
import { FileExcelOutlined, SyncOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import axios from 'axios';

/**
 * Download Component
 * @param {*} props
 */
function DownloadComponent(props){
  const [active, setActive] = React.useState(false);

  async function Download(){
    try {
      setActive(true);
      const body = {
        data: props.data,
        options: {
          colorTabLetter: 'F5F5F0',
          colorTab: '0277bd',
          image: {
            name: 'roldan'
          },
        },
      };
      /**
       * keys - Columns keys to change the name.
       * headers - Header name for every column with respect to columns keys
       */
      if(props.keys) body.options.keys = props.keys;
      if(props.headers) body.options.headers = props.headers;

      const resp = await axios.post('https://servicereportapi.azurewebsites.net/report/dynamic/xlsx', body);
      if (resp.data.base64) {
        const linkSource = `data:application/xlsx;base64,${resp.data.base64}`;
        const fileName = props.fileName ? props.fileName+'.xlsx': (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';
        saveAs(linkSource, fileName);
        setActive(false);
      }
    }catch(error){
      setActive(false);
    }
  }

  return (
    <React.Fragment>
      {
        active ?
          <SyncOutlined style={{ color: '#2f89fc', fontSize: '2.5em' }} spin />:
          <FileExcelOutlined style={{ color: '#52c41a', fontSize: '2.5em'}} onClick={props.data ? Download: () => {}}/>

      }
    </React.Fragment>
  );
}

DownloadComponent.propTypes = {
  data: propTypes.array,
  fileName: propTypes.string
};
DownloadComponent.defaultProps = {
  data: [],
  fileName: ''
};

export default DownloadComponent;