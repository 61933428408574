import React from 'react';
import propTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';

function HeaderComponent(props){
  return(
    <Header as={props.as}>
      <Icon name={props.icon}/>
      <Header.Content>{props.title}</Header.Content>
    </Header>
  );
}

HeaderComponent.propTypes = {
  as: propTypes.string,
  icon: propTypes.string,
  title: propTypes.string
};

HeaderComponent.defaultProps = {
  as: 'h2',
  icon: '',
  title: ''
};

export default HeaderComponent;