import React, { Component, Suspense } from "react";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import { MyResponsivePieCanvas } from "../../Components/Graphics/DonutGraph";
import { MyResponsiveLine } from "../../Components/Graphics/ResponsiveLine";
import { MyResponsiveBarCanvas } from '../../Components/Graphics/BarChart';
import {Card, CardBody, CardText, Col, Row} from 'reactstrap';
import { Tab } from "semantic-ui-react";
import '../../../styles/customStyle.scss';

const Arrived = React.lazy(() => import('./arrived'));
const NextToArrive = React.lazy(() => import('./nextToArrive'));


class ContainerContol extends Component {
  state = {
    graphs: [],
    arribados: 0,
    dataDrawer: '',
    polyline: [],
    visible: false,
    data: [],
    dataOut: [],
    donutChart: [
      { id: "Cartagena", label: "Cartagena", value: 35, color: "#00a9ff" },
      { id: "Bogota", label: "Bogota", value: 30, color: "#67c7f7" },
      { id: "Buenaventura", label: "Buenaventura", value: 25, color: "#33adea" },
      { id: "Santa Marta", label: "Santa Marta", value: 25, color: "#85b6f2" }

    ],
    lineChartData: [{
      "id": "",
      "color": "hsl(182, 70%, 50%)",
      "data": [
        {
          "x": "sem1",
          "y": 147
        },
        {
          "x": "sem2",
          "y": 149
        },
        {
          "x": "sem3",
          "y": 148
        },
        {
          "x": "sem4",
          "y": 150
        },
      ]
    },
    ],
    barTable: [
      { status: "En Tiempo", Cancelled: 2, CancelledColor: "#51ba32" },
      { status: "Por Vencer", Cancelled: 5, CancelledColor: "#51ba32" },
      { status: "Devueltos a Tiempo", Cancelled: 3, CancelledColor: "#51ba32" },
      { status: "Devoluciones Vencidas", Cancelled: 4, CancelledColor: "#51ba32" },
    ],
    totalCards: [
      {
        title: 'lugar En Puerto',
        value: '536',
        icon: '',
        firstColor: {
          backgroundColor: '#2f80e7',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#7eb3f6',
          color: '#fff'
        }
      },
      {
        title: "En Transito a Destino",
        value: '350',
        icon: '',
        firstColor: {
          backgroundColor: '#7b42e5',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#a88aea',
          color: '#fff'
        }
      },
      {
        title: 'Llegada a Destino',
        value: '280',
        icon: '',
        firstColor: {
          backgroundColor: '#57b65b',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#83db86',
          color: '#fff'
        }
      },
      {
        title: 'Estado Devueltos',
        value: '280',
        icon: '',
        firstColor: {
          backgroundColor: '#e8ab27',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#e5be6b',
          color: '#fff'
        }
      }
    ],
    ProximosAarribar: [
      {
        title: 'Próximos a Arribar',
        value: '536',
        icon: '',
        firstColor: {
          backgroundColor: '#2f80e7',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#7eb3f6  ',
          color: '#fff'
        }
      }
    ],

  };

  componentDidMount = () =>{


    // {400} height={270}
    this.setState({graphs: [
      {
        title: 'Operaciones por Tráfico',
        type: 'line',
        data: this.state.lineChartData,
        width: this.calculateWidth(),
        height: "280"
      },
      {
        title: 'Contenedores por Puertos',
        type: 'pie',
        data: this.state.donutChart,
        width: this.calculateWidth(),
        height: "280"

      }
    ],
     graphsArribo: [
      {
        title: 'Contenedores por Estatus de Devolución',
        type: 'bar',
        data: this.state.barTable,
        width: this.calculateWidth(),
        height: "280"

      },
      {
        title: 'Contenedores por Puertos',
        type: 'pie',
        data: this.state.donutChart,
        width: this.calculateWidth(),
        height: "280"

      }
    ]
  })

    window.addEventListener('resize', () =>{
      let newGraphs = this.state.graphs;
      newGraphs.map((e) =>{
        e.width = this.calculateWidth();
        return e
      })
      this.setState({graphs: newGraphs})
    });
  }

  calculateWidth = () =>{
    if(window.innerWidth >= 1756){
      return (window.innerWidth / 3).toString()
    }
    else{
      return (window.innerWidth / 3.2).toString()
    }
  }

  toggleDrawerMap = data => evt => {
    if (evt) evt.preventDefault();
    this.handleSelectedFile(data.id);
    this.setState({ visible: true, dataDrawer: data, polyline: this.formatPolyLine(data.From, data.To) });

  };

  toggleDrawMap = data => {
    this.setState({ visible: true, dataDrawer: data, polyline: this.formatPolyLine(data.From, data.To) }, () => { this.handleSelectedFile(data.id) });
  }

  formatPolyLine = (from, to) => {
    let out = [
      { lat: parseFloat(from.Location.Latitude), lng: parseFloat(from.Location.Longitude) },
      { lat: parseFloat(to.Location.Latitude), lng: parseFloat(to.Location.Longitude) }
    ];
    return out;
  }

  render() {
    let { totalCards, ProximosAarribar ,graphs,graphsArribo} = this.state;




    let graphsTotal = this.state.arribados === 1 ? graphsArribo : graphs
    let totalCard = this.state.arribados === 1 ? totalCards : ProximosAarribar

    return (
      <ContentWrapper>
        <div className="content-heading">
          {/* ENCABEZADO  */}
          <div className="row">
            <em className="fas fa-user-secret mr-2"></em>
            Control de Contenedores
          </div>
        </div>
        {/* ENCABEZADO  */}
        <Col md={12}>
            <Row>
              <Col lg={2} md={2} sm={12} xs={12}>
                {/* <div className="col">
                  <div className="row"> */}
                  <Row>
                    {
                      totalCard.map((e, index) => {
                        return (
                          <Col md={12}>
                            <div key={index} className="card flex-row align-items-center align-items-stretch border-0">
                              <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={e.firstColor}>
                                {!e.icon ? <center>{e.value}</center> : <><em className={`fas fa-2x ${e.icon}`}></em></>}
                              </div>
                              <div className="col-8 rounded-right totalCardInfo" style={e.secondColor}>
                                <div style={{ fontSize: "14px", textAlign: 'center', marginTop: !e.icon ? "16px" : "" }}>{e.title}</div>
                                {!e.icon ? "" : <div style={{ fontSize: "18px" }}>
                                  {" "}
                                  <center style={{ fontSize: "20px", marginTop:"6px" }}><strong>{e.value}</strong></center>
                                </div>}
                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row>
                  {/* </div>
                </div> */}
              </Col>
              <Col>
                <Row>
                  {
                    graphsTotal.map((e, index) => {
                      let typeGraph = e.type === 'pie' ? (
                        <MyResponsivePieCanvas data={e.data} />) : e.type === 'line' ?
                        <MyResponsiveLine data={e.data} /> :
                        <MyResponsiveBarCanvas position="horizontal" data={e.data} />
                      return (
                        <Col md={6} lg={6} sm={12} xs={12}>
                          <Card className="graphcardsize">
                            <CardBody className="graphcardbody">
                              <CardText style={{textAlign: 'center',fontFamily:'unset', marginTop: '10px',fontWeight: "bold"}}>{e.title}</CardText>
                              {typeGraph}
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>
              </Col>
            </Row>
          <div style={{ height: "50px" }}></div>
          <Col md={12}>
            <Tab
              onTabChange={(event, data) => { this.setState({ arribados: data.activeIndex }) }}
              menu={{ secondary: true, className: 'myWrapped' }}
              menuPosition='right'
              panes={[
                { menuItem: 'Próximos a Arribar', render: () => <Suspense fallback={<div>Loading...</div>}><NextToArrive /></Suspense> },
                { menuItem: "Arribados", render: () => <Suspense fallback={<div>Loading...</div>}><Arrived /></Suspense> },
              ]} />
          </Col>
        </Col>
      </ContentWrapper>
    );
  }
}

export default ContainerContol;
