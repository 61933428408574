/**
 * Initial State
 */
export const init = () => {
  return {
    projectDetail: false,
    project: {
      name: '',
      client: '',
      logisticCost: 0
    }
  };
};