export const Services = {
   "CATALOGS_CHATBOT": {
      "path": "https://roldanapichatbot.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO GET PORT CATALOGS"
   },
   "SEND_MAIL_CHATBOT": {
      "path": "https://roldanapimail.azurewebsites.net",
      "method": "POST",
      "descrp": "SEND EMAILS TO USERS"
   },
   "OPERATION_CHATBOT": {
      "path": "https://roldanapichatbot.azurewebsites.net",
      "method": "POST",
      "descrp": "METHOD TO GET QUERY OPERATIONS"
   },
   "DOCS_CHATBOT": {
      "path": "https://roldanapidocument.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO GET QUERY DOCUMENTS"
   }
}