import React from "react";
import { Col } from "reactstrap";

export const Cards = props => {
  let {data, element} = props;

  return (
    <Col md={11} style={{align:"center"}}>
     <div key={element} className="card flex-row align-items-center align-items-stretch border-0">
        <div className="col-12 rounded-left rounded-right totalCardInfo" style={data.color}>
          <div style={{ fontSize: "14px", textAlign: "center", marginTop: "6px" }}>
            {data.title}
          </div>

          {
            data.title === 'Promedio en Sesión' 
            ?
            <>
              <div style={{ fontSize: "17px", textAlign: "center", marginTop: "4px", marginBottom: "0px" }}>
                <b>{data.value / 60 ? Math.round(data.value / 60 ) + " Hrs  " + data.value % 60 + " Min" : data.value % 60 + " Min"}</b>
              </div>
             {/* <div style={{ fontSize: "15px", textAlign: "center", marginBottom: "4px", color: data.color }}>
               <br/>
              </div>  */}
            </>  
            :
            <>
              <div style={{ fontSize: "17px", textAlign: "center", marginTop: "2px", marginBottom: "0px" }}>
                <b>{data.value + " " + data.label}</b>
              </div>

              {/* <div style={{ fontSize: "15px", textAlign: "center", marginBottom: "4px" }}>
                {data.label}
              </div> */}
            </> 
          }
        </div>
      </div>
    </Col>
  );
};
