import React, { Component, Suspense } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Row } from 'reactstrap';
import { Tab } from 'semantic-ui-react';
import Documents from '../../Components/Documents';
import Comments from '../../Components/Comments';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Services } from '../Services';
const Events = React.lazy(() => import('./Events'));
const SumaryDetail = React.lazy(() => import('./Sumary/SumaryDetail'));


class ShipmentDetail extends Component {
  state = {
    id: this.props.match.params.id,
    allSteps: [],
    commentsData: [{
      ComType: 'Tipo 1',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-06-20',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    },
    {
      ComType: 'Tipo 2',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-07-02',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    }],
    catalogsData:
      [
        { ComTypeId: 1, ModuleId: 1, ComType: 'Supplier Follow Up', value: 1, label: 'Supplier Follow Up' },
        { ComTypeId: 2, ModuleId: 1, ComType: 'Order Quality', value: 2, label: 'Order Quality' },
        { ComTypeId: 3, ModuleId: 1, ComType: 'Order Quantities', value: 3, label: 'Order Quantities' },
        { ComTypeId: 10, ModuleId: 1, ComType: 'Other', value: 10, label: 'Other' },
      ],
    steps: [
      {
        label: 'Carga Lista',
        value: '',
        id: 3,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Recolección / Recepción',
        value: '',
        id: 4,
        completed: false,
        order: 2,
        idStep: '',
        middleStep: ''
      },
      {
        label: 'ATD',
        value: '',
        id: 9,
        completed: false,
        order: 3,
        middle: false,
        idStep: '',
        middleStep: ''
      },
      {
        label: 'ATA',
        value: '',
        id: 11,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Salida del Puerto',
        value: '',
        id: 22,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: ''
      },
      {
        label: 'Destino Final',
        value: '',
        id: 23,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      }
    ],
  }


  componentDidMount = () => {
    this.getEvents();
    this.getShipmentsForId();
  }

  componentWillReceiveProps = (nextProps) => {
    const newProp = nextProps.match.params.id ? nextProps.match.params.id : this.props.match.params.id;
    this.setState({ id: newProp }, () => {
      this.getEvents();
      this.getShipmentsForId();
    });
  }

  getEvents = () => {
    const { steps, id } = this.state;
    let moduleId = 3;
    // let idContainer = 1816;
    axios.get(`${Services.Get_Events_General.path}/events/all/${moduleId}/${id}`)
      .then((response) => {
        let update = false;
        for (let index = response.data.steps.length; index >= 0; index--) {
          const element = response.data.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = steps;
        let dataEvents = response.data.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {

            if (e.EventDescriptionId === f.id) {
              findNext = true;
              f.value = e.EventDate;
              if (e.InTimeLine === '1') {
                f.completed = true;
              }
              else {
                findNext = false;
              }
            }
            else {
              findNext = false;
            }
            if (findNext) {
              f.completed = true;
            }
            return e;
          });
          return f;
        });
        newSteps.sort(function (a, b) { return b.order - a.order; });
        let findTrue = false;
        newSteps.map(e => {
          if (e.completed) {
            findTrue = true;
          }
          if (findTrue) {
            e.completed = true;
          }
          return e;
        });
        newSteps.sort(function (a, b) { return a.order - b.order; });

        newSteps.sort(function (a, b) { return a.order - b.order; });
        this.setState({ steps: newSteps, allSteps: dataEvents }, () => {
        });
      }).catch((error) => {
      });
  }

  getShipmentsForId = () => {
    axios.get(`${Services.Get_Traceability_Shipments_ForId.path}/shipments/dataShipments/${this.state.id}`).then(success => {
      let commentsData = [];
      let data = {
        ComType: '',
        ComTypeId: 1,
        Comment: success.data.data.infoEmbarque.Observaciones,
        CommentId: '',
        Date: success.data.data.infoEmbarque.Observaciones ? success.data.data.infoEmbarque.ETA : '',
        Module: 'Comentario',
        ModuleId: 1,
        RowId: '',
        UpdateBy: 'API',
      };
      commentsData.push(data);
      let Info = [];
      Info.push(success.data.data.infoEmbarque);
      this.setState({
        commentsData,
        DoEmbarque: success.data.data.infoEmbarque ? Info[0].DoEmbarque : '',
        HBL_HAWB: success.data.data.infoEmbarque ? Info[0].HBL_HAWB : '',
        Estatus: success.data.data.infoEmbarque ? Info[0].Estatus : '',
        ModoTransporte: success.data.data.infoEmbarque ? Info[0].ModoTransporte : '',
        cliente: success.data.data.infoEmbarque ? Info[0].Cliente : '',
        NIT: success.data.data.infoEmbarque ? Info[0].NIT : '',
        Grupo: success.data.data.infoEmbarque ? Info[0].Grupo : '',
      });
    }).catch(error => {
      console.warn(error);
    });
  }

  render() {
    let logoUrl = this.state.ModoTransporte === 'AEREA' ? 'https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png' : this.state.ModoTransporte === 'MARITIMA' ? 'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png' : 'https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png';
    return (
      <ContentWrapper>
        <div className="content-heading">
          <legend className='legendTitle'>
            <i className='fas fa-globe fa-1x mr-2' style={{ color: '#001489' }} />
            Detalle del Embarque
          </legend>
        </div>
        <Tab
          menu={{ secondary: true, className: 'wrapped' }}
          menuPosition='right'
          panes={[
            { menuItem: 'Resumen', render: () => <Suspense fallback={<div>Loading...</div>}><SumaryDetail steps={this.state.steps} /></Suspense> },
            { menuItem: 'Eventos', render: () => <Suspense fallback={<div>Loading...</div>}><Events cliente={this.state.cliente} NIT={this.state.NIT} Grupo={this.state.Grupo} logoUrl={logoUrl} DoEmbarque={this.state.DoEmbarque} HBL_HAWB={this.state.HBL_HAWB} Estatus={this.state.Estatus} allSteps={this.state.allSteps} steps={this.state.steps} /> </Suspense> },
            { menuItem: 'Comentarios', render: () => <div><Comments data={this.state.commentsData} catalogs={this.state.catalogsData} /></div> },
            { menuItem: 'Documentos', render: () => <div><Documents rolId={12} shipment="" shipmentDataPlane="" /></div> },
          ]} />
      </ContentWrapper>
    );
  }
}
export default withRouter(ShipmentDetail);
