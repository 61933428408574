import React from 'react';
import './styles.scss';
// import imagenLogin from './../../../resources/1A18.png';
import imagenLogin from '../../../resources/splashLogin.jpg';
import { Label, Input } from 'reactstrap';
import { Services } from './Services';
import axios from 'axios';
import nameRol4ll from '../../../resources/Login_Logo.png';
import { withRouter } from 'react-router-dom';

const BackgroundHead = {
  height: '100%',
  backgroundImage: 'url(' + imagenLogin + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 'calc(100vw + 48px)',
  margin: -24,
  padding: 24,
};
/*=============================================
=            Login Component                  =
=============================================*/
function Recover(props) {
  const [redirectlogin, setRedirectlogin] = React.useState({
    login: {
      newcontrasena: '',
      valid: false,
      confirmarcontrasena: '',
      check: false,
      email: '',
    },
  });
  let [Mostrar, setMostrar] = React.useState(false);
  let [exito, setexito] = React.useState(false);
  let [TokenValido, setTokenValido] = React.useState(true);

  const getAutentication = React.useCallback(() => {
    axios
      .get(
        `${Services.SetAutenticationrecover.path}/admin/recovery?token=${props.match.params.token}`,
        {}
      )
      .then((response) => {
        redirectlogin.login.email = response.data.email;
        setRedirectlogin({ redirectlogin, ...redirectlogin });
        setTokenValido(true);
      })
      .catch((error) => {
        console.error('error', error);
        setTokenValido(false);
      });
  }, [props.match.params.token]);

  React.useEffect(() => {

    getAutentication();
  }, [getAutentication]);

  function change(event) {
    document.getElementById('email_sin').setAttribute('hidden', 'hidden');
    document.getElementById('pass_sin').setAttribute('hidden', 'hidden');
    if (event.target.name === 'confirmarcontrasena') {
      let value = event.target.value;
      if (redirectlogin.login.newcontrasena === value) {
        document
          .getElementById('pass_diferentes')
          .setAttribute('hidden', 'hidden');
        document.getElementById('se_cumple').removeAttribute('hidden');
        document.getElementById('input_correo').className = 'input100';
      } else {
        document.getElementById('pass_diferentes').removeAttribute('hidden');
        document.getElementById('input_correo').className = 'invalidInput';
        document.getElementById('se_cumple').setAttribute('hidden', 'hidden');
      }
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    } else {
      let value = event.target.value;

      const emailRegex = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])([A-Za-z\d$@$!%*?&_-]|[^ ]){8,15}$/
      );
      if (emailRegex.test(value)) {
        document.getElementById('input_email').className = 'input100';
        document.getElementById('123456').setAttribute('hidden', 'hidden');
      } else {
        document.getElementById('input_email').className = 'invalidInput';
        document.getElementById('123456').removeAttribute('hidden');
      }
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    }
  }

  function handleLoginId() {
    if (!redirectlogin.login.newcontrasena) {
      document.getElementById('email_sin').removeAttribute('hidden');
    } else if (!redirectlogin.login.confirmarcontrasena) {
      document.getElementById('pass_sin').removeAttribute('hidden');
    } else {
      document.getElementById('email_sin').setAttribute('hidden', 'hidden');
      document.getElementById('pass_sin').setAttribute('hidden', 'hidden');
      let data = {
        email: redirectlogin.login.email,
        password: redirectlogin.login.newcontrasena,
      };

      axios
        .put(`${Services.changePassword.path}/admin/changePass`, data)
        .then((response) => {
          document.getElementById('pass_changue').removeAttribute('hidden');
          setexito(true);
        })
        .catch((error) => {
          console.error('error', error);
        });
    }
  }
  return (
    <>
      <div className="limiter" style={BackgroundHead}>
        <div className="container-login100">
          {TokenValido ? (
            <div
              className="wrap-login100 p-t-0 p-b-0"
              style={
                {
                  // width: '22em',
                  // marginTop: '9em',
                  // marginLeft: '5em',
                  // background: 'transparent',
                }
              }
            >
              <div>
                <div className="col-12">
                  {exito ? (
                    <label style={{ color: '#003F70', fontSize: '22px' }}>
                      Contraseña actualizada correctamente
                    </label>
                  ) : (
                      <>
                        <div style={{ height: '1em' }} className="col-12"></div>
                        <div className="col-12">
                          <center>
                            <img
                              // className="img-fluid"
                              style={{ marginBottom: '1em' }}
                              src={nameRol4ll}
                              alt="App Logo"
                              width={'240px'}
                              height={'120px'}
                            />
                          </center>
                        </div>
                        <label style={{ color: '#003F70', fontSize: '24px' }}>
                          Recuperar Contraseña
                    </label>
                        <label style={{ color: '#003F70', fontSize: '11px' }}>
                          La contraseña debe de estar compuesta por:
                    </label>
                        <label style={{ color: '#003F70', fontSize: '11px' }}>
                          * Mínimo 8 digitos y hasta máximo 15 digitos.
                    </label>
                        <label style={{ color: '#003F70', fontSize: '11px' }}>
                          * Al menos una letra mayúscula y una minúscula.
                    </label>
                        <label style={{ color: '#003F70', fontSize: '11px' }}>
                          * Un carácter especial (*!$%&?).
                    </label>
                        <div style={{ height: '1em' }} className="col-12"></div>
                      </>
                    )}
                </div>
              </div>
              <div style={{ height: 5 }} />
              {exito ? (
                <></>
              ) : (
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    <div
                      className="wrap-input100 validate-input m-b-12 "
                      data-validate="Usuario es Requerido"
                    >
                      <input
                        className="input100"
                        id="input_email"
                        type={Mostrar ? 'text' : 'password'}
                        value={redirectlogin.login.newcontrasena}
                        name="newcontrasena"
                        onChange={change}
                        placeholder="Nueva contraseña"
                      />
                      <span className="focus-input100"></span>
                    </div>
                    <div
                      className="wrap-input100 validate-input m-b-12"
                      data-validate="Contraseña es Requerida"
                    >
                      <input
                        id="input_correo"
                        className="input100"
                        type={Mostrar ? 'text' : 'password'}
                        value={redirectlogin.login.confirmarcontrasena}
                        name="confirmarcontrasena"
                        onChange={change}
                        placeholder="Confirmar contraseña"
                      />
                      <span className="focus-input100"></span>
                    </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="email_sin"
                      hidden
                    >
                      Contraseña es obligatoria
                </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="pass_sin"
                      hidden
                    >
                      Confirme la contraseña
                </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="pass_diferentes"
                      hidden
                    >
                      Las Contraseñas deben ser iguales
                </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="pass_changue"
                      hidden
                    >
                      Su contraseña ha sido cambiada exitosamente
                </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="123456"
                      hidden
                    >
                      Contraseña inválida
                </div>
                  </div>
                )}
              {exito ? (
                <></>
              ) : (
                  <div className=" col-6">
                    <div style={{ fontSize: '11px !important' }} className="col-12">
                      <Label>
                        <Input
                          type="checkbox"
                          onChange={() => {
                            setMostrar(!Mostrar);
                          }}
                          checked={Mostrar}
                        />
                    Mostrar
                  </Label>
                    </div>
                  </div>
                )}
              <div style={{ height: 5 }} />
              {exito ? (
                <></>
              ) : (
                  <div className="container-login100-form-btn">
                    <div className="col-6"></div>
                    <div className="col-6" style={{ textAlign: '-webkit-right' }}>
                      <button
                        id="se_cumple"
                        style={{ marginBottom: '1em' }}
                        hidden
                        className="login100-form-btn"
                        onClick={handleLoginId}
                      >
                        <strong>Cambiar</strong>
                      </button>
                    </div>
                  </div>
                )}
            </div>
          ) : (
              <div>
                <div style={{ height: '4em' }} className="col-12"></div>
                <div style={{ background: 'white', borderRadius: '1em' }} className="col-12">
                  <div style={{ height: '1em' }} className="col-12"></div>
                  <div className="col-12">
                    <center>
                      <img
                        // className="img-fluid"
                        style={{ marginBottom: '1em' }}
                        src={nameRol4ll}
                        alt="App Logo"
                        width={'240px'}
                        height={'120px'}
                      />
                    </center>
                  </div>
                  <div style={{ height: '1em' }} className="col-12"></div>
                  <label style={{ color: '#003F70', fontSize: '20px' }}>
                    Error: "El token ya se ha utilizado o ha caducado"{' '}
                  </label>
                  <div style={{ height: '1em' }} className="col-12"></div>
                </div>
              </div>
            )}
        </div>
        <div
          className="col-12"
          style={{ height: '5em' }}
        >
        </div>
      </div>
    </>
  );
}
export default withRouter(Recover);
