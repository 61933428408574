import React from 'react';
import DialogList from '../SecComponents/dialogList';

const availableReferences = {
  message: 'Por favor, indique alguna de las referencias:',
  optionList: [
    'MBL/AWB',
    'HBL/HAWB (Documento de Transporte)',
    'Número de Contenedor',
    'Referencia de Cliente',
    'DO (Número de Embarque)',
  ],
};

function International(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <DialogList references={availableReferences} />
    </div>
  );
}

export default International;
