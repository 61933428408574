import React from 'react';
import DialogList from '../SecComponents/dialogList';

const availableReferences = {
  message: 'Por favor, indique alguna de las referencias:',
  optionList: [
    'No. de DO',
    'Referencia de Cliente',
    'Documento de Transporte',
  ],
};

function AduanaDocumentos() {
  return (
    <div>
      <DialogList references={availableReferences} />
    </div>
  );
}

export default AduanaDocumentos;
