import React, { Component } from 'react';
import pubsub from 'pubsub-js';
import { Collapse } from 'reactstrap';

class SidebarUserBlock extends Component {

    state = {
        userBlockCollapse: true,
        username: this.props.User.SideBarConfig.UserName,
        userlastmane: this.props.User.SideBarConfig.UserNameLast,
        idRol: this.props.User.SideBarConfig.RoleId,
        Rol: this.props.User.SideBarConfig.Rol
    }

    componentDidMount() {
        this.pubsub_token = pubsub.subscribe('toggleUserblock', () => {
            this.setState({
                userBlockCollapse: !this.state.userBlockCollapse
            });
        });
    }

    componentWillUnmount() {
        pubsub.unsubscribe(this.pubsub_token);

        this.setState({
            userBlockCollapse: !this.state.userBlockCollapse
        });
    }

    render() {
        let { username, userlastmane, Rol } = this.state;
        return (
            <Collapse id="user-block" isOpen={this.state.userBlockCollapse}>
                <div>
                    <div style={{ textAlign: 'center' }} className="item user-block">
                        {/* User picture */}
                        <div className="user-block-picture">
                            <div className="user-block-status">
                                <img className="img-thumbnail rounded-circle" src="https://fsposeidon.blob.core.windows.net/files/user-default-grey.png" alt="Avatar" width="80" height="80" />
                                {/* <div className="circle bg-success circle-lg"></div> */}
                            </div>
                        </div>
                        {/* Name and Job */}
                        <div className="user-block-info">
                            <span className="user-block-name">Hola, {username} {userlastmane}</span>
                            <span className="user-block-role">{Rol}</span>
                        </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

export default SidebarUserBlock;
