import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import NoResultsFound from '../SecComponents/noResultsFound';
import CardContent from '../SecComponents/cardContent';
import { Card, Button, Grid } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Table from '../../DocumentManager/Custom/components/dataTablev1';
import { Services } from '../Services';

const noResultsNextSteps = {
  newSearch: 'Consultar estatus de operación',
  mainMenu: 'SecondaryMenu',
  rateService: 'CalificarServicio',
};

const avilableOptions = {
  Internacional: {
    Icon: 'ModoTransporte',
    Header: 'DoEmbarque',
    SubHeader: 'Estatus',
    Action: { path: 'externalPath', id: 'EmbarqueId' },
    val1: { title: 'Referencia de Cliente:', id: 'RefCliente' },
    val2: { title: 'Origen:', id: 'POL' },
    val3: { title: 'Destino:', id: 'POD' },
  },
  Aduana: {
    Icon: 'Aduana',
    Header: 'AduanaDo',
    SubHeader: 'Estatus',
    Action: { path: 'externalPath', id: 'externalId' },
    val1: { title: 'Referencia de Cliente:', id: 'RefCliente' },
    val2: { title: 'Doc. de Transporte:', id: 'DocTransporte' },
    val3: { title: 'cardTitle', id: 'CardUlt' },
  },
  Integrada: {
    Icon: 'ModoTransporte',
    Header: 'DoEmbarque',
    SubHeader: 'Estatus',
    Action: { path: 'externalPath', id: 'externalId' },
    val1: { title: 'Referencia de cliente:', id: 'NoPedido' },
    val2: { title: 'Origen:', id: 'origenCard' },
    val3: { title: 'Destino:', id: 'destinoCard' },
  },
  Almacen: {
    Icon: 'AlmacenType',
    Header: 'AlmacenDO',
    SubHeader: 'Estatus',
    Action: { path: 'externalPath', id: 'externalId' },
    val1: { title: 'Referencia de Cliente:', id: 'RefCliente' },
    val2: { title: 'Tipo de Almacén:', id: 'TipoAlmacen' },
    val3: { title: 'cardTitle', id: 'CardUlt' },
  },
};

const defaultStyles = {
  fontSize: '13px',
};

function ResultsOperationStatus(props) {
  const [thereAreResults, setThereAreResults] = React.useState(false);
  const [results, setResults] = React.useState('');
  const [singleResult, setSingleResult] = React.useState('');
  const [option, setOption] = React.useState('');

  const [loader, setLoader] = React.useState(true);

  const { previousStep } = props;
  const { id, value } = previousStep;

  const [open, setOpen] = React.useState(false);

  const [newCardResult, setNewCardResult] = React.useState([]);

  const search = async () => {
    let operationType;

    if (id === 'BusquedaInternacional') operationType = 'Internacional';
    if (id === 'BusquedaAduana') operationType = 'Aduana';
    if (id === 'BusquedaIntegrada') operationType = 'Integrada';
    if (id === 'BusquedaAlmacen') operationType = 'Almacen';

    const qSearch = value;
    const data = {
      cliente: JSON.stringify(props.clients),
      tipoOperacion: operationType,
    };

    setOption(operationType);

    const url = `${Services.OPERATION_CHATBOT.path}/chatbot?q=${qSearch}&op=${operationType}`;

    try {
      const getResults = await axios.post(url, data);

      if (!getResults.data.data.length) {
        setLoader(false);
        return setThereAreResults(true);
      }

      let opType;

      if (getResults.data.data.length === 1) {
        opType = getResults.data.data[0];
        setSingleResult(opType);
        setNewCardResult([...newCardResult, opType]);
      }

      if (getResults.data.data.length > 1) {
        opType = getResults.data.data;
        setResults(opType);
      }

      setLoader(false);
      props.triggerNextStep({
        value: ['Consultar estatus de operación', 'CalificarServicio'],
        trigger: 'RealizarOtraConsulta',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ERROR: ', error);
    }
  };

  const handleTypeOperation = React.useCallback(() => {
    search();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    handleTypeOperation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  const actionButton = () => {
    setOpen((prevState) => !prevState);
  };

  const handlerResponse = (value) => {

    setNewCardResult([...newCardResult, value]);

    setSingleResult(value);
    setOpen((prevState) => !prevState);
  };

  const colData = {
    Almacen: [
      { label: 'Tipo de operación', key: 'AlmacenType' },
      { label: 'No. DO', key: 'AlmacenDO' },
      { label: 'Almacen', key: 'Almacen' },
      { label: 'Referencia de Cliente', key: 'RefCliente' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ],
    Integrada: [
      { label: 'Tipo de operación', key: 'TypeName' },
      { label: 'Do Embarque', key: 'DoEmbarque' },
      { label: 'Referencia de Cliente', key: 'NoPedido' },
      { label: 'Modo de Transporte', key: 'ModoTransporte' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ],
    Internacional: [
      { label: 'No. Embarque', key: 'DoEmbarque' },
      { label: 'Estatus', key: 'Estatus' },
      { label: 'Ref. Cliente', key: 'RefCliente' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ],
    Aduana: [
      { label: 'Tipo de operación', key: 'Aduana' },
      { label: 'Aduana Do', key: 'AduanaDo' },
      { label: 'Referencia de Cliente', key: 'RefCliente' },
      { label: 'Doc. de Transporte', key: 'DocTransporte' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ],
  };

  const multipleresults = () => {
    return (
      <Card fluid>
        <Card.Content style={defaultStyles}>
          <Grid divided="vertically">
            <Grid.Column>
              <Grid.Row
                columns={1}
                style={{ fontSize: '12px', marginLeft: '10px' }}
              >
                Se encontrarón los siguientes resultados para &nbsp;
                <b> {` ${value}`}</b>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Card.Content>

        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              basic
              fluid
              compact
              color="grey"
              onClick={() => actionButton()}
              style={defaultStyles}
            >
              Ver lista de resultados
            </Button>
          </div>
        </Card.Content>


        <Modal isOpen={open} toggle={toggle} size="lg">
          <ModalHeader
            className="advancedSearchModal"
            style={{ background: '#003F70', color: 'white' }}
            toggle={toggle}
          >
            <b>Lista de resultados {` ${value}`}</b>
          </ModalHeader>
          <ModalBody>
            <Table columnsTable={colData[option]} data={results}></Table>
          </ModalBody>
        </Modal>


      </Card>
    );
  };

  const singleResults = () => {
    
    const listResults = newCardResult.map( element => 
      <>
        <CardContent
          data={element}
          option={option}
          avilableOptions={avilableOptions[option]}
          next={props}
        />
      </>
    );

    return listResults;

  };

  const showData = () => {
    return thereAreResults ? (
      <NoResultsFound botSteps={props} nextSteps={noResultsNextSteps} />
    ) : (
      ''
    );
  };

  return (
    <div className="row" style={{ width: '100%' }}>
      {loader ? <Loader active inline="centered" /> : showData()}
      {results.length > 1 ? multipleresults() : ''}
      { singleResult ? singleResults() : '' }
    </div>
  );
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsOperationStatus);
