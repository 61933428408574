import React from 'react';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const columns = (history, onDelete) => [
  { label: 'Nombre del Grupo', key: 'Grupo', custom: (group) => {
    return (
      <p className='mlink' onClick={() => history.push(`/group/edit/${group.GrupoId}?edit=true`)}>{group.Grupo}</p>
    );
  }},
  { label: 'Fecha de creación', key: 'FechaCreacion' },
  { label: 'Fecha de ultima modificacón', key: 'FechaActualizacion' },
  { label: 'Usuarios asociados', key: 'TotalUsuarios' },
  { label: '', key: 'delete', custom: (group) => {
    return (
      <Popconfirm
        title="¿Está seguro de querer eliminar el grupo?"
        onConfirm={() => onDelete(group.GrupoId)}
        okText="Si"
        cancelText="Cancelar"
      >
        <DeleteOutlined style={{fontSize: '24px', color: '#ff304f'}}/>
      </Popconfirm>
    );
  }}
];