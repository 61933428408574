import React, { Suspense } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import ContentWrapper from '../../template/Layout/ContentWrapper';
// import StandardReports from '../Components/Reports/StandardReports';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
const StandardReports = React.lazy(() => import('./StandardReports'));
const MyReports = React.lazy(() => import('./MyReports/List'));
const SharedReports = React.lazy(() => import('./SharedReports'));

/*=============================================
=            Reports && analytics            =
=============================================*/

function ReportAnalytics(props) {
  const tabs = [
    {
      menuItem: 'Reportes Estándar',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <StandardReports type="Standard" user={props._user} />
        </Suspense>
      ),
    },
    {
      menuItem: 'Mis Reportes',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <MyReports type="MyReports" user={props._user} />
        </Suspense>
      ),
    },
    {
      menuItem: 'Reportes Compartidos',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <SharedReports type="SharedReports" user={props._user} />
        </Suspense>
      ),
    },
  ];
  // ? Validación por roles de usuario
  // if(props._rolId === '1' || props._rolId === '4'){
  //   tabs.push({ menuItem: 'Mis Reportes', render: () => <Suspense fallback={<div>Loading...</div>}> <MyReports type="MyReports" user={props._user}/></Suspense>});
  //   tabs.push({ menuItem: 'Reportes Compartidos', render: () => <Suspense fallback={<div>Loading...</div>}> <SharedReports type="SharedReports" user={props._user}/></Suspense>});
  // }

  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend className="legendTitle">
          <img
            src="https://fsposeidon.blob.core.windows.net/roldan/icon_reports.png"
            height="28"
            width="28"
          ></img>
          Mis Reportes
        </legend>
      </div>
      <Card className="myCard">
        <CardBody>
          <Tab
            menu={{ secondary: true, className: 'myWrapped' }}
            //       // onTabChange={this.onChangeTab}
            //       // activeIndex={tabIndex}
            menuPosition="right"
            panes={tabs}
          />
        </CardBody>
      </Card>
    </ContentWrapper>
  );
}

export default withRouter(ReportAnalytics);
