import React, { Component } from 'react';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { Card, CardBody, Col } from 'reactstrap';


class Line extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      items: ''
    };
  }

  componentDidMount = () => {
    this.setState({
      comments: this.props.comments
    }, () => this.BuildComments());
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      comments: nextProps.comments
    }, () => this.BuildComments());
  }

  BuildComments = () => {
    let { comments } = this.state;
    let items = comments.map((e, index) => {
      return (

        <TimelineItem
          key={index}
          dateText={e.Date}
          dateInnerStyle={{ background: '#1D5E97', fontWeight: 'bold', fontSize: '13px' }} >
          <Card className="myCard">
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <legend style={{ fontSize: '14px', fontWeight: 'bold', }} >{e.Module}</legend>
                </div>
                <div className="col-12">
                  <span style={{ fontWeight: 'bold', fontSize: '12px !important' }}>{e.ComType}</span>
                </div>
                <div className="col-12">
                  <span style={{ fontSize: '12px !important' }}>{e.Comment}</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </TimelineItem>

      );
    });
    this.setState({
      items
    });
  }
  render() {
    return (<>
      {/* // <Card className="myCard"> */}
      {/* <CardBody style={{overflow:"auto",height:"34rem"}}> */}
      <Timeline lineColor={'#fff'}>
        {this.state.items}
      </Timeline>
      {/* </CardBody> */}
      {/* // </Card> */}
    </>
    );
  }
}

export default Line;

