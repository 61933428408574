import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core/';
import { Card, CardBody } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { Button } from 'reactstrap';
import Table from '../../../../Components/Table';
import axios from 'axios';
import { Services } from '../../../Services';
import ListGroup from './listgroup';
import Errors from '../../../../Components/Errors';

class createGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alldata: [],
      selectcheck: true,
      modal: false,
      dataDocuments: [],
      dataReport: {},
      flatNext: false,
      reset: false,
      UserList: [],
      newSelected: [],
      mainColumns: [],
      clientesAsociados: [],
      roles: [],
      CustomerId: '',
      CreateFormData: [],
      CreateForAdd: [],
      Form: this.props.form,
      selected: [],
      data: [],
      error: {
        open: false,
        message: '',
        type: 'admin',
      },
      user: {
        Name: '',
      },
      columns: [
        {
          label: 'ID',
          key: 'ClienteId',
          custom: (value) => (
            <em
              onClick={() =>
                this.setState({ active: !this.state.active }, () => {})
              }
            >
              {' '}
              <label style={{}}>{value.ClienteId}</label>{' '}
            </em>
          ),
        },
        { label: 'Nombre del Cliente', key: 'Nombre' },
        { label: 'NIT', key: 'NIT' },
        { label: 'Teléfono', key: 'Telefono' },
        { label: 'País', key: 'Pais' },
        { label: 'Logo', key: '' },
        { label: 'Acción', key: '', custom: (value) => this.custom1(value) },
      ],
      columnsAsociados: [
        {
          label: 'ID',
          key: 'ClienteId',
          custom: (value) => (
            <em
              onClick={() =>
                this.setState({ active: !this.state.active }, () => {})
              }
            >
              {' '}
              <label style={{}}>{value.ClienteId}</label>{' '}
            </em>
          ),
        },
        { label: 'Nombre del Cliente', key: 'Nombre' },
        { label: 'NIT', key: 'NIT' },
        { label: 'Teléfono', key: 'Telefono' },
        { label: 'País', key: 'Pais' },
        { label: 'Logo', key: '' },
        { label: 'Acción', key: '', custom: (value) => this.custom2(value) },
      ],
      errorValidate: false,
    };
  }

  componentDidMount = () => {
    let { customerList } = this.props;
    let newListClient = [];
    customerList.map((f) => {
      if (f.GrupoId === null || f.GrupoId === '' || f.GrupoId === 0) {
        newListClient.push(f);
      }
      return f;
    });
    this.setState(
      {
        data: newListClient,
        alldata: customerList,
      },
      () => {
        this.getGroups();
        this.buildFormCreate();
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    let newListClient = [];
    nextProps.customerList.map((f) => {
      if (f.GrupoId === null || f.GrupoId === '' || f.GrupoId === 0) {
        newListClient.push(f);
      }
      return f;
    });
    this.setState(
      {
        data: newListClient,
        newSelected: [],
        selectcheck: false,
        Form: nextProps.form,
        reset: true,
      },
      () => {
        this.getRoles();
        this.getGroups();
      }
    );
  };

  custom1 = (value) => (
    <em
      class="fa-2x mr-2 fas fa-plus-circle"
      onClick={this.addClientGroup(value)}
      style={{ color: 'rgb(0, 121, 184)', marginTop: '5px' }}
    ></em>
  );
  custom2 = (value) => (
    <em
      class="fa-2x mr-2 fas fa-minus-circle"
      onClick={this.deleteClient(value)}
      style={{ color: 'rgb(0, 121, 184)', marginTop: '5px' }}
    ></em>
  );

  addClientGroup = (index) => (event) => {
    let { clientesAsociados } = this.state;
    let newClietAsociados = clientesAsociados;
    newClietAsociados.push(index);
    let deleteValue = index;
    let itemNew = this.state.data.filter((e) => {
      if (e.ClienteId !== deleteValue.ClienteId) {
        return e;
      }
      return 0;
    });
    this.setState({
      clientesAsociados: newClietAsociados,
      data: itemNew,
    });
  };

  deleteClient = (index) => (event) => {
    let deleteValue = index;
    let itemNew = this.state.clientesAsociados.filter((e) => {
      if (e.ClienteId !== deleteValue.ClienteId) {
        return e;
      }
      return 0;
    });
    let newClietAsociados = this.state.data;
    newClietAsociados.push(index);
    newClietAsociados.sort(function(a, b) {
      return a.ClienteId - b.ClienteId;
    });
    this.setState({
      clientesAsociados: itemNew,
      data: newClietAsociados,
    });
  };

  getGroups = (save) => {
    axios
      .get(`${Services.GET_LIST_GROUP.path}/admin/Getgroup`, {})
      .then((response) => {
        if (save) {
          this.setState({
            UserList: response.data.data,
          });
        } else {
          this.setState({
            UserList: response.data.data,
            reset: false,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getRoles = () => {
    axios
      .get(`${Services.GetRole.path}/admin/roles`, {})
      .then((response) => {
        this.setState({ roles: response.data.data }, () => {
          this.buildFormCreate();
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  validateSaveUser = (e) => (evt) => {
    let validation = [];
    let errors = '';
    let Form = this.props.form.createGroup;
    Form.map((e) => {
      if (e.required === true) {
        validation.push(e);
      }
      return e;
    });
    validation.forEach((e) => {
      if (this.state.user[e.id] === '') {
        errors += e.label + ', ';
        document.getElementById(e.id).style.color = 'red';
      }
    });
    if (errors.length > 0) {
      this.setState(
        {
          submitt: true,
        },
        () => {
          this.buildFormCreate();
        }
      );
      this.setState({
        error: {
          message: 'Campos requeridos: ' + errors + '',
          open: true,
          type: 'error',
        },
      });
    } else {
      this.saveUser();
    }
  };

  saveUser = () => {
    let data = {
      data: this.state.user,
      items: this.state.clientesAsociados,
    };
    axios
      .post(`${Services.POST_LIST_GROUP.path}/admin/createGroup`, data)
      .then((response) => {
        this.setState(
          {
            clientesAsociados: [],
            user: {
              Name: '',
            },
          },
          () => {
            this.buildFormCreate();
            this.props.getlist();
            this.props.onError({
              error: {
                message: 'El Grupo se ha guardado correctamente!',
                open: true,
                type: 'admin',
              },
            });
          }
        );
      })
      .catch((error) => {
        this.setState({
          error: {
            message: 'Dato incorrecto, favor de verificar',
            open: true,
            type: 'error',
          },
        });
      });
  };

  PutUser = (dataUserPut) => {
    axios
      .put(`${Services.PUT_GROUP_ID.path}/admin/updateGroup`, dataUserPut)
      .then((response) => {
        this.setState(
          {
            error: {
              message: 'El Grupo de ha guardado correctamente!',
              open: true,
              type: 'admin',
            },
            modal: false,
            errorValidate: false,
          },
          () => {
            this.getGroups();
            this.buildFormCreate();
            this.props.getlist();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  handleChange = (name) => (event) => {
    document.getElementById(name).style.color = 'black';
    let { user } = this.state;
    user[name] = event.target.value;
    this.setState(
      {
        user,
      },
      () => {
        this.buildFormCreate();
      }
    );
  };

  handleChangeSelect = (selected) => (event) => {
    document.getElementById(selected).style.color = 'black';
    if (event && event.value) {
      let { user } = this.state;
      user[selected] = event.value;
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    } else {
      let { user } = this.state;
      user[selected] = '';
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
  };

  buildFormCreate = () => {
    const { Form, user, roles } = this.state;
    let CreateFormData = Form.createGroup.map((e, i) => {
      if (e.type === 'Input')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {' '}
              <span id={e.id} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}
                </label>
              </span>
            </Fade>
            <Input
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={user[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === 'Select')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.id} style={e.style}>
                {' '}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}{' '}
                </label>
              </span>
            </Fade>
            <Select
              required
              type={e.datatype}
              name={e.name}
              value={user[e.id]}
              placeholder="Elegir..."
              onChange={this.handleChangeSelect(e.id)}
              options={roles}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      return e;
    });
    this.setState({
      CreateFormData,
    });
  };
  recieve = (array) => {
    this.setState({
      newSelected: array,
    });
  };

  dataReport = (data, edit) => {
    let dataReport = { data: data, userId: this.props._user };
    this.setState({
      dataReport,
    });
  };

  dataDocuments = (dataPo, dataship, edit) => {
    let array = [];
    dataPo.map((e) => {
      if (e.check) {
        let data = {
          DocCategoryId: e.DocCategoryId,
          ModuleId: e.ModuleId,
          UserId: this.props._user,
        };
        array.push(data);
      }
      return e;
    });
    dataship.map((e) => {
      if (e.check) {
        let data = {
          DocCategoryId: e.DocCategoryId,
          ModuleId: e.ModuleId,
          UserId: this.props._user,
        };
        array.push(data);
      }
      return e;
    });
    this.setState({
      dataDocuments: array,
    });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    let { clientesAsociados } = this.state;
    return (
      <>
        <Errors
          open={this.state.error.open}
          type={this.state.error.type}
          message={this.state.error.message}
          setProps={this.recieveProps}
        ></Errors>
        <Card>
          <CardBody className="myCard">
            <ExpansionPanel elevation={0} defaultExpanded>
              <div className="col-md-12">
                <strong style={{ marginLeft: '-12px' }}>
                  Información del Grupo
                </strong>
                <br></br>
              </div>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col-md-12">
                    <div className="row">{this.state.CreateFormData}</div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {/* {this.state.flatNext ?
              <div className="col-md-12"><ReportAsing dataDocuments={this.dataDocuments} dataReport={this.dataReport} columns={this.state.columns} newSelected={this.state.newSelected}></ReportAsing></div>
              : */}
            {/* <> */}
            <div className="col-md-12">
              <strong style={{ marginLeft: '-12px', color: 'black' }}>
                Seleccionar los Cliente del Grupo
              </strong>
              <br></br>
              <br></br>
            </div>
            <Table
              columns={this.state.columns}
              data={this.state.data}
              itemsByPage={5}
              options={{
                large: true,
                search: true,
                pagination: true,
                downloads: {
                  pdf: false,
                  xlsx: false,
                },
              }}
            />
            <div className="col-md-12">
              <strong style={{ marginLeft: '-12px', color: 'black' }}>
                Clientes Asociados
              </strong>
              <br></br>
              <br></br>
            </div>
            <Table
              columns={this.state.columnsAsociados}
              data={clientesAsociados}
              itemsByPage={5}
              options={{
                large: true,
                pagination: true,
                downloads: {
                  pdf: false,
                  xlsx: false,
                },
              }}
            />
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: 'center' }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="danger"
                  className="button"
                  onClick={() => {
                    this.setState(
                      {
                        reset: true,
                        flatNext: false,
                        user: {
                          Name: '',
                          LastName: '',
                          Position: '',
                          Email: '',
                          Password: '',
                          RoleId: '',
                          Telephone: '',
                        },
                        newSelected: [],
                      },
                      () => {
                        let Form = this.props.form.CreateUser;
                        Form.map((e) => {
                          document.getElementById(e.id).style.color = 'black';
                          return e;
                        });
                        this.buildFormCreate();
                        this.setState({ reset: false });
                      }
                    );
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button
                  variant="contained"
                  disabled={clientesAsociados.length <= 0 ? true : false}
                  color="primary"
                  className="button"
                  onClick={this.validateSaveUser()}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
        <ListGroup
          errorValidate={this.state.errorValidate}
          userId={this.props._user}
          modal={this.state.modal}
          dataDocuments={this.dataDocuments}
          dataReport={this.dataReport}
          saveUser={this.PutUser}
          onError={this.onError}
          deleteGroup={this.props.deleteGroup}
          customerList={this.state.data}
          customerListall={this.state.alldata}
          Roles={this.state.roles}
          Form={this.state.Form}
          UserList={this.state.UserList}
        ></ListGroup>
      </>
    );
  }
}

export default createGroups;
