import React, { Component } from 'react';
import ContentWrapper from '../../template/Layout/ContentWrapper.js';
import { Tab } from 'semantic-ui-react';
import { Card, CardBody, CardText, Row, Col } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';

import { MyResponsivePieCanvas } from '../Components/Graphics/DonutGraph.js';
import { MyResponsiveBarCanvas2 } from '../Components/Graphics/BarChart2.js';
import { MyResponsiveBarCanvas } from '../Components/Graphics/BarChart.js';
import Map from '../Components/Maps/map.js';
import IngresosCard from './Components/Ingresos.js';
import DespachosCard from './Components/Despachos.js';
import Loader from '../../Global/Loader/index.js';
import '../../styles/customStyle.scss';
import { Services } from './Services.js';
// import ClientesList from '../../../services/storageClient.js';

// const clientSave = new ClientesList();

class Storage extends Component {
  state = {
    // clientesSelect: clientSave.getAuthentication('client'),
    clientesSelect: this.props.clients ? JSON.stringify(this.props.clients) : '[0]',
    totalAlmacen: 0,
    totalIngresos: 0,
    totalDespachos: 0,
    zoom: 5,
    position: {
      lat: 4.0000000,
      lng: -72.0000000
    },
    infoMap: {},
    markers: [],
    dataMap: [],
    graphs: [],
    // data: mapJson.dataTable,
    dataDrawer: '',
    // dataMap: mapJson.dataMap,
    donutChart: [
      { id: 'Bodega Nacional', label: 'Terrestre', value: 35, color: '#00a9ff' },
      { id: 'Depósito Habilitado', label: 'Aereo', value: 30, color: '#67c7f7' },
      { id: 'Zona Franca', label: 'FCL', value: 25, color: '#33adea' },
    ],
    donutChartTrafico: [
      { id: 'Zona Franca', label: 'Expo', value: 30, color: '#0bc637' },
      { id: 'Depósito Habilitado', label: 'Trinagulado', value: 40, color: '#149e16' },
      { id: 'Bodega Nacional', label: 'Inpo', value: 30, color: '#39ef64' },
    ],
    barTable: [
      { status: 'Arribo', Cancelled: 2, CancelledColor: '#efbc04' },
      { status: 'Zarpo', Cancelled: 5, CancelledColor: '#efbc04' },
      { status: 'Carga Lista', Cancelled: 5, CancelledColor: '#efbc04' },
    ],
    visible: false
  }

  componentDidMount = () => {
    this.getChartsData();
    this.getLocationCustom();
    window.addEventListener('resize', () => {
      let newGraphs = this.state.graphs;
      newGraphs.map((e) => {
        e.width = this.calculateWidth();
        return e;
      });
      this.setState({ graphs: newGraphs });
    });
  }

  getChartsData = () => {
    let data = {
      cliente: this.state.clientesSelect
    };
    axios.post(`${Services.Get_Charts_IncomeOffice.path}/storage/dataCharts`, data).then(success => {
      this.setState({
        totalIngresos: success.data.data.totalIncome,
        totalDespachos: success.data.data.totalOffice,
        graphs: [
          {
            title: 'Operaciones por Estatus Ingresos',
            type: 'bar',
            data: success.data.data.chartIncome,
            width: this.calculateWidth(),
            height: '280',
            class: 6,
          },
          {
            title: 'Operaciones por Estatus Despachos',
            type: 'bar',
            data: success.data.data.chartOffice,
            width: this.calculateWidth(),
            height: '280',
            class: 6,
            left: 100
          },
          // {
          //   title: 'Operaciones por Estatus',
          //   type: 'bar',
          //   data: this.state.barTable,
          //   width: this.calculateWidth(),
          //   height: '280'
          // },
          {
            id: 'Week',
            title: 'Operaciones por Tipos de Almacén',
            keys: ['Ingreso', 'Despacho'],
            colors: ['#2B98F0', '#50AE55'],
            data: success.data.data.chartTypeStoage,
            class: 12,
            left: 130
          }
        ],
        fullLoader: true,
      })
    }).catch(error => {
      console.warn(error);
    });
  }

  getLocationCustom = () => {
    let data = {
      cliente: this.state.clientesSelect
    };
    axios.post(`${Services.Get_Location_Almace.path}/storage/locationAlmacen`, data).then(success => {
      let newMarkers = [];
      let dataMap = [];
      let infoMap = {};
      success.data.data.map((e, index) => {
        let obj = {
          key: `${index}`,
          title: e.Almacen,
          name: e.Almacen,
          transacciones: e.Almacen,
          ingresos: e.TotalIngresos,
          Despachos: e.TotalDespachos,
          inventario: e.TotalInventario,
          position: {
            lat: Number(e.Latitud),
            lng: Number(e.Longitud)
          },
          icon: {
            url: 'https://fsposeidon.blob.core.windows.net/roldan/icon_almacen.png'
          },
          data: [
            {
              data: (<img style={{ marginTop: '-7px' }} alt="" src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png" height="40" width="40"></img>),
              img: false,
              title: 'ALMACEN',
              value: e.Almacen,
              key: 'name'
            },
            {
              data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: '#00D8FF' }}></em>),
              img: false,
              title: 'Transacciones de Ingresos',
              value: e.Almacen,
              key: 'name'
            },
            {
              data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: '#00D8FF' }}></em>),
              img: false,
              title: 'Transacciones de Despachos',
              value: e.Almacen,
              key: 'despachos'
            },
          ],
          config: {
            button: true,
            link: '/storage-entries-detail/',
            textButton: 'Ver Inventario'
          }
        };
        infoMap = {
          data: [
            {
              data: (<img style={{ marginTop: '-7px' }} alt="" src="https://fsposeidon.blob.core.windows.net/roldan/icon_almacen.png" height="30" width="30"></img>),
              img: false,
              title: 'ALMACEN',
              value: e.Almacen,
              key: 'name'
            },
            {
              data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: '#001489' }}></em>),
              img: false,
              title: 'Transacciones de Ingresos',
              value: e.TipoAlmacen,
              key: 'ingresos'
            },
            {
              data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: '#582C83' }}></em>),
              img: false,
              title: 'Transacciones de Despachos',
              value: e.TotalDespachos,
              key: 'Despachos'
            },
          ],
          config: {
            button: true,
            link: '/storage-entries-detail/',
            textButton: 'Ver Inventario'
          }
        };

        let position = {
          lat: Number(e.Longitud),
          lng: Number(e.Latitud)
        };
        newMarkers.push(obj);
        dataMap.push(position);
        return e;
      });
      this.setState({
        markers: newMarkers,
        dataMap: dataMap,
        infoMap: infoMap,
        totalAlmacen: success.data.data.length,
      });
    }).catch(error => {
      console.warn(error);
    });
  }

  calculateWidth = () => {
    if (window.innerWidth >= 1756) {
      return (window.innerWidth / 4.8).toString();
    }
    else {
      return (window.innerWidth / 5.2).toString();
    }
  }

  render() {
    let { dataDrawer, infoMap, markers, position, zoom, graphs, fullLoader } = this.state;
    let totalCards = [
      {
        title: 'Total de Almacenes',
        value: (Number(this.state.totalAlmacen).toLocaleString('En-us')),
        icon: 'fa-arrow-alt-circle-right',
        firstColor: {
          backgroundColor: '#9e9e9e ',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#e0e0e0',
          color: '#fff'
        }
      },
      {
        title: 'Total de Ingresos',
        value: (Number(this.state.totalIngresos).toLocaleString('En-us')),
        icon: 'fa-arrow-alt-circle-right',
        firstColor: {
          backgroundColor: '#57b65b',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#83db86',
          color: '#fff'
        }
      },
      {
        title: 'Total de Despachos',
        value: (Number(this.state.totalDespachos).toLocaleString('En-us')),
        icon: 'fa-arrow-alt-circle-left',
        firstColor: {
          backgroundColor: '#2f80e7',
          color: '#fff'
        },
        secondColor: {
          backgroundColor: '#7eb3f6 ',
          color: '#fff'
        }
      }
    ];
    let polylineData = [];
    return (
      <ContentWrapper>
        <Loader hidden={fullLoader}></Loader>
        <div className="content-heading">
          <legend className="legendTitle">
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/icon_almacen.png"
              height="28"
              width="28"
            />
            Almacenamiento
          </legend>
        </div>
        <Row>
          <Col xs={12} md={12} lg={12} >
            <div className='row'>
              {totalCards.map((e, index) => {
                return (
                  <div key={index} className="card flex-row align-items-center align-items-stretch border-0 col-xs-12 col-sm-4 col-md-3 col-lg-2">
                    <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={e.firstColor}>
                      <em className={`fas fa-2x ${e.icon}`}></em>{' '}
                    </div>
                    <div className="col-8 rounded-right totalCardInfo" style={e.secondColor}>
                      <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '4px' }}>{e.title}</div>
                      <div style={{ fontSize: '18px' }}>
                        {' '}
                        <center style={{ fontSize: '20px', marginTop: '6px' }}><strong>{e.value}</strong></center>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} >
            <Row>
              {
                graphs.map((e) => {
                  let typeGraph = e.type === 'pie' ? (<MyResponsivePieCanvas keys={e.keys} colors={e.colors} double={true} position="horizontal" width={e.width} height={e.height} data={e.data} />) : e.type === 'bar' ? <MyResponsiveBarCanvas position="horizontal" width={e.width} left={e.left} height={e.height} data={e.data} /> : <MyResponsiveBarCanvas2 left={e.left} data={e.data} keys={e.keys} colors={e.colors} />;
                  return (
                    <Col md={e.class} lg={e.class} sm={12} xs={12}>
                      <Card className="graphcardsize">
                        <CardBody className="graphcardbody">
                          <CardText style={{ textAlign: 'center', fontFamily: 'unset', marginTop: '10px', fontWeight: 'bold' }}>{e.title}</CardText>
                          {typeGraph}
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })
              }
            </Row>
          </Col>
          <Col xs={12} md={12} lg={12} >
            <Map heidIcon={45} style={{ maxHeight: '300px' }} position={position} zoom={zoom} marker={markers} visible={true} type={'info'} data={polylineData} dataOptions={infoMap} dataDrawer={dataDrawer} />
            <Tab
              menu={{ secondary: true, className: 'wrapped' }}
              menuPosition='right'
              panes={[
                { menuItem: 'Ingresos', render: () => <IngresosCard clientesSelect={this.state.clientesSelect} /> },
                { menuItem: 'Despachos', render: () => <DespachosCard clientesSelect={this.state.clientesSelect} /> }
              ]}
            />
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}
const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
