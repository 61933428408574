import React, { Suspense, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Accordion, Icon } from 'semantic-ui-react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import './style.scss';

function LogisticsTools(props) {
  let typeErr = -1;
  if (props.location.state) {
    typeErr = props.location.state.errorType;
  }

  const [activeIndex, setActiveIndex] = useState(typeErr);

  const handleClick = (titleProps) => {
    const newIndex = activeIndex === titleProps ? -1 : titleProps;

    setActiveIndex(newIndex);
  };

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div className="row">
          <em className="far fa-question-circle"></em>
          <div style={{ marginLeft: '10px' }}>Preguntas Frecuentes</div>
        </div>
      </div>

      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={() => handleClick(0)}
        >
          ¿Cómo modifico mi contraseña?
          <Icon
            name={activeIndex === 0 ? 'chevron up' : 'chevron down'}
            style={{ float: 'right' }}
          />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <p>
            Para modificar tu contraseña deberás ingresar a la pagina de inicio
            de Rol4ll sin iniciar sesión y dar click en "¿Olvidaste tu
            contraseña?".
          </p>
          <br />
          <center>
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/ROLDAN_FAQ_LOGIN.JPG"
              alt=""
              style={{
                borderRadius: '5%' /* , height: '400px', width: '450px' */,
              }}
            />
          </center>
          <br />

          <p>
            Posteriormente, deberás ingresar la cuenta de correo electrónico de
            tu usuario en Rol4ll.
          </p>

          <br />
          <center>
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/ROL4LL_FAQ_CHANGE_PASS.JPG"
              alt=""
              style={{ borderRadius: '5%' }}
            />
          </center>
          <br />
          <p>
            Recibirás un correo electrónico a dicha cuenta, deberás dar click en
            el botón de "Restablecer" en el correo, el botón te redireccionará a
            la página donde deberás configurar tu nueva contraseña.
          </p>
          <br />

          <p>La contraseña debe estar compuesta por:</p>
          <li key={`minimum`} style={{ listStyleType: 'circle' }}>
            Mínimo 8 dígitos y hasta máximo 15 dígitos.
          </li>
          <li key={`minimum`} style={{ listStyleType: 'circle' }}>
            Al menos una letra mayúscula y una minúscula.
          </li>
          <li key={`minimum`} style={{ listStyleType: 'circle' }}>
            Un carácter especial (*!$%&?).
          </li>
        </Accordion.Content>
      </Accordion>

      <br />
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={() => handleClick(1)}
        >
          <Icon
            name={activeIndex === 1 ? 'chevron up' : 'chevron down'}
            style={{ float: 'right' }}
          />
          No encuentro mi operación
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <p>
            Para localizar tu operación de forma rápida, puedes hacer uso del
            QuickSearch.
          </p>
          <br />
          <center>
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/ROL4LL_FAQ_QUICKSEARCH01.png"
              alt=""
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                borderRadius: '15px 15px 15px 15px',
                // height: '150px',
                // width: '750px',
              }}
            />
          </center>
          <br />
          <p>
            Aquí puedes introducir cualquiera de las referencias para localizar
            tu operación.
          </p>
          <br />
          <center>
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/ROL4LL_FAQ_QUICKSEARCH02.png"
              alt=""
              style={{
                borderRadius: '15px 15px 15px 15px',
                maxHeight: '100%',
                maxWidth: '100%',
                // height: '150px',
                // width: '750px',
              }}
            />
          </center>
          <br />
          <p>
            Si aún no puedes visualizar tu operación te solicitamos que lo
            reportes a través del Chatbot.
          </p>
        </Accordion.Content>
      </Accordion>

      <br />
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={() => handleClick(2)}
        >
          <Icon
            name={activeIndex === 2 ? 'chevron up' : 'chevron down'}
            style={{ float: 'right' }}
          />
          No puedo ingresar desde la apliación móvil
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <p>
            Para ingresar desde la aplicación móvil es necesario utilizar el
            mismo usuario y contraseña que utilizas en la versión web.
          </p>
          <p>
            Es importante confirmar que la versión de Rol4ll sea la más
            reciente, esto lo puedes confirmar directamente desde la tienda de
            tu dispositivo.
          </p>
        </Accordion.Content>
      </Accordion>

      <div style={{ height: 300 }} />
    </ContentWrapper>
  );
}

export default withRouter(LogisticsTools);
