class SessionStorage{
  addItem(key, value){
    sessionStorage.setItem(key, value);
  }
  getItem(key){
    return sessionStorage.getItem(key);
  }
  updateItem(key, value){
    sessionStorage.setItem(key, value);
  }
  deleteItem(key){
    sessionStorage.removeItem(key);
  }
}

export default SessionStorage;