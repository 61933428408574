import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { message } from 'antd';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

// import moment from 'moment';
import Notification from '../../../components/Alert';
import CreateContext from '../../store/CreateContext';
import { validateFrequency } from './utils';
import { Services } from '../../../../Services';

const Notify = ({ type, text }) => {
  if (type === 'success') {
    message.success(text);
  } else if (type === 'error') {
    message.error(text);
  } else if (type === 'warning') {
    message.warning(text);
  }
};

function SaveReport(props) {
  const context = React.useContext(CreateContext);
  const [updating, setUpdating] = React.useState(false);
  const [isRedirect, setIsRedirect] = React.useState(false);

  /*=============================================
  =            Scheme definition                =
  =============================================*/

  function createOndemandScheme(context, reportId = 0) {
    let scheme = {
      reportType: context.report_type,
      reportName: context.report_name.value,
      reportDescription: context.report_description.value,
      shareTo: context.share_to,
      module: context.module_selected,
      fields: context.selected_fields,
      filters: context.filters,
      order: context.order_fields,

      emailList: context.emailList,
      customer: props.clients,
      user: localStorage.getItem('dataUser'),
      reportId: reportId,
    };

    if (!reportId) delete scheme.reportId;

    return scheme;
  }
  function createScheduledScheme(context, reportId = 0) {
    let scheme = {
      reportType: context.report_type,
      reportName: context.report_name.value,
      reportDescription: context.report_description.value,
      shareTo: context.share_to,

      type: context.config.frequency,
      recurrence:
        context.config.frequency === 'Monthly'
          ? context.config.Monthly
          : context.config.Daily,
      startDate: context.config.startDate.value,
      endDate: context.config.endDate.value,
      hour: context.config.hour.value,
      day: Number(context.config.day),
      timeZone: context.config.timeZone,
      timeZoneValue: context.config.timeZoneValue,
      timeZoneStr: context.config.timeZoneStr,

      module: context.module_selected,
      filters: context.filters,
      order: context.order_fields,
      emailList: context.emailList,
      customer: props.clients,
      user: localStorage.getItem('dataUser'),
      fields: context.selected_fields,
      reportId: reportId,
    };

    if (!reportId) delete scheme.reportId;

    return scheme;
  }

  /*=============================================
  =            Creation Functions               =
  =============================================*/

  const CreateOnDemand = React.useCallback(async (context) => {
    try {
      setUpdating(true);
      const body = createOndemandScheme(context);
      // const respCreate =
      await axios.post(
        `${Services.REPORTS.path}/report/builder/create/ondemand`,
        body
      );
      setUpdating(false);
      Notification('Creado', 'Reporte creado correctamente', 'success');

      context.model_reset();

      setTimeout(() => {
      }, 3000);

      setIsRedirect(true);
    } catch (error) {
      setUpdating(false);
      Notification('Error', 'Error al intentar crear reporte', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreateScheduled = React.useCallback(async (context) => {
    try {
      setUpdating(true);
      const body = createScheduledScheme(context);
      // const respScheduled =
      await axios.post(
        `${Services.REPORTS.path}/report/builder/create/scheduled`,
        body
      );

      setUpdating(false);
      Notification('Creado', 'Reporte creado correctamente', 'success');

      context.model_reset();

      setTimeout(() => {
      }, 3000);

      setIsRedirect(true);
    } catch (error) {
      setUpdating(false);
      Notification('Error', 'Error al intentar crear reporte', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*=============================================
  =            Update Functions            =
  =============================================*/

  const UpdateOnDemand = React.useCallback(
    async (context, reportId, onEvent) => {
      try {
        setUpdating(true);
        const body = createOndemandScheme(context, reportId);
        // const resUpdate =
        await axios.put(
          `${Services.REPORTS.path}/report/builder/update/ondemand`,
          body
        );

        setUpdating(false);
        Notification(
          'Reporte actualizado',
          'Configuración de reporte actualizada exitosamente',
          'success'
        );
        setTimeout(() => {
          onEvent();
        }, 9000);
      } catch (error) {
        setUpdating(false);
        Notification(
          'Error',
          'Ocurrió un error al intentar actualizar reporte',
          'error'
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const UpdateScheduled = React.useCallback(
    async (context, reportId, onEvent) => {
      try {
        setUpdating(true);
        const body = createScheduledScheme(context, reportId);
        // const resUpdate =
        await axios.put(
          `${Services.REPORTS.path}/report/builder/update/scheduled`,
          body
        );
        setUpdating(false);
        Notification(
          'Reporte actualizado',
          'Configuración de reporte actualizada exitosamente',
          'success'
        );
        setTimeout(() => {
          onEvent();
        }, 9000);
      } catch (error) {
        // console.error('UpdateScheduled -- ', error);
        setUpdating(false);
        Notification(
          'Error',
          'Error al intentar actualizar el reporte',
          'error'
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  function handleSaveReport(type) {
    let valid = true;

    if (!context.report_name.value) {
      Notify({ type: 'error', text: 'Nombre requerido' });
      valid = false;
    } else if (!context.report_description.value) {
      Notify({ type: 'error', text: 'Descripción requerida' });
      valid = false;
    }

    if (context.report_type === 'ondemand') {
      if (!context.selected_fields.length) {
        Notify({
          type: 'error',
          text: 'Debe de existir al menos un campo seleccionado',
        });
        valid = false;
      }
      if (valid) {
        if (type === 'create') {
          CreateOnDemand(context);
        } else {
          UpdateOnDemand(context, props.reportId, props.onEvent);
          // props.onEvent();
        }
      }
    } else if (context.report_type === 'scheduled') {
      if (context.config.frequency === 'Daily') {
        if (!validateFrequency(context.config.Daily)) {
          Notify({
            type: 'error',
            text: 'No se han seleccionado dias de recurrencia',
          });
          valid = false;
        }
        if (!context.config.hour.value) {
          Notify({
            type: 'error',
            text: 'No se ha seleccionado una hora valida',
          });
          valid = false;
        }
        if (!context.emailList.length) {
          Notify({
            type: 'error',
            text: 'No se ha proporcionado una lista de correos',
          });
          valid = false;
        }
        if (!context.selected_fields.length) {
          Notify({
            type: 'error',
            text: 'Debe de existir al menos un campo seleccionado',
          });
          valid = false;
        }
      } else if (context.config.frequency === 'Monthly') {
        if (!validateFrequency(context.config.Monthly)) {
          Notify({
            type: 'error',
            text: 'No se han seleccionado meses de recurrencia',
          });
          valid = false;
        }
        if (!context.config.hour.value) {
          Notify({
            type: 'error',
            text: 'No se ha seleccionado una hora valida',
          });
          valid = false;
        }
        if (!context.emailList.length) {
          Notify({
            type: 'error',
            text: 'No se ha proporcionado una lista de correos',
          });
          valid = false;
        }
        if (!context.config.day) {
          Notify({
            type: 'error',
            text: 'No se ha proporcionado un dia del mes',
          });
          valid = false;
        }
        if (!context.selected_fields.length) {
          Notify({
            type: 'error',
            text: 'Debe de existir al menos un campo seleccionado',
          });
          valid = false;
        }
      } else {
        Notify({ type: 'error', text: 'No se ha seleccionado la frecuencia' });
        valid = false;
      }

      if (valid) {
        if (type === 'create') {
          CreateScheduled(context);
        } else {
          UpdateScheduled(context, props.reportId, props.onEvent);
          // props.onEvent();
        }
      }
    }
  }

  return (
    <div className="col-md-6 d-flex  justify-content-sm-center justify-content-xs-start justify-content-center col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6">
      {isRedirect ? <Redirect to={'/reporting'} /> : ''}
      <div>
        {props.action === 'create' ? (
          <Button.Group size="tiny">
            <Button onClick={() => props.history.push('/reporting')}>
              Cancelar
            </Button>
            <Button.Or text="-" />
            <Button
              loading={updating}
              positive
              onClick={() => handleSaveReport('create')}
            >
              Guardar
            </Button>
          </Button.Group>
        ) : (
          <React.Fragment>
            <Button
              loading={updating}
              positive
              size="tiny"
              onClick={() => handleSaveReport('edit')}
            >
              Guardar
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

SaveReport.propTypes = {
  onEvent: propTypes.func,
};
SaveReport.defaultProps = {
  onEvent: () => {},
};

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SaveReport));
