import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const opStatusMenu = [
  {
    label: ['Necesito cambiar mi contraseña', 'tipoError'],
    value: 'currentLabel',
  },
  {
    label: ['No encuentro mi operación', 'tipoError'],
    value: 'currentLabel',
  },
  {
    label: ['No puedo ingresar desde la aplicación móvil', 'tipoError'],
    value: 'currentLabel',
  },
  {
    label: ['Otro', 'DescripcionProblemaRoldan'],
    value: 'currentLabel',
  },
];

function RoldanProblemMenu(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <ChatbotButtons
        buttons={opStatusMenu}
        botSteps={props}
        size={'col-md-12'}
      />
    </div>
  );
}

export default RoldanProblemMenu;
