import React from 'react';
import Axios from 'axios';
import { Search } from 'semantic-ui-react';
import { connect } from 'react-redux';
import TableComponent from './dataTablev1'; 
import Loader from '../../../../Global/Loader';
import { Services } from '../../Services';


function Importaciones(props) {

  let [clients, setClients] = React.useState(JSON.stringify(props.clients));
  let [data, setData] = React.useState([]);
  let [cpyData, setCpyData] = React.useState([]);
  let [fullLoad, setFullLoad] = React.useState(false);
  
  const getCustomImports = React.useCallback(async () => {
    try {
      let objPost = {
        pag: 1,
        limit: 10,
        clients: clients,
      };

      const trazabilidadUrl = `${Services.Trazabilidad_manager.path}/customs/customImports`;
      let success = await Axios.post(trazabilidadUrl, objPost);

      setData(success.data.data);
      setCpyData(success.data.data);
      setFullLoad(true);
    } catch (error) {
      console.error('getCardList ==>', error);
    }
  }, [clients]);
  
  React.useEffect(() => {
    getCustomImports();
  }, [getCustomImports]);

  let url = `${Services.Doc_manager.path}/document/documentHash/`;

  let viewDocument = async (id) => {
    let response = await Axios.get(url+id);
    window.open(response.data.data.url, '_blank', 'noopener,noreferrer');
  };

  let custom = (value) => <em onClick={() => viewDocument(value.ImportacionId)} /* style={{ color: '#36D9F9' }} */ className={'download fa-2x far fa-eye mr-2'}></em>;
  let checkIsDta = (value) => value.EsDTA === '1' ? <em className={'fas fa-check'}></em> : '';

  let mockColumns = [
    {label: 'No. de DO', key: 'DOAduanaImpo'},
    {label: 'Estatus', key: 'Estatus'},
    {label: 'Cliente', key: 'Cliente'},
    {label: 'Referencia', key: 'RefCliente'},
    {label: 'Doc. de Transporte', key: 'DocTransporte'},
    {label: 'DTA', key: 'EsDTA', custom: (value) => checkIsDta(value) },
    {label: 'Fecha de Levante', key: 'FechaLevante'},
    {label: '', key: '', custom: (value) => custom(value)}
  ];


  const handleChange = (event) => {
    
    let search = event.target.value;
    search = search.toUpperCase();

    let all = cpyData;  

    let results = [];
    all.forEach((e) => {
      let found = 0;
      let keys = ['DOAduanaImpo', 'Estatus', 'Cliente', 'RefCliente', 'FechaLevante', 'DocTransporte'];
      keys.forEach((key) => {
        if ((e[key] ? (e[key] + '').toLowerCase().indexOf(search.toLowerCase()) : -1) >= 0) found += 1;
      });
      if (found !== 0) results.push(e);
    });

    setData(results);
  };

  return (
    <> 
      <Loader hidden={fullLoad}></Loader>
      <div className="col-xs-12 col-md-4 col-lg-3">
        <Search
          input={{ icon: 'search', iconPosition: 'left', fluid: true }}
          placeholder="..."
          //value={searchIn}
          name="search"
          id="search"
          onSearchChange={handleChange}
          showNoResults={false}
        />
        <br/>
      </div>
      <TableComponent columnsTable={mockColumns} data={data}></TableComponent>
    </>
  );
}

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Importaciones);