import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper.js';
import { Row } from 'reactstrap';
import Comments from '../../Components/Comments/index.js';
import Documents from '../../Components/Documents/index.js';
import Summary from './Components/Summary.js';
import Events from './Components/Events.js';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Services } from '../Services.js';
import axios from 'axios';
import queryString from 'query-string';

class StorageDetail extends Component {
  state = {
    dataDoc: [],
    Info: [],
    steps: [
      {
        label: 'Notificación de Ingreso',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Llegada a Almacén',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Inicio de Descargue',
        value: '',
        id: 3,
        completed: false,
        order: 3,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Fin de Descargue',
        value: '',
        id: 4,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Manifiesto (Público)',
        value: '',
        id: 6,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: ''
      },
      {
        label: 'Vencimiento de Manifiesto',
        value: '',
        id: 7,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      },
      {
        label: 'Disponibilidad de Inventario',
        value: '',
        id: 8,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      }
    ],
    typeAlmacen: 1,
    commentsData: [{
      ComType: 'Tipo 1',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-06-20',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    },
    {
      ComType: 'Tipo 2',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-07-02',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    }],
    allSteps: [],
    id: this.props.match.params.id,
    stepsDepoHab: [
      {
        label: 'Notificación de Ingreso',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Llegada a Almacén',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Inicio de Descargue',
        value: '',
        id: 3,
        completed: false,
        order: 3,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Fin de Descargue',
        value: '',
        id: 4,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Manifiesto (Público)',
        value: '',
        id: 6,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: ''
      },
      {
        label: 'Vencimiento de Manifiesto',
        value: '',
        id: 7,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      },
      {
        label: 'Disponibilidad de Inventario',
        value: '',
        id: 8,
        completed: false,
        order: 8,
        idStep: 'step6',
        middleStep: ''
      }
    ],
    stepsBodNac: [
      {
        label: 'Notificación de Ingreso',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Llegada a Almacén',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Inicio de Descargue',
        value: '',
        id: 3,
        completed: false,
        order: 3,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Fin de Descargue',
        value: '',
        id: 4,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Disponibilidad de Inventario',
        value: '',
        id: 8,
        completed: false,
        order: 5,
        idStep: 'step6',
        middleStep: ''
      }
    ],
    stepsZonFra: [
      {
        label: 'Notificación de Ingreso',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Llegada a Almacén',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Inicio de Descargue',
        value: '',
        id: 3,
        completed: false,
        order: 3,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Fin de Descargue',
        value: '',
        id: 4,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Devolucion de Contenedor',
        value: '',
        id: 5,
        completed: false,
        order: 5,
        idStep: 'step6',
        middleStep: ''
      },
      {
        label: 'Disponibilidad de Inventario',
        value: '',
        id: 8,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      }
    ],
    isDoc: false,
    tabActive: 0
  }


  componentDidMount = () => {
    const isDocComponent = queryString.parse(this.props.location.search);
    if (isDocComponent.doc) {
      this.setState({ tabActive: 3 });
    }

    this.getDataForId();
  }

  componentWillReceiveProps = (nextProps) => {

    const isDocComponent = queryString.parse(this.props.location.search);
    if (isDocComponent.doc) {
      this.setState({ tabActive: 3 });
    }

    const newProp = nextProps.match.params.id ? nextProps.match.params.id : this.props.match.params.id;
    this.setState({ id: newProp }, () => {
      this.getDataForId();
    });
  }

  getEvents = () => {
    const { stepsDepoHab, stepsBodNac, stepsZonFra, typeAlmacen, id } = this.state;
    let moduleId = 6;
    axios.get(`${Services.Get_Events_General.path}/events/all/${moduleId}/${id}`)
      .then((response) => {
        let update = false;
        for (let index = response.data.steps.length; index >= 0; index--) {
          const element = response.data.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = typeAlmacen === 1 ? stepsBodNac : typeAlmacen === 2 ? stepsZonFra : stepsDepoHab;
        let dataEvents = response.data.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {
            if (e.EventDescriptionId === f.id) {
              findNext = true;
              f.value = e.EventDate;
              if (e.InTimeLine === '1') {
                f.completed = true;
              }
              else {
                findNext = false;
              }
            }
            else {
              findNext = false;
            }
            if (findNext) {
              f.completed = true;
            }
            return e;
          });
          return f;
        });
        newSteps.sort(function (a, b) { return b.order - a.order; });
        let findTrue = false;
        newSteps.map(e => {
          if (e.completed) {
            findTrue = true;
          }
          if (findTrue) {
            e.completed = true;
          }
          return e;
        });
        newSteps.sort(function (a, b) { return a.order - b.order; });
        this.setState({ stepsDepoHab: newSteps, allSteps: dataEvents }, () => {
          this.getDocForId()
        });
      }).catch((error) => {
      });
  }

  getDocForId = () => {
    let info = this.state.Info[0] ? this.state.Info[0] : ''
    let data = []

    if (info) {
      let newdata =
      {
        "punto": info.Codigo,
        "proceso": info.Proceso,
        "tipo_documento": info.Proceso,
        "documento": info.NoIngreso
      }
      data.push(newdata)
    }
    axios.post(`${Services.Get_Storage_Income_Doc.path}/document/income`, data).then(success => {
      let response = success.data.message.arreglo_respuestas[0] ? success.data.message.arreglo_respuestas[0].arreglo_conexiones : [];
      let newData = []
      response.map((e) => {
        let obj = {
          Name: e.nombre_archivo[0].split('/')[8],
          Type: e.nombre_archivo[0].split('.')[3],
          CreationDate: "",
          CreateBy: 'API',
          URL: e.nombre_archivo[0],
          DocumentKeyAsString: "https://" + e.nombre_archivo[0],
          origin: 'ingreso'
        }
        newData.push(obj);
        return e;
      })
      this.setState({
        dataDoc: newData
      })
    }).catch(error => {
      console.warn(error);
    });
  }

  getDataForId = () => {
    axios.get(`${Services.Get_Storage_Income_ForId.path}/storage/dataStorageIncome/${this.state.id}`).then(success => {
      let commentsData = [];
      let data = {
        ComType: '',
        ComTypeId: 1,
        Comment: success.data.data[0].ObservacionesRecepcion,
        CommentId: '',
        Date: success.data.data[0].ObservacionesRecepcion ? success.data.data[0].FechaLlegadaAlmacen : '',
        Module: 'Comentario',
        ModuleId: 1,
        RowId: '',
        UpdateBy: 'API',
      };
      commentsData.push(data);
      let Info = [];
      Info.push(success.data.data[0]);
      this.setState({
        Info: Info,
        commentsData: commentsData ? commentsData : [],
        typeAlmacen: success.data.data[0].TipoAlmacenId ? success.data.data[0].TipoAlmacenId : 1,
        ReferenciaDeCliente: success.data.data[0].RefCliente ? success.data.data[0].RefCliente : '',
        DocTransporte: success.data.data[0].DocTransporte ? success.data.data[0].DocTransporte : '',
        NoIngreso: success.data.data[0].NoIngreso ? success.data.data[0].NoIngreso : '',
        Estatus: success.data.data[0].Estatus ? success.data.data[0].Estatus : '',
        cliente: success.data.data[0].Cliente ? success.data.data[0].Cliente : '',
        NIT: success.data.data[0].NIT ? success.data.data[0].NIT : '',
        Grupo: success.data.data[0].Grupo ? success.data.data[0].Grupo : '',
        FechaActualizacion: success.data.data[0].FechaActualizacion ? success.data.data[0].FechaActualizacion : '',
      }, () => this.getEvents());
    }).catch(error => {
      console.warn(error);
    });
  }

  render() {
    const catalogsData = [
      { ComTypeId: 1, ModuleId: 1, ComType: 'Supplier Follow Up', value: 1, label: 'Supplier Follow Up' },
      { ComTypeId: 2, ModuleId: 1, ComType: 'Order Quality', value: 2, label: 'Order Quality' },
      { ComTypeId: 3, ModuleId: 1, ComType: 'Order Quantities', value: 3, label: 'Order Quantities' },
      { ComTypeId: 10, ModuleId: 1, ComType: 'Other', value: 10, label: 'Other' },
    ];

    const { tabActive } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <legend className='legendTitle'>
            <img
              alt=""
              src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13.png"
              height="28"
              width="28"
            />
            Detalle de la Transacción (Ingreso)
          </legend>
        </div>
        <Tab
          menu={{ secondary: true, className: 'wrapped', }}
          menuPosition='right'
          onTabChange={(event, data) => this.setState({ tabActive: data.activeIndex })}
          activeIndex={tabActive}
          //defaultActiveIndex={tabActive}
          panes={[
            { menuItem: 'Resumen', render: () => <Summary FechaActualizacion={this.state.FechaActualizacion} cliente={this.state.cliente} NIT={this.state.NIT} Grupo={this.state.Grupo} Estatus={this.state.Estatus} NoIngreso={this.state.NoIngreso} steps={this.state.stepsDepoHab} ReferenciaDeCliente={this.state.ReferenciaDeCliente} DocTransporte={this.state.DocTransporte} /> },
            { menuItem: 'Eventos', render: () => <Events cliente={this.state.cliente} NIT={this.state.NIT} Grupo={this.state.Grupo} Estatus={this.state.Estatus} NoIngreso={this.state.NoIngreso} allSteps={this.state.allSteps} steps={this.state.stepsDepoHab} ReferenciaDeCliente={this.state.ReferenciaDeCliente} DocTransporte={this.state.DocTransporte} /> },
            { menuItem: 'Comentarios', render: () => <Comments data={this.state.commentsData} catalogs={catalogsData} /> },
            { menuItem: 'Documentos', render: () => <Documents dataDoc={this.state.dataDoc} storage={true} rolId={12} shipment="" shipmentDataPlane="" /> },
          ]} />
      </ContentWrapper>
    );
  }
}

export default withRouter(StorageDetail);

