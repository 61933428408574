import React, { Component } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Offsidebar from './Offsidebar';
import Footer from './Footer';
import ChatBot from '../../Roldan/Chatbot'

// Roles
// 1	"Administrador"
// 2	"Cliente"
// 3	"Roldan"
// 4	"Roldan SU"
// 5	"Gestor Documental"
class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
    };
  }
  render() {
    return (
      <div className="wrapper">
        <Header changeProps={() => {this.setState({key: Math.random()});}}/>
        <Sidebar _userconfig={{...this.props}}/>
        <Offsidebar/>
        <section className="section-container" key={this.state.key}>
          {this.props.children}
        </section>
        {this.props.SideBarConfig.RoleId === '1' ||
          this.props.SideBarConfig.RoleId === '2' ||
          this.props.SideBarConfig.RoleId === '3' ||
          this.props.SideBarConfig.RoleId === '4' ? 
          <ChatBot/> : ''
        }
        <Footer/>
      </div>
    );
  }
}

export default Base;