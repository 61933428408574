import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Search } from 'semantic-ui-react';
import TableComponent from './dataTablev1'; 
import Loader from '../../../../Global/Loader';
import { Services } from '../../Services';

function Exportaciones(props) {

  let [clients, setClients] = React.useState(JSON.stringify(props.clients));
  let [data, setData] = React.useState([]);
  let [cpyData, setCpyData] = React.useState([]);
  let [fullLoad, setFullLoad] = React.useState(false);

  const getCustomExpo = React.useCallback(async () => {
    try {
      let objPost = {
        pag: 1,
        limit: 100000,
        EsDTA: 0,
        search: '',
        client: clients,
        advanced: []
      };
      
      
      let success = await Axios.post(`${Services.Trazabilidad_manager.path}/customs/listCustomExport/?docmanager=true`, objPost);

      setData(success.data.data);
      setCpyData(success.data.data);
      setFullLoad(true);
    } catch (error) {
      console.error('error ==>', error);
    }
  }, [clients]);
  
  React.useEffect(() => {
    getCustomExpo();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomExpo]);

  let mockColumns = [
    {CollapseTable: true, flatColor: false, key: '',  label: ''},
    {label: 'No. de DO',key: 'DOAduanaExpo'},
    {label: 'Estatus', key: 'Estatus'},
    {label: 'Cliente', key: 'Cliente'},
    {label: 'Referencia', key: 'RefCliente'},
    {label: 'Doc. de Transporte', key: 'DocTransporte'},
    {label: 'Manifiesto Carga', key: 'FechaManifiestoCarga'}
  ];

  let sbTable = [
    {label: 'Nombre del Documento', key: 'Nombre'},
    {label: 'Tipo Documento', key: 'Fecha'},
    {label: 'Fecha de Creación', key: 'Descripcion'},
    {label: '', key: 'Download', separator: true}
  ];

  const handleChange = (event) => {
    
    let search = event.target.value;
    search = search.toUpperCase();

    let all = cpyData;  

    let results = [];
    all.forEach((e) => {
      let found = 0;
      let keys = ['DOAduanaExpo', 'Estatus', 'Cliente', 'RefCliente', 'FechaManifiestoCarga', 'DocTransporte'];
      keys.forEach((key) => {
        if ((e[key] ? (e[key] + '').toLowerCase().indexOf(search.toLowerCase()) : -1) >= 0) found += 1;
      });
      if (found !== 0) results.push(e);
    });

    setData(results);
  };

  return (
    <>
      <Loader hidden={fullLoad}></Loader>
      <div className="col-xs-12 col-md-4 col-lg-3">
        <Search
          input={{ icon: 'search', iconPosition: 'left', fluid: true }}
          placeholder="..."
          //value={searchIn}
          name="search"
          id="search"
          onSearchChange={handleChange}
          showNoResults={false}
        />
        <br/>
      </div>
      <TableComponent columnsTable={mockColumns} data={data} subTable={sbTable}></TableComponent>
    </>
  );
}

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Exportaciones);