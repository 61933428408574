const Values = {
  Cards: [
    {
      title: "Accesos por Día",
      aka:"AccesosPorDia",
      value: 0,
      label: "Usuarios",
      color: {
        backgroundColor: "#5D9CEC",
        color: "#fff"
      }
    },
    {
      title: "Sesiones Activas",
      aka:"SesionesActivas",
      value: 0,
      label: "Usuarios Conectados",
      color: {
        backgroundColor: "#FFAC35",
        color: "#fff"
      }
    },
    {
      title: "Promedio en Sesión",
      aka:"PromedioSesion",
      value: 0,
      label: "MIN",
      color: {
        backgroundColor: "#57B65B",
        color: "#fff"
      }
    },
    
  ]
};

export default Values;
