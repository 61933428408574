import React, { Component } from "react";
import pubsub from "pubsub-js";
import { Checkbox } from "@material-ui/core/";
import { Dropdown, List, Modal } from "semantic-ui-react";
import { InputGroup, Input, Col, Row } from "reactstrap";
import { Button } from "reactstrap";
import { Redirect } from "react-router";
import { connect } from "react-redux";
// import { Link } from 'react-router-dom';
// import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
// ? Local imports
import ToggleState from "../Common/ToggleState.js";
import TriggerResize from "../Common/TriggerResize.js";
import ToggleFullscreen from "../Common/ToggleFullscreen.js";
import HeaderRun from "./Header.run.js";
import smallIMG from "../../../resources/minilogo2.png";
import largeIMG from "../../../resources/Rol4ll2.png";
import { Link, withRouter } from "react-router-dom";
import Authentication from "../../../services/Authentication.js";
// import ClientesList from '../../../../src/services/storageClient.js';
import { SetNewListGroups } from "../../../state/actions/Header/index.js";
import SessionStorage from "../../../services/SessionStorage.js";

import "./style.scss";

const auth = new Authentication();
const session = new SessionStorage();
// const clientSave = new ClientesList();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customWidth: window.innerWidth,
      redirect: false,
      openselect: false,
      dataDrop: [],
      dataDrop1: [],
      checkAll: true,
      rolId: auth.getAuthentication('RolId'),
      cout: 0,

      onlyDesktop: false,
      onlyMobile: false,
    };
  }

  handleResize = () => {
    this.setState({ customWidth: window.innerWidth });
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const data = this.props.group_list;

    let cout = 0;
    data.map((e) => {
      e.data.map((f) => {
        if (f.check) {
          cout = cout + 1;
        }
        return f;
      });
      return e;
    });

    this.setState(
      {
        dataDrop: [...data] ? [...data] : [],
        dataDrop1: [...data] ? [...data] : [],
        cout,
      },
      () => {
        // this.getCheck();
      }
    );
    HeaderRun();
  }
  /*=============================================
    =            Handle Togle User Block         =
    =============================================*/
  toggleUserblock(e) {
    e.preventDefault();
    pubsub.publish("toggleUserblock");
  }
  /*=============================================
  =            Handle LogOut Function           =
  =============================================*/
  handleLogOut = async () => {
    const createOffsetValue = (date) => {
      var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date.getTimezoneOffset());
      var hours = Math.floor(offset / 60);
      var minutes = ((offset % 60) / 60) * 10;
      return sign + hours + "." + minutes;
    };

    let endSession = {
      SessionId: auth.getAuthentication("token"),
      EndConnection: new Date(),
      timezone: createOffsetValue(new Date()),
      Logout: "1",
    };

    navigator.sendBeacon(
      "https://roldanapiaudit.azurewebsites.net/user/logout",
      JSON.stringify(endSession)
    );

    // Auth.signout(() => {
    //   this.setState({redirect: true});
    // });
  };

  removeToken = () => {
    this.handleLogOut();
    auth.deleteAuthentication("token");
    // clientSave.deleteAuthentication('client');
    auth.deleteAuthentication("dataUser");
    auth.deleteAuthentication("customerSelect");
    session.deleteItem("clients");
    session.deleteItem("nit");
    session.deleteItem("group_list");
    this.props.history.push("/");
  };

  handleSearch = (name) => (event) => {
    if (event.keyCode === 32) {
      event.target.value = event.target.value + " ";
    }
    this.setState({ [name]: event.target.value }, () => this.setSearch());
  };

  setSearch = () => {
    let haveSearch = false;
    if (this.state.search.length > 0) {
      haveSearch = true;
    }
    let all = [...this.state.dataDrop];
    let newAll = all.map((e) => {
      let newE = { ...e };

      let newData2 = newE.data.map((item) => {
        const nameData = item.value
          ? item.value.toUpperCase()
          : "".toUpperCase();
        const nameNIT = item.NIT ? item.NIT.toUpperCase() : "".toUpperCase();
        const idData = item.id
          ? item.id.toString().toUpperCase()
          : "".toUpperCase();
        const textData = this.state.search.toUpperCase();

        if (
          nameData.indexOf(textData) > -1 ||
          idData.indexOf(textData) > -1 ||
          nameNIT.indexOf(textData) > -1
        ) {
          item.hidden = false;
        } else {
          item.hidden = haveSearch ? true : false;
        }
        return item;
      });
      newE.data = newData2;
      return newE;
    });
    let finalAll = newAll.filter((item) => {
      return item.data.length > 0;
    });
    this.setState({ dataDrop: finalAll });
  };

  handeInputData = (selected) => (event) => {
    this.setState({ searchValue: event.target.value });
  };

  checkGroup = (index, all, uncheck) => {
    let newData = this.state.dataDrop;
    if (all) {
      if (uncheck) {
        newData[index].check = false;
        newData[index].data.map((e) => {
          e.check = false;
          return e;
        });
      } else {
        newData[index].check = true;
        newData[index].data.map((e) => {
          e.check = true;
          return e;
        });
      }
    } else {
      newData[index].check = !newData[index].check;
      newData[index].data.map((e) => {
        e.check = newData[index].check;
        return e;
      });
    }

    let cout = 0;
    newData.map((e) => {
      e.data.map((f) => {
        if (f.check) {
          cout = cout + 1;
        }
        return f;
      });
      return e;
    });

    this.setState({ dataDrop: newData, cout });
  };

  getCheck = async () => {
    let clientes = [];
    let NIT = [];
    this.state.dataDrop.map((e) => {
      e.data.map((f) => {
        if (f.check) {
          clientes.push(f.id);
          NIT.push(f.NIT);
        }
        return f;
      });
      return e;
    });
    this.setState({
      openselect: false,
      search: "",
    });
    session.updateItem("clients", JSON.stringify(clientes));
    session.updateItem("nit", JSON.stringify(NIT));
    session.updateItem("group_list", JSON.stringify(this.state.dataDrop));
    await this.props.SetNewListGroups({
      group_list: this.state.dataDrop,
      clients: clientes,
      nit: NIT,
    });
    // clientSave.setAuthentication(clientes, NIT); //FIXME: quitar
    this.props.changeProps();
    this.change()
  };

  change = () => {
    this.setState({ onlyMobile: false, onlyDesktop: false });
  };

  checkClient = (index, ind) => {
    let newData = this.state.dataDrop;
    newData[index].data[ind].check = !newData[index].data[ind].check;
    if (!newData[index].data[ind].check) {
      newData[index].check = false;
    }
    let cout = 0;
    newData.map((e) => {
      e.data.map((f) => {
        if (f.check) {
          cout = cout + 1;
        }
        return f;
      });
      return e;
    });

    this.setState({ dataDrop: newData, cout });
  };

  checkAll = () => {
    if (!this.state.checkAll) {
      let newData = this.state.dataDrop;
      newData.map((e, index) => {
        this.checkGroup(index, true, false);
        return e;
      });
      this.setState({ checkAll: true });
    } else {
      let newData = this.state.dataDrop;
      newData.map((e, index) => {
        this.setState({ checkAll: false });
        this.checkGroup(index, true, true);
        return e;
      });
    }
  };

  activeEntities = () => {
    let { customWidth } = this.state
    if (customWidth > 758) {
      this.setState({ onlyDesktop: true, onlyMobile: false });
    } else {
      this.setState({ onlyDesktop: false, onlyMobile: true });
    }
  }

  render() {
    let { redirect, searchValue, dataDrop, checkAll, cout, openselect, customWidth, onlyDesktop, onlyMobile } = this.state;
    if (redirect) return <Redirect to="/login" />;
    let acction;
    if (searchValue === "" || searchValue === undefined) {
    } else {
      acction = (
        <Link to={`/search/${encodeURIComponent(searchValue)}`}>
          <button className="d-none" type="submit">
            Submit
          </button>
        </Link>
      );
    }

    return (
      <header className="topnavbar-wrapper">
        {/* START Top Navbar */}
        <nav className="navbar topnavbar">
          {/* START navbar header */}
          <div className="navbar-header">
            <a className="navbar-brand" href="#/">
              <div className="brand-logo">
                <img
                  className="img-fluid"
                  src={largeIMG}
                  alt="App Logo"
                  style={{ maxWidth: '58%' }}
                />
              </div>
              <div className="brand-logo-collapsed">
                <img className="img-fluid" src={smallIMG} alt="App Logo" />
              </div>
            </a>
          </div>
          {/* END navbar header */}
          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <TriggerResize>
                <ToggleState state="aside-collapsed">
                  <a
                    href=" "
                    className="nav-link d-none d-md-block d-lg-block d-xl-block"
                  >
                    <em className="fas fa-bars"></em>
                  </a>
                </ToggleState>
              </TriggerResize>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <ToggleState state="aside-toggled" nopersist={true}>
                <a href=" " className="nav-link sidebar-toggle d-md-none">
                  <em className="fas fa-bars"></em>
                </a>
              </ToggleState>
            </li>
            {/* START User avatar toggle */}
            <li className="nav-item d-none d-md-block">
              <a
                href=" "
                title="User"
                className="nav-link"
                onClick={this.toggleUserblock}
              >
                <em className="icon-user"></em>
              </a>
            </li>
            <li className="nav-item">
              {this.state.rolId !== "5" ? (
                <a className="nav-link" href=" " data-search-open="">
                  <em className="icon-magnifier"></em>
                </a>
              ) : (
                ""
              )}
            </li>
            <h4 className="nav-link d-flex align-items-center" style={{ color: "#fff" }}>|</h4>
            <li
              // className="nav-item d-none d-md-block"
              className="nav-item"
              onClick={() => { this.activeEntities() }}
            >
              <button className="nav-link" >
                <em
                  className="icon-people"
                  style={{ cursor: "pointer", fontSize: "15px" }}
                  title=""
                >
                  <i
                    style={{
                      fontSize: "11px",
                      marginLeft: "4px",
                      fontStyle: "normal",
                    }}
                    onClick={() => { this.activeEntities() }}
                  >
                    ▼
                  </i>
                </em>
                <Dropdown open={onlyDesktop}
                  icon=''>
                  <Dropdown.Menu>
                    <div
                      style={{
                        background: "#bab9bc24",
                        width: "876px",
                        height: "330px",
                      }}
                    >
                      <div>
                        <InputGroup style={{ width: "556px" }}>
                          <Input
                            style={{
                              marginLeft: "23px",
                              marginTop: "21px",
                              height: "29px",
                            }}
                            placeholder="Nombre del Cliente"
                            onKeyUp={this.handleSearch("search")}
                          />
                          <br></br>
                          <em
                            className="fa-2x icon-magnifier"
                            style={{
                              fontSize: "23px",
                              marginLeft: "10px",
                              color: "#a5a0a0",
                              marginTop: "24px",
                            }}
                            onClick={""}
                          ></em>
                          <div style={{ width: '2em' }}></div>
                          <h5 style={{ color: '#0e0e0e', marginTop: '24px', fontWeight: 'bold' }}>Clientes Seleccionados:{cout}</h5>

                        </InputGroup>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          marginRight: "23px",
                          marginTop: "-33px",
                        }}
                      >
                        <Button color="danger" onClick={this.change}>
                          x
                        </Button>{" "}
                        <Button color="success" onClick={this.getCheck}>
                          ✓
                        </Button>{" "}
                      </div>
                      <div class="ui divider"></div>
                      <div style={{ overflowY: "scroll", height: "230px" }}>
                        <List
                          style={{
                            color: "black",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                          as="ol"
                        >
                          <List.Item as="li" value="">
                            <Checkbox
                              checked={checkAll}
                              onChange={this.checkAll}
                              value={""}
                            />
                            Marcar todos
                          </List.Item>
                        </List>
                        <Dropdown.Divider />
                        <div>
                          <List
                            style={{
                              color: "black",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                            as="ol"
                          >
                            {dataDrop.map((e, index) => {
                              if (e.hidden) {
                                return <></>;
                              } else {
                                // let divider = <Dropdown.Divider />;
                                // if (index + 1 === dataDrop.length) {
                                // 	divider = <></>;
                                // }
                                return (
                                  <div>
                                    <List.Item as="li" value="">
                                      <Checkbox
                                        checked={e.check}
                                        onChange={() =>
                                          this.checkGroup(index)
                                        }
                                        key={index}
                                        value={e.id}
                                      />
                                      {e.value}
                                      <List.Item as="ol">
                                        {e.data.map((f, ind) => {
                                          if (f.hidden) {
                                            return <></>;
                                          } else {
                                            return (
                                              <List.Item
                                                style={{
                                                  fontWeight: "initial",
                                                  fontSize: "12px",
                                                }}
                                                as="li"
                                                value=""
                                              >
                                                <Checkbox
                                                  checked={f.check}
                                                  onChange={() =>
                                                    this.checkClient(
                                                      index,
                                                      ind
                                                    )
                                                  }
                                                  value={f.id}
                                                />{" "}
                                                {f.NIT} -- {f.value}
                                              </List.Item>
                                            );
                                          }
                                        })}
                                      </List.Item>
                                    </List.Item>
                                    {index + 1 !== dataDrop.length ? (
                                      <Dropdown.Divider />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                );
                              }
                            })}
                          </List>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </button>
            </li>
          </ul>
          <div style={{ width: "120px" }}></div>
          <h3
            style={{ fontSize: "13px", color: "white", letterSpacing: "15px" }}
            title=""
            className='nav-item d-none d-md-block'
          >
            PROCESOS INTEGRADOS
          </h3>
          <ul className="navbar-nav flex-row">
            <li className="nav-item d-none d-md-block">
              <ToggleFullscreen className="nav-link" />
            </li>
          </ul>
          <form className="navbar-form">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                onChange={this.handeInputData()}
                placeholder="Búsqueda por: No. Solicitud, DO ID, Estatus, Doc. Transporte, Aduana, Proveedor, País Destino, Placas de Vehículo..."
              />
              <div
                className="main-close-especial fa fa-times navbar-form-close" /* onClick={()=> this.navigateToExactPath()} */
              ></div>
            </div>
            {acction}
          </form>
          <p onClick={this.removeToken} className="nav-item d-none d-md-block">
            <Link
              to=" "
              title="Log Out"
              className="nav-link"
              style={{ color: "#ffffff" }}
            >
              <em className="icon-logout"></em>
            </Link>
          </p>
        </nav>
        <Modal open={onlyMobile} size="fullscreen">
          <Modal.Header>
            <div className="d-flex justify-content-end pb-2">
              <Button color="danger" onClick={this.change} className="mr-1">
                x
              </Button>
              <Button color="success" onClick={this.getCheck} className="ml-1">
                ✓
              </Button>
            </div>
            <Row>
              <Col xs={12} md={7} lg={7} className="mb-2">
                <Input
                  placeholder={'Nombre del Cliente'}
                  onChange={this.handleSearch("search")}
                />
              </Col>
              <Col xs={12} md={5} lg={5}>
                <h5 style={{ color: "#0e0e0e", fontWeight: "bold", }}>
                  Clientes Seleccionados:{cout}
                </h5>
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Content>
            <div style={{ overflowY: "scroll", height: window.innerHeight }}>
              <List
                style={{
                  color: "black",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
                as="ol"
              >
                <List.Item as="li" value="">
                  <Checkbox
                    checked={checkAll}
                    onChange={this.checkAll}
                    value={""}
                  />
                  Marcar todos
                </List.Item>
              </List>
              <List
                style={{
                  color: "black",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
                as="ol"
              >
                {dataDrop.map((e, index) => {
                  if (e.hidden) {
                    return <></>;
                  } else {
                    return (
                      <div>
                        <List.Item as="li" value="">
                          <Checkbox
                            checked={e.check}
                            onChange={() =>
                              this.checkGroup(index)
                            }
                            key={index}
                            value={e.id}
                          />
                          {e.value}
                          <List.Item as="ol">
                            {e.data.map((f, ind) => {
                              if (f.hidden) {
                                return <></>;
                              } else {
                                return (
                                  <List.Item
                                    style={{ fontSize: "10px", }}
                                    as="li"
                                    value=""
                                  >
                                    <Checkbox
                                      checked={f.check}
                                      onChange={() =>
                                        this.checkClient(
                                          index,
                                          ind
                                        )
                                      }
                                      value={f.id}
                                    />{" "}
                                    {f.NIT} -- {f.value}
                                  </List.Item>
                                );
                              }
                            })}
                          </List.Item>
                        </List.Item>
                        {index + 1 !== dataDrop.length ? (
                          <Dropdown.Divider />
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  }
                })}
              </List>
            </div>
          </Modal.Content>
        </Modal>
      </header>
    );
  }
}
const mapStateToProps = (state) => ({
  group_list: state.header.group_list,
});
const mapDispatchToProps = (dispatch) => ({
  SetNewListGroups: (new_group_list) =>
    dispatch(SetNewListGroups(new_group_list)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
