import React, { Component } from 'react';
import StepperEvent from '../../../../../Components/StepperEvent';
import TablesEvent from './tablesEvent';
import Table from '../../../../../Components/Table';
import { Card, CardBody, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';

class Events extends Component {
  state = {
    id: this.props.match.params.id,
    allSteps: [],
    allTables: [],
    data: [],
    columns: [
      { label: 'Descripción del Evento', key: 'EventDescription', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Fecha del Evento', key: 'EventDate', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Categoría de Responsable', key: 'CategoryResponsable', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Origen', key: 'Origin', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Fecha de Creación', key: 'CreateDate', idTh: 'firstTh', idTd: 'firstTd' },
    ],
    steps: [
      {
        label: 'Instrucción Cliente AA',
        value: '19/05/2017',
        id: 31,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'ATA (Manifiesto)',
        value: '29/05/2017',
        id: 34,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Ingreso Depósito',
        value: '30/06/2017',
        id: 62,
        completed: false,
        order: 3,
        middle: false,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Levante',
        value: '19/07/2017',
        id: 91,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Despacho',
        value: '',
        id: 97,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: ''
      },
      {
        label: 'Radicación de Factura',
        value: '',
        id: 98,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      }
    ],
    HBL_HAWB: '',
    DocTransporte: '',
    doImportImpo: ''
  }

  componentDidMount = () => {
    this.setState({
      steps: this.props.steps,
      allSteps: this.props.allSteps,
      HBL_HAWB: this.props.HBL_HAWB,
      DocTransporte: this.props.DocTransporte,
      doImportImpo: this.props.doImportImpo,
      Estatus: this.props.Estatus,
      cliente: this.props.cliente,
      NIT: this.props.NIT,
      Grupo: this.props.Grupo
    }, () => {
      this.createTables();
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      steps: nextProps.steps,
      allSteps: nextProps.allSteps,
      HBL_HAWB: nextProps.HBL_HAWB,
      DocTransporte: nextProps.DocTransporte,
      doImportImpo: nextProps.doImportImpo,
      Estatus: nextProps.Estatus,
      cliente: nextProps.cliente,
      NIT: nextProps.NIT,
      Grupo: nextProps.Grupo
    }, () => { this.createTables(); });
  }

  createTables = () => {
    var allTables = [];
    this.state.allSteps.map((e) => {
      var findTable = false;
      allTables.map((f) => {
        if (f.eventType === e.EventType) {
          f.data.push(e);
          findTable = true;
        }
        return f;
      });
      if (!findTable) {
        var obj = {
          eventType: e.EventType,
          data: [e]
        };
        allTables.push(obj);
      }
      return e;
    });
    this.setState({ allTables: allTables });
  }

  render() {
    let { cliente, NIT, Grupo } = this.state
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <Row>
              <div className='col-xs-2 col-md-1 col-lg-1 d-flex justify-content-center d-flex align-items-center'>
                <i style={{ color: '#001489' }} className="fa-3x fas fa-arrow-alt-circle-right"></i>
              </div>
              <div className='col-xs-10 col-md-6 col-lg-6' >
                <p className='sizeTextComents'>{cliente} ({NIT}) / {Grupo}</p>
                <p className='sizeTextComents' style={{ color: '#0099ff' }}>{this.state.doImportImpo}</p>
                <p className='sizeTextComents'>{this.state.DocTransporte}</p>
                <p className='sizeTextComents'>{this.state.HBL_HAWB}</p>
              </div>
              <div className='col-12 mt-1'>
                {this.state.Estatus}
              </div>
            </Row>

            <div className="col-12">
              <StepperEvent steps={this.state.steps} />
            </div>

          </CardBody>
        </Card>
        <Card className="myCard">
          <CardBody>
            {this.state.allTables.length > 0 ? <TablesEvent columns={this.state.columns} allTables={this.state.allTables} /> :
              <Table
                columns={this.state.columns}
                data={this.state.data}
                options={{
                  large: false,
                  downloads: {
                    pdf: false,
                    xlsx: false
                  }
                }} />}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(Events);
