import React from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import CreateProvider from './store/CreateProvider';
import DashboardIndex from './Dashboard';
import './styles.scss';

/**
 * Control Dashboard Component
 */
function ControlDashboard(){
  return (
    <ContentWrapper>
      <CreateProvider>
        <DashboardIndex/>
      </CreateProvider>
    </ContentWrapper>
  );
}
export default ControlDashboard;