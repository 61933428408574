import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import "./styles/cards.scss";

const limit = 12;
const highLimit = 20;

export const CardExportaciones = (props) => {
  let { element } = props;
  return (
    <>
      <Card className="_clickCard mySearchCard" id={Math.random()}>
        <CardBody>
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                {/* Icono & DO ID & Estatus */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================ICONO===================================== */}
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                EXPO
                              </span>
                            </div>
                            <div className="mb-mail-subject">
                              <em
                                className={
                                  "fa-2x mr-2 fas fa-arrow-alt-circle-left"
                                }
                                style={{
                                  color: "rgb(88, 44, 131)",
                                  marginLeft: "11px",
                                }}
                              ></em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ==========================cliente =========================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Cliente ? (
                                element.Cliente.length > limit ? (
                                  <Popup
                                    content={element.Cliente}
                                    key={element.Cliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Cliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Cliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================No. de DO===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              DO ID
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              <Link
                                to={
                                  "/exports-detail-view/" +
                                  element.ExportacionId
                                }
                              >
                                {element.DOAduanaExpo ? (
                                  element.DOAduanaExpo.length > highLimit ? (
                                    <Popup
                                      content={element.DOAduanaExpo}
                                      key={element.DOAduanaExpo}
                                      position=""
                                      trigger={
                                        <span>
                                          {element.DOAduanaExpo.substring(
                                            0,
                                            limit - 3
                                          )}
                                          ...
                                        </span>
                                      }
                                    />
                                  ) : (
                                    element.DOAduanaExpo
                                  )
                                ) : (
                                  ""
                                )}
                              </Link>
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* MODO transporte & doc transporte */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================Estatus===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              <span>Estatus </span>
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <span style={{ fontSize: "12px" }}>
                            {element.Estatus ? (
                              element.Estatus.length > limit ? (
                                <Popup
                                  content={element.Estatus}
                                  key={element.Estatus}
                                  position=""
                                  trigger={
                                    <span>
                                      {element.Estatus.substring(0, limit - 3)}
                                      ...
                                    </span>
                                  }
                                />
                              ) : (
                                element.Estatus
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* ==========================MODO transporte===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Modo de Transporte
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.ModoTransporte ? (
                                element.ModoTransporte.length > limit ? (
                                  <Popup
                                    content={element.ModoTransporte}
                                    key={element.ModoTransporte}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.ModoTransporte.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.ModoTransporte
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================ref cliente===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Referencia de Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.RefCliente ? (
                                element.RefCliente.length > limit ? (
                                  <Popup
                                    content={element.RefCliente}
                                    key={element.RefCliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.RefCliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.RefCliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Aduana & País origen*/}
                <div className="col-md-2">
                  <div className="row text-center">
                    {/* ==========================Aduana ===================================== */}
                    <div className="col-md-5">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Aduana
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Aduana ? (
                                element.Aduana.length > limit ? (
                                  <Popup
                                    content={element.Aduana}
                                    key={element.Aduana}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Aduana.substring(0, limit - 3)}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Aduana
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Pais Destino===================================== */}
                    <div className="col-md-7">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              País Destino
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.PaisDestino ? (
                                element.PaisDestino.length > limit ? (
                                  <Popup
                                    content={element.PaisDestino}
                                    key={element.PaisDestino}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.PaisDestino.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.PaisDestino
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Instruccion & ATA */}
                <div className="col-md-4">
                  <div className="row text-center">
                    {/* ========================== Instruccion===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Instrucción
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaInstruccionCliente ? (
                                element.FechaInstruccionCliente.length >
                                limit ? (
                                  <Popup
                                    content={element.FechaInstruccionCliente}
                                    key={element.FechaInstruccionCliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaInstruccionCliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaInstruccionCliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Autorización ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Autorización
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaAutorizaEmbarque ? (
                                element.FechaAutorizaEmbarque.length > limit ? (
                                  <Popup
                                    content={element.FechaAutorizaEmbarque}
                                    key={element.FechaAutorizaEmbarque}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaAutorizaEmbarque.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaAutorizaEmbarque
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Manifiesto ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Manifiesto
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaManifiestoCarga ? (
                                element.FechaManifiestoCarga.length > limit ? (
                                  <Popup
                                    content={moment(
                                      element.FechaManifiestoCarga
                                    )
                                      .utc()
                                      .format("DD-MM-YYYY")}
                                    key={moment(element.FechaManifiestoCarga)
                                      .utc()
                                      .format("DD-MM-YYYY")}
                                    position=""
                                    trigger={
                                      <span>
                                        {moment(element.FechaManifiestoCarga)
                                          .utc()
                                          .format("DD-MM-YYYY")
                                          .substring(0, limit - 2)}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  moment(element.FechaManifiestoCarga)
                                    .utc()
                                    .format("DD-MM-YYYY")
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* END OF MAIN DIV */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
