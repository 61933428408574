import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const opStatusMenu = [
  {
    label: ['Transporte Internacional', 'Cotizador'],
    value: 'currentLabel',
  },
  {
    label: ['Transporte Nacional', 'Cotizador'],
    value: 'currentLabel',
  },
  {
    label: ['Agenciamiento Importación', 'Cotizador'],
    value: 'currentLabel',
  },
  {
    label: ['Agenciamiento Exportación', 'Cotizador'],
    value: 'currentLabel',
  },
  {
    label: ['Almacenamiento', 'Cotizador'],
    value: 'currentLabel',
  },
  {
    label: ['Proyecto', 'Cotizador'],
    value: 'currentLabel',
  },
];

function CotizarServicioMenu(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <ChatbotButtons buttons={opStatusMenu} botSteps={props} />
    </div>
  );
}

export default CotizarServicioMenu;
