import React, { Component } from "react";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import "./styles/main.scss";
import { ModelData } from "./models/FiltersModel";
import DataTable from "./DataTable";
import Filters from "./FiltersData";
import Graphics from "./graphics/graphics";
import { Services } from "./Services";
import axios from "axios";
import { Search } from "semantic-ui-react";
import Axios from "axios";
import { saveAs } from "file-saver";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import Loader from "../../Global/Loader";

const mapColor = {
  12: "danger",
  13: "grayi",
};

const headers = [
  "NIT",
  "Cliente",
  "Proceso",
  "No. Documento",
  "Fecha Facturación",
  "Fecha Vencimiento",
  "Tipo",
  "No. Documento Asociado",
  "Referencia",
  "Moneda",
  "Concepto",
  "Monto",
  "Monto Total",
  "Estatus",
];
const keys = [
  "NIT",
  "Cliente",
  "Proceso",
  "NoFactura",
  "FechaFactura",
  "FechaVencimiento",
  "Tipo",
  "DocRelacionado",
  "Referencia",
  "Moneda",
  "Concepto",
  "TotalConcepto",
  "TotalFactura",
  "Estatus",
];

class FacturacioCostos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: false,
      numberPageInvoice: 1,
      searchInvoice: "",
      limitInvoice: 10,

      fecha: "",
      newCatconcepto: false,
      clientesSelect: this.props.clients
        ? JSON.stringify(this.props.clients)
        : "[0]",
      userId: this.props._user,
      loading: false,
      pages: 0,
      search: "",
      Amoun: "0",
      toltlAmoun: "0",
      yearCat: [],
      ModelData: ModelData,
      form: "",
      filters: "",
      data: [],
      respladoCatalogCombo: {},
      catalogCombo: {
        EquipmentNo: [],
        Code: [],
        Concept: [],
        Status: [],
        Invoice: [],
        TransportMode: [],
        ShipmentNo: [],
      },
      dataTable: [],
      repChartFacturacion: [],
      tercerChart: [],
      cuartaChar: [],
      CupoCartera: [],
      columnsTable: [
        {
          label: "",
          key: "",
          CollapseTable: true,
          flatColor: true,
          keydatavalidate: "Status",
          minWidth:50
        },
        { label: "NIT", key: "NIT" },
        { label: "Cliente", key: "Cliente" },
        { label: "Proceso", key: "Proceso" },
        { label: "No. Documento", key: "NoFactura" },
        { label: "Fecha Facturación", key: "FechaFactura" },
        { label: "Fecha Vencimiento", key: "FechaVencimiento" },
        { label: "Tipo", key: "Tipo" },
        { label: "No. Documento Asociado", key: "DocRelacionado" },
        {
          label: "Referencia",
          key: "Referencia",
          popover: true,
          titlePopover: "Referencia",
        },
        { label: "Moneda", key: "Moneda" },
        { label: "Monto Total", key: "TotalFactura", separator: true },
        {
          label: "",
          key: "Estatus",
          custom: (value) => (
            <Button
              className={"btn-oval"}
              style={{
                background:
                  value.EstatusFactura === "Pagada" ? "#4896db" : "#bfbcbc",
                color: value.EstatusFactura === "Pagada" ? "" : "#fff",
              }}
              color={value.EstatusFactura === "Pagada" ? "primary" : ""}
              onClick={() => { }}
            >
              {value.EstatusFactura}
            </Button>
          ),
        },
      ],
      subTable: [
        { label: "Código", key: "Code" },
        { label: "Concepto", key: "Concept" },
        { label: "Monto", key: "Amount", separator: true },
      ],
      fullLoader: false,
    };
  }

  custom = (value) => (
    <em
      onClick={() => this.downloadPdf(value.ShipmentNo)}
      style={{ color: "#bf360c" }}
      className={"download fa-2x fas fa-file-pdf mr-2"}
    ></em>
  );

  downloadPdf = async (val) => {
    // try {
    //   let success = await axios.get(`${Services.GET_DOC_INVOICING.path}invoicing/getdoc/${val}`);
    //   for (let index = 0; index < success.data.data.length; index++) {
    //     const e = success.data.data[index];
    //     const url = `https://poseidonbooking.azurewebsites.net/documents/downloadFile/${e.URL}`;
    //     try {
    //       let success = await axios.get(`${url}`);
    //       const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.pdf';
    //       const linkSource = `data:application/pdf;base64,${success.data.data}`;
    //       saveAs(linkSource, fileName);
    //     }
    //     catch (err) {
    //       console.error(err);
    //     }
    //   }
    // }
    // catch (err) {
    //   console.error(err);
    // }
  };
  /*=============================================
  =            Function for formCreate          =
  =============================================*/
  getCat = async () => {
    let { catalogCombo } = this.state;

    let data = {
      client: this.state.clientesSelect,
    };

    try {
      let success = await axios.post(
        `${Services.Api_Costos.path}/invoicing/getcatalog`,
        data
      );
      let Concepto = success.data.data.Concepto.map((e) => {
        return {
          value: e.Concepto,
          label: e.Concepto ? e.Concepto : "Sin Valor",
        };
      });
      let EstatusFactura = success.data.data.EstatusFactura.map((e) => {
        return {
          value: e.EstatusFactura,
          label: e.EstatusFactura ? e.EstatusFactura : "",
        };
      });
      let Proceso = success.data.data.Proceso.map((e) => {
        return { value: e.Proceso, label: e.Proceso ? e.Proceso : "" };
      });

      let NewEstatusFactura = [];
      let NewConcepto = [];

      Concepto.forEach((e) => {
        if (e.value !== "") {
          NewConcepto.push(e);
        }
      });

      EstatusFactura.forEach((e) => {
        if (e.value !== null) {
          NewEstatusFactura.push(e);
        }
      });

      NewConcepto.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });

      Proceso.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });

      let newcatalogCombo = catalogCombo;
      newcatalogCombo.Concepto = NewConcepto;
      newcatalogCombo.EstatusFactura = NewEstatusFactura;
      newcatalogCombo.Proceso = Proceso;

      this.setState({
        catalogCombo: newcatalogCombo,
        respladoCatalogCombo: newcatalogCombo,
      });
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  };

  /*=============================================
  =            LifeCycle            =
  =============================================*/
  componentDidMount = () => {
    this.getCat();
    this.getResults();
  };

  refreshTable = (results) => {
    this.setState({
      data: results,
    });
  };

  downloadExcel = async () => {
    this.setState({
      loading: true,
    });
    let dateNow = new Date();
    let titleDownload = `Facturacion${dateNow.getDate() +
      "" +
      (dateNow.getMonth() + 1) +
      "" +
      dateNow.getFullYear()}`;

    let {
      numberPageInvoice,
      limitInvoice,
      searchInvoice,
      clientesSelect,
      filters,
      search,
    } = this.state;

    let data = {
      client: clientesSelect,
      pag: numberPageInvoice,
      limit: limitInvoice,
      search: searchInvoice,
      allData: true,
      search: search,
    };

    axios
      .post(
        `${Services.Api_Costos.path}/invoicing/downloadgetdataall?${filters}`,
        data
      )
      .then((success) => {
        const fileName = titleDownload + ".xlsx";
        const linkSource = `data:application/xlsx;base64,${success.data.dataReport}`;
        saveAs(linkSource, fileName);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        // this.setState({ loading: false });
      });
  };

  changeFilter = (filters, flat) => {
    let FiltersQuery = "";
    let keys = Object.keys(filters);
    let newCatconcepto = false;
    keys.map((e) => {
      if (flat) {
        filters["Concepto"] = "";
      }
      if (e === "Proceso") {
        if (filters[e] !== "") {
          newCatconcepto = true;
        }
      }
      if (filters[e]) FiltersQuery += e + "=" + filters[e] + "&";
    });

    this.setState(
      {
        filters: FiltersQuery,
        fullLoader: false,
        newCatconcepto,
        numberPageInvoice: 1,
      },
      () => {
        this.getResults();
      }
    );
  };

  handleSearch = (name) => (event) =>
    this.setState(
      { [name]: event.target.value }
    );
  setSearch = () => {
    let search = this.state.search;
    this.getResults();
  };

  getResults = async () => {
    let {
      catalogCombo,
      newCatconcepto,
      numberPageInvoice,
      limitInvoice,
      searchInvoice,
      clientesSelect,
      filters,
      allData,
      search,
      userId,
    } = this.state;

    let data = {
      client: clientesSelect,
      pag: numberPageInvoice,
      limit: limitInvoice,
      search: searchInvoice,
      allData: allData,
      search: search,
      userId: userId,
    };
    let yearCat = [];
    let newCAT = [];

    axios
      .post(`${Services.Api_Costos.path}/invoicing/getdataall?${filters}`, data)
      .then((success) => {
        if (success.data && success.data.data) {
          let findGraph = false;
          success.data.repChartFacturacion.map((e) => {
            yearCat.map((f) => {
              if (e.Switche === f.key) {
                findGraph = true;
              } else {
                findGraph = false;
              }
              return f;
            });
            if (!findGraph) {
              let year = {
                value: e.Switche,
                key: e.Switche,
                flag: e.Switche,
                text: e.Switche,
              };
              yearCat.push(year);
            }
          });

          var result = yearCat.reduce((unique, o) => {
            if (
              !unique.some((obj) => obj.value === o.value && obj.key === o.key)
            ) {
              unique.push(o);
            }
            return unique;
          }, []);

          success.data.data.map((e) => {
            let findGraphcat = false;

            if (newCatconcepto) {
              newCAT.map((f) => {
                if (e.Concepto === f.value) {
                  findGraphcat = true;
                }
                return f;
              });
              if (!findGraphcat) {
                let year = {
                  value: e.Concepto,
                  label: e.Concepto,
                };
                newCAT.push(year);
              }
            }
          });

          let ahoracatalogCombo = { ...catalogCombo };

          let NewConcepto = [];

          newCAT.forEach((e) => {
            if (e.value !== "") {
              NewConcepto.push(e);
            }
          });

          NewConcepto.sort(function (a, b) {
            if (a.value < b.value) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
            return 0;
          });
          ahoracatalogCombo.Concepto = NewConcepto;

          this.setState(
            {
              fecha:
                success.data.data.length > 0
                  ? success.data.data[0].FechaActualizacion
                  : "",
              CupoCartera: success.data.repChartCupoCartera
                ? success.data.repChartCupoCartera
                : [],
              fitertcatalogCombo: ahoracatalogCombo,
              repChartFacturacion: success.data.repChartFacturacion
                ? success.data.repChartFacturacion
                : [],
              dataTable: success.data.data ? success.data.data : [],
              yearCat: result,
              cuartaChar: success.data.repChartEstatus
                ? success.data.repChartEstatus
                : [],
              tercerChart: success.data.repChartConceptos
                ? success.data.repChartConceptos
                : [],
              Amoun: success.data ? success.data.totalDocumento : "0",
              toltlAmoun: success.data
                ? "$" + Number(success.data.montoTotal).toLocaleString("En-us")
                : "0",
              fullLoader: true,
              pages: success.data ? success.data.totalPage : 1,
            },
            () => {
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  changePage = (changePage) => {
    this.setState(
      {
        numberPageInvoice: changePage,
        fullLoader: false,
      },
      () => {
        this.getResults();
      }
    );
  };

  render() {
    let {
      fecha,
      CupoCartera,
      newCatconcepto,
      fitertcatalogCombo,
      form,
      fullLoader,
      loading,
      tercerChart,
      cuartaChar,
      catalogCombo,
      columnsTable,
      subTable,
      yearCat,
      Amoun,
      toltlAmoun,
      search,
      pages,
      numberPageInvoice,
      repChartFacturacion,
      dataTable,
    } = this.state;
    return (
      <>
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <div className="content-heading">
            <legend className="legendTitle">
              <img
                src="https://fsposeidon.blob.core.windows.net/roldan/icon_facturacion.png"
                height="28"
                width="28"
              />
              Facturación y Costos
            </legend>
            <legend className="date-header mr-3">
              <i>Fecha de corte: </i> {fecha}
            </legend>
          </div>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="mb-2">
              <span style={{ fontSize: "14px", fontWeight: "bold", }}>
                Facturación
              </span>
            </div>
            <Row>
              <div className="col-12 mb-5">
                <Graphics
                  yearCat={yearCat}
                  cuartaChar={cuartaChar}
                  tercerChart={tercerChart}
                  CupoCartera={CupoCartera}
                  data={repChartFacturacion}
                ></Graphics>
              </div>
              <Col xs={12} sm={12} md={12} lg={12}>
                <center className="mb-4">
                  <h4 >Filtros</h4>
                </center>
                <Filters
                  catalogCombo={
                    newCatconcepto ? fitertcatalogCombo : catalogCombo
                  }
                  sendQuery={this.changeFilter}
                  refreshTable={this.refreshTable}
                // data={this.state.data}
                ></Filters>
              </Col>
            </Row>
          </Col>
          <Card className="myCard">
            <CardBody>
              <Col md={12} xl={12}>
                <Row>
                  {/* <Col md={12} xl={12}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginBottom: "2em",
                      }}
                      className="row"
                    >
                      <span className="">Facturación</span>
                      <br></br>
                    </div>
                  </Col> */}
                  {/* <Col md={12} xl={12}>
                    <Graphics
                      yearCat={yearCat}
                      cuartaChar={cuartaChar}
                      tercerChart={tercerChart}
                      CupoCartera={CupoCartera}
                      data={repChartFacturacion}
                    ></Graphics>
                  </Col> */}
                  {/* <div style={{ height: 800 }}></div> */}
                  {/* <Col md={12} xl={12}>
                    <center>
                      <h4 className="mb-3 py-2">Filtros</h4>
                    </center>
                    <Row>
                      <Col md={12} xl={12}>
                        <Filters
                          catalogCombo={
                            newCatconcepto ? fitertcatalogCombo : catalogCombo
                          }
                          sendQuery={this.changeFilter}
                          refreshTable={this.refreshTable}
                        // data={this.state.data}
                        ></Filters>
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
              </Col>
              {/* <div style={{ height: 67 }}></div> */}
              <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6 row pb-3">
                  <div className="col-10">
                    <Search
                      input={{
                        icon: "search",
                        iconPosition: "left",
                        fluid: true,
                      }}
                      placeholder="..."
                      value={search}
                      open={false}
                      name="search"
                      id="search"
                      onSearchChange={this.handleSearch("search")}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          this.setState(
                            {
                              fullLoader: false,
                              numberPageInvoice: 1,
                            },
                            () => this.setSearch()
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-2 visible-xls">
                    {loading ? (
                      <center>
                        {" "}
                        <Icon
                          loading={loading}
                          style={{ marginTop: "9px" }}
                          name="circle notch"
                          size="large"
                        />
                      </center>
                    ) : (
                      <center>
                        <em
                          style={{ color: "#43a047", marginTop: "9px" }}
                          onClick={this.downloadExcel}
                          className="download fa-2x fas fa-file-excel mr-2"
                        ></em>
                      </center>
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-xs-6 col-md-5 col-lg-5 total-amount">
                      <p className="total-amount">Monto Total Documentos</p>
                      <div className="h4 total-amount">{toltlAmoun}</div>
                    </div>
                    <div className="col-xs-6 col-md-5 col-lg-5 total-amount">
                      <p className="total-amount">Total Documentos</p>
                      <div className="h4 total-amount">
                        {Number(Amoun).toLocaleString("En-us")}
                      </div>
                    </div>
                    <div className="col-2 visible-xls-desktops">

                      {loading ? (
                        <center>
                          {" "}
                          <Icon
                            loading={loading}
                            style={{ marginTop: "9px" }}
                            name="circle notch"
                            size="large"
                          />
                        </center>
                      ) : (
                        <center>
                          <em
                            style={{ color: "#43a047", marginTop: "9px" }}
                            onClick={this.downloadExcel}
                            className="download fa-2x fas fa-file-excel mr-2"
                          ></em>
                        </center>
                      )}
                    </div>
                  </div>


                </div>
              </div>
              <DataTable
                data={dataTable}
                columnsTable={columnsTable}
                subTable={subTable}
                activePage={numberPageInvoice}
                numberPageInvoice={pages}
                handlePaginationChange={this.changePage}
              ></DataTable>
            </CardBody>
          </Card>
        </ContentWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(FacturacioCostos);
