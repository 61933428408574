import React from 'react';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
// ? components
import { RadioSelect } from '../../components/RadioButtons';
import Header from '../../components/Header';
// ? store
import CreateContext from '../../store/CreateContext';

function ReportTypeComponent(){
  const context = React.useContext(CreateContext);
  return (
    <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6'>
      <div className='ml-3 mb-1'>
        <Header
          as='h6'
          icon='file alternate'
          title='Tipo De Reporte'
        />
      </div>
      <RadioGroup className='ml-3' row aria-label='report-type' name='report-type' value={context.report_type} onClick={(evt) => {console.log('this is the event', evt.target); if(evt.target.value) context.onChangeType(evt.target.value);}}>
        <FormControlLabel
          value='ondemand'
          control={<RadioSelect/>}
          label='Bajo demanda'
        />
        <FormControlLabel
          value='scheduled'
          control={<RadioSelect/>}
          label='Programado'
        />
      </RadioGroup>
    </div>
  );
}

export default ReportTypeComponent;