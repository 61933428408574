import React from 'react';
import { ConfigProvider } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

function EmptyComponent(){
  return (
    <div style={{ textAlign: 'center' }}>
      <InboxOutlined style={{ fontSize: 30 }} />
      <p>Sin datos</p>
    </div>
  );
}

function CustomProvider(props){
  return (
    <ConfigProvider renderEmpty={EmptyComponent}>
      {props.children}
    </ConfigProvider>
  );
}

export default CustomProvider;