import React, { Component, Suspense } from 'react';
import StepperEvent from '../../../Components/StepperEvent';
import { withRouter } from 'react-router-dom';
import './styles.css';
import { Services } from '../../Services';
import axios from 'axios';
import { Card, CardBody, Col, Row } from 'reactstrap';
const CardInfo = React.lazy(() => import('../../../Components/Cards'));

class Summary extends Component {
  state = {
    FechaActualizacion: this.props.FechaActualizacion,
    NoIngreso: this.props.NoIngreso,
    id: this.props.match.params.id,
    CardIndoHeaders: { title: 'Información del Almacén', subTitle: '', Icon: '', style: { color: '#25dfeae3' } },
    Info: [{ Referencia: 'CERSKJ8', Aduana: 'CNKJ876', Proyecto: 'CKJHSKDJH', Totales: 'No Aplica', Iniciales: '100cm', Anticipadas: '28-09-2019', Ordinarias: 'Transister', Temporales: '', TotalIVA: '$200,000', TotalAraceles: '$200,000', TotalTributos: '$400,000' }],
    config: [
      { title: 'Referencia del Cliente', positionTitleLeft: true, id: 'RefCliente', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Doc. de Transporte', id: 'DocTransporte', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Documento', id: 'Documento', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Do de Aduana ', id: 'DoAduana', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Tipo de Almacén', id: 'TipoAlmacen', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Almacén', id: 'Almacen', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Número de Recepción', id: 'NoIngreso', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Cantidades Esperadas', id: 'CantidadesEsperadas', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Cantidades Recibidas', id: 'CantidadesRecibidas', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Cantidades con Calidad', id: 'CantidadesCalidad', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Tipo de Vehículo', id: 'TipoVehiculo', positionTitleLeft: true, className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Observaciones', id: 'ObservacionesRecepcion', positionTitleLeft: true, className: 'col-md-3 col-lg-12 col-sm-6' },
    ],
    steps: [
      {
        label: 'Notificación de Ingreso',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Llegada a Almacén',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Inicio de Descargue',
        value: '',
        id: 3,
        completed: false,
        order: 3,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Fin de Descargue',
        value: '',
        id: 4,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Manifiesto (Público)',
        value: '',
        id: 6,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: ''
      },
      {
        label: 'Vencimiento de Manifiesto',
        value: '',
        id: 7,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      },
      {
        label: 'Disponibilidad de Inventario',
        value: '',
        id: 8,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      }
    ],
  }

  componentDidMount = () => {
    this.getDataForId();
    this.setState({
      steps: this.props.steps,
      ReferenciaDeCliente: this.props.ReferenciaDeCliente,
      DocTransporte: this.props.DocTransporte,
      NoIngreso: this.props.NoIngreso,
      Estatus: this.props.Estatus,
      cliente: this.props.cliente,
      NIT: this.props.NIT,
      Grupo: this.props.Grupo,
      FechaActualizacion: this.props.FechaActualizacion
    });
  }

  componentWillReceiveProps = (nextProps) => {
    const newProp = nextProps.match.params.id ? nextProps.match.params.id : this.props.match.params.id;
    this.setState({
      steps: nextProps.steps,
      ReferenciaDeCliente: nextProps.ReferenciaDeCliente,
      DocTransporte: nextProps.DocTransporte,
      NoIngreso: nextProps.NoIngreso,
      Estatus: nextProps.Estatus,
      cliente: nextProps.cliente,
      NIT: nextProps.NIT,
      Grupo: nextProps.Grupo,
      FechaActualizacion: nextProps.FechaActualizacion,
      id: newProp,
    }, () => this.getDataForId());
  }

  getDataForId = () => {
    axios.get(`${Services.Get_Storage_Income_ForId.path}/storage/dataStorageIncome/${this.state.id}`).then(success => {
      let Info = [];
      Info.push(success.data.data[0]);
      this.setState({
        Info
      });
    }).catch(error => {
      console.warn(error);
    });
  }
  render() {
    let { steps, cliente, NIT, Grupo, FechaActualizacion } = this.state;
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <Row>
              <div className='col-xs-2 col-md-1 col-lg-1 d-flex justify-content-center d-flex align-items-center'>
                <i style={{ color: '#001489' }} className="fa-3x fas fa-sort-amount-down"></i>
              </div>
              <div className='col-xs-10 col-md-6 col-lg-6'>
                <p className='sizeTextComents' >{cliente} ({NIT}) / {Grupo}</p>
                <p className='sizeTextComents' style={{ color: '#0099ff' }}>{this.state.NoIngreso}</p>
                <p className='sizeTextComents' > {this.state.ReferenciaDeCliente}</p>
                <p className='sizeTextComents' > {this.state.DocTransporte}</p>
              </div>
              <div className='col-xs-12 col-md-5 col-lg-5 d-flex justify-content-end'>
                <span className='sizeTextComents' > <i>Última Actualización:</i>  {FechaActualizacion}</span>
              </div>
              <div className='col-12 mt-1'>
                {this.state.Estatus}
              </div>
            </Row >
            <div className="col-12">
              <StepperEvent steps={steps} />
            </div>
          </CardBody >
        </Card >
        <Suspense fallback={<div>Loading...</div>}>
          <CardInfo
            CardIndoHeaders={this.state.CardIndoHeaders}
            config={this.state.config}
            data={this.state.Info}
            cardInfon={true}>
          </CardInfo>
        </Suspense>
      </>
    );
  }
}

export default withRouter(Summary);
