import React, { Component } from 'react';
import ContentWrapper from '../../../../../../template/Layout/ContentWrapper';
import { Row } from 'reactstrap';
import Comments from '../../../../../Components/Comments';
import Documents from '../../../../../Components/Documents';
import Summary from './Summary';
import Events from './Events';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Services } from '../../../../Services';
import axios from 'axios';

class ExportDetail extends Component {
  state = {
    dataDoc: [],
    id: this.props.match.params.id,
    commentsData: [],
    catalogsData: [
      {
        ComTypeId: 1,
        ModuleId: 1,
        ComType: 'Supplier Follow Up',
        value: 1,
        label: 'Supplier Follow Up',
      },
      {
        ComTypeId: 2,
        ModuleId: 1,
        ComType: 'Order Quality',
        value: 2,
        label: 'Order Quality',
      },
      {
        ComTypeId: 3,
        ModuleId: 1,
        ComType: 'Order Quantities',
        value: 3,
        label: 'Order Quantities',
      },
      {
        ComTypeId: 10,
        ModuleId: 1,
        ComType: 'Other',
        value: 10,
        label: 'Other',
      },
    ],
    steps: [
      {
        label: 'Instrucción Cliente AA (Reserva)',
        value: '',
        id: 2,
        completed: false,
        order: 1,
        middleStep: '',
      },
      {
        label: 'Autorización de Embarque',
        value: '',
        id: 8,
        completed: false,
        order: 2,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Cierre Físico',
        value: '',
        id: 13,
        completed: false,
        order: 3,
        middle: false,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Manifiesto de Carga',
        value: '',
        id: 16,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: '',
      },
      {
        label: 'Facturación',
        value: '',
        id: 18,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: '',
      },
      {
        label: 'Radicación Factura',
        value: '',
        id: 19,
        completed: false,
        order: 6,
        idStep: 'step5',
        middleStep: '',
      },
    ],
  };

  componentDidMount = () => {
    this.getEvents();
    this.getDataForId();
  };

  getDataForId = () => {
    axios
      .get(
        `${Services.Get_Traceability_Export_ForId.path}/customs/dataCustomExport/${this.state.id}`
      )
      .then((success) => {
        let commentsData = [];
        let data = {
          ComType: '',
          ComTypeId: 1,
          Comment: success.data.data.infoExport[0].Observaciones,
          CommentId: '',
          Date: success.data.data.infoExport[0].Observaciones
            ? success.data.data.infoExport[0].FechaInstruccionCliente
            : '',
          Module: 'Comentario',
          ModuleId: 1,
          RowId: '',
          UpdateBy: 'API',
        };
        commentsData.push(data);
        let Info = [];
        Info.push(success.data.data.infoExport[0]);
        this.setState(
          {
            commentsData,
            HBL_HAWB: success.data.data.HBL_HAWB
              ? success.data.data.infoExport[0].HBL_HAWB
              : '',
            Estatus: success.data.data.infoExport
              ? success.data.data.infoExport[0].Estatus
              : '',
            DocTransporte: success.data.data.infoExport
              ? success.data.data.infoExport[0].DocTransporte
              : '',
            DOAduanaExpo: success.data.data.infoExport
              ? success.data.data.infoExport[0].DOAduanaExpo
              : '',
            cliente: success.data.data.infoExport ? Info[0].Cliente : '',
            NIT: success.data.data.infoExport ? Info[0].NIT : '',
            Grupo: success.data.data.infoExport ? Info[0].Grupo : '',
            FechaActualizacion: success.data.data.infoExport
              ? Info[0].FechaActualizacion
              : '',
          },
          () => {
            this.getDocuments();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getDocuments = () => {
    axios
      .get(
        `${Services.Get_Doc_Export_ForId.path}/document/Documents/${this.state.DOAduanaExpo}`
      )
      .then((success) => {
        let newData = [];

        success.data.data.recordset.map((e) => {
          let obj = {
            Name: e.Nombre.split('.')[0],
            Type: e.ext ? e.ext : 'PDF',
            Category: '',
            CreationDate: e.Fecha,
            CreateBy: 'API',
            URL: e.URL,
            DocumentKeyAsString: e.URL,
            origin: 'expo',
            Descripcion: e.Descripcion,
          };
          newData.push(obj);
          return e;
        });
        this.setState({
          dataDoc: newData,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getEvents = () => {
    const { steps, id } = this.state;
    let moduleId = 2;
    // let idContainer = 1816;
    axios
      .get(`${Services.Get_Events_General.path}/events/all/${moduleId}/${id}`)
      .then((response) => {
        let update = false;
        for (let index = response.data.steps.length; index >= 0; index--) {
          const element = response.data.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = steps;
        let dataEvents = response.data.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {
            if (e.EventDescriptionId === f.id) {
              findNext = true;
              f.value = e.EventDate;
              if (e.InTimeLine === '1') {
                f.completed = true;
              } else {
                findNext = false;
              }
            } else {
              findNext = false;
            }
            if (findNext) {
              f.completed = true;
            }
            return e;
          });
          return f;
        });
        newSteps.sort(function (a, b) {
          return b.order - a.order;
        });
        let findTrue = false;
        newSteps.map((e) => {
          if (e.completed) {
            findTrue = true;
          }
          if (findTrue) {
            e.completed = true;
          }
          return e;
        });
        newSteps.sort(function (a, b) {
          return a.order - b.order;
        });
        this.setState({ steps: newSteps, allSteps: dataEvents }, () => { });
      })
      .catch((error) => { });
  };

  render() {
    const { commentsData, catalogsData, dataDoc } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <legend className="legendTitle">
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png"
              height="28"
              width="28"
            />
            Detalle de Aduana Exportación
          </legend>
        </div>
        <Tab
          menu={{ secondary: true, className: 'wrapped' }}
          menuPosition="right"
          panes={[
            {
              menuItem: 'Resumen',
              render: () => (
                <Summary
                  FechaActualizacion={this.state.FechaActualizacion}
                  cliente={this.state.cliente}
                  NIT={this.state.NIT}
                  Grupo={this.state.Grupo}
                  DOAduanaExpo={this.state.DOAduanaExpo}
                  Estatus={this.state.Estatus}
                  HBL_HAWB={this.state.HBL_HAWB}
                  DocTransporte={this.state.DocTransporte}
                  steps={this.state.steps}
                />
              ),
            },
            {
              menuItem: 'Eventos',
              render: () => (
                <Events
                  cliente={this.state.cliente}
                  NIT={this.state.NIT}
                  Grupo={this.state.Grupo}
                  DOAduanaExpo={this.state.DOAduanaExpo}
                  Estatus={this.state.Estatus}
                  HBL_HAWB={this.state.HBL_HAWB}
                  DocTransporte={this.state.DocTransporte}
                  steps={this.state.steps}
                  allSteps={this.state.allSteps}
                />
              ),
            },
            {
              menuItem: 'Comentarios',
              render: () => (
                <Comments data={commentsData} catalogs={catalogsData} />
              ),
            },
            {
              menuItem: 'Documentos',
              render: () => (
                <Documents
                  columDescripcion={true}
                  dataDoc={dataDoc}
                  rolId={12}
                  shipment=""
                  shipmentDataPlane=""
                />
              ),
            },
          ]}
        />
      </ContentWrapper>
    );
  }
}

export default withRouter(ExportDetail);
