export const Services = {
   "TICKETS": {
      "path": "https://servicedeskapitickets.azurewebsites.net/"
   },
   "REPORTS": {
      "path": "https://roldanapireport.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO SET REPORT"
   },
   "REPORT_SERVICE": {
      "path": "https://servicereportapi.azurewebsites.net",
      "descrp": "API Reportes"
   }
}