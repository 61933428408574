import React, { Component, Suspense } from 'react';
import { Grid } from 'semantic-ui-react';
import './styles/cards.scss';
import { searchBy } from './search';
import axios from 'axios';
import { Icon, Pagination } from 'semantic-ui-react';
import Select from 'react-select';
import {
  Button,
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { saveAs } from 'file-saver';
// import Modal from '../../../template/Modal/AdvancedSearch'
import IMG from '../../../../resources/filter.png';
import { WARNIMG } from '../../../../resources/warnimage';
import Authentication from "../../../../services/Authentication";
import { Dropdown, Button as BtnS } from 'semantic-ui-react';

const CardData = React.lazy(() => import('./card'));

// const urlDinamicReport = 'http://localhost:3050/report/dynamic/xlsx';
const urlDinamicReport =
  'https://servicereportapi.azurewebsites.net/report/dynamic/xlsx';

const Icn = () => (
  <img className="_imgdropdown" height="20px" width="20px" src={WARNIMG} alt='img_drop' />
);

const SingleText = (props) => {
  return (
    <em className="_format_text">{props.text}</em>
  );
};

const opBtns = [
  { key: 'allOrders', icon: '', text: <SingleText text={'Todas las Operaciones'} />, value: 'allOperation' },
  { key: 'newCusComment', icon: <Icn />, text: <SingleText text={'Arribo sin fecha de levante'} />, value: 'onlyAlerts' }
];

const auth = new Authentication();
let RoleId = auth.getAuthentication("RolId");
class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customWidth: window.innerWidth,
      _user: props._user ? props._user : {},
      currentValues: [],
      data: [],
      isOpen: false,
      loaderExcel: false,
      cards: '',
      search: '',
      toggleDrawer: this.props.toggleDrawer,
      resetCards: this.props.reset,
      labelStatus: this.props.labelStatus,
      dataSearch: this.props.dataSearch,
      activePage: 1,
      totalPages: 0,
      infoCard: false,
      allowUser: ["1", "2", "3", "4", "5"],
      advancedSearchData: this.props.advancedSearchData
        ? this.props.advancedSearchData
        : [],
    };
  }

  handleResize = () => {
    this.setState({ customWidth: window.innerWidth });
  };
  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  componentDidMount = () => {
    if (this.props.cardInfon) {
      this.setState(
        {
          data: this.props.data,
          infoCard: true,
        },
        () => this.buildCards()
      );
    } else {
      this.setState(
        {
          data: this.props.data,
          dataSearch: [...this.props.data],
          allMyData: [...this.props.data],
        },
        () => this.initPages()
      );
    }
    if (this.props.arrivoAlert) {
      this.setState({ dropValue: 'onlyAlerts' });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.data)
      this.setState(
        {
          data: nextProps.data,
          dataSearch: nextProps.data,
          allMyData: nextProps.data,
        },
        () => {
          this.initPages();
        }
      );
    if (nextProps.labelStatus)
      this.setState({ labelStatus: nextProps.labelStatus }, () => {
        this.buildCards();
        try {
          let selectedFileDoom = document.getElementById(nextProps.labelStatus);
          selectedFileDoom.classList.add('_cardSelected');
        } catch (e) { }
      });
  };

  initPages = () => {
    let pages = Math.ceil(parseInt(this.state.dataSearch.length) / 5);
    if (pages === 0) pages = 1;
    this.setState(
      {
        activePage: 1,
        totalPages: pages,
      },
      () => {
        this.buildPageResults();
      }
    );
  };

  buildPageResults = () => {
    let { activePage, totalPages } = this.state;
    let data = this.setResultsByPage(
      activePage,
      this.state.dataSearch,
      totalPages,
      10
    );
    this.setState(
      {
        data,
      },
      () => {
        this.buildCards();
      }
    );
  };

  setResultsByPage = (page, all, pages, itemsByPage) => {
    page = page - 1;
    const pag = all.slice(page * itemsByPage, (page + 1) * itemsByPage);
    return pag;
  };

  buildCards = () => {
    let { data } = this.state;
    let tags = data.map((e, index) => {
      return (
        <div
          key={index}
          className={this.props.config.length > 12 ? '' : ''}
          onClick={this.props.cardInfon ? () => { } : this.state.toggleDrawer(e)}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <CardData
              className={this.props.config.length > 12 ? '' : ''}
              CardIndoHeaders={this.props.CardIndoHeaders}
              infoCard={this.state.infoCard}
              key={index}
              config={this.props.config}
              data={e}
              element={e}
            ></CardData>
          </Suspense>
        </div>
      );
    });
    this.setState(
      {
        cards: tags,
      },
      () => this.setState({ cards: tags })
    );
  };

  handleChange = (name) => (event) => {
    if (this.props.newSearch) {
      this.props.newSearch(event.target.value);
    } else {
      let { allMyData } = this.state;
      this.setState(
        {
          [name]: event.target.value,
        },
        () => {
          if (this.state.search === '') {
            this.setState(
              {
                dataSearch: allMyData,
              },
              () => this.initPages()
            );
          } else {
            let results = searchBy(
              this.state.search,
              allMyData,
              this.props.config
            );
            this.setState(
              {
                dataSearch: results,
              },
              () => this.initPages()
            );
          }
        }
      );
    }
  };

  changePage = (page) => {
    this.setState(
      {
        activePage: page,
      },
      () => {
        this.buildPageResults();
      }
    );
  };

  handlAdvancedSearch = (search) => {
    this.props.backRes(search);
    this.setState({ isOpen: false });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleSearchChange = (e, i) => {
    this.setState({ search: i.value });
  };

  splitArray = (list, howMany) => {
    var idx = 0;
    var result = [];

    while (idx < list.length) {
      if (idx % howMany === 0) result.push([]);
      result[result.length - 1].push(list[idx++]);
    }
    return result;
  };

  callGenerate = (objPost) => {
    if (objPost.options.headers.length > 0) {
      axios
        .post(urlDinamicReport, objPost)
        .then((success) => {
          // axios.post('http://localhost:3003/reports/dynamic',objPost).then(success =>{
          if (success.data.base64) {
            //const fileName = (Math.floor(Math.random() * 900000) + 100000) + Math.floor(Date.now() / 1000) + '.xlsx';

            let fileName;
            this.props.nameXlsx
              ? (fileName = this.props.nameXlsx)
              : (fileName =
                Math.floor(Math.random() * 900000) +
                100000 +
                Math.floor(Date.now() / 1000) +
                '.xlsx');

            const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
            saveAs(linkSource, fileName);
            this.setState({ loaderExcel: false });
          } else console.warn('Error downloading file', success);
        })
        .catch((error) => {
          console.error('errorr', error);
          this.setState({ loaderExcel: false });
        });
    }
  };

  generateExcel = () => {
    this.setState({ loaderExcel: true });
    let { data } = this.state;
    let headers = [];
    let keys = [];
    this.props.config.map((e) => {
      if (e.xls) {
        if (e.first) {
          if (e.firstExpo) {
            headers.push('Embarque');
            keys.push('DoEmbarque');
            headers.push('Documento de Transporte');
            keys.push('DocTransporte');
          } else {
            headers.push('Embarque');
            keys.push('DoEmbarque');
            headers.push('Documento de Transporte');
            keys.push('HBL_HAWB');
          }
        } else {
          if (e.titleId) {
            headers.push(e.titleId);
          } else {
            headers.push(e.title);
          }
          if (e.idExcel) {
            keys.push(e.idExcel);
          } else {
            keys.push(e.id);
          }
        }
      }
      return e;
    });
    if (this.props.urlReport) {
      axios
        .post(this.props.urlReport, this.props.paramsxml)
        .then((response) => {
          if (response.data.data) {
            if (response.data.data.length > 10000) {
              let obj = {
                data: response.data.data.splice(0, 10000),
                options: {
                  headers: headers,
                  keys: keys,
                  image: {
                    name: 'roldan',
                  },
                },
              };
              this.callGenerate(obj);
            } else {
              let obj = {
                data: response.data.data,
                options: {
                  headers: headers,
                  keys: keys,
                  image: {
                    name: 'roldan',
                  },
                },
              };
              this.callGenerate(obj);
            }
          }
        })
        .catch((error) => {
          console.error('errorr', error);
          this.setState({ loaderExcel: false });
        });
    } else {
      let obj = {
        data: data,
        options: {
          headers: headers,
          keys: keys,
          image: {
            name: 'roldan',
          },
        },
      };
      if (headers.length > 0) {
        axios
          .post(urlDinamicReport, obj)
          .then((success) => {
            // axios.post('http://localhost:3003/reports/dynamic',obj).then(success =>{
            if (success.data.base64) {
              //const fileName = (Math.floor(Math.random() * 900000) + 100000) + Math.floor(Date.now() / 1000) + '.xlsx';

              let fileName;
              this.props.nameXlsx
                ? (fileName = this.props.nameXlsx)
                : (fileName =
                  Math.floor(Math.random() * 900000) +
                  100000 +
                  Math.floor(Date.now() / 1000) +
                  '.xlsx');

              const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
              saveAs(linkSource, fileName);
              this.setState({ loaderExcel: false });
            } else console.warn('Error downloading file', success);
          })
          .catch((error) => {
            console.error('errorr', error);
            this.setState({ loaderExcel: false });
          });
      }
    }
  };

  toggle = (evt) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getAdvancedData = (e, index, firstIndex) => {
    return (
      <Row key={index}>
        {e.map((f, index1) => {
          return this.getFilterData(f, index1, index, firstIndex);
        })}
      </Row>
    );
  };

  resetData = () => {
    let newAdvanced = this.state.advancedSearchData;
    newAdvanced.map((e) => {
      e.data.map((f) => {
        f.map((g) => {
          if (g.type === 'dateRange') {
            g.date = {
              init: '',
              end: '',
            };
          } else {
            g.value = '';
          }
          return g;
        });
        return f;
      });
      return e;
    });
    this.setState({ advancedSearchData: newAdvanced });
  };

  findAdvanced = () => {
    let obj = [];
    this.state.advancedSearchData.map((e) => {
      e.data.map((f) => {
        f.map((g) => {
          if (!g.undefined) {
            let newPush = {
              id: g.id,
              UseIN: g.UseIN ? g.UseIN : false,
              isDate: g.type === 'dateRange' ? true : false,
              subconsulta: g.subconsulta ? g.subconsulta : false,
              value:
                g.type === 'dateRange'
                  ? g.date
                  : g.type === 'select'
                    ? g.value
                      ? g.value.value
                      : ''
                    : g.value,
            };
            obj.push(newPush);
          }
          return g;
        });
        return f;
      });
      return e;
    });

    this.setState({ isAdSearchActive: true, isButtonActive: true });
    this.props.advancedSearch(obj);
    this.toggle();
  };

  handleOnChangeData = (evt, { value }) => {
    this.setState({ currentValues: value }, () => {
      // this.getFilterData();
    });
  };

  getFilterData = (e, index, secondIndex, firstIndex) => {
    switch (e.type) {
      case 'text':
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? '!' : ''}
              <InputGroup>
                <Input
                  type="text"
                  value={e.value}
                  name={e.title}
                  onChange={(e) => {
                    let newAdvanced = this.state.advancedSearchData;
                    if (newAdvanced[firstIndex]) {
                      if (newAdvanced[firstIndex].data) {
                        if (newAdvanced[firstIndex].data[secondIndex]) {
                          if (
                            newAdvanced[firstIndex].data[secondIndex][index]
                          ) {
                            newAdvanced[firstIndex].data[secondIndex][
                              index
                            ].value = e.target.value;
                            this.setState({ advancedSearchData: newAdvanced });
                          }
                        }
                      }
                    }
                  }}
                />
              </InputGroup>
            </label>
          </div>
        );
      case 'select':
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? '!' : ''}
              <Select
                placeholder=""
                value={e.value}
                options={e.values}
                onChange={(value) => {
                  let newAdvanced = this.state.advancedSearchData;
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (newAdvanced[firstIndex].data[secondIndex][index]) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].value = value;
                          this.setState({ advancedSearchData: newAdvanced });
                        }
                      }
                    }
                  }
                }}
              />
            </label>
          </div>
        );
      case 'multipleSelect':
        let ejemplos = [
          { key: 9, text: 'En Inventari', value: 9 },
          { key: 10, text: 'Despachado', value: 10 },
          { key: 11, text: 'Levantado', value: 11 },
          { key: 12, text: 'Instruccionado', value: 12 },
        ];
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? '!' : ''}
              <Dropdown
                className="NewClass"
                // onChange={this.handleOnChangeData}
                onChange={(evt, { value }) => {
                  let newAdvanced = this.state.advancedSearchData;

                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (newAdvanced[firstIndex].data[secondIndex][index]) {

                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].value = value;

                          this.setState({
                            advancedSearchData: newAdvanced,
                            currentValues: value,
                          });
                        }
                      }
                    }
                  }
                }}
                id={e.id}
                options={e.values}
                clearable
                search
                selection
                fluid
                value={this.state.currentValues}
                multiple
                allowAdditions
              />
            </label>
          </div>
        );
      case 'date':
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? '!' : ''}
              <InputGroup>
                <Input
                  type="date"
                  name={e.title}
                  onChange={(e) => {
                    let newAdvanced = this.state.advancedSearchData;
                    if (newAdvanced[firstIndex]) {
                      if (newAdvanced[firstIndex].data) {
                        if (newAdvanced[firstIndex].data[secondIndex]) {
                          if (
                            newAdvanced[firstIndex].data[secondIndex][index]
                          ) {
                            newAdvanced[firstIndex].data[secondIndex][
                              index
                            ].value = e.target.value;
                            this.setState({ advancedSearchData: newAdvanced });
                          }
                        }
                      }
                    }
                  }}
                />
              </InputGroup>
            </label>
          </div>
        );
      case 'dateRange':
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <center>
              {e.title} {e.undefined ? '!' : ''}
            </center>
            <div className="row">
              <div className="col-6">
                <label>
                  Inicio
                  <InputGroup>
                    <Input
                      type="date"
                      value={e.date.init}
                      placeholder=" "
                      name={e.title}
                      onChange={(e) => {
                        let newAdvanced = this.state.advancedSearchData;
                        if (newAdvanced[firstIndex]) {
                          if (newAdvanced[firstIndex].data) {
                            if (newAdvanced[firstIndex].data[secondIndex]) {
                              if (
                                newAdvanced[firstIndex].data[secondIndex][index]
                              ) {
                                newAdvanced[firstIndex].data[secondIndex][
                                  index
                                ].date.init = e.target.value;
                                this.setState({
                                  advancedSearchData: newAdvanced,
                                });
                              }
                            }
                          }
                        }
                      }}
                    />
                  </InputGroup>
                </label>
              </div>
              <div className="col-6">
                <label>
                  Fin
                  <InputGroup>
                    <Input
                      type="date"
                      value={e.date.end}
                      placeholder=" "
                      name={e.title}
                      onChange={(e) => {
                        let newAdvanced = this.state.advancedSearchData;
                        if (newAdvanced[firstIndex]) {
                          if (newAdvanced[firstIndex].data) {
                            if (newAdvanced[firstIndex].data[secondIndex]) {
                              if (
                                newAdvanced[firstIndex].data[secondIndex][index]
                              ) {
                                newAdvanced[firstIndex].data[secondIndex][
                                  index
                                ].date.end = e.target.value;
                                this.setState({
                                  advancedSearchData: newAdvanced,
                                });
                              }
                            }
                          }
                        }
                      }}
                    />
                  </InputGroup>
                </label>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label>
              {e.title} {e.undefined ? '!' : ''}
              <InputGroup>
                <Input type="text" name={e.title} />
              </InputGroup>
            </label>
          </div>
        );
    }
  };

  textChanger = (evt, data) => {
    this.setState({ isAdSearchActive: false, dropValue: data.value });
    this.props.seekAlerts(data.value, this.state.isButtonActive);
  }


  render() {
    let { activePage, totalPages, allowUser, customWidth } = this.state;

    return (
      <>
        {this.props.cardInfon || this.props.broswer === false ? (
          ''
        ) : (
          <Row>
            <Col xs={12} md={6} lg={4} className='mb-4'>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  Buscar
                </InputGroupAddon>
                <Input onChange={this.handleChange('search')} />
                <div className='d-flex align-items-center ml-1'>
                  <img
                    onClick={() => {
                      if (this.state.advancedSearchData.length > 1) {
                        this.setState({ isOpen: true });
                      }
                    }}
                    alt="img"
                    width={26}
                    height={26}
                    src={IMG}
                    style={{ cursor: 'pointer' }}
                  ></img>
                  {this.state.loaderExcel ? (
                    <Icon.Group size="large">
                      <Icon
                        loading={this.state.loaderExcel}
                        name="circle notch"
                        size="large"
                      />
                      <Icon color="green" name="file excel" />
                    </Icon.Group>
                  ) : (
                    <Icon
                      onClick={() => {
                        this.generateExcel();
                      }}
                      color="green"
                      className="__downloader"
                      name="file excel"
                      size="big"
                    />
                  )}
                </div>
              </InputGroup>

            </Col>
            {
              allowUser.includes(this.props._user ? this.props._user._rolId : '0') ? this.props.alert ?

                <Col xs={12} sm={12} md={12} lg={12} className='mb-3'>
                  <BtnS.Group floated='right' size={customWidth < 758 ? 'mini' : 'tiny'} className='alerts' compact={customWidth < 758 ? true : false}>
                    <BtnS>{this.props.arrivoAlert ? 'Arribo sin fecha de levante' : 'Todas las Operaciones'}</BtnS>
                    <Dropdown
                      className='button icon'
                      floating
                      name='default'
                      value={this.props.arrivoAlert ? 'onlyAlerts' : this.state.dropValue}
                      options={opBtns}
                      onChange={(evt, data) => { this.textChanger(evt, data); }}
                      trigger={<></>}
                    />

                  </BtnS.Group>
                  {/* <Grid.Column style={{ margin: '0', marginLeft: 'auto', padding: '0' }} width={7}>
                    <BtnS.Group floated='right' size="tiny" className='alerts'>
                      <BtnS>{this.props.arrivoAlert ? 'Arribo sin fecha de levante' : 'Todas las Operaciones'}</BtnS>
                      <Dropdown
                        className='button icon'
                        floating
                        name='default'
                        value={this.props.arrivoAlert ? 'onlyAlerts' : this.state.dropValue}
                        options={opBtns}
                        onChange={(evt, data) => { this.textChanger(evt, data); }}
                        trigger={<></>}
                      />

                    </BtnS.Group>
                  </Grid.Column> */}
                </Col>
                : ''
                : ''

            }
          </Row>
        )}
        {/* <Row>
          <div className={this.props.config.length > 12 ? 'containerCard' : 'col-12'}>
            {this.state.cards}
          </div>
        </Row> */}
        {this.state.cards}
        <Modal isOpen={this.state.isOpen} toggle={this.toggle} size="lg">
          <ModalHeader
            className="advancedSearchModal"
            style={{ background: '#001489', color: 'white' }}
            toggle={this.toggle}
          >
            <b>Búsqueda Avanzada</b>
          </ModalHeader>
          <ModalBody>
            {this.state.advancedSearchData.map((f, index1) => {
              return (
                <Container
                  key={index1}
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Row>
                    <Col style={{ fontWeight: 'bold' }}>
                      <center>{f.title ? f.title : `${'\u00A0'}`}</center>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {f.data.map((h, index2) => {
                        return this.getAdvancedData(h, index2, index1);
                      })}
                    </Col>
                  </Row>
                  {index1 !== this.state.advancedSearchData.length - 1 ? (
                    <div
                      style={{
                        background: '#f0f0f0',
                        height: 1,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Container>
              );
            })}
          </ModalBody>
          <ModalFooter className="modalFooterCenter">
            <center>
              <Button
                color="primary"
                onClick={() => {
                  this.findAdvanced();
                }}
              >
                Buscar
              </Button>{' '}
              {
                // <Button color="secondary" onClick={() =>{this.resetData();}}>Reset</Button>{' '}
              }
              <Button outline color="danger" onClick={this.toggle}>
                Cancelar
              </Button>
            </center>
          </ModalFooter>
        </Modal>
        {/* <CardData data={this.state.data} header ={this.props.headerCard}></CardData> */}
        {this.props.cardInfon || this.props.pagination === false ? (
          ''
        ) : (
          <div className="">
            <center>
              <Pagination
                activePage={activePage}
                ellipsisItem={null}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) =>
                  this.changePage(activePage)
                }
              />
            </center>
          </div>
        )}
      </>
    );
  }
}
export default Cards;
