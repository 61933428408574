import React, { Component } from 'react';
import Line from './lineComments'
import AddComment from './addComments'
import { Modal, ModalHeader, ModalBody, Card, CardBody } from 'reactstrap';


class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            comments: []
        };
    }

    componentDidMount = () => {
        this.setState({
            comments: this.props.data,
        }, () => ({}))
    }

    toggle = evt => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <>
                <Card className="myCard">
                    <CardBody style={{ overflow: "auto", height: "34rem" }}>
                        <Line comments={this.state.comments}></Line>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}><b>Crear nuevo comentario</b></ModalHeader>
                    <ModalBody>
                        <AddComment type="0" catalogs={this.props.catalogs}/* callback={this.callback} */ /* onError={this.onError} */ /* id={this.state.id} */ />
                    </ModalBody>
                </Modal>
            </>

        );
    }
}

export default Comments;