export const Services = {
   "Doc_manager": {
      "path": "https://roldanapidocument.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO GET DOCUMENTS"
   },
   "Trazabilidad_manager": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO GET TRAZABILIDAD"
   },
   "Almacen_manager": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO GET ALMACEN"
   }
}