import React, { Suspense } from 'react';
import { Col, Row } from 'reactstrap';
import { Tab, Header, Icon } from 'semantic-ui-react';
import Context from '../store/CreateContext';
const Integrated = React.lazy(() => import('./modules/Integrated'));
const Detail = React.lazy(() => import('./modules/Detail'));

/**
 * Proyect Detail
 */
function ProyectDetail(){
  const context = React.useContext(Context);
  const tabs = [
    { menuItem: 'Integrado', render: () => <Suspense fallback={<div>Loading...</div>}> <Integrated/></Suspense> },
    { menuItem: 'Detalle', render: () => <Suspense fallback={<div>Loading...</div>}> <Detail/></Suspense> },
  ];

  // ? Function to return to main window
  function handleBack(){
    context.IsProjectDetail(false);
  }

  return (
    <React.Fragment>
      <div className='content-heading'>
        <div className='row'>
          <em className='fas fa-1x mr-2 fas fa-briefcase'/>
          Detalle del Proyecto
        </div>
      </div>
      <Col md={12}>
        <Row>
          <Header as='h3' className='_btnBack' onClick={handleBack}>
            <Icon.Group size='large'>
              <Icon link name='angle left' className='_btnBack' size='big' onClick={handleBack} />
            </Icon.Group>
            {'Tablero de Control'}
          </Header>
          <Col md={12}>
            <Tab
              menu={{secondary: true, className: 'myWrapped'}}
              menuPosition={'right'}
              // activeIndex={1}
              panes={tabs}
            />
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
}

ProyectDetail.defaultProps = {
};

ProyectDetail.propTypes = {
};

export default ProyectDetail;