import React from 'react';
import { init } from '../model';
import CreateContext from './CreateContext';

function reducer(state, action){
  switch(action.type){
  // ? Report Type
  case 'REPORT_TYPE':
    return {
      ...state,
      report_type: action.payload
    };
  case 'SHARE_TO':
    return {
      ...state,
      share_to: action.payload
    };    
  // ? Report Info
  case 'SET_REPORT_NAME':
    return {
      ...state,
      report_name: { ...action.payload }
    };
  case 'SET_REPORT_DESCRIPTION':
    return {
      ...state,
      report_description: { ...action.payload }
    };
  case 'UPDATE_NAME':
    return {
      ...state,
      name: action.payload
    };
  // ? Filters
  case 'ADD_FILTER':
    state.filters.push(action.payload);
    return {
      ...state
    };
  case 'REMOVE_FILTER':
    state.filters.push(action.payload);
    return {
      ...state
    };
  case 'SET_FILTER':
    return {
      ...state,
      filters: action.payload
    };
  case 'SET_FILTERS_DATA':
    return {
      ...state,
      filters_data: action.payload
    };    
  case 'REMOVE_BY_FILTER':
    const NEW_FILTERS= state.filters.filter(filter => `${filter.hash}` !== `${action.payload}`);
    return {
      ...state,
      filters: NEW_FILTERS
    };
  // ? Fields
  case 'SET_MODULES':
    return {
      ...state,
      modules: action.payload
    };
  case 'SET_SELECTED_MODULE':
    return {
      ...state,
      module_selected: action.payload
    };
  case 'SET_LEVEL_INFO':
    return {
      ...state,
      level_info: action.payload
    };
  case 'ADD_FIELD':
    state.selected_fields.push(action.payload);
    return {
      ...state
    };
  case 'SET_FIELDS':
    state.selected_fields = action.payload;
    return {
      ...state
    };   
  // ? Order Fields
  case 'SET_ORDER_FIELDS':
    return {
      ...state,
      order_fields: action.payload
    };
  case 'ADD_ORDER_FIELDS':
    state.order_fields.push(action.payload);
    return {
      ...state
    };
  case 'REMOVE_ORDER_FIELDS':
    const NEW_ORDER= state.order_fields.filter(filter => filter.field !== action.payload);
    return {
      ...state,
      order_fields: NEW_ORDER
    };
  // ? Scheduling
  case 'SCHEDULING_FREQUENCY':
    state.config.frequency = action.payload;
    return {
      ...state,
      // frequency: action.payload
    };
  case 'SCHEDULING_UPDATE_CONFIG':
    state.config[action.payload.key] = action.payload.value;
    return {
      ...state,
      // frequency: action.payload
    };
  // ? Email List
  case 'ADD_EMAIL':
    state.emailList.push(action.payload);
    return {
      ...state,
      // frequency: action.payload
    };
  case 'REMOVE_EMAIL':
    state.emailList = state.emailList.filter(email => email !== action.payload);
    return {
      ...state,
      // frequency: action.payload
    };
  case 'SET_EMAIL_LIST':
    return {
      ...state,
      emailList: action.payload
    };
  case 'MODEL_RESET':
    return {
      ...init()
    };
  default:
    return state;
  }
}
    
function CreateProvider(props){
  const [state, dispatch] = React.useReducer(reducer, init(), init);
  async function reportType(value){
    await dispatch({type: 'REPORT_TYPE', payload: value});
  }

  return (
    <CreateContext.Provider
      value={{
        // ? Report Type
        name: state.name,
        onChangeType: reportType,
        report_type: state.report_type,
        // ? Share To
        share_to: state.share_to,
        set_share_to: (payload) => dispatch({type: 'SHARE_TO', payload: payload}),
        // ? Report Info
        report_name: state.report_name,
        report_description: state.report_description,
        set_report_name: (payload) => dispatch({type: 'SET_REPORT_NAME', payload: payload}),
        set_report_description: (payload) => dispatch({type: 'SET_REPORT_DESCRIPTION', payload: payload}),
        update_name: () => dispatch({type: 'UPDATE_NAME', payload: 'Josh'}),
        // ? Fields
        module_selected: state.module_selected,
        set_selected_module: (selected_module) => dispatch({ type: 'SET_SELECTED_MODULE', payload: selected_module}),
        modules: state.modules,
        set_modules: (modules) => dispatch({type: 'SET_MODULES', payload: modules}),
        level_info: state.level_info,
        set_level_info: (level_info) => dispatch({type: 'SET_LEVEL_INFO', payload: level_info}),
        selected_fields: state.selected_fields,
        add_field: (field) => dispatch({type: 'ADD_FIELD', payload: field}),
        set_fields: (newFields) => dispatch({type: 'SET_FIELDS', payload: newFields}),
        // ? Filters
        filters: state.filters,
        filters_data: state.filters_data,
        add_filter: (payload) => dispatch({type: 'ADD_FILTER', payload: payload}),
        remove_filter: (index) => dispatch({type: 'REMOVE_FILTER', payload: index}),
        update_filter: (index, type, value) => dispatch({type: 'REMOVE_FILTER', payload: {index, type, value}}),
        set_filter: (payload) => dispatch({type: 'SET_FILTER', payload: payload}),
        set_filters_data: (payload) => dispatch({type: 'SET_FILTERS_DATA', payload}),
        remove_by_filter: (payload) => dispatch({type: 'REMOVE_BY_FILTER', payload: payload}),
        // ? Order
        order_fields: state.order_fields,
        set_order_fields: (payload) => dispatch({type: 'SET_ORDER_FIELDS', payload: payload}),
        add_order_fields: (payload) => dispatch({type: 'ADD_ORDER_FIELDS', payload: payload}),
        remove_order_fields: (payload) => dispatch({type: 'REMOVE_ORDER_FIELDS', payload: payload}),
        // ? sheduling
        // frequency: state.frequency,
        config: state.config,
        set_frequency: (payload) => dispatch({type: 'SCHEDULING_FREQUENCY', payload: payload}),
        update_config: (payload) => dispatch({type: 'SCHEDULING_UPDATE_CONFIG', payload: payload}),
        // ? Email list
        emailList: state.emailList,
        add_email: (payload) => dispatch({type: 'ADD_EMAIL', payload: payload}),
        remove_email: (payload) => dispatch({type: 'REMOVE_EMAIL', payload: payload}),
        set_emailList: (payload) => dispatch({type: 'SET_EMAIL_LIST', payload: payload}),
        // ? Model Reset
        model_reset: () => dispatch({type: 'MODEL_RESET'}),
      }}
    >
      {props.children}
    </CreateContext.Provider>
  );
}

export default CreateProvider;