import React, { Component, Suspense } from "react";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import { Row } from "reactstrap";
import { Tab } from "semantic-ui-react";
import Documents from "./Documents";
import Comments from "../../Components/Comments";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Services } from "../Services";
import { validateIdParams } from "../../../utils/regularExpressionValidation";
import Errors from "../../Components/Errors";
import Loader from "../../../Global/Loader";

const Events = React.lazy(() => import("./Events"));
const SumaryDetail = React.lazy(() => import("./Sumary/SumaryDetail"));

class ShipmentDetail extends Component {
  state = {
    id: this.props.match.params.id,
    allSteps: [],
    allEvents: [],
    FechaActualizacion: "",
    NoSolicitud: "",
    DocTransporte: "",
    Estatus: "",
    Modalidad: "",
    TipoTransporte: "",
    NIT: "",
    commentsData: [
      {
        ComType: "Tipo 1",
        ComTypeId: 1,
        Comment: "Comentario de prueba",
        CommentId: 73,
        Date: "2019-06-20",
        Module: "Comentario",
        ModuleId: 1,
        RowId: 861,
        UpdateBy: "API",
      },
      {
        ComType: "Tipo 2",
        ComTypeId: 1,
        Comment: "Comentario de prueba",
        CommentId: 73,
        Date: "2019-07-02",
        Module: "Comentario",
        ModuleId: 1,
        RowId: 861,
        UpdateBy: "API",
      },
    ],
    catalogsData: [
      {
        ComTypeId: 1,
        ModuleId: 1,
        ComType: "Supplier Follow Up",
        value: 1,
        label: "Supplier Follow Up",
      },
      {
        ComTypeId: 2,
        ModuleId: 1,
        ComType: "Order Quality",
        value: 2,
        label: "Order Quality",
      },
      {
        ComTypeId: 3,
        ModuleId: 1,
        ComType: "Order Quantities",
        value: 3,
        label: "Order Quantities",
      },
      {
        ComTypeId: 10,
        ModuleId: 1,
        ComType: "Other",
        value: 10,
        label: "Other",
      },
    ],
    steps: [
      {
        label: "Carga Lista",
        value: "",
        id: 3,
        completed: false,
        order: 1,
        middleStep: "",
      },
      {
        label: "Salida",
        value: "",
        id: 4,
        completed: false,
        order: 2,
        idStep: "step2",
        middleStep: "",
      },
      {
        label: "Llegada",
        value: "",
        id: 9,
        completed: false,
        order: 3,
        middle: false,
        idStep: "step3",
        middleStep: "",
      },
    ],
    error: {
      open: false,
      message: "",
      type: "admin",
    },
  };

  componentDidMount = () => {
    this.getShipmentsForId();
    //this.getEvents();
    this.getSteps();
    this.getComments();
  };

  componentWillReceiveProps = (nextProps) => {
    const newProp = nextProps.match.params.id
      ? nextProps.match.params.id
      : this.props.match.params.id;
    this.setState({ id: newProp }, () => {
      this.getShipmentsForId();
      this.getSteps();
    });
  };

  getEvents = () => {
    const { steps, id } = this.state;
    let moduleId = 11;
    // let idContainer = 1816;
    axios
      .get(`${Services.Get_Events_General.path}/events/all/${moduleId}/${id}`)
      .then((response) => {
        this.setState({
          allEvents: response.data.data,
        });
      })
      .catch((error) => { });
  };

  getSteps = () => {
    axios
      .get(
        `${Services.Get_Transportation_ForId.path}/timeline/${this.state.id}`
      )
      .then((response) => {
        this.setState({ steps: response.data.data.spEventsTimeLine }, () => { });
      })
      .catch((error) => { });
  };

  getComments = () => {
    axios
      .get(
        `${Services.Get_Transportation_ForId.path}/comments/${this.state.id}`
      )
      .then((response) => {
        this.setState({ commentsData: response.data.data }, () => { });
      })
      .catch((error) => { });
  };

  getShipmentsForId = () => {
    let idValidate = validateIdParams(this.state.id);
    if (!idValidate) {
      this.setState(
        {
          error: {
            message: "Dato incorrecto, favor de verificar",
            open: true,
            type: "error",
          },
        },
        () => {
          setTimeout(() => {
            this.props.history.push("/transportation");
          }, 2000);
          return;
        }
      );
    }
    axios
      .get(
        `${Services.Get_Transportation_ForId.path}/customs/byId/${this.state.id}`
      )
      .then((success) => {
        this.setState(
          {
            cliente: success.data.data.Cliente,
            NIT: success.data.data.NIT,
            FechaActualizacion: success.data.data.FechaActualizacion,
            NoSolicitud: success.data.data.NoSolicitud,
            DocTransporte: success.data.data.DocTransporte,
            Estatus: success.data.data.Estatus,
            Modalidad: success.data.data.Modalidad,
            TipoTransporte: success.data.data.TipoTransporte,
            fullLoader: true,
          },
          () => {
            this.getEvents();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });
  render() {
    let logoUrl =
      "https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png";
    let { fullLoader } = this.state;
    return (
      <>
        <Errors
          open={this.state.error.open}
          type={this.state.error.type}
          message={this.state.error.message}
          setProps={this.recieveProps}
        ></Errors>
        <ContentWrapper>
          <Loader hidden={fullLoader}></Loader>
          <div className="content-heading">
            <legend className="legendTitle">
              <img
                src="https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png"
                height="28"
                width="28"
              />
              Transporte Terrestre
            </legend>
          </div>
          <Tab
            menu={{ secondary: true, className: 'wrapped' }}
            menuPosition="right"
            panes={[
              {
                menuItem: "Resumen",
                render: () => (
                  <Suspense fallback={<div>Loading...</div>}>
                    <SumaryDetail
                      steps={this.state.steps}
                      NIT={this.state.NIT}
                    />
                  </Suspense>
                ),
              },
              {
                menuItem: "Eventos",
                render: () => (
                  <Suspense fallback={<div>Loading...</div>}>
                    <Events
                      logoUrl={logoUrl}
                      allSteps={this.state.allSteps}
                      steps={this.state.steps}
                      allEvents={this.state.allEvents}
                      FechaActualizacion={this.state.FechaActualizacion}
                      cliente={this.state.cliente}
                      NIT={this.state.NIT}
                      NoSolicitud={this.state.NoSolicitud}
                      DocTransporte={this.state.DocTransporte}
                      Estatus={this.state.Estatus}
                      Modalidad={this.state.Modalidad}
                      TipoTransporte={this.state.TipoTransporte}
                    />{" "}
                  </Suspense>
                ),
              },
              {
                menuItem: "Novedades",
                render: () => (
                  <div>
                    <Comments
                      data={this.state.commentsData}
                    //catalogs={this.state.catalogsData}
                    />
                  </div>
                ),
              },
              {
                menuItem: "Documentos",
                render: () => (
                  <div>
                    <Documents solicitud={this.state.NoSolicitud} />
                  </div>
                ),
              },
            ]}
          />
        </ContentWrapper>
      </>
    );
  }
}
export default withRouter(ShipmentDetail);
