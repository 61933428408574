import React, { useState } from 'react';
import axios from 'axios';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-virtualized-select';
import {
  Button,
  Container,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Services } from '../Services';
import Authentication from '../../../../services/Authentication';
import SessionStorage from '../../../../services/SessionStorage';
const auth = new Authentication();
const session = new SessionStorage();

function TransporteInternacional(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [origenCat, setOrigenCat] = React.useState([{}]);
  const [destinoCat, setDestinoCat] = React.useState([{}]);

  const [originSelect, setOriginSelect] = React.useState('');
  const [destinoSelect, setDestinoSelect] = React.useState('');
  const [modTransSel, setModTransSel] = React.useState('');
  const [contTypeSel, setContTypSel] = React.useState('');
  const [mercTypeSel, setMercTypeSel] = React.useState('');
  const [incoSel, setIncoSel] = React.useState('');

  const [noContIn, setNoContIn] = React.useState('');
  const [weightIn, setWeightIn] = React.useState('');
  const [dimenIn, setDimenIn] = React.useState('');
  const [extraInfo, setExtraInfo] = React.useState('');

  const [isDanger, setIsDanger] = React.useState(false);
  const [allClients, setAllClients] = JSON.parse(session.getItem('group_list'));

  const toggle = () => {
    props.onResults({ message: null });
    setIsOpen((prevState) => !prevState);
  };

  const merchDanger = () => {
    setIsDanger((prevState) => !prevState);
  };

  const getCatalogs = React.useCallback(async () => {
    try {
      const urlCatalogs = `${Services.CATALOGS_CHATBOT.path}/catalogs/paises`;

      const catalogs = await axios.get(urlCatalogs);

      setOrigenCat(catalogs.data.data);
      setDestinoCat(catalogs.data.data);
    } catch (error) {
      console.error('Error en peticion Catalogos', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modTransCat = [
    { label: 'Marítimo FCL', value: 'FCL' },
    { label: 'Marítimo LCL', value: 'LCL' },
    { label: 'Aéreo', value: 'aereo' },
    { label: 'Terrestre', value: 'terrestre' },
    { label: 'OTM', value: 'otm' },
  ];

  const containerType = [
    { key: '2stdr', value: '2stdr', label: '20′ Standard Dry Container' },
    { key: '4stdr', value: '4stdr', label: '40′ Standard Dry Container' },
    { key: '40hcd', value: '40hcd', label: '40′ High Cube Dry Container' },
    { key: '45hcd', value: '45hcd', label: '45′ High Cube Dry Container' },
  ];

  const mmerchType = [
    { key: '1', value: 'general', label: 'General' },
    { key: '2', value: 'maquinaria', label: 'Maquinaria' },
    { key: '3', value: 'equipos', label: 'Equipos' },
    { key: '4', value: 'granel', label: 'Granel' },
    { key: '5', value: 'extraDimensionada', label: 'Extra dimensionada' },
    { key: '6', value: 'refrigerada', label: 'Refrigerada' },
    { key: '7', value: 'otros', label: 'Otros' },
  ];

  const incotermsCat = [
    { key: '1', value: 'CFR', label: 'CFR' },
    { key: '2', value: 'CIF', label: 'CIF' },
    { key: '3', value: 'CPT', label: 'CPT' },
    { key: '4', value: 'CIP', label: 'CIP' },
    { key: '5', value: 'DPU', label: 'DPU' },
    { key: '6', value: 'DAP', label: 'DAP' },
    { key: '7', value: 'DDP', label: 'DDP' },
    { key: '8', value: 'EXW', label: 'EXW' },
    { key: '9', value: 'FCA', label: 'FCA' },
    { key: '10', value: 'FAS', label: 'FAS' },
    { key: '11', value: 'FOB', label: 'FOB' },
  ];

  const handeInputData = (selected) => (event) => {
    if (event.target.id === 'noContainer') setNoContIn(event.target.value);
    if (event.target.id === 'weight') setWeightIn(event.target.value);
    if (event.target.id === 'dimensiones') setDimenIn(event.target.value);
    if (event.target.id === 'infoAdicional') setExtraInfo(event.target.value);
  };

  const sendData = async () => {

    const crmBody = {
      service: 'transporteInternacional',
      clients: allClients.data
    };

    const dataSend = {
      userName: auth.getAuthentication('UserName'),
      email: auth.getAuthentication('EmailUser'),
      rolId: auth.getAuthentication('RolId'),
      origen: originSelect.label,
      destino: destinoSelect.label,
      modotransporte: modTransSel.label,
      tipoContenedor: contTypeSel.label,
      numeroContenedor: noContIn,
      peso: weightIn,
      dimensiones: dimenIn,
      tipoMercancia: mercTypeSel.label,
      mercanciaPeligrosa: isDanger ? 'Si' : 'No',
      incoterm: incoSel.label,
      infoAdicional: extraInfo,
      userId:auth.getAuthentication('dataUser'),
      cc: []
    };

    const urlMail = `${Services.SEND_MAIL_CHATBOT.path}/chatbot/internacional`;
    // const urlMail = `http://localhost:3010/chatbot/internacional`
    const urlCrm = `${Services.CATALOGS_CHATBOT.path}/crm/getMails`;

    try {
      if(auth.getAuthentication('RolId') === '2'){
        const getCrmEmails = await axios.post(urlCrm, crmBody);
        dataSend.sendEmails = getCrmEmails.data.data;
  
        if(!dataSend.sendEmails.length){
          dataSend.sendEmails.push({
            email: 'jarango@roldanlogistica.com',
            name: 'roldanlogistica'
          });
        }else{
          dataSend.cc.push({
            email: 'jarango@roldanlogistica.com',
            name: 'roldanlogistica'
          });
        }
        dataSend.cc.push({
          email: 'mrodriguez@roldanlogistica.com',
          name: 'roldanlogistica'
       }); 
      }

      await axios.post(urlMail, dataSend);
    
      props.onResults({ message: 'se envio correo' });

      setIsOpen(false);
    } catch (error) {
      setIsOpen((prevState) => !prevState);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader
          className="advancedSearchModal"
          style={{ background: '#003F70', color: 'white' }}
          toggle={toggle}
        >
          <i class="calculator icon"></i>
          <b>Cotización</b>
        </ModalHeader>
        <ModalBody>
          <Container
            key={'trinternacional'}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Row>
              <Col style={{ fontWeight: 'bold' }}>
                <center>Transporte Internacional</center>
              </Col>
            </Row>

            <Form>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="origen">Origen</Label>
                    <Select
                      options={origenCat}
                      placeholder={'País Origen'}
                      onChange={(selectValue) => setOriginSelect(selectValue)}
                      value={originSelect}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="destino">Destino</Label>
                    <Select
                      options={destinoCat}
                      placeholder={'País Destino'}
                      onChange={(selectValue) => setDestinoSelect(selectValue)}
                      value={destinoSelect}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="modoTransporte">Modo Transporte</Label>
                    <Select
                      options={modTransCat}
                      placeholder={'Modo de transporte'}
                      onChange={(selectValue) => setModTransSel(selectValue)}
                      value={modTransSel}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="typeContainer">Tipo de Contenedor</Label>
                    <Select
                      options={containerType}
                      placeholder={'Tipo de contenedor'}
                      disabled={
                        modTransSel
                          ? modTransSel.value !== 'FCL'
                            ? true
                            : false
                          : false
                      }
                      onChange={(selectValue) => setContTypSel(selectValue)}
                      value={contTypeSel}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="noContainer">Número de Contenedores</Label>
                    <Input
                      type="number"
                      name="noContainer"
                      id="noContainer"
                      disabled={
                        modTransSel
                          ? modTransSel.value === 'FCL'
                            ? false
                            : true
                          : false
                      }
                      //value={order[e.name] ? order[e.name] : ""}
                      onChange={handeInputData()}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="weight">Peso</Label>
                    <Input
                      type="number"
                      name="weight"
                      id="weight"
                      onChange={handeInputData()}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="dimensiones">Dimensiones</Label>
                    <Input
                      type="text"
                      name="dimensiones"
                      id="dimensiones"
                      onChange={handeInputData()}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="mercanciaType">Tipo de Mercancía</Label>
                    <Select
                      options={mmerchType}
                      placeholder={'Tipo de Mercancía'}
                      onChange={(selectValue) => setMercTypeSel(selectValue)}
                      value={mercTypeSel}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup check>
                    <Label for="peso">Mercancía Peligrosa</Label>
                    <Input
                      type="checkbox"
                      name="peso"
                      id="peso"
                      onClick={merchDanger}
                    />{' '}
                    Sí
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="Incoterm">Incoterm</Label>
                    <Select
                      options={incotermsCat}
                      placeholder={'Tipo de contenedor'}
                      onChange={(selectValue) => setIncoSel(selectValue)}
                      value={incoSel}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="infoAdicional">Información Adicional</Label>
                    <Input
                      type="textarea"
                      name="infoAdicional"
                      id="infoAdicional"
                      onChange={handeInputData()}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="row">
                <div className="col-md-6 col-lg-4 col-sm-12"></div>
                <div
                  style={{ textAlign: 'center' }}
                  className="col-md-2 col-lg-2 col-sm-4"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="button"
                    disabled={
                      originSelect ||
                      destinoSelect ||
                      modTransSel ||
                      contTypeSel ||
                      mercTypeSel ||
                      incoSel ||
                      noContIn ||
                      weightIn ||
                      dimenIn ||
                      extraInfo ||
                      isDanger
                        ? false
                        : true
                    }
                    onClick={sendData}
                  >
                    Enviar
                  </Button>
                </div>
                <div className="col-md-2 col-lg-1 col-sm-4">
                  <Button
                    variant="contained"
                    color="danger"
                    className="button"
                    onClick={toggle}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Form>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
}

export default TransporteInternacional;
