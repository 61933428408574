export const validateFrequency = (Values={}) => {
  const keyValues = Object.keys(Values);
  let valid = false;

  for (let index = 0; index < keyValues.length; index++) {
    const item = keyValues[index];
    if(Values[item]){
      valid = true;
    }
  }
  return valid;
};