import React from 'react';
import { Divider } from 'semantic-ui-react';
import propTypes from 'prop-types';
import CreateProvider from './store/CreateProvider';
import ContentWrapper from '../../../../template/Layout/ContentWrapper';

// ? Actions imports
import ReportType from './app/ReportType';
import ReportConfig from './app/ReportConfig';
import ShareTo from './app/ShareTo';
import Scheduling from './app/Scheduling';
import ReportPreview from './app/ReportPreview';
import SaveReport from './app/SaveReport';

function CreateAndEdit(props) {
  return (
    <CreateProvider>
      <ContentWrapper>
        {
          props.action === 'create' ?
            <div className="content-heading">
              <legend className="legendTitle">
                <i className="fas fa-1x mr-2 fas fa-pencil-alt" />
                Crear Reporte Especial
              </legend>
            </div>
            : ''
        }
        <div className='row'>
          <ReportType />
          <SaveReport action={props.action} reportId={props.reportId} onEvent={props.onEvent} />
        </div>
        <Divider section />
        <ReportConfig />
        <ShareTo />
        <Scheduling />
        <Divider section />
        <div class="float-right mb-5">
          <p className='text-muted'>Nota: El total de resultados del reporte está limitado a 1000 registros. </p>
        </div>
        <ReportPreview action={props.action} reportId={props.reportId} />
      </ContentWrapper>
    </CreateProvider>
  );
}

CreateAndEdit.propTypes = {
  action: propTypes.string,
  reportId: propTypes.number,
};
CreateAndEdit.defaultProps = {
  action: 'create',
  reportId: 0
};

export default CreateAndEdit;