import React, { Component } from 'react';
// import { Tab } from 'semantic-ui-react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  Checkbox,
} from '@material-ui/core/';
import { Col, Row } from 'reactstrap';
import './styles/styles.scss';
import axios from 'axios';
import { Services } from '../../../Services';
import { Dropdown } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';

class reportAsing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportsCombo: [],
      reportForUser: [
        {
          type: 'Dropdown',
          label: 'Reporte',
          class: 'col-md-6',
          placeHolder: 'Reporte',
          name: 'Reporte',
          id: 'Reporte',
          style: {},
          required: false,
        },
      ],
      ReportValues: [],
      currentValues: [],
      colums: [
        { label: 'ID', key: 'CustomerId' },
        { label: 'Nombre del Cliente', key: 'CustomerName' },
        { label: 'NIT', key: 'CustomerCode' },
        { label: 'Reporte', key: 'TaxId' },
        { label: '', key: '', custom: (value) => this.custom1(value) },
      ],
      data: props.newSelected,
      dataPo: [],
      dataShip: [],
      mainColumns: [],
      table1: [],
      table2: [],
      table3: [],
      clientes: [],
    };
  }

  custom1 = (value) => (
    <em
      onClick={() => console.warn('')}
      className="fa-2x mr-2 far fa-trash-alt"
      style={{ color: '#297AB9' }}
    ></em>
  );

  componentDidMount = () => {
    this.getCatReport();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.edit) {
      let newDataPo = this.state.dataPo.map((f) => {
        nextProps.statusDocuemtens.map((e) => {
          if (
            e.ModuleId === f.ModuleId &&
            e.DocCategoryId === f.DocCategoryId
          ) {
            f.check = true;
          }
          return f;
        });
        return f;
      });
      let newDataShip = this.state.dataShip.map((f) => {
        nextProps.statusDocuemtens.map((e) => {
          if (
            e.ModuleId === f.ModuleId &&
            e.DocCategoryId === f.DocCategoryId
          ) {
            f.check = true;
          }
          return f;
        });
        return f;
      });
      this.setState(
        {
          data: nextProps.newSelected,
          dataPo: newDataPo,
          dataShip: newDataShip,
          currentValues: nextProps.reportsId,
          ReportValues: nextProps.reportsId,
        },
        () => {
          this.buildFormCreate();
          this.table2();
          this.table3();
          axios
            .get(`${Services.GET_REPORTS_CATALOGS.path}/report/all`, {})
            .then((response) => {
              this.builArray(response.data.data);
            })
            .catch((error) => {
              console.warn(error);
            });
        }
      );
    }
  };

  getCatReport = (data) => {
    axios
      .get(`${Services.GET_REPORTS_CATALOGS.path}/report/all`, {})
      .then((response) => {
        let reports = [];
        this.builArray(response.data.data);
        response.data.data.forEach((e) => {
          let combo = {
            key: e.ReporteId,
            text: e.Nombre,
            value: e.ReporteId,
          };
          reports.push(combo);
        });
        this.setState({ reportsCombo: reports }, () => this.buildFormCreate());
      })
      .catch((error) => {
        console.warn(error);
      });
    // axios.get(`${Services.GET_DOCUMENTS_CATALOGS.path}documents/all`, {
    // }).then(response => {
    //   let po = [];
    //   let ship = [];
    //   response.data.data.map((w) => {
    //     w.check = false;
    //     if (w.ModuleId === 1) {
    //       po.push(w);
    //     }
    //     if (w.ModuleId === 3) {
    //       ship.push(w);
    //     }
    //     return w;
    //   });
    //   this.setState({
    //     dataPo: po,
    //     dataShip: ship
    //   }, () => {
    this.table2();
    this.table3();
    //   });
    // }).catch(error => {
    //   console.warn(error);
    // });
  };

  builArray = (array) => {
    this.state.data.map((e) => {
      e.reports = [];
      array.map((w) => {
        if (e.CustomerId === w.CustomerId) {
          let combo = {
            key: w.ReportId,
            text: w.Name,
            value: w.ReportId,
          };
          e.reports.push(combo);
        }
        return w;
      });
      return e;
    });
    this.setState(
      {
        data: this.state.data,
      },
      () => {
        this.table1();
      }
    );
  };

  // asignacion de reporte por usuario
  buildFormCreate = () => {
    const { reportForUser, reportsCombo, ReportValues } = this.state;

    let CreateFormRoles = reportForUser.map((e, i) => {
      if (e.type === 'Dropdown')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {' '}
              <span id={e.label} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}
                </label>
              </span>
            </Fade>
            <Dropdown
              onChange={this.handleOnChangeReportUser}
              id="select"
              options={reportsCombo}
              clearable
              placeholder="Reportes..."
              search
              fluid
              selection
              value={ReportValues}
              multiple
              scrolling={true}
              allowAdditions
              compact={false}
            />
          </div>
        );
      return e;
    });
    this.setState({
      CreateFormRoles,
    });
  };

  table1 = () => {
    let table = [];
    this.state.data.map((e, index) => {
      table.push(
        <tr key={index}>
          <td>{e.ClienteId}</td>
          <td>{e.Nombre}</td>
          <td>{e.NIT}</td>
          <td>
            <div>
              <Dropdown
                onChange={this.handleOnChangeData}
                id="select"
                options={e.reports}
                clearable
                placeholder="reports ..."
                search
                fluid
                selection
                value={this.state.currentValues}
                multiple
                scrolling={true}
                allowAdditions
                compact={false}
              />
            </div>
          </td>
        </tr>
      );
      return e;
    });
    let tale2 = [];
    tale2.push(
      <Col md="12">
        <div className="table-wrapper-scroll-y">
          <table className="table">
            <thead>
              <tr>
                <th className="eventsTableHeader">ID</th>
                <th className="eventsTableHeader">Nombre</th>
                <th className="eventsTableHeader">NIT</th>
                <th className="eventsTableHeader">Reporte</th>
              </tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      table1: tale2,
    });
  };

  table2 = () => {
    let table = [];
    this.state.dataPo.map((e, index) => {
      table.push(
        <tr key={index}>
          <td>{e.Description}</td>
          <th>
            <Checkbox
              checked={e.check}
              onChange={this.cheked}
              value={e.check}
              id={e.DocCategoryId}
            />
          </th>
        </tr>
      );
      return e;
    });

    let tale2 = [];
    tale2.push(
      <Col md="12">
        <div className="table-wrapper-scroll-y">
          <table className="table">
            <thead>
              <tr>
                <th className="eventsTableHeader">Category</th>
                <th className="eventsTableHeader">Select</th>
              </tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      table2: tale2,
    });
  };
  table3 = () => {
    let table = [];
    this.state.dataShip.map((e, index) => {
      table.push(
        <tr key={index}>
          <td>{e.Description}</td>
          <th>
            <Checkbox
              checked={e.check}
              onChange={this.cheked2}
              value={e.check}
              id={e.DocCategoryId}
            />
          </th>
        </tr>
      );
      return e;
    });
    let tale2 = [];
    tale2.push(
      <Col md="12">
        <div className="table-wrapper-scroll-y">
          <table className="table">
            <thead>
              <tr>
                <th className="eventsTableHeader">Category</th>
                <th className="eventsTableHeader">Select</th>
              </tr>
            </thead>
            <tbody className="eventsTableBody">{table}</tbody>
          </table>
        </div>
      </Col>
    );
    this.setState({
      table3: tale2,
    });
  };

  checked3 = (name, isPo) => {
    var newArr = this.state.dataShip;
    if (isPo) {
      newArr = this.state.dataPo;
    }
    let newClientes = newArr.map((e) => {
      if (e.DocCategoryId === name.target.id) {
        e.check = !e.check;
      }
      return e;
    });
    this.setState(
      {
        clientes: newClientes,
      },
      () => {
        this.table3();
        this.table2();
        this.props.dataDocuments(this.state.dataPo, this.state.dataShip);
      }
    );
  };

  cheked = (name) => {
    let newClientes = this.state.dataPo.map((e) => {
      if (e.DocCategoryId === name.target.id) {
        e.check = !e.check;
      }
      return e;
    });
    this.setState(
      {
        clientes: newClientes,
      },
      () => {
        this.table3();
        this.table2();
        this.props.dataDocuments(this.state.dataPo, this.state.dataShip);
      }
    );
  };
  cheked2 = (name) => {
    let newClientes = this.state.dataShip.map((e) => {
      if (e.DocCategoryId === name.target.id) {
        e.check = !e.check;
      }
      return e;
    });
    this.setState(
      {
        clientes: newClientes,
      },
      () => {
        this.table3();
        this.table2();
        this.props.dataDocuments(this.state.dataPo, this.state.dataShip);
      }
    );
  };

  handleOnChangeData = (evt, { value }) => {
    this.setState({ currentValues: value }, () => {
      this.props.dataReport(this.state.currentValues);
      this.table1();
    });
  };

  handleOnChangeReportUser = (evt, { value }) => {
    this.setState({ ReportValues: value }, () => {
      this.props.dataReport(this.state.ReportValues);
      this.buildFormCreate();
    });
  };

  render() {
    let { CreateFormRoles } = this.state;
    return (
      <>
        <ExpansionPanel elevation={0} defaultExpanded>
          <ExpansionPanelDetails>
            <div className="col-md-12">
              <Row>
                <Col md="8">
                  <div className="row">
                    <strong style={{ marginLeft: '-12px' }}>
                      Asignación de Reportes
                    </strong>
                  </div>
                  <div style={{ height: 10 }}></div>
                  {/* asignacion de reportes por usuario */}
                  {CreateFormRoles}
                  {/* asignacion de reportes por ciente  */}
                  {/* {this.state.table1} */}
                </Col>
                {/* <Col md="4"><>
                  <div className="row">
                    <strong style={{ marginLeft: '-12px' }}>Documents</strong>
                  </div>
                  <div style={{ height: 5 }}></div>
                  <Tab
                    menu={{ secondary: true, className: 'myWrapped' }}
                    menuPosition='right'
                    panes={[
                      { menuItem: 'Purchase Order', render: () => <>{this.state.table2}</> },
                      { menuItem: 'Shipment', render: () => <>{this.state.table3}</> },
                    ]} /></>
                </Col> */}
              </Row>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    );
  }
}

export default reportAsing;
