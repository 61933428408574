import React from 'react';
import Paper from '@material-ui/core/Paper';

import './styles.scss';

function Loader(props) {
  return (
    <Paper elevation={0} className="__loader_container_" hidden={props.hidden}>
      <div className="col-md-12 __loader_">
        <div className="abs-center wd-xl">
          <div className="text-center mb-4">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 218"
              style={{
                'enable-background': 'new 0 0 256 218',
              }}
              space="preserve"
            >
              <style type="text/css"></style>
              <path
                style={{
                  fill: 'none',
                  stroke: '#D61D28',
                  'stroke-miterlimit': 10,
                }}
                class="st0"
                d="M235.8,187.8c-0.4,0.4-4.2,0.5-6.4,0.7c-24.2,1.2-62.5-40.6-80.8-85.6c25.5-9.5,50.8-25.4,52.7-37.6
	c2.4-14.7-29.4-31.6-66.8-36c-0.7-0.1-1.3-0.2-2-0.2c3-2,6.2-3.8,9.7-5.5l-0.9-1.8c-7.4,2.4-13.7,4.6-19.1,6.5
	C69.8,25.5,19.5,44.1,19.5,65.7c0,8.5,7.7,15.2,17.6,15.2l0.7-1.5c-2.6-2.2-3.9-5.7-3.9-10.3c0-23.8,39.3-39.3,81.3-38.4
	c-12.3,4.6-18.6,8-23,12c-8.8,7.9-16,23.3-19.3,41.9c-3.5,18.7-4.6,48.3-2.6,68.9c0,0.2,0.2,0.4,0.6,0.4l13-1.5
	c0.2,0,0.4-0.2,0.4-0.5c0.6-20,4-38.2,11.9-63.2c9.8-30.4,18.3-46.1,32.6-57c1.7,0.2,3.3,0.4,5,0.6c24.2,3.3,37.7,15.2,35.4,31.8
	c-1.7,12.5-11.7,23.5-23.7,31c-2-5.7-3.7-11.4-4.9-17.1c-0.2-0.7-0.5-1.1-1.3-0.7c-21.3,10.3-27.9,14.1-32.9,17.6
	c-10.7,7.7-11.2,14.7,0.6,16c5.4,0.7,12.3,0,19.8-1.5c11.6,30.7,35.2,66.3,59.9,79.4c16,8.5,36.2,9,49.6,0.9L235.8,187.8z
	 M122.5,104.8c-18.1,3.9-14.8-7.8-1.3-14.2c0.9,4.3,2.2,8.9,3.8,13.6C124.2,104.4,123.4,104.6,122.5,104.8z"
              >
                <animate
                  accumulate="none"
                  additive="replace"
                  attributeName="stroke-dasharray"
                  calcMode="linear"
                  dur="4s"
                  fill="remove"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  restart="always"
                  values="480 100;480 110"
                ></animate>

                <animate
                  accumulate="none"
                  additive="replace"
                  attributeName="stroke-dashoffset"
                  calcMode="linear"
                  dur="4s"
                  fill="remove"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  restart="always"
                  values="0;-1180"
                ></animate>
              </path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
}
export default Loader;
