import React from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import MenuImageView from '../../../resources/Roldn-Logistics.png';
import ImagenROll from '../../../resources/Login_Logo2.png';

const BackgroundHead = {
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 'calc(100vw + 48px)',
  margin: -24,
  padding: 24,
};
/*=============================================
=            Login Component                  =
=============================================*/
function Politicas(props) {

  return (
    <div className="limiter" style={BackgroundHead}>
      <div className="">
        <br></br>
        <div className="col-12" style={{ marginBottom: '30px', textAlign: 'center' }}>
          <Col md={12}>
            <Row>
              <Col md={2}>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={4} style={{ textAlign: 'center' }}>
                    <img style={{ width: '18em', height: '9em', marginBottom:'1em' }} src={MenuImageView} alt='menu-view'/>
                  </Col>
                  <Col md={4} style={{ textAlign: 'center' }}>
                  </Col>
                  <Col md={4} style={{ textAlign: 'center' }}>
                    <img style={{ width: '18em', height: '9em' }} src={ImagenROll} alt='roll-4-all'/>
                  </Col>
                </Row>
              </Col>
              <Col md={2}>
              </Col>
            </Row>
          </Col>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div style={{ background: 'black', borderBottom: '1px', marginTop: '-2px' }} class="ui divider"></div>
              <br></br>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-2">
            </div>
            <div className="col-8">
              <h5 style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>POLITICA DE PRIVACIDAD - ROL4ALL</h5>
              <div style={{ height: 40 }}></div>




              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Recogida y tratamiento de datos de carácter personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Los datos de carácter personal son los que pueden ser utilizados para identificar a una persona o ponerse en contacto con ella.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ROLDAN LOGISTICA puede solicitar datos personales de usuarios al acceder a aplicaciones de la empresa o de otras empresas afiliadas, así como la posibilidad de que entre estas empresas puedan compartir esos datos para mejorar los productos y servicios ofrecidos. Si no se facilitan esos datos personales, en muchos casos no podremos ofrecer los productos o servicios solicitados, es por ello que usted debe garantizar que cuenta con las debidas autorizaciones del titular de la información facilitada por usted
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Estos son algunos ejemplos de las categorías de datos de carácter personal que ROLDAN LOGISTICA puede recoger y la finalidad para los que puede llevar a cabo el tratamiento de estos datos.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ¿Qué datos de carácter personal se pueden recopilar?
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Al crear un ID, descargar una actualización de software, se recopilan diferentes datos, como nombre, dirección postal, número de teléfono, dirección de
              <p style={{ color: 'black', textAlign: 'justify' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp; correo electrónico.
              </p>
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Cuando se comparten contenidos con familiares y amigos o se invita a otras personas a participar en los servicios o foros, pueden recogerse los datos que
              <p style={{ color: 'black', textAlign: 'justify' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp; facilitamos sobre esas personas, como su nombre, domicilio, correo electrónico y número de teléfono. Se utilizarán dichos datos para completar sus
              </p>
                &nbsp;&nbsp;&nbsp;&nbsp; operaciones, ejecutar el servicio correspondiente o para combatir cualquier anomalía detectada.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Propósito del tratamiento de datos de carácter personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ROLDAN LOGISTICA podrá utilizar los datos personales suministrados para:
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Mantenerle informado acerca de los últimos servicios, las actualizaciones de software disponibles y los próximos eventos.
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Elaborar, perfeccionar, gestionar, proporcionar y mejorar los servicios, nuestros contenidos y nuestra publicidad.
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• También se utilizan esos datos de carácter personal con propósitos internos, incluyendo auditorías, análisis de datos y sondeos, para mejorar los productos,
              <p style={{ color: 'black', textAlign: 'justify' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;servicios y comunicaciones a clientes
              </p>
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Si participa en un sorteo, un concurso o una promoción, pueden usarse los datos proporcionados para administrar estos programas
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Recopilación y tratamiento de datos de carácter no personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ROLDAN LOGISTICA también recopilará datos de un modo que, por sí mismos, no pueden ser asociados directamente a una persona determinada. Estos datos de carácter no personal y públicos se pueden recopilar, tratar, transferir y publicar con cualquier intención. Estos son algunos ejemplos de las clases de datos de carácter no personal que ROLDAN LOGISTICA puede recopilar y los fines para los que se realiza su tratamiento:
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Datos tales como profesión, idioma, código postal, identificador único de dispositivo, etc. para comprender mejor la conducta de nuestros clientes y
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;mejorar nuestros servicios y anuncios publicitarios.
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Datos sobre cómo se usan determinados servicios, incluidas las consultas de búsqueda. Esta información se puede utilizar para incrementar la importancia
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;de los resultados que aportan los servicios ofrecidos.
              </p>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;• Datos sobre cómo usa su dispositivo y las aplicaciones a los desarrolladores la mejora de esas aplicaciones.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Si juntamos datos de carácter no personal con datos personales, los datos mezclados serán tratados como datos personales mientras sigan estando combinados.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Divulgación a terceros</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Ocasionalmente ROLDAN LOGISTICA puede facilitar determinados datos de carácter personal a socios estratégicos que trabajen con nosotros para proveer productos y servicios o nos ayudan en nuestras actividades de marketing. No se compartirán los datos con ningún tercero para sus propios fines de marketing.
              </p> <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Otros terceros</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Es posible que ROLDAN LOGISTICA divulgue datos de carácter personal por mandato legal, en el marco de un proceso judicial o por petición de una autoridad pública, tanto dentro como fuera de su país de residencia. Igualmente se puede publicar información personal si es necesaria o conveniente por motivos de seguridad nacional, para acatar la legislación vigente o por otras razones relevantes de orden público.
              </p>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Protección de datos de carácter personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ROLDAN LOGISTICA garantizará la protección de los datos personales mediante cifrado durante el tránsito y, los alojados en instalaciones, con medidas de seguridad físicas.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Al usar ciertos productos, servicios o aplicaciones o al publicar opiniones en foros, salas de chat o redes sociales, el contenido y los datos de carácter personal que se comparta serán visible para otros usuarios, que tendrán la posibilidad de leerlos, compilarlos o usarlos.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Usted será responsable de los datos de carácter personal que distribuya o proporcione en estos casos.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Integridad y conservación de datos de carácter personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ROLDAN LOGISTICA garantizará la exactitud y la calidad de los datos personales, se conservarán durante el tiempo necesario para cumplir los fines para los que fueron recopilados , salvo que la ley exija conservarlos durante más tiempo.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Acceso a los datos de carácter personal</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Respecto a los datos de carácter personal que conservamos, ROLDAN LOGISTICA le ofrece acceso a ellos para cualquier fin, incluyendo las solicitudes de rectificación en caso de que sean incorrectos o de eliminación en caso de no estar obligados a conservarlos por imperativo legal o por razones legítimas de negocio. Nos reservamos el derecho a no tramitar aquellas solicitudes que sean improcedentes o desobligantes que pongan en riesgo la privacidad de terceros, que resulten inviables o para las que la legislación local no exija derecho de acceso. Las solicitudes de acceso, rectificación o eliminación podrán enviarse a nuestra dirección ROLDAN LOGISTICA o en la cuenta de correo electrónico aarango@roldanlogistica.com
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Niños y educación</h5>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Esta aplicación no está destinada para menores de edad y por ende, se prohíbe su uso por parte de dicha población.</h5>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ROLDAN LOGISTICA es consciente de la necesidad de establecer precauciones adicionales para preservar la privacidad y la seguridad de los menores que utilizan las aplicaciones y exigir consentimiento de sus progenitores en caso de que no tengan la edad mínima exigida por la legislación (en Colombia, ## años).
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Si se han recopilado datos personales de un menor de ## años, sin el consentimiento necesario, se debe eliminar esa información lo antes posible.
              </p>
              <br></br>
              <br></br>
              <h5 style={{ color: 'black', fontWeight: 'bold' }}>Servicios de localización</h5>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Para prestar servicios de localización ROLDAN LOGISTICA podrá reunir, utilizar y compartir datos exactos sobre ubicaciones, incluyendo la situación geográfica en tiempo real de su ordenador o de su dispositivo.
              </p>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                Salvo que nos den su consentimiento, estos datos de localización se recogen de manera anónima de forma que no pueden utilizarse para identificarlo. personalmente, y son usados para suministrar y mejorar sus servicios de localización.
              </p>
              <br></br>
              <br></br>
              <p style={{ color: 'black', textAlign: 'justify' }}>
                ROLDAN LOGISTICA Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
              </p>
            </div>
            <div className="col-2">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Politicas);
