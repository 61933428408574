import React, { useState } from 'react';
import axios from 'axios';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-virtualized-select';
import {
  Button,
  Container,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Services } from '../Services';
import Authentication from '../../../../services/Authentication';
import SessionStorage from '../../../../services/SessionStorage';
const auth = new Authentication();
const session = new SessionStorage();

function TransporteInternacional(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [portCat, setPortCat] = React.useState([{}]);

  const [portSelect, setPortSelect] = React.useState('');
  const [mercTypeSel, setMercTypeSel] = React.useState('');

  const [cantidadIn, setCantidadIn] = React.useState('');
  const [extraInfo, setExtraInfo] = React.useState('');

  const [isDanger, setIsDanger] = React.useState(false);
  const [selectIsActive, setSelectIsActive] = React.useState(false);
  const [allClients, setAllClients] = JSON.parse(session.getItem('group_list'));

  const toggle = () => {
    props.onResults({ message: null });
    setIsOpen((prevState) => !prevState);
  };

  const merchDanger = () => {
    setIsDanger((prevState) => !prevState);
  };

  const getCatalogs = React.useCallback(async () => {
    try {
      const urlPorts = `${Services.CATALOGS_CHATBOT.path}/catalogs/ports`;

      const catalogs = await axios.get(urlPorts);

      setPortCat(catalogs.data.data);
    } catch (error) {
      console.error('Error en peticion Catalogos', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mmerchType = [
    { key: '1', value: 'general', label: 'General' },
    { key: '2', value: 'maquinaria', label: 'Maquinaria' },
    { key: '3', value: 'equipos', label: 'Equipos' },
    { key: '4', value: 'granel', label: 'Granel' },
    { key: '5', value: 'extraDimensionada', label: 'Extra dimensionada' },
    { key: '6', value: 'refrigerada', label: 'Refrigerada' },
    { key: '7', value: 'otros', label: 'Otros' },
  ];

  const handeInputData = (selected) => (event) => {
    if (event.target.id === 'cantidad') setCantidadIn(event.target.value);
    if (event.target.id === 'infoAdicional') setExtraInfo(event.target.value);
  };

  const sendData = async () => {
    const crmBody = {
      service: 'exportacion',
      clients: allClients.data
    };

    const dataSend = {
      userName: auth.getAuthentication('UserName'),
      email: auth.getAuthentication('EmailUser'),
      rolId: auth.getAuthentication('RolId'),
      port: portSelect.label,
      cantidadItems: cantidadIn,
      tipoMercancia: mercTypeSel.label,
      mercanciaPeligrosa: isDanger ? 'Si' : 'No',
      infoAdicional: extraInfo,
      userId:auth.getAuthentication('dataUser'),
      cc: []
    };

    // const urlMail = 'http://localhost:3010/chatbot/agexportacion';
    const urlMail = `${Services.SEND_MAIL_CHATBOT.path}/chatbot/agexportacion`;

    const urlCrm = `${Services.CATALOGS_CHATBOT.path}/crm/getMails`;

    try {

      if(auth.getAuthentication('RolId') === '2'){
        const getCrmEmails = await axios.post(urlCrm, crmBody);
        dataSend.sendEmails = getCrmEmails.data.data;
  
        if(!dataSend.sendEmails.length){
          dataSend.sendEmails.push({
            email: 'jarango@roldanlogistica.com',
            name: 'roldanlogistica'
          });
        }else{
          dataSend.cc.push({
            email: 'jarango@roldanlogistica.com',
            name: 'roldanlogistica'
          });
        }
        dataSend.cc.push({
          email: 'mrodriguez@roldanlogistica.com',
          name: 'roldanlogistica'
       }); 
      }

      await axios.post(urlMail, dataSend);

      props.onResults({ message: 'se envio correo' });

      setIsOpen(false);
    } catch (error) {
      setIsOpen((prevState) => !prevState);
      console.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader
          className="advancedSearchModal"
          style={{ background: '#003F70', color: 'white' }}
          toggle={toggle}
        >
          <i class="calculator icon"></i>
          <b>Cotización</b>
        </ModalHeader>
        <ModalBody>
          <Container
            key={'trinternacional'}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Row>
              <Col style={{ fontWeight: 'bold' }}>
                <center>Agenciamiento Exportación</center>
              </Col>
            </Row>

            <Form>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="puerto">Aduana</Label>
                    <Select
                      options={portCat}
                      placeholder={'Aduana'}
                      onChange={(selectValue) => setPortSelect(selectValue)}
                      value={portSelect}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="cantidad">Cantidad Items</Label>
                    <Input
                      type="number"
                      name="cantidad"
                      id="cantidad"
                      onChange={handeInputData()}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="mercanciaType">Tipo de Mercancía</Label>
                    <Select
                      options={mmerchType}
                      placeholder={'Tipo de Mercancía'}
                      onChange={(selectValue) => setMercTypeSel(selectValue)}
                      value={mercTypeSel}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={4}>
                  <FormGroup check>
                    <Label for="peso">Mercancía Peligrosa</Label>
                    <Input
                      type="checkbox"
                      name="peso"
                      id="peso"
                      onClick={merchDanger}
                    />{' '}
                    Sí
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="infoAdicional">Información Adicional</Label>
                    <Input
                      type="textarea"
                      name="infoAdicional"
                      id="infoAdicional"
                      onChange={handeInputData()}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="row">
                <div className="col-md-6 col-lg-4 col-sm-12"></div>
                <div
                  style={{ textAlign: 'center' }}
                  className="col-md-2 col-lg-2 col-sm-4"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="button"
                    disabled={
                      portSelect ||
                      mercTypeSel ||
                      isDanger ||
                      extraInfo ||
                      cantidadIn
                        ? false
                        : true
                    }
                    onClick={sendData}
                  >
                    Enviar
                  </Button>
                </div>
                <div className="col-md-2 col-lg-1 col-sm-4">
                  <Button
                    variant="contained"
                    color="danger"
                    className="button"
                    onClick={toggle}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Form>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
}

export default TransporteInternacional;
