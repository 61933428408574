import React, { Component } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  MenuItem,
} from '@material-ui/core/';
import { Card, CardBody, Input, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Select from 'react-select';
import './style/style.scss';
import ListContact from './listContact';
import ListCustomer from './listCustomer';
import axios from 'axios';
import { Services } from '../../../Services';
import Line from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Selecion from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Errors from '../../../../Components/Errors';
import { Dropdown } from 'semantic-ui-react';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

class createCustomer extends Component {
  constructor(props) {
    super(props);
    this.pond = React.createRef();
    this.state = {
      currentValues: [],
      imaAvatar:
        'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png',
      country: [],
      CustomerId: '',
      CreateFormData: [],
      CreateForAdd: [],
      Form: this.props.form,
      selected: [],
      customer: {
        CustomerName: '',
        Telephone: '',
        NIT: '',
        Country: '',
        Avatar: '',
      },
      items: [],
      contact: {
        Nombre: '',
        Apellido: '',
        Posicion: '',
        Telefono: '',
        Email: '',
      },
      error: {
        open: false,
        message: '',
        type: 'admin',
      },
      submitt: false,
      part: {},
      servicesServicces: [
        {
          CargoId: 1,
          Descripcion: 'My Dashboard',
          check: false,
          disable: false,
        },
        {
          CargoId: 2,
          Descripcion: 'Warehousing',
          check: false,
          disable: false,
        },
        {
          CargoId: 3,
          Descripcion: 'Purchase Orders',
          check: false,
          disable: false,
        },
        {
          CargoId: 4,
          Descripcion: 'Tracking',
          check: false,
          disable: false,
        },
      ],
      combo: [],
    };
  }

  componentDidMount = () => {
    this.getModule();
  };
  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        Form: nextProps.form,
      },
      () => {
        this.getModule();
      }
    );
  };

  validateAddContac = (e) => {
    if (this.state.items.length > 0) {
      this.saveCustomer();
    } else {
      this.props.onError({
        error: {
          message: 'Contacto agregado correctamente!',
          open: true,
          type: 'warning',
        },
      });
    }
  };

  validateSaveCustomer = (e) => (evt) => {
    let validation = [];
    let errors = '';
    let Form = this.props.form.CreateCustomer;
    Form.map((e) => {
      if (e.required === true) {
        validation.push(e);
      }
      return e;
    });
    validation.forEach((e) => {
      if (this.state.customer[e.id] === '') {
        errors += e.label + ', ';
        document.getElementById(e.id).style.color = 'red';
      }
    });
    if (errors.length > 0) {
      this.setState(
        {
          submitt: true,
        },
        () => {
          this.buildFormCreate();
          this.buildFormAdd();
          this.props.getlist();
        }
      );
      this.setState({
        error: {
          message: 'Campos requeridos: ' + errors + '',
          open: true,
          type: 'error',
        },
      });
    } else {
      this.validateAddContac();
    }
  };

  saveCustomer = (name) => {
    let newarraymodule = [];
    this.state.servicesServicces.map((e, i) => {
      if (e.check) {
        newarraymodule.push(e);
      }
      return e;
    });
    // error de redacion de codigo
    // this.state.customer['Avatar'] = this.state.imaAvatar;
    // let data = this.state.customer;
    const { customer } = this.state;
    customer['Avatar'] = this.state.imaAvatar;
    let data = customer;
    axios
      .post(`${Services.PostCustomer.path}/admin/createCustomer`, data)
      .then((success) => {
        this.setState(
          {
            CustomerId: success.data.data.ClienteId,
          },
          () => {
            let data = {
              CustomerId: this.state.CustomerId,
              contact: this.state.items,
            };
            axios
              .post(`${Services.PostContact.path}/admin/createContact`, data)
              .then((response) => {
                let contact = {
                  Nombre: '',
                  Apellido: '',
                  Posicion: '',
                  Telefono: '',
                  Email: '',
                };
                let customer = {
                  CustomerName: '',
                  Telephone: '',
                  NIT: '',
                  Country: '',
                  Avatar: '',
                };
                this.pond.current.removeFiles();
                this.setState(
                  {
                    contact,
                    items: [],
                    customer: customer,
                    currentValues: [],
                  },
                  () => {
                    this.buildFormCreate();
                    this.buildFormAdd();
                    this.props.getlist();
                    this.props.onError({
                      error: {
                        message: 'El Cliente ha sido correctamente',
                        open: true,
                        type: 'admin',
                      },
                    });
                  }
                );
              })
              .catch((error) => {
                console.warn(error.response.data.data.message);
                this.props.onError({
                  error: {
                    message: 'Dato incorrecto, favor de verificar',
                    open: true,
                    type: 'error',
                  },
                });
              });
          }
        );
      })
      .catch((error) => {
        console.warn(error.response.data.data.message);
        this.props.onError({
          error: {
            message: 'Dato incorrecto, favor de verificar',
            open: true,
            type: 'error',
          },
        });
      });
  };

  getModule = () => {
    axios
      .get(
        `${Services.GetModuls.path}/all/module`
        )
      .then((success) => {
        success.data.data.sort(function(a, b) {
          if (a.text > b.text) {
            return 1;
          }
          if (a.text < b.text) {
            return -1;
          }
          return 0;
        });

        this.setState({ combo: success.data.data }, () => {
          this.getCountrys();
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  getCountrys = () => {
    axios
      .get(
        `${Services.GetCountrys.path}/all/country`
        )
      .then((success) => {
        this.setState({ country: success.data.data }, () => {
          this.buildFormCreate();
          this.buildFormAdd();
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  moreContact = (name) => (event) => {
    let validation = [];
    let errors = '';
    let Form = this.props.form.AddContact;
    Form.map((name) => {
      if (name.required === true) {
        validation.push(name);
      }
      return name;
    });
    validation.forEach((name) => {
      if (this.state.contact[name.id] === '') {
        errors += name.label + ', ';
        document.getElementById(name.id).style.color = 'red';
      }
    });
    if (errors.length > 0) {
      this.setState(
        {
          submitt: true,
        },
        () => {
          this.buildFormAdd();
        }
      );
      this.setState({
        error: {
          message: 'Campos requeridos: ' + errors + '',
          open: true,
          type: 'error',
        },
      });
    } else {
      let { items } = this.state;
      items.push(this.state.contact);
      let contact = {
        Nombre: '',
        Apellido: '',
        Posicion: '',
        Telefono: '',
        Email: '',
      };
      this.setState(
        {
          items,
          contact: contact,
          CustomerId: '',
        },
        () => {
          this.buildFormAdd();
        }
      );
    }
  };

  DeleteContact = (items) => {
    this.setState({
      items: items,
    });
  };

  handleChangeAdd = (name) => (event) => {
    document.getElementById(name).style.color = 'black';
    let { contact } = this.state;
    contact[name] = event.target.value;
    this.setState(
      {
        contact,
      },
      () => {
        this.buildFormAdd();
      }
    );
  };

  handleChange = (name) => (event) => {
    document.getElementById(name).style.color = 'black';
    let { customer } = this.state;
    customer[name] = event.target.value;
    this.setState(
      {
        customer,
      },
      () => {
        this.buildFormCreate();
      }
    );
  };

  handleChangeSelect = (selected) => (event) => {
    if (event && event.value) {
      let { customer } = this.state;
      customer[selected] = event.value;
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    } else {
      let { customer } = this.state;
      customer[selected] = '';
      this.setState({ selected }, () => {
        this.buildFormCreate();
      });
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.state.servicesServicces.map((e) => {
      if (e.CargoId === event.target.value) {
        e.check = !e.check;
      }
      return e;
    });
    this.setState(
      {
        servicesServicces: this.state.servicesServicces,
      },
      () => {
        this.buildFormCreate();
      }
    );
    return name;
  };

  buildFormCreate = () => {
    const { Form, customer, country } = this.state;
    // let catalog = [
    //   {
    //     value: 1,
    //     label: 'Ceramic Tiles, Stone & Marble'
    //   },
    // ];

    let CreateFormData = Form.CreateCustomer.map((e, i) => {
      if (e.type === 'Input')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {' '}
              <span id={e.id} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}
                </label>
              </span>
            </Fade>
            <Input
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={customer[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max}
              error={true}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === 'Select')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.id} style={e.style}>
                {' '}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}{' '}
                </label>
              </span>
            </Fade>
            <Select
              required
              type={e.datatype}
              name={e.name}
              placeholder="Elegir..."
              value={customer[e.id]}
              onChange={this.handleChangeSelect(e.id)}
              options={country}
              error={true}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === 'check') {
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label} style={e.style}>
                {' '}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}{' '}
                </label>
              </span>
            </Fade>
            <Selecion
              style={{ width: '100%', maxWidth: '838px' }}
              multiple
              className="textField"
              value={[]}
              onChange={this.handleChangeCheck(e.id)}
              name=""
              MenuProps={MenuProps}
              input={<Line maxlength="6" id="select-multiple-checkbox" />}
              margin="normal"
              error={true}
            >
              {this.state.servicesServicces.map((option) => (
                <MenuItem
                  key={option.CargoId}
                  value={option.CargoId}
                  _value={option.Descripcion}
                >
                  <Checkbox checked={option.check} />
                  <ListItemText
                    style={{ fontSize: '8px !important' }}
                    primary={option.Descripcion}
                  />
                </MenuItem>
              ))}
            </Selecion>
          </div>
        );
      } else if (e.type === 'multiCombo') {
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label}>
                {' '}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}{' '}
                </label>
              </span>
            </Fade>
            <Dropdown
              onChange={this.handleOnChangeData}
              id={e.id}
              options={this.state.combo}
              clearable
              placeholder="Módulos..."
              search
              selection
              fluid
              value={this.state.currentValues}
              multiple
              allowAdditions
            />
          </div>
        );
      }
      return e;
    });
    this.setState({
      CreateFormData,
    });
  };

  handleOnChangeData = (evt, { value }) => {
    this.setState({ currentValues: value }, () => {
      this.buildFormCreate();
    });
  };

  buildFormAdd = () => {
    const { Form, contact } = this.state;
    let CreateForAdd = Form.AddContact.map((e) => {
      if (e.type === 'Input')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {' '}
              <span id={e.id} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}
                </label>
              </span>
            </Fade>
            <Input
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={contact[e.id]}
              onChange={this.handleChangeAdd(e.id)}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      return e;
    });
    this.setState({
      CreateForAdd,
    });
  };

  updateImg = (str) => {
    this.setState({ imaAvatar: str });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    var img = '';
    return (
      <>
        <Card>
          <CardBody className="myCard">
            <Errors
              open={this.state.error.open}
              type={this.state.error.type}
              message={this.state.error.message}
              setProps={this.recieveProps}
            ></Errors>
            <ExpansionPanel elevation={0} defaultExpanded>
              <div className="col-md-12">
                <strong style={{ marginLeft: '-12px' }}>
                  Información del Cliente
                </strong>
              </div>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <FilePond
                  id="filepondclient"
                    allowFileEncode={true}
                    labelIdle="LOGO"
                    ref={this.pond}
                    labelFileWaitingForSize={false}
                    styleButtonRemoveItemPosition="right"
                    labelButtonRetryItemLoad={false}
                    onupdatefiles={(fileItems) => {
                      if (fileItems.length > 0) {
                        if (
                          fileItems[0].file.type === 'image/jpeg' ||
                          fileItems[0].file.type === 'image/png' ||
                          fileItems[0].file.type === 'image/jpg'
                        ) {
                          if (fileItems[0].file.size < 1000000) {
                            var reader = new FileReader();
                            reader.readAsDataURL(fileItems[0].file);
                            reader.onload = () => {
                              this.updateImg(reader.result);
                            };
                          } else {
                            this.pond.current.removeFiles();
                            this.updateImg(
                              'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png'
                            );
                            this.props.onError({
                              error: {
                                message: 'Excede el tamaño maximo permitido!',
                                open: true,
                                type: 'error',
                              },
                            });
                          }
                        } else {
                          this.pond.current.removeFiles();
                          this.updateImg(
                            'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png'
                          );
                          this.props.onError({
                            error: {
                              message: 'Tipo de archivo no soportado!',
                              open: true,
                              type: 'error',
                            },
                          });
                        }
                      } else {
                        this.pond.current.removeFiles();
                        this.updateImg(
                          'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png'
                        );
                      }
                    }}
                  ></FilePond>
                  <img
                    alt=""
                    src={this.state.imaAvatar}
                    width="117"
                    height="117"
                    className="circule"
                  />
                  <div className="col-md-10">
                    <div className="row">{this.state.CreateFormData}</div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel elevation={0} defaultExpanded>
              <div className="col-md-12">
                <strong style={{ marginLeft: '-12px' }}>
                  Agregar Contacto
                </strong>
              </div>
              <ExpansionPanelDetails>
                <div className="col-md-11">
                  <div className="row">{this.state.CreateForAdd}</div>
                </div>
                <div align="center" className="col-md-1">
                  <em
                    onClick={this.moreContact()}
                    className="fa-2x mr-2 fas fa-plus-circle"
                    style={{ marginTop: '33px', color: '#0079B8' }}
                  ></em>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ListContact
              DeleteContact={this.DeleteContact}
              item={this.state.items}
            ></ListContact>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: 'center' }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button variant="contained" color="danger" className="button">
                  Cancelar
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={this.validateSaveCustomer()}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
        <ListCustomer
          combo={this.state.combo}
          chamge={this.props.chamge}
          errorValidate={this.props.errorValidate}
          onError={this.props.onError}
          putCustomer={this.props.putCustomer}
          deleteCustomer={this.props.deleteCustomer}
          country={this.state.country}
          Form={this.state.Form}
          customer={this.props.customerList}
        ></ListCustomer>
      </>
    );
  }
}

export default createCustomer;
