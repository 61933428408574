import React from 'react';
import Table from '../../../../../Components/Table';
import './styles.css';

/*=============================================
=            Table Events Builder             =
=============================================*/

function TablesEvent(props) {
  return (
    <div>
      {
        props.allTables.map((e, key) => (
          <div className="col-12" key={key}>
            <span className='titleCard'>{e.eventType}</span>
            <Table
              columns={props.columns}
              data={e.data}
              options={{
                large: true,
                downloads: {
                  pdf: false,
                  xlsx: false
                }
              }}
            />
          </div>
        ))
      }
    </div>
  );
}

export default TablesEvent;
