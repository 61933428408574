import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Popup } from "semantic-ui-react";
import "./styles/cards.scss";

const limit = 12;
const highLimit = 20;

export const CardTransporte = (props) => {
  let { element } = props;
  return (
    <>
      <Card className="_clickCard mySearchCard" id={Math.random()}>
        <CardBody>
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="row text-center">
                    {/* ==========================No de solicitud===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              No. de solicitud
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              <Link
                                to={
                                  "/transportation-detail-view/" + element.TmsId
                                }
                              >
                                {element.NoSolicitud ? (
                                  element.NoSolicitud.length > highLimit ? (
                                    <Popup
                                      content={element.NoSolicitud}
                                      key={element.NoSolicitud}
                                      position=""
                                      trigger={
                                        <span>
                                          {element.NoSolicitud.substring(
                                            0,
                                            limit - 3
                                          )}
                                          ...
                                        </span>
                                      }
                                    />
                                  ) : (
                                    element.NoSolicitud
                                  )
                                ) : (
                                  ""
                                )}
                              </Link>
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Estatus================================================ */}
                    <div className="col-md-4">
                      <div>
                        <div>
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Estatus
                            </span>
                          </center>
                        </div>
                        <div>
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Estatus ? (
                                element.Estatus.length > highLimit ? (
                                  <Popup
                                    content={element.Estatus}
                                    key={element.Estatus}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Estatus.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Estatus
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================REF. Cliente=========================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Cliente ? (
                                element.Cliente.length > limit ? (
                                  <Popup
                                    content={element.Cliente}
                                    key={element.Cliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Cliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Cliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row text-center">
                    {/* ==========================Transportista===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Transportista
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.EmpresaTransportadora ? (
                                element.EmpresaTransportadora.length > limit ? (
                                  <Popup
                                    content={element.EmpresaTransportadora}
                                    key={element.EmpresaTransportadora}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.EmpresaTransportadora.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.EmpresaTransportadora
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Doc Transporte===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Doc. transporte
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.DocTransporte ? (
                                element.DocTransporte.length > limit ? (
                                  <Popup
                                    content={element.DocTransporte}
                                    key={element.DocTransporte}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.DocTransporte.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.DocTransporte
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================No de DO===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              No. de DO
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.NoDo ? (
                                element.NoDo.length > highLimit ? (
                                  <Popup
                                    content={element.NoDo}
                                    key={element.NoDo}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.NoDo.substring(0, limit - 3)}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.NoDo
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row text-center">
                    {/* ==========================Modalidad===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Modalidad
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Modalidad ? (
                                element.Modalidad.length > limit ? (
                                  <Popup
                                    content={element.Modalidad}
                                    key={element.Modalidad}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Modalidad.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Modalidad
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Origen===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Origen
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.CiudadOrigen ? (
                                element.CiudadOrigen.length > limit ? (
                                  <Popup
                                    content={element.CiudadOrigen}
                                    key={element.CiudadOrigen}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.CiudadOrigen.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.CiudadOrigen
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Destino===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Destino
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.CiudadDestino ? (
                                element.CiudadDestino.length > limit ? (
                                  <Popup
                                    content={element.CiudadDestino}
                                    key={element.CiudadDestino}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.CiudadDestino.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.CiudadDestino
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row text-center">
                    {/* ========================== Fecha salida ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Fecha de salida
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaSalidaRuta ? (
                                element.FechaSalidaRuta.length > limit ? (
                                  <Popup
                                    content={element.FechaSalidaRuta}
                                    key={element.FechaSalidaRuta}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaSalidaRuta.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaSalidaRuta
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Fecha estimada llegada ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Fecha estimada de llegada
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaEstimadaArribo ? (
                                element.FechaEstimadaArribo.length > limit ? (
                                  <Popup
                                    content={element.FechaEstimadaArribo}
                                    key={element.FechaEstimadaArribo}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaEstimadaArribo.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaEstimadaArribo
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Fecha descargue ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Fecha descargue
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaDescargue ? (
                                element.FechaDescargue.length > limit ? (
                                  <Popup
                                    content={element.FechaDescargue}
                                    key={element.FechaDescargue}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaDescargue.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaDescargue
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* END OF MAIN DIV */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
