const Forms = {
  BODY:[],
  HEADER:[
    {
      type: 'Datetime',
      label: 'Fecha de Facturación',
      name: 'FechaFactura',
      class: 'col-md-3 col-lg-4 col-sm-12',
      id: 'FechaFactura',
      style : { fontSize : '11px',fontWeight:'bold'},
    },
    {
      type: 'Datetime',
      label: 'Fecha de Vencimiento',
      name: 'FechaVencimiento',
      class: 'col-md-3 col-lg-4 col-sm-12',
      id: 'FechaVencimiento',
      style : { fontSize : '11px',fontWeight:'bold'},
    },
    {
      type: 'Select',
      label: 'Estatus',
      options: 'EstatusFactura',
      name: 'EstatusFactura',
      class: 'col-md-3 col-lg-4 col-sm-12',
      id: 'EstatusFactura',
      style : { fontSize : '11px',fontWeight:'bold'},
    },
    {
      type: 'Select',
      options: 'Proceso',
      label: 'Proceso',
      name: 'Proceso',
      class: 'col-md-3 col-lg-4 col-sm-12',
      id: 'Proceso',
      style : { fontSize : '11px',fontWeight:'bold'},
    },
    {
      type: 'Select',
      options: 'Concepto',
      label: 'Concepto',
      name: 'Concepto',
      class: 'col-md-3 col-lg-4 col-sm-12',
      id: 'Concepto',
      style : { fontSize : '11px',fontWeight:'bold'},
    },
  ]
};

export default Forms;