import React from 'react';
import propTypes from 'prop-types';
import { Bar } from '@ant-design/charts';

// Consts definition
const themes = (theme) => {
  let varThemes = {
    colors10: {
      'colors10':['#215B77','#1B9CD0','#61C9FF','#ABDFFF','#EFF3DE','#FFDE94','#FFC741','#D09C10','#795B16'],
      'colors20':['#215B77','#227BA2','#1B9CD0','#22BAED','#61C9FF','#8AD4FF','#ABDFFF','#C9E9FF','#EFF3DE','#FFE9B8','#FFDE94','#FFD470','#FFC741','#EDB40A','#D09C10','#A37B16','#795B16']
    },
    colors11: {
      'colors10':['#80b1d3', '#b3de69', '#8dd3c7', '#fdb462','#bebada', '#e0e0e0', '#ffffb3'],
      'colors20':['#80b1d3', '#b3de69', '#8dd3c7', '#fdb462','#bebada', '#e0e0e0', '#ffffb3']
    }
  };
  return varThemes[theme];
};
// ? Configuration example
// var config = {
//   data: data,
//   xField: 'action',
//   yField: 'value',
//   yAxis: { label: { autoRotate: false } },
//   seriesField: 'value',
//   legend: {
//     position: 'top-left'
//   },
//   // scrollbar: { type: 'vertical' },
//   slider: {
//     start: 0,
//     end: 1
//   },
//   // theme: {}
//   // color: '#a8ddb5'
//   // conversionTag: {},
// };

/**
 * Graphic Bar component
 * @param {*} props -
 */
function BarAntd(props){
  return (
    <div style={{padding: '0em 1em 1em 1em' }}>
      {/* ? Example using Column */}
      {/* <Column theme={themes(props.theme)} {...props.config}></Column> */}
      <Bar theme={themes(props.theme)} {...props.config}></Bar>
    </div>
  );
};
BarAntd.propTypes = {
  theme: propTypes.string
};

BarAntd.defaultProps = {
  theme: 'colors11'
};
export default BarAntd;