import React, { Component } from 'react';
import { Table, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Pagination, Grid } from 'semantic-ui-react';
import Select from 'react-select';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import Checkbox from '@material-ui/core/Checkbox';
// import './styles.scss';
import { saveAs } from 'file-saver';
import { Services } from '../../../Services';
// import Modal from "../Modal/AdvancedSearch";
// import IMG from '../../../../resources/filter.png';
// import IMG from '../../../../../../../resources/filter.png';

const Column = (props) => {
	return (
		<div style={props.isLarge ? { width: '350px', fontSize: '12px', textAlign: 'center' } : { width: '150px', fontSize: '12px', textAlign: 'center' }} ><p>{props.title}</p></div>
	);
};
// const SetStyles = {
//     scroll: {
//         //  overflow: 'scroll', height: '400px', 'overflow-x': 'hidden'
//     },
//     pagination: {}
// }

// const countryOptions = [
//   { key: 'af', value: 'af', text: 'Zona Franca' },
// ];

class FormExtended extends Component {
	constructor(props) {
		super(props);
		this.state = {
			styletd: false,
			grupo: '',
			valor: '',
			grupoOpcion: [],
			countryOptions: this.props.catalog ? this.props.catalog : [],
			all: this.props.data,
			all2: this.props.data2,
			data: [],
			columns: [],
			mainColumns: [],
			columnsLogic: {},
			MainTable: '',
			/**
						 *
						 */
			___rows: [],
			___columns: [],
			___mainColumns: [],
			/**
						 *
						 */
			pages: 0,
			activePage: 1,
			tableData: [],
			/**
						 *
						 */
			haveCheck: (props.options ? (props.options.selectItems) : false),
			search: '',
			sorts: {},
			selected: [],
			selectControls: {},
			// mainColumns: '',
			/**
						 * downloads
						 */
			xlsx: '',
		};
	};

	toggle = () => this.setState({ collapse: !this.state.collapse });

	handleChange = name => event => {
		let { selectControls, data } = this.state;
		let checked = event.target.checked;
		selectControls[name] = checked;
		this.setState({
			selectControls,
		}, () => {
			this.buildRows();
			let array = this.state.selected;
			let index = data.findIndex(x => x.mainKey === name);
			if (checked) {
				array.push(data[index]);
			}
			else {
				if (array.length === 1)
					array = [];
				else
					array.splice(index, 1);
			}
			this.setState({ selected: array }, () => {
				this.props.onSelect(this.state.selected);
			});
		});
	};

	componentDidMount = () => {
		let { options, columns } = this.props;
		// console.log("options",options);
		if (options.selectGroup) {
			// console.log("data",this.props.data);
			let newgrupoOpcion = [];
			let data = this.props.data;
			data.map((e) => {
				let findcantalog = false;
				if (e.GrupoId == null) {
					e.GrupoId = 0
					e.Grupo = "No Group"
				}
				newgrupoOpcion.map((f) => {
					if (e.GrupoId === f.value) {
						findcantalog = true;
					}
					return f;
				});
				if (!findcantalog) {
					let obj = {
						value: e.GrupoId, label: e.Grupo
					};
					newgrupoOpcion.push(obj);
				};
				return e;
			});
			// console.log("newgrupoOpcion",newgrupoOpcion);
			this.setState({
				grupoOpcion: newgrupoOpcion
			})

		}


		let xlsx = options.downloads && options.downloads.xlsx ? <div><em className="fa-2x fas fa-file-excel mr-2" style={{ color: '#66bb6a', cursor: 'pointer' }} onClick={this.XLSX}></em></div> : '';
		this.setState({
			xlsx,
		}, () => {
			if (options && options.selectItems) {
				columns.map((f, index) => {
					if (f.key === 'select') {
						columns.splice(index, 1);
					}
					return f;
				});
				let selectControls = {};
				let lengthData = this.props.data.length;
				for (var i = 0; i < lengthData; i++) {
					this.props.data[i]['mainKey'] = i;
					selectControls[i] = false;
				}
				this.setState({ selectControls, haveCheck: true }, () => {
					columns.unshift({
						label: '',
						key: 'select',
						custom: (value) => {
							return (<Checkbox
								checked={this.state.selectControls[value.mainKey]}
								onChange={this.handleChange(value.mainKey)}
								value={value.mainKey}
								color="primary"
							/>);
						}
					});
					this.setPagination(columns, this.props);
				});
			}
			else this.setPagination(columns, this.props);
		});
	}

	buildSorts = () => {
		let { columns } = this.state;
		let sorts = {};
		columns.forEach((e) => {
			sorts[e.key ? e.key : e.label] = {
				active: false,
				sortBy: 'ASC'
			};
		});
		this.setState({ sorts }, () => {
			this.buildMainColumns();
			this.buildColumns();
		});
	}

	partirArray = (page, all, pages) => {
		let { itemsByPage } = this.state;
		let array = [];
		if (page === 1)
			for (let i = 0; i < (all.length < itemsByPage ? all.length : itemsByPage); i++)
				array.push(all[i]);
		else if (page !== 1 && page !== pages)
			for (let i = (page - 1) * itemsByPage; i < (page) * itemsByPage; i++)
				array.push(all[i]);
		else
			for (let i = (page - 1) * itemsByPage; i < all.length; i++)
				array.push(all[i]);
		return array;
	}

	setPagination = (columns, props) => {
		let { activePage } = this.state;
		this.setState({
			mainColumns: props.mainColumns ? props.mainColumns : [],
			columns,
			itemsByPage: props.itemsByPage ? props.itemsByPage : 10,
			data: props.data,
		}, () => {
			this.buildSorts();
			if (props.options && props.options.pagination) {
				let pages = props.data.length > this.state.itemsByPage ? (props.data.length / this.state.itemsByPage) + 1 : 1;
				let tableData = this.partirArray(activePage, props.data, pages);
				let width = (this.state.columns.length > 2 ? 100 / this.state.columns.length : 50);
				this.setState({
					activePage,
					pages: parseInt(pages),
					tableData,
					width: parseInt(width)
				}, () => this.buildRows());
			}
			else this.setState({ tableData: props.data }, () => this.buildRows());
		});
	}





	componentWillReceiveProps = (nextProps) => {
		if (nextProps.options) {
			if (nextProps.options.reset) {
				let keysSelectControls = Object.keys(this.state.selectControls);
				let newSelectControls = this.state.selectControls;
				keysSelectControls.map((e) => {
					newSelectControls[e] = false;
					return e;
				});
				nextProps.data.map((e) => {
					e.checked = false;
					return e;
				});
				this.setState({ selected: [], selectControls: newSelectControls, styletd: nextProps.options.styleColumn ? true : false });
			}
		}
		let { options, columns } = nextProps;
		let { selectControls } = this.state;
		let xlsx = options.downloads && options.downloads.xlsx ? <div><em className="fa-2x fas fa-file-excel mr-2" style={{ color: '#66bb6a', cursor: 'pointer' }} onClick={this.XLSX}></em></div> : '';

		this.setState({
			xlsx,
			countryOptions: nextProps.options.catalog
		}, () => {
			if (options && options.selectItems && !this.state.haveCheck) {
				let lengthData = nextProps.data.length;
				for (var i = 0; i < lengthData; i++) {
					nextProps.data[i]['mainKey'] = i;
					if (selectControls[i] === true)
						selectControls[i] = true;
					else
						selectControls[i] = false;
				}
				this.setState({ selectControls, xlsx }, () => {
					let index = columns.findIndex(x => x.key === 'select');
					// console.log("index",index);

					if (index === -1)
						columns.unshift({
							label: 'Select',
							key: 'select',
							custom: (value) => {
								return (<Checkbox
									checked={this.state.selectControls[value.mainKey]}
									onChange={this.handleChange(value.mainKey)}
									value={value.mainKey}
									color="primary"
								/>);
							}
						});
					this.setPagination(columns, nextProps);
				});
			}
			else this.setPagination(columns, nextProps);
		});
	}

	buildRows = () => {

		let { columns } = this.state;


		let ___rows = this.state.tableData.map((e, _index) => {
			let tds = [];
			if (e) {
				columns.forEach((column, index_) => {

					if (column.custom) {
						tds.push(
							<td key={index_} className={this.state.styletd === true ? "table-td2" : "table-td"} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', textAlign: 'center', minWidth: '150px', maxWidth: '100px' }}>{column.custom(e)}</td>
						);
					}
					else if (column.index) {

						tds.push(
							<td key={index_} className={this.state.styletd === true ? "table-td2" : "table-td"} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', textAlign: 'center', minWidth: '150px', maxWidth: '100px' }}>
								{_index + 1}
							</td>
						);
					}
					else {
						tds.push(
							<td key={index_} className={this.state.styletd === true ? "table-td2" : "table-td"} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]} style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center', minWidth: '150px', maxWidth: '100px' }}>{e[column.key] ? e[column.key] : ''}</td>
						);
					}

				});
			}


			return (
				<tr key={_index}>
					{tds}
				</tr>
			);
		});

		this.setState({ ___rows }, () => this.buildColumns());
	}

	_sortBy = id => event => {
		let { data, sorts } = this.state;
		if (sorts[id]) {
			let { sortBy } = sorts[id];
			let formatedData = this.makeSort(data, id, sortBy);
			sorts[id].sortBy = sorts[id].sortBy === 'ASC' ? 'DESC' : 'ASC';
			this.setState({
				sorts,
				sortKey: id,
				sortBy: sorts[id].sortBy
			}, () => {
				let tableData = this.partirArray(this.state.activePage, formatedData, this.state.pages);
				this.setState({ tableData }, () => this.buildRows());
			});
		}
	}

	makeSort = (items, key, by) => {
		switch (by) {
			case 'ASC': return items.sort(function (a, b) {
				if (a[key] > b[key]) {
					return 1;
				}
				if (a[key] < b[key]) {
					return -1;
				}
				return 0;
			});
			case 'DESC':
				return items.sort(function (a, b) {
					if (a[key] < b[key]) {
						return 1;
					}
					if (a[key] > b[key]) {
						return -1;
					}
					return 0;
				});
			default: return items;
		}
	}

	buildMainColumns = () => {
		let ___mainColumns = [];
		this.state.mainColumns.forEach((e, index) => {
			___mainColumns.push(
				<th key={index} id={index} style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }} colspan={e.colspan}>{e.label}</th>
			);
		});
		this.setState({ ___mainColumns });
	}

	buildColumns = () => {
		let ___columns = [];
		let { sortKey, sortBy } = this.state;
		this.state.columns.forEach((e, index) => {
			let column = this.props.options.large ? <Column title={e.label} isLarge={e.isLarge} /> : e.label;

			if (e.key === sortKey) {
				___columns.push(sortBy === 'DESC' ?
					<th key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)} ><Fade left opposite ><em className="icon-arrow-down mr-2"></em></Fade>{column}</th>
					: <th key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)}><Fade left opposite ><em className="icon-arrow-up mr-2"></em></Fade>{column}</th>
				);
			}


			else {
				// if (e.key === 'addAll'){
				//   ___columns.push(
				//     <th key={index} id={e.idTh ? e.idTh : index}  > <span style={{ color: '#8c8c8c', fontSize: '12px', cursor: 'pointer', textAlign: 'center' }} >Agregar Todos <em class="fa-2x mr-2 fas fa-plus-circle"  onClick={ () => console.log("esto es para el all data")} style={{marginTop: "33px", color: "rgb(0, 121, 184)", marginTop : '5px'}} ></em></span></th>
				//   );
				// }
				// else {
				___columns.push(
					<th key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)} > <span style={{ color: '#8c8c8c', fontSize: '12px', cursor: 'pointer', textAlign: 'center' }} >{column}</span></th>
				);
				// }

			}
		});
		this.setState({ ___columns }, () => {
			this.buildTable();
		});
	}
	buildTable = () => {
		if (this.props.bordered) {

		}
		else {

		}
	}
	handlePaginationChange = (e, { activePage }) => {
		let { data, pages } = this.state;
		let tableData = this.partirArray(activePage, data, pages);
		this.setState({ activePage, tableData }, () => this.buildRows());
	}

	handleSearch = name => event => this.setState({ [name]: event.target.value }, () => this.setSearch())

	selectSearch = (value) => {
		let all = this.props.data2;
		let results = [];
		if (value) {
			all.forEach((e) => {
				if (e.punto === value.value) {
					results.push(e);
				}
			});
			this.setState({ data: results }, () => {
				let pages = results.length > this.state.itemsByPage ? (Math.ceil(parseInt(results.length) / this.state.itemsByPage)) : 1;
				let tableData = this.partirArray(1, results, pages);
				this.setState({
					activePage: 1,
					pages: parseInt(pages),
					tableData,
					valor: value.value
				}, () => {
					this.props.renderData(this.state.data);
					this.buildRows();
				});
			});
		}
		else {
			this.props.renderData(this.props.all2);
			this.setState({
				valor: ''
			}, () => {
				this.setSearch();
			});

		}
	}

	selectSearch2 = (value) => {
		let all = this.props.data;
		let results = [];
		if (value) {
			all.forEach((e) => {
				if (e.GrupoId === value.value) {
					results.push(e);
				}
			});
			this.setState({ data: results }, () => {
				let pages = results.length > this.state.itemsByPage ? (Math.ceil(parseInt(results.length) / this.state.itemsByPage)) : 1;
				let tableData = this.partirArray(1, results, pages);
				this.setState({
					activePage: 1,
					pages: parseInt(pages),
					tableData,
					grupo: value.value
				}, () => {
					this.props.renderData(this.state.data);
					this.buildRows();
				});
			});
		}
		else {
			this.props.renderData(this.props.all2);
			this.setState({
				grupo: ''
			}, () => {
				this.setSearch();
			});

		}
	}


	setSearch = () => {
		let all = this.props.data;
		let results = [];
		all.forEach((e) => {
			let found = 0;
			let keys = Object.keys(e);
			keys.forEach((key) => {
				if ((e[key] ? (e[key] + '').toLowerCase().indexOf(this.state.search.toLowerCase()) : -1) >= 0) found += 1;
			});
			if (found !== 0) results.push(e);
		});
		this.setState({ data: results }, () => {
			let pages = results.length > this.state.itemsByPage ? (results.length / this.state.itemsByPage) + 1 : 1;
			let tableData = this.partirArray(1, results, pages);
			this.setState({
				activePage: 1,
				pages: parseInt(pages),
				tableData
			}, () => this.buildRows());
		});
	}
	XLSX = () => {
		this.setState({
			xlsx:
				<div className="ball-clip-rotate">
					<div></div>
				</div>
		}, () => {
			let { columns, data } = this.state;
			let headers = [];
			let keys = [];
			columns.map(e => {
				headers.push(e.label);
				keys.push(e.key);
				return e;
			});
			let obj = {
				data: data,
				headers: headers,
				keys: keys
			};
			if (headers.length > 0) {
				axios.post(`${Services.REPORT_SERVICE.path}/reports/dynamic`, obj).then(success => {
					if (success.data.base64) {
						const fileName = (Math.floor(Math.random() * 900000) + 100000) + Math.floor(Date.now() / 1000) + '.xlsx';
						const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
						saveAs(linkSource, fileName);
					} else console.warn('Error downloading file', success);
				}).catch(error => {
					console.error('errorr', error);
				});
			}
		});
	}

	handleAdvancedSearch = search => {
		let results = search;
		if (results === undefined) results = 0;
		this.setState({ data: search }, () => {
			let pages =
				results.length > this.state.itemsByPage
					? results.length / this.state.itemsByPage + 1
					: 1;
			let tableData;

			results === 0
				? (tableData = [])
				: (tableData = this.partirArray(1, results, pages));

			this.setState(
				{
					activePage: 1,
					pages: parseInt(pages),
					tableData
				},
				() => this.buildRows()
			);
		});
		this.setState({ isOpen: false });
	}

	handleSearchChange = (e, i) => {
		this.setState({ search: i.value });
	}

	toggleModal = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	render() {
		const options = this.props.options ? this.props.options : {};
		return (
			<div className="col-12">
				<Grid>
					<Grid.Row>
						{this.props.options.SelectZone ? <>
							<Grid.Column width={2}>
								<h4 style={{ marginTop: '12px', textAlign: 'end' }}>Almacén:</h4>
							</Grid.Column>
							<Grid.Column style={{ marginLeft: '5px' }} width={3}>
								<Select
									placeholder="Seleccione un Almacén"
									value={this.state.valor}
									options={this.state.countryOptions}
									onChange={(value) => { this.selectSearch(value); }}
								/>                            </Grid.Column></> : <></>}

						{this.props.options.selectGroup ? <>
							<Grid.Column style={{ marginLeft: '5px' }} width={3}>
								<Select
									placeholder="Group"
									value={this.state.grupo}
									options={this.state.grupoOpcion}
									onChange={(value) => { this.selectSearch2(value); }}
								/>
							</Grid.Column>

							<Grid.Column width={4}>
								<InputGroup>
									{/* <InputGroupAddon addonType="prepend">Buscar</InputGroupAddon> */}
									<Input placeholder='Customer Name' onChange={this.handleSearch('search')} />
								</InputGroup>
							</Grid.Column>
							<Grid.Column style={{ marginTop: '2px' }} width={4}>
								<em className="fa-2x icon-magnifier mr-2" style={{ color: '#a5a0a0', marginTop: '3px', marginLeft: '-22px' }} onClick={this.XLSX}></em>
							</Grid.Column>
						</> : <> </>}
						<Grid.Column width={4}>
							{options.search ?
								<InputGroup>
									<InputGroupAddon addonType="prepend">Search</InputGroupAddon>
									<Input onChange={this.handleSearch('search')} />
								</InputGroup>
								: ''}
							{/* <Modal
                                show={this.state.isOpen}
                                onClose={this.toggleModal}
                                triggerParentupdate={this.handleAdvancedSearch}
                                name={this.props.name}
                                modalName={this.props.modalName}
                                centered={true}
                            /> */}
							<>
							</>
						</Grid.Column>
						{/* 
						<Grid.Column width={4} style={{ display: 'flex', alignItems: 'center', margin: '0', padding: '0' }} >
							{options.modal ?
								<img width={22} height={22} src={IMG} alt="xml" onClick={this.toggleModal} style={{ cursor: 'pointer' }}></img>
								: ''}
						</Grid.Column> */}

						{this.props.options.SelectZone ? <></>
							: <Grid.Column width={7}>
							</Grid.Column>}

						<Grid.Column width={1}>
							{this.state.xlsx}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Table responsive className={this.props.type === 'GENERIC' ? 'table-height table table-sticky table-bordered' : ''}>
					<thead style={{ textAlign: this.props.noCenter ? '' : 'center' }}>
						<tr>
							{this.state.___mainColumns}
						</tr>
						<tr style={{ cursor: 'pointer' }}>
							{this.state.___columns}
						</tr>
					</thead>
					<tbody className="table-body">
						{this.state.___rows}
					</tbody>
				</Table>
				{
					options && options.pagination ?
						<Pagination
							ellipsisItem={null}
							activePage={this.state.activePage}
							onPageChange={this.handlePaginationChange}
							totalPages={this.state.pages}>
						</Pagination>
						: ''
				}
			</div>
		);
	}
}
export default FormExtended;
