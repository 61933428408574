export const Services = {
   "GetListCustomer": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method TO OBTAIN CUSTOMER LIST ******ROLDAN********"
   },
   "GetInfoUser": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain clientes and users related to the customer, for the modality of view and edition ******ROLDAN********"
   },
   "Get_AlertsCustomer_Alert": {
      "path": "https://roldanapialertas.azurewebsites.net",
      "method": "GET",
      "descrp": "alert ALL"
   },
   "Post_Save_Alert": {
      "path": "https://roldanapialertas.azurewebsites.net",
      "method": "GET",
      "descrp": "alert SAVE"
   },
   "Get_Catalog_Alert": {
      "path": "https://roldanapialertas.azurewebsites.net",
      "method": "GET",
      "descrp": "alert GET"
   },
   "Delete_Alert": {
      "path": "https://roldanapialertas.azurewebsites.net",
      "method": "DELETE",
      "descrp": "alert DELETE"
   },
   "Put_Update_Alert": {
      "path": "https://roldanapialertas.azurewebsites.net",
      "method": "PUT",
      "descrp": "alert DELETE"
   }
}