import React, { Component, Suspense } from 'react';
import { Row, Card, CardBody, CardText, Col } from 'reactstrap';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';

import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { MyResponsivePieCanvas } from '../../Components/Graphics/DonutGraph';
import { MyResponsiveBarCanvas } from '../../Components/Graphics/BarChart';
import Map from '../../Components/Maps/map';
import Loader from '../../../Global/Loader';
import axios from 'axios';
import { Services } from '../Services';
import './styles.scss';
// import ClientesList from '../../../../services/storageClient.js';

// const clientSave = new ClientesList();
let coloresAzules = ['#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD',];
const CustomImports = React.lazy(() => import('./components/CustomImports'));
const CustomDta = React.lazy(() => import('./components/CustomDta'));
const CustomExports = React.lazy(() => import('./components/CustomsExports'));

class Custom extends Component {
  state = {
    // clientesSelect: clientSave.getAuthentication('client'),
    clientesSelect: this.props.clients ? JSON.stringify(this.props.clients) : '[0]',
    zoom: 5,
    position: {
      lat: 4.0000000,
      lng: -72.0000000
    },
    CustomLocation: [],
    markers: [],
    dataMap: [],
    graphs: [],
    dataDrawer: '',
    polyline: [],
    visible: false,
    data: [],
    dataImports: [],
    dataOut: [],
    donutChart: [
      { id: 'Cartagena', label: 'Cartagena', value: 35, color: '#00a9ff' },
      { id: 'Bogota', label: 'Bogota', value: 30, color: '#67c7f7' },
      { id: 'Buenaventura', label: 'Buenaventura', value: 25, color: '#33adea' }
    ],
    donutChartTrafico: [
      { id: 'Salida de Puerto', label: 'Salida de Puerto', value: 30, color: '#0bc637' },
      { id: 'En Inspeccion', label: 'En Inspeccion', value: 40, color: '#149e16' },
      { id: 'Liberado', label: 'Liberad', value: 30, color: '#39ef64' }
    ],
    barTable: [
      {
        id: 'Salida de Puerto',
        label: 'Salida de Puerto',
        value: 30,
        color: '#e5d275'
      },
      {
        id: 'En Inspeccion',
        label: 'En Inspeccion',
        value: 40,
        color: '#f9f14f'
      },
      { id: 'Liberado', label: 'Liberad', value: 30, color: '#efbc04' }
    ],
    totalCards: [],

  };

  toggleDrawerMap = data => evt => {
    if (evt) evt.preventDefault();
    this.handleSelectedFile(data.id);
    this.setState({ visible: true, dataDrawer: data, polyline: this.formatPolyLine(data.From, data.To) });

  };

  componentDidMount = () => {
    this.getDataCharts();
    window.addEventListener('resize', () => {
      let newGraphs = this.state.graphs;
      newGraphs.map((e) => {
        e.width = this.calculateWidth();
        return e;
      });
      this.setState({ graphs: newGraphs });
    });
  }


  getDataCharts = () => {
    let data = {
      cliente: this.state.clientesSelect
    };
    axios.post(`${Services.Get_Chars_StatusImpoExpo.path}/customs/charts`, data).then(success => {
      this.setState({
        barTable: success.data.data.charExpo,
        donutChartTrafico: success.data.data.chartImpr,
      }, () => {
        this.getLocationCustom();
      });
    }).catch(error => {
      console.warn(error);
    });
  }

  getLocationCustom = () => {
    let data = {
      cliente: this.state.clientesSelect
    };

    axios.post(`${Services.Get_Location_Custom.path}/customs/locationAduana`, data).then(success => {
      let newMarkers = [];
      let dataMap = [];
      let totalImport = 0;
      let totalDTA = 0;
      let totalExpor = 0;
      let donutChart = [];
      success.data.data.map((e, index) => {
        let obj = {
          key: `${index}`,
          title: e.Aduana,
          name: e.Aduana,
          data: e,
          position: {
            lat: Number(e.Latitud),
            lng: Number(e.Longitud)
          },
          icon: {
            url: 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png'
          }
        };
        let OperacionesAduana = {
          id: e.Codigo,
          label: e.Aduana,
          value: Number(e.Total),
        };

        let position = {
          lat: Number(e.Longitud),
          lng: Number(e.Latitud)
        };

        newMarkers.push(obj);
        dataMap.push(position);
        donutChart.push(OperacionesAduana);
        totalImport = totalImport + Number(e.TotalImpo);
        totalDTA = totalDTA + Number(e.TotalDTA);
        totalExpor = totalExpor + Number(e.TotalExpo);
        return e;
      });

      donutChart.map((f, index) => {
        f.color = coloresAzules[index];
        return f;
      });

      let totalCard = [
        {
          title: 'Total de Importaciones',
          value: (Number(totalImport).toLocaleString('En-us')),
          icon: 'fa-arrow-alt-circle-right',
          firstColor: {
            backgroundColor: '#9e9e9e',
            color: '#fff'
          },
          secondColor: {
            backgroundColor: '#e0e0e0',
            color: '#fff'
          }
        },
        {
          title: 'Total de DTA\'s',
          value: (Number(totalDTA).toLocaleString('En-us')),
          icon: 'fa-arrow-alt-circle-right',
          firstColor: {
            backgroundColor: '#57b65b',
            color: '#fff'
          },
          secondColor: {
            backgroundColor: '#83db86',
            color: '#fff'
          }
        },
        {
          title: 'Total de Exportaciones',
          value: (Number(totalExpor).toLocaleString('En-us')),
          icon: 'fa-arrow-alt-circle-left',
          firstColor: {
            backgroundColor: '#2f80e7',
            color: '#fff'
          },
          secondColor: {
            backgroundColor: '#7eb3f6',
            color: '#fff'
          }
        }
      ];
      this.setState({
        CustomLocation: success.data.data,
        markers: newMarkers,
        dataMap: dataMap,
        totalCards: totalCard,
        donutChart: donutChart,
        fullLoader: true,
      }, () => {
        this.setState({
          graphs: [
            {
              title: 'Operaciones por Aduana',
              type: 'pie',
              data: this.state.donutChart,
              width: this.calculateWidth(),
              height: '280',
              theme: true,
              class: 6
            },
            {
              title: 'Operaciones por Estatus Impo',
              type: 'bar',
              data: this.state.donutChartTrafico,
              width: this.calculateWidth(),
              height: '280',
              class: 6

            },
            {
              title: 'Operaciones por Estatus Expo',
              type: 'bar',
              data: this.state.barTable,
              width: this.calculateWidth(),
              height: '280',
              class: 12,
              left: 120
            }
          ]
        });
      });
    }).catch(error => {
      console.warn(error);
    });
  }

  toggleDrawMap = data => {
    this.setState({ visible: true, dataDrawer: data, polyline: this.formatPolyLine(data.From, data.To) }, () => { this.handleSelectedFile(data.id); });
  }

  calculateWidth = () => {
    if (window.innerWidth >= 1756) {
      return (window.innerWidth / 4.8).toString();
    }
    else {
      return (window.innerWidth / 5.5).toString();
    }
  }

  formatPolyLine = (from, to) => {
    let out = [
      { lat: parseFloat(from.Location.Latitude), lng: parseFloat(from.Location.Longitude) },
      { lat: parseFloat(to.Location.Latitude), lng: parseFloat(to.Location.Longitude) }
    ];
    return out;
  }

  render() {
    let { markers, totalCards, graphs, position, zoom, fullLoader } = this.state;
    return (
      <ContentWrapper>
        <Loader hidden={fullLoader}></Loader>
        <div className="content-heading">
          <legend className="legendTitle">
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/audanamorado.png"
              height="28"
              width="28"
            />
            Trazabilidad de Aduana
          </legend>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row >
              {
                totalCards.map((e, index) => {
                  return (
                    <Col xs={12} sm={6} md={6} lg={3}>
                      <div key={index} className="card flex-row align-items-center align-items-stretch border-0">
                        <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={e.firstColor}>
                          <em className={`fas fa-2x ${e.icon}`}></em>{' '}
                        </div>
                        <div className="col-8 rounded-right totalCardInfo" style={e.secondColor}>
                          <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '4px' }}>{e.title}</div>
                          <div style={{ fontSize: '18px' }}>
                            {' '}
                            <center style={{ fontSize: '20px', marginTop: '6px' }}><strong>{e.value}</strong></center>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
              }
              {
                graphs.map((e) => {
                  let typeGraph = e.type === 'pie' ? (<MyResponsivePieCanvas data={e.data} theme={e.theme} />) : <MyResponsiveBarCanvas left={e.left} position="horizontal" data={e.data} />;
                  return (
                    <Col md={e.class} lg={e.class} sm={12} xs={12}>
                      <Card className="graphcardsize">
                        <CardBody className="graphcardbody">
                          <CardText style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'unset', fontWeight: 'bold' }}>{e.title}</CardText>
                          {typeGraph}
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })
              }
              <Col xs={12} sm={12} md={12} lg={12} className='mb-3'>
                <Map style={{ maxHeight: '300px' }} marker={markers} visible={true} heidIcon={45} type={'info'} position={position} zoom={zoom} dataDrawer={markers} />
              </Col>
            </Row>
          </Col>

        </Row>
        <Tab
          menu={{ secondary: true, className: 'wrapped' }}
          menuPosition='right'
          panes={[
            { menuItem: 'Importaciones', render: () => <Suspense fallback={<div>Loading...</div>}><CustomImports _user={this.props} clientesSelect={this.state.clientesSelect} /></Suspense> },
            { menuItem: 'DTA\'s', render: () => <Suspense fallback={<div>Loading...</div>}><CustomDta clientesSelect={this.state.clientesSelect} /></Suspense> },
            { menuItem: 'Exportaciones', render: () => <Suspense fallback={<div>Loading...</div>}><CustomExports clientesSelect={this.state.clientesSelect} /></Suspense> }
          ]} />
      </ContentWrapper>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Custom);
