import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const color='#3b4c7f';
// const color='#001489';
const useStyles = makeStyles(() => ({
  primary: {
    padding: '8px',
    backgroundColor: color,
    borderColor: color,
    boxShadow: 'none',
    color: '#ffff',    
    '&:hover': {
      backgroundColor: color,
      borderColor: color,
      boxShadow: 'none',
      color: '#ffff',
    },
  }
}));


function CircleButton(props){
  const classes = useStyles();
  return (
    <IconButton aria-label="actions" className={classes.primary} onClick={props.onClick} disabled={props.disabled}>
      <Icon fontSize="small">add</Icon>
    </IconButton>
  );
}

CircleButton.propTypes = {
  onClick: propTypes.func,
  disabled: propTypes.bool
};
CircleButton.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default CircleButton;