import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const opStatusMenu = [
  {
    label: ['Internacional', 'ProblemaOperacionMensaje'],
    value: 'currentLabel',
  },
  {
    label: ['Importación', 'ProblemaOperacionMensaje'],
    value: 'currentLabel',
  },
  {
    label: ['Exportación', 'ProblemaOperacionMensaje'],
    value: 'currentLabel',
  },
  {
    label: ['Ingresos', 'ProblemaOperacionMensaje'],
    value: 'currentLabel',
  },
  {
    label: ['Despachos', 'ProblemaOperacionMensaje'],
    value: 'currentLabel',
  },
];

function ProblemOperationMenu(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <ChatbotButtons buttons={opStatusMenu} botSteps={props} />
    </div>
  );
}

export default ProblemOperationMenu;
