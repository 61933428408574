import React, { Component } from "react";
import { Row, Label } from "reactstrap";
import { Pagination } from "semantic-ui-react";
import { CardDespacho } from "./Despachos";
import { CardInternacional } from "./Internacional";
import { CardImportaciones } from "./Importaciones";
import { CardExportaciones } from "./Exportaciones";
import { CardIngresos } from "./Ingresos";
import { CardTransporte } from "./Transporte";

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activePageInternal: 0,
      activePageImports: 0,
      activePageExports: 0,
      activePageIngresos: 0,
      activePageDespachos: 0,
      activePageTransporte: 0,

      totalPagesInter: 0,
      totalPagesImports: 0,
      totalPagesExoports: 0,
      totalPagesIngresos: 0,
      totalPagesDespachos: 0,
      totalPagesTransporte: 0,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.data)
      this.setState(
        {
          data: nextProps.data,
          dataPage: nextProps.data,
          allData: nextProps.data,
        },
        () => {
          this.initPages();
        }
      );
  };

  initPages = () => {
    const { dataPage } = this.state;

    let pagInternacional = Math.ceil(
      parseInt(dataPage.Internacional.Total) / 10
    );
    let pagImportaciones = Math.ceil(
      parseInt(dataPage.Importaciones.Total) / 10
    );
    let pagExportaciones = Math.ceil(
      parseInt(dataPage.Exportaciones.Total) / 10
    );
    let pagIngresos = Math.ceil(parseInt(dataPage.Ingresos.Total) / 10);
    let pagDespachos = Math.ceil(parseInt(dataPage.Despachos.Total) / 10);
    let pagTransporte = Math.ceil(parseInt(dataPage.Transporte.Total) / 10);

    let totalInternal = dataPage.Internacional.Total;
    let totalImpo = dataPage.Importaciones.Total;
    let totalExpo = dataPage.Exportaciones.Total;
    let totalIngreso = dataPage.Ingresos.Total;
    let totalDespachos = dataPage.Despachos.Total;
    let totalTransporte = dataPage.Transporte.Total;

    this.setState(
      {
        activePageInternal: 1,
        activePageImports: 1,
        activePageExports: 1,
        activePageIngresos: 1,
        activePageDespachos: 1,
        activePageTransporte: 1,

        totalPagesInter: pagInternacional,
        totalPagesImports: pagImportaciones,
        totalPagesExoports: pagExportaciones,
        totalPagesIngresos: pagIngresos,
        totalPagesDespachos: pagDespachos,
        totalPagesTransporte: pagTransporte,
        totalInternal,
        totalImpo,
        totalExpo,
        totalIngreso,
        totalDespachos,
        totalTransporte,
      },
      () => {
        this.buildPageResults();
      }
    );
  };

  buildPageResults = () => {
    let {
      activePageDespachos,
      activePageInternal,
      activePageImports,
      activePageExports,
      activePageIngresos,
      activePageTransporte,
    } = this.state;

    let dataInternacional = this.setResultsByPage(
      activePageInternal,
      this.state.dataPage.Internacional.Internacional
    );
    let dataImportaciones = this.setResultsByPage(
      activePageImports,
      this.state.dataPage.Importaciones.Importaciones
    );
    let dataExportaciones = this.setResultsByPage(
      activePageExports,
      this.state.dataPage.Exportaciones.Exportaciones
    );
    let dataIngreso = this.setResultsByPage(
      activePageIngresos,
      this.state.dataPage.Ingresos.Ingresos
    );
    let dataDespachos = this.setResultsByPage(
      activePageDespachos,
      this.state.dataPage.Despachos.Despachos
    );
    let dataTransporte = this.setResultsByPage(
      activePageTransporte,
      this.state.dataPage.Transporte.Transporte
    );

    this.setState(
      {
        dataDespachos,
        dataInternacional,
        dataImportaciones,
        dataExportaciones,
        dataIngreso,
        dataTransporte,
      },
      () => {
        this.buildCards();
      }
    );
  };

  setResultsByPage = (page, all) => {
    page = page - 1;
    const pag = all.slice(page * 10, (page + 1) * 10);
    return pag;
  };

  changePage = (page, from) => {
    if (from === "internacional") {
      this.setState(
        {
          activePageInternal: page,
        },
        () => {
          this.buildPageResults();
        }
      );
    }
    if (from === "importaciones") {
      this.setState(
        {
          activePageImports: page,
        },
        () => {
          this.buildPageResults();
        }
      );
    }
    if (from === "exportaciones") {
      this.setState(
        {
          activePageExports: page,
        },
        () => {
          this.buildPageResults();
        }
      );
    }
    if (from === "ingresos") {
      this.setState(
        {
          activePageIngresos: page,
        },
        () => {
          this.buildPageResults();
        }
      );
    }
    if (from === "despachos") {
      this.setState(
        {
          activePageDespachos: page,
        },
        () => {
          this.buildPageResults();
        }
      );
    }
    if (from === "transporte") {
      this.setState(
        {
          activePageTransporte: page,
        },
        () => {
          this.buildPageResults();
        }
      );
    }
  };

  buildCards = () => {
    const {
      dataDespachos,
      dataInternacional,
      dataImportaciones,
      dataExportaciones,
      dataIngreso,
      dataTransporte,
    } = this.state;

    /* =====================================INTERNACIONAL===================================================*/
    let tagsInternacional = dataInternacional.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <CardInternacional element={e}></CardInternacional>
        </div>
      );
    });
    this.setState(
      {
        cardsInternacional: "",
      },
      () => this.setState({ cardsInternacional: tagsInternacional })
    );

    /* =====================================ADUANA IMPORTACIONES y DTA===================================================*/
    let tagsImportaciones = dataImportaciones.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <CardImportaciones element={e}></CardImportaciones>
        </div>
      );
    });
    this.setState(
      {
        cardsImportaciones: "",
      },
      () => this.setState({ cardsImportaciones: tagsImportaciones })
    );

    /* =====================================ADUANA EXPORTACIONES================================================== */
    let tagsExportaciones = dataExportaciones.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <CardExportaciones element={e}></CardExportaciones>
        </div>
      );
    });
    this.setState(
      {
        cardsExportaciones: "",
      },
      () => this.setState({ cardsExportaciones: tagsExportaciones })
    );

    /* =====================================INGRESOS================================================== */
    let tagsIngresos = dataIngreso.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <CardIngresos element={e}></CardIngresos>
        </div>
      );
    });
    this.setState(
      {
        cardsIngresos: "",
      },
      () => this.setState({ cardsIngresos: tagsIngresos })
    );

    /* =====================================DESPACHOS================================================== */
    let tagsDespachos = dataDespachos.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <CardDespacho element={e}></CardDespacho>
        </div>
      );
    });
    this.setState(
      {
        cardsDespachos: "",
      },
      () => this.setState({ cardsDespachos: tagsDespachos })
    );

    /* =====================================Transporte================================================== */
    let tagsTransporte = dataTransporte.map((e, index) => {
      return (
        <div className="col-12" key={index}>
          <CardTransporte element={e}></CardTransporte>
        </div>
      );
    });
    this.setState(
      {
        cardsTransporte: "",
      },
      () => this.setState({ cardsTransporte: tagsTransporte })
    );
  };

  render() {
    let {
      activePageInternal,
      activePageImports,
      activePageExports,
      activePageIngresos,
      activePageDespachos,
      activePageTransporte,
      totalInternal,
      totalImpo,
      totalExpo,
      totalIngreso,
      totalDespachos,
      totalTransporte,
    } = this.state;
    return (
      <Row>
        <div className="col-12">
          <br />
          <h4>Internacional</h4>
          <Label>
            {activePageInternal * 10 > totalInternal
              ? totalInternal
              : activePageInternal * 10}{" "}
            de {totalInternal} resultados
          </Label>
          <div className="col-12">{this.state.cardsInternacional}</div>
          {totalInternal !== 0 ? (
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageInternal}
                  ellipsisItem={null}
                  totalPages={this.state.totalPagesInter}
                  onPageChange={(e, { activePage }) =>
                    this.changePage(activePage, "internacional")
                  }
                />
              </center>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-12">
          <br />
          <h4>Aduana Importaciones y DTA's</h4>
          <Label>
            {activePageImports * 10 > totalImpo
              ? totalImpo
              : activePageImports * 10}{" "}
            de {totalImpo} resultados
          </Label>
          <div className="col-12">{this.state.cardsImportaciones}</div>
          {totalImpo !== 0 ? (
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageImports}
                  ellipsisItem={null}
                  totalPages={this.state.totalPagesImports}
                  onPageChange={(e, { activePage }) =>
                    this.changePage(activePage, "importaciones")
                  }
                />
              </center>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-12">
          <br />
          <h4>Aduana Exportaciones</h4>
          <Label>
            {activePageExports * 10 > totalExpo
              ? totalExpo
              : activePageExports * 10}{" "}
            de {totalExpo} resultados
          </Label>
          <div className="col-12">{this.state.cardsExportaciones}</div>
          {totalExpo !== 0 ? (
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageExports}
                  ellipsisItem={null}
                  totalPages={this.state.totalPagesExoports}
                  onPageChange={(e, { activePage }) =>
                    this.changePage(activePage, "exportaciones")
                  }
                />
              </center>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-12">
          <br />
          <h4>Almacén Ingresos</h4>
          <Label>
            {activePageIngresos * 10 > totalIngreso
              ? totalIngreso
              : activePageIngresos * 10}{" "}
            de {totalIngreso} resultados
          </Label>
          <div className="col-12">{this.state.cardsIngresos}</div>
          {totalIngreso !== 0 ? (
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageIngresos}
                  ellipsisItem={null}
                  totalPages={this.state.totalPagesIngresos}
                  onPageChange={(e, { activePage }) =>
                    this.changePage(activePage, "ingresos")
                  }
                />
              </center>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-12">
          <br />
          <h4>Almacén Despachos</h4>
          <Label>
            {activePageDespachos * 10 > totalDespachos
              ? totalDespachos
              : activePageDespachos * 10}{" "}
            de {totalDespachos} resultados
          </Label>
          <div className="col-12">{this.state.cardsDespachos}</div>
          {totalDespachos !== 0 ? (
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageDespachos}
                  ellipsisItem={null}
                  totalPages={this.state.totalPagesDespachos}
                  onPageChange={(e, { activePage }) =>
                    this.changePage(activePage, "despachos")
                  }
                />
              </center>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-12">
          <br />
          <h4>Transporte</h4>
          <Label>
            {activePageTransporte * 10 > totalTransporte
              ? totalTransporte
              : activePageTransporte * 10}{" "}
            de {totalTransporte} resultados
          </Label>
          <div className="col-12">{this.state.cardsTransporte}</div>
          {totalTransporte !== 0 ? (
            <div className="col-12">
              <center>
                <Pagination
                  activePage={activePageTransporte}
                  ellipsisItem={null}
                  totalPages={this.state.totalPagesTransporte}
                  onPageChange={(e, { activePage }) =>
                    this.changePage(activePage, "transporte")
                  }
                />
              </center>
            </div>
          ) : (
            ""
          )}
        </div>
      </Row>
    );
  }
}
export default Cards;
