import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Popup } from "semantic-ui-react";
import "./styles/cards.scss";

const limit = 12;
const highLimit = 20;

const status_ = {
  MARITIMA: (
    <img
      alt=""
      src="https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png"
      width="50"
    ></img>
  ),
  AEREA: (
    <img
      alt=""
      src="https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png"
      width="50"
    ></img>
  ),
  OTM: (
    <img
      alt=""
      src="https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png"
      width="50"
    ></img>
  ),
  TERRESTRE: (
    <img
      alt=""
      src="https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png"
      width="50"
    ></img>
  ),
};

export const CardInternacional = (props) => {
  let { element } = props;
  return (
    <>
      <Card className="_clickCard mySearchCard" id={Math.random()}>
        <CardBody>
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                {/* Icono & DO ID & Estatus */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================ICONO===================================== */}
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                Transporte
                              </span>
                            </div>
                            <div className="mb-mail-subject">
                              {status_[element.ModoTransporte]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ==========================cliente =========================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Cliente ? (
                                element.Cliente.length > limit ? (
                                  <Popup
                                    content={element.Cliente}
                                    key={element.Cliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Cliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Cliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================DO ID===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              DO ID
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              <Link
                                to={"/shipmentsDetal/" + element.EmbarqueId}
                              >
                                {element.DoEmbarque ? (
                                  element.DoEmbarque.length > highLimit ? (
                                    <Popup
                                      content={element.DoEmbarque}
                                      key={element.DoEmbarque}
                                      position=""
                                      trigger={
                                        <span>
                                          {element.DoEmbarque.substring(
                                            0,
                                            limit - 3
                                          )}
                                          ...
                                        </span>
                                      }
                                    />
                                  ) : (
                                    element.DoEmbarque
                                  )
                                ) : (
                                  ""
                                )}
                              </Link>
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* MODO transporte & doc transporte */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================Estatus===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              <span>Estatus </span>
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <span style={{ fontSize: "12px" }}>
                            {element.Estatus ? (
                              element.Estatus.length > limit ? (
                                <Popup
                                  content={element.Estatus}
                                  key={element.Estatus}
                                  position=""
                                  trigger={
                                    <span>
                                      {element.Estatus.substring(0, limit - 3)}
                                      ...
                                    </span>
                                  }
                                />
                              ) : (
                                element.Estatus
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* ==========================MODO transporte===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Modo de Transporte
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.ModoTransporte ? (
                                element.ModoTransporte.length > limit ? (
                                  <Popup
                                    content={element.ModoTransporte}
                                    key={element.ModoTransporte}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.ModoTransporte.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.ModoTransporte
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================ref cliente===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Doc. de Transporte
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.HBL_HAWB ? (
                                element.HBL_HAWB.length > limit ? (
                                  <Popup
                                    content={element.HBL_HAWB}
                                    key={element.HBL_HAWB}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.HBL_HAWB.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.HBL_HAWB
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Aduana & País origen*/}
                <div className="col-md-2">
                  <div className="row text-center">
                    {/* ==========================Aduana ===================================== */}
                    <div className="col-md-5">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Referencia del Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.RefCliente ? (
                                element.RefCliente.length > limit ? (
                                  <Popup
                                    content={element.RefCliente}
                                    key={element.RefCliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.RefCliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.RefCliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Pais Destino===================================== */}
                    <div className="col-md-7">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Proveedor
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Proveedor ? (
                                element.Proveedor.length > limit ? (
                                  <Popup
                                    content={element.Proveedor}
                                    key={element.Proveedor}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Proveedor.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Proveedor
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Instruccion & ATA */}
                <div className="col-md-4">
                  <div className="row text-center">
                    {/* ========================== Instruccion===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Origen
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.POL ? (
                                element.POL.length > limit ? (
                                  <Popup
                                    content={element.POL}
                                    key={element.POL}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.POL.substring(0, limit - 3)}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.POL
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Autorización ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Destino
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.POD ? (
                                element.POD.length > limit ? (
                                  <Popup
                                    content={element.POD}
                                    key={element.POD}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.POD.substring(0, limit - 3)}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.POD
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Manifiesto ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              ETD
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.ETD ? (
                                element.ETD.length > limit ? (
                                  <Popup
                                    content={element.ETD}
                                    key={element.ETD}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.ETD.substring(0, limit - 3)}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.ETD
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* END OF MAIN DIV */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
