export const Services = {
   "PostContact": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "POST",
      "descrp": "method to add contact  ******ROLDAN********"
   },
   "GetInfoCustomer": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain contacts and users related to the customer, for the modality of view and edition ******ROLDAN********"
   },
   "DeleteContact": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to deleted contact  ******ROLDAN********"
   },
   "PutCustomers": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "PUT",
      "descrp": "CUSTOMER UPDATE method ******ROLDAN********"
   },
   "GetRole": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain roles the user ******ROLDAN********"
   },
   "PostUser": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "POST",
      "descrp": "method to add User ******ROLDAN********"
   },
   "GetUser": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain user ******ROLDAN******** "
   },
   "GetInfoUser": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain clientes and users related to the customer, for the modality of view and edition ******ROLDAN********"
   },
   "PutUser": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "PUT",
      "descrp": "User UPDATE method ******ROLDAN********"
   },
   "PostCustomer": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "POST",
      "descrp": "User UPDATE method ******ROLDAN********"
   },
   "GetListCustomer": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method TO OBTAIN CUSTOMER LIST ******ROLDAN********"
   },
   "DeleteCustomer": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "DELETE",
      "descrp": "DELETE CUSTOMER FOR ID ******ROLDAN********"
   },
   "GetCountrys": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "ADMIN ******ROLDAN********"
   },
   "GetModuls": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "ADMIN ******ROLDAN********"
   },
   "DeleteUser": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "DELETE",
      "descrp": "GET REPORTS CATALOGS ******ROLDAN********"
   },
   "GET_REPORTS_CATALOGS": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "GET REPORTS CATALOGS ******ROLDAN********"
   },
   "GET_DOCUMENTS_CATALOGS": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "GET REPORTS CATALOGS"
   },
   "GET_DOCUMENTS_RELATION": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "GET REPORTS CATALOGS"
   },
   "GET_REPORTS_RELATION": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "GET REPORTS CATALOGS  ******ROLDAN********"
   },
   "POST_LIST_GROUP": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "POST",
      "descrp": "method TO OBTAIN GROUP LIST ******ROLDAN********"
   },
   "GET_LIST_GROUP": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method TO OBTAIN GROUP LIST ******ROLDAN********"
   },
   "GET_INFO_GROUP": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain clients related to the grupos, for the modality of view and edition ******ROLDAN********"
   },
   "PUT_GROUP_ID": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "PUT",
      "descrp": "User UPDATE method ******ROLDAN********"
   },
   "DELETE_GROUP_FORID": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "DELETE",
      "descrp": "GET REPORTS CATALOGS ******ROLDAN********"
   }
}