import React, { Component, Suspense } from 'react';
import ContentWrapper from '../../../../../template/Layout/ContentWrapper';
import { Row, Col, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import '../../../../../styles/customStyle.scss';
import Comments from '../../../../Components/CommenInteger';
import Loader from '../../../../../Global/Loader';
import moment from 'moment';
// import './../styles.scss';
import { CloseIcon } from '../../Impo/Detail/config';
import axios from 'axios';
import { Services } from '../../../Services';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { validateIdParams } from '../../../../../utils/regularExpressionValidation';
import Errors from '../../../../Components/Errors';

const StepperContainer = React.lazy(() =>
  import('../../../../Components/StepperContainer')
);

const status_ = {
  AEREA: 'https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png',
  MARITIMA: 'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png',
  OTM: 'https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png',
  TERRESTRE: 'https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png',
  AEREO: 'https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png',
  MARITIMO: 'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png',
};

class IntegratedDetailExpo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        open: false,
        message: "",
        type: "admin",
      },
      customWidth: window.innerWidth,
      id: props.match.params.id,
      embarque: '',
      Integrado: [],
      fullLoader: false,
      commentsData: [
        {
          ComType: 'Tipo 1',
          ComTypeId: 1,
          Comment: 'Comentario de prueba',
          CommentId: 73,
          Date: '2019-06-20',
          Module: 'Comentario',
          ModuleId: 1,
          RowId: 861,
          UpdateBy: 'API',
        },
        {
          ComType: 'Tipo 2',
          ComTypeId: 1,
          Comment: 'Comentario de prueba',
          CommentId: 73,
          Date: '2019-07-02',
          Module: 'Comentario',
          ModuleId: 1,
          RowId: 861,
          UpdateBy: 'API',
        },
      ],
      stepsDepoHab: [
        {
          label: 'Notificación de Ingreso',
          value: '',
          id: 1,
          completed: false,
          order: 1,
          middleStep: '',
        },
        {
          label: 'Llegada a Almacén',
          value: '',
          id: 2,
          completed: false,
          order: 2,
          idStep: 'step2',
          middleStep: '',
        },
        {
          label: 'Inicio de Descargue',
          value: '',
          id: 3,
          completed: false,
          order: 3,
          idStep: 'step3',
          middleStep: '',
        },
        {
          label: 'Fin de Descargue',
          value: '',
          id: 4,
          completed: false,
          order: 4,
          idStep: 'step4',
          middleStep: '',
        },
        {
          label: 'Manifiesto (Público)',
          value: '',
          id: 6,
          completed: false,
          order: 5,
          idStep: 'step5',
          middleStep: '',
        },
        {
          label: 'Vencimiento de Manifiesto',
          value: '',
          id: 7,
          completed: false,
          order: 6,
          idStep: 'step6',
          middleStep: '',
        },
        {
          label: 'Disponibilidad de Inventario',
          value: '',
          id: 8,
          completed: false,
          order: 7,
          idStep: 'step6',
          middleStep: '',
        },
      ],
      stepsEmbarque: [
        {
          label: 'Destino Final',
          value: '',
          id: 23,
          completed: false,
          order: 6,
        },
        {
          label: 'Salida de Puerto',
          value: '',
          id: 22,
          completed: false,
          order: 5,
        },
        {
          label: 'ATA',
          value: '',
          id: 11,
          completed: false,
          order: 4,
        },
        {
          label: 'ATD',
          value: '',
          id: 9,
          completed: false,
          order: 3,
        },
        {
          label: 'Recolección / Recepción',
          value: '',
          id: 4,
          completed: false,
          order: 2,
        },
        {
          label: 'Carga Lista',
          value: '',
          id: 3,
          completed: false,
          order: 1,
        },
      ],
      stepsImport: [
        {
          label: 'Instrucción Cliente AA (Reserva)',
          value: '',
          id: 2,
          completed: false,
          order: 1,
          middleStep: '',
        },
        {
          label: 'Autorización de Embarque',
          value: '',
          id: 8,
          completed: false,
          order: 2,
          idStep: '',
          middleStep: '',
        },
        {
          label: 'Cierre Físico',
          value: '',
          id: 13,
          completed: false,
          order: 3,
          middle: false,
          idStep: '',
          middleStep: '',
        },
        {
          label: 'Manifiesto de Carga',
          value: '',
          id: 16,
          completed: false,
          order: 4,
          idStep: 'step4',
          middleStep: '',
        },
        {
          label: 'Facturación',
          value: '',
          id: 18,
          completed: false,
          order: 5,
          idStep: 'step5',
          middleStep: '',
        },
        {
          label: 'Radicación Factura',
          value: '',
          id: 19,
          completed: false,
          order: 6,
          idStep: 'step5',
          middleStep: '',
        },
      ],
      steps: [
        {
          icon:
            'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13.png',
          classes: 'class1',
          card: {
            title: 'Depósito Habilitado',
            progress: {
              // title: '0 días - No iniciado',
              progress: 'notStarted',
            },
            data: [
              {
                title: 'No de Ingreso',
                value: '',
                data: [
                  {
                    label: 'N098',
                    value: 'N098',
                  },
                  {
                    label: 'N099',
                    value: 'N099',
                  },
                  {
                    label: 'N100',
                    value: 'N100',
                  },
                ],
                primary: false,
                select: true,
              },
              {
                title: 'Nombre del Almacén',
                value: '',
                primary: false,
              },
              {
                title: 'Tipo de Vehículo',
                value: '',
                primary: false,
              },
              {
                title: 'Fecha de Llegada',
                value: '',
                primary: false,
              },
              {
                title: 'Cantidades Recibidas',
                value: '',
                primary: false,
              },
            ],
            comments: [],
          },
        },
        {
          icon:
            'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png',
          classes: 'class2',
          card: {
            title: 'Aduana',
            progress: {
              // title: '10 días - En Proceso',
              progress: 'process',
            },
            data: [
              {
                title: 'DO de Aduana',
                value: '',
                primary: true,
              },
              {
                title: 'Aduana',
                value: '',
                primary: false,
              },
              {
                title: 'Agente Aduanal',
                value: '',
                primary: false,
              },
              {
                title: 'Radicacion de Facturacion',
                value: '',
                primary: false,
              },
              {
                title: 'Manifesto de Carga',
                value: '',
                primary: false,
              },
              {
                title: 'Autorización de embarque',
                value: '',
                primary: false,
              },
            ],
            comments: [],
          },
        },
        {
          icon:
            'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png',
          classes: 'class1',
          card: {
            title: 'Embarque',
            progress: {
              // title: '25 días - Finalizado',
              progress: 'finish',
            },
            data: [
              {
                title: 'DO de Embarque',
                value: '',
                primary: true,
              },
              {
                title: 'Proveedor',
                value: '',
                primary: false,
              },
              {
                title: 'Modo de Transporte',
                value: '',
                primary: false,
              },
              {
                title: 'Agente de Carga',
                value: '',
                primary: false,
              },
              {
                title: 'Carrier',
                value: '',
                primary: false,
              },
              {
                title: 'ETD',
                value: '',
                primary: false,
              },
              {
                title: 'ETA',
                value: '',
                primary: false,
              },
              {
                title: 'POL',
                value: '',
                primary: false,
              },
              {
                title: 'POD',
                value: '',
                primary: false,
              },
            ],
            comments: [],
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getIntegredForId();
  };
  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ customWidth: window.innerWidth });
  };


  getIntegredForId = () => {
    let { steps, id } = this.state;
    let idValidate = validateIdParams(id);
    if (idValidate) {
      axios
        .get(
          `${Services.Get_Traceability_Integred_Expo_ForId.path}/integrated/expo/${this.state.id}`
        )
        .then((success) => {

          let DataAduana = success.data.data.Aduana.Aduana;

          let DataEmbarque = success.data.data.infoEmbarque.infoEmbarque;
          let DataDepoHabilitado =
            success.data.data.DataDepositoHabilitado.DepositoHabi;

          let EventsDepoHabilitado =
            success.data.data.DataDepositoHabilitado.events;
          let EventsEmbarque = success.data.data.infoEmbarque.events;
          let EventsAduana = success.data.data.Aduana.events;

          let commentsDataEbarque = [];
          let commentsDataDepHab = [];
          let commentsDataAduna = [];

          let Success = success.data.data;
          let dataSelectDepositoHabilitado = [];

          if (EventsAduana) {
            var newStepsAduana = this.state.stepsImport;
            let findNext = false;
            newStepsAduana.map((f) => {
              EventsAduana.map((e) => {
                if (e.EventDescriptionId === f.id) {
                  findNext = true;
                  f.value = e.EventDate;
                  if (e.InTimeLine === '1') {
                    f.completed = true;
                  } else {
                    findNext = false;
                  }
                } else {
                  findNext = false;
                }
                if (findNext) {
                  f.completed = true;
                }
                return e;
              });
              return f;
            });
            newStepsAduana.sort(function (a, b) {
              return b.order - a.order;
            });
            let findTrue = false;
            newStepsAduana.map((e) => {
              if (e.completed) {
                findTrue = true;
              }
              if (findTrue) {
                e.completed = true;
              }
              return e;
            });
          }

          if (EventsDepoHabilitado) {
            var newStepsDepHab = this.state.stepsDepoHab;
            var findNext = false;
            newStepsDepHab.map((f) => {
              EventsDepoHabilitado.map((e) => {
                if (e.EventDescriptionId === f.id) {
                  findNext = true;
                  f.value = e.EventDate;
                  if (e.InTimeLine === '1') {
                    f.completed = true;
                  } else {
                    findNext = false;
                  }
                } else {
                  findNext = false;
                }
                if (findNext) {
                  f.completed = true;
                }
                return e;
              });
              return f;
            });
            newStepsDepHab.sort(function (a, b) {
              return b.order - a.order;
            });
            let findTrue = false;
            newStepsDepHab.map((e) => {
              if (e.completed) {
                findTrue = true;
              }
              if (findTrue) {
                e.completed = true;
              }
              return e;
            });
          }

          if (EventsEmbarque) {
            var newStepsEmbarque = this.state.stepsEmbarque;
            let findNext = false;
            newStepsEmbarque.map((f) => {
              EventsEmbarque.map((e) => {
                if (e.EventDescriptionId === f.id) {
                  findNext = true;
                  f.value = e.EventDate;
                  if (e.InTimeLine === '1') {
                    f.completed = true;
                  } else {
                    findNext = false;
                  }
                } else {
                  findNext = false;
                }
                if (findNext) {
                  f.completed = true;
                }
                return e;
              });
              return f;
            });
            newStepsEmbarque.sort(function (a, b) {
              return b.order - a.order;
            });
            let findTrue = false;
            newStepsEmbarque.map((e) => {
              if (e.completed) {
                findTrue = true;
              }
              if (findTrue) {
                e.completed = true;
              }
              return e;
            });
          }

          if (DataDepoHabilitado) {
            if (DataDepoHabilitado.ObservacionesRecepcion) {
              let commetEmbarque = {
                ComType: '',
                ComTypeId: 1,
                Comment: DataDepoHabilitado.ObservacionesRecepcion,
                CommentId: '',
                Date: DataDepoHabilitado.FechaNotificacionIngreso,
                Module: 'Comentario',
                ModuleId: 1,
                RowId: '',
                UpdateBy: 'API',
              };
              commentsDataDepHab.push(commetEmbarque);
            }
          }
          if (DataEmbarque) {
            let commetEmbarque = {
              ComType: '',
              ComTypeId: 1,
              Comment: DataEmbarque.Observaciones,
              CommentId: '',
              Date: DataEmbarque.ETA,
              Module: 'Comentario',
              ModuleId: 1,
              RowId: '',
              UpdateBy: 'API',
            };
            commentsDataEbarque.push(commetEmbarque);
          }

          if (DataAduana) {
            if (DataAduana.Observaciones) {
              let commetEmbarque = {
                ComType: '',
                ComTypeId: 1,
                Comment: DataAduana.Observaciones,
                CommentId: '',
                Date: DataAduana.FechaActualizacion,
                Module: 'Comentario',
                ModuleId: 1,
                RowId: '',
                UpdateBy: 'API',
              };
              commentsDataAduna.push(commetEmbarque);
            }
          }

          if (Success.DepositoHabilitado.length > 1) {
            Success.DepositoHabilitado.map((e) => {
              dataSelectDepositoHabilitado.push({
                label: e,
                value: e,
              });
              return e;
            });
          } else {
            dataSelectDepositoHabilitado = [];
          }

          steps = [
            {
              icon:
                Object.entries(DataDepoHabilitado).length === 0
                  ? 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13gris.png'
                  : 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13.png',
              classes: 'class2',
              completed:
                Object.entries(DataDepoHabilitado).length === 0 ? false : true,
              card: {
                link: '/storage-entries-detail/',
                id:
                  Object.entries(DataDepoHabilitado).length === 0
                    ? ''
                    : DataDepoHabilitado.IngresoId,
                title: 'Depósito Habilitado',
                progress: {
                  // title: '0 días - No iniciado',
                  progress: DataDepoHabilitado ? 'finish' : 'notStarted',
                },
                steps: EventsDepoHabilitado
                  ? newStepsDepHab
                  : this.state.stepsDepoHab.sort(function (a, b) {
                    return b.order - a.order;
                  }),
                data: [
                  {
                    title: 'No de Ingreso',
                    value: DataDepoHabilitado.NoIngreso,
                    data: dataSelectDepositoHabilitado,
                    primary: false,
                    select:
                      dataSelectDepositoHabilitado.length > 1 ? true : false,
                  },
                  {
                    title: 'Nombre del Almacén',
                    value: DataDepoHabilitado
                      ? DataDepoHabilitado.Almacen
                      : '---',
                    primary: false,
                  },
                  {
                    title: 'Tipo de Vehículo',
                    value: DataDepoHabilitado
                      ? DataDepoHabilitado.TipoVehiculo
                      : '---',
                    primary: false,
                  },
                  {
                    title: 'Fecha de Llegada',
                    value: DataDepoHabilitado
                      ? DataDepoHabilitado.FechaLlegadaAlmacen
                      : '---',
                    primary: false,
                  },
                  {
                    title: 'Cantidades Recibidas',
                    value: DataDepoHabilitado
                      ? DataDepoHabilitado.CantidadesRecibidas
                      : '---',
                    primary: false,
                  },
                ],
                comments: commentsDataDepHab ? commentsDataDepHab : [],
              },
            },
            {
              icon: DataAduana
                ? 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png'
                : 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19gris.png',
              classes: 'class2',
              completed: DataAduana ? true : false,
              card: {
                link: '/exports-detail-view/',
                id:
                  Object.entries(DataAduana).length === 0
                    ? ''
                    : DataAduana.ExportacionId,
                title: 'Aduana',
                progress: {
                  // title: '10 días - En Proceso',
                  progress: DataAduana ? 'finish' : 'notStarted',
                },
                steps: EventsAduana ? newStepsAduana : this.state.stepsImport,

                data: [
                  {
                    title: 'DO de Aduana',
                    value: DataAduana ? DataAduana.DOAduanaExpo : '',
                    primary: true,
                  },
                  {
                    title: 'Aduana',
                    value: DataAduana ? DataAduana.Aduana : '',
                    primary: false,
                  },
                  {
                    title: 'Agente Aduanal',
                    value: 'ROLDÁN ADUANA',
                    primary: false,
                  },
                  {
                    title: 'Radicacion de Facturacion',
                    value: DataAduana
                      ? DataAduana.FechaFacturacion
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Manifesto de Carga',
                    value: DataAduana
                      ? DataAduana.FechaManifiestoCarga
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Autorización de embarque',
                    value: DataAduana ? DataAduana.FechaAutorizaEmbarque : '',
                    primary: false,
                  },
                ],
                comments: commentsDataAduna ? commentsDataAduna : [],
                noSolicitud: DataAduana.NoSolicitud
              },
            },
            {
              icon:
                Object.entries(DataEmbarque).length === 0
                  ? 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-01gris.png'
                  : status_[DataEmbarque.ModoTransporte]
                    ? status_[DataEmbarque.ModoTransporte]
                    : 'https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png',
              classes: 'class2',
              completed: Object.entries(DataEmbarque).length === 0 ? false : true,
              card: {
                link: '/shipmentsDetal/',
                title: 'Embarque',
                id:
                  Object.entries(DataEmbarque).length === 0
                    ? ''
                    : DataEmbarque.EmbarqueId,
                progress: {
                  // title: '25 días - Finalizado',
                  progress: DataEmbarque ? 'finish' : 'notStarted',
                },
                steps: EventsEmbarque
                  ? newStepsEmbarque
                  : this.state.stepsEmbarque,
                data: [
                  {
                    title: 'DO de Embarque',
                    value: DataEmbarque ? DataEmbarque.DoEmbarque : '',
                    primary: true,
                  },
                  {
                    title: 'Proveedor',
                    value: DataEmbarque ? DataEmbarque.Proveedor : '',
                    primary: false,
                  },
                  {
                    title: 'Modo de Transporte',
                    value: DataEmbarque ? DataEmbarque.ModoTransporte : '',
                    primary: false,
                  },
                  {
                    title: 'Agente de Carga',
                    value: DataEmbarque ? 'GLODEX' : '',
                    primary: false,
                  },
                  {
                    title: 'Carrier',
                    value: DataEmbarque ? DataEmbarque.Carrier : '',
                    primary: false,
                  },
                  {
                    title: 'ETD',
                    value: DataEmbarque ? DataEmbarque.ETD : '',
                    primary: false,
                  },
                  {
                    title: 'ETA',
                    value: DataEmbarque ? DataEmbarque.ETA : '',
                    primary: false,
                  },
                  {
                    title: 'Origen',
                    value: DataEmbarque ? DataEmbarque.POL : '',
                    primary: false,
                  },
                  {
                    title: 'Destino',
                    value: DataEmbarque ? DataEmbarque.POD : '',
                    primary: false,
                  },
                ],
                comments: commentsDataEbarque ? commentsDataEbarque : [],
                noSolicitud: DataEmbarque.NoSolicitud
              },
            },
          ];

          this.setState({
            Integrado: success.data.data.infoIntegrado,
            steps,
            fullLoader: true,
          });
        })
        .catch((error) => {
          this.setState({
            fullLoader: true,
          });
          console.warn(error);
        });
    } else {
      this.setState({
        error: {
          message: "Dato incorrecto, favor de verificar",
          open: true,
          type: "error",
        }
      }, () => {
        setTimeout(() => {
          this.props.history.push("/integrada");
        }, 2000);
      })
    }
  };

  openCommt = (value, id) => {
    this.setState({ openComments: true, commentsData: value, id });
  };

  render() {
    let { Integrado, fullLoader, commentsData, id, customWidth, error } = this.state;
    return (
      <>
        <Loader hidden={fullLoader}></Loader>
        <Errors open={error.open} type={error.type} message={error.message} setProps={this.recieveProps} />
        <ContentWrapper>
          <div className="content-heading">
            <legend className='legendTitle'>
              <img
                src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-18.png"
                height="28"
                width="28"
              />
              Trazabilidad Integrada
            </legend>
          </div>
          <Col xs={12} md={12} lg={12}>
            <div>
              <p className='sizeTextComents' style={{ fontWeight: 'bold' }}>
                {Integrado ? Integrado.Cliente : ''} (
                {Integrado ? Integrado.NIT : ''}) /{' '}
              </p>
              <p className='sizeTextComents' style={{ color: '#458CC9', fontWeight: 'bold' }}>
                {Integrado ? Integrado.DoEmbarque : ''}
              </p>
              <p className='sizeTextComents' >Referencia del Cliente: {Integrado ? Integrado.RefCliente : ''}
              </p>
              <p className='sizeTextComents'>
                Doc. de Transporte: {Integrado ? Integrado.DocTransporte : ''}
              </p>
              <p className='sizeTextComents'>Proyecto:</p>
              <p className='sizeTextComents' style={{ fontWeight: 'bold' }}>Estatus: {Integrado ? Integrado.Estatus : ''}</p>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <StepperContainer
                steps={this.state.steps}
                openCommt={this.openCommt}
              />
              <Sidebar.Pushable as="Segment">
                <Segment>
                  <Sidebar
                    as={Menu}
                    animation="overlay"
                    //  icon='labeled'
                    className="_comment"
                    direction="right"
                    // onHide={() => setVisible(false)}
                    vertical
                    visible={this.state.openComments}
                    width="very wide"
                    style={{ width: customWidth < 758 ? customWidth : 500, }}
                  >
                    <Col className="margintopp" md={12}>
                      <Row>
                        <Col
                          md={12}
                          style={{ marginTop: '-25px!important' }}
                          className="close_container"
                        >
                          <div className="header_comments">
                            {this.state.id}
                          </div>
                          <div className="close_icon">
                            <Menu.Item
                              as="a"
                              onClick={() => {
                                this.setState({ openComments: false });
                              }}
                            >
                              <CloseIcon />
                            </Menu.Item>
                          </div>
                        </Col>
                        <Comments
                          style={{ marginTop: '38px!important' }}
                          data={commentsData}
                          id={id}
                        />
                      </Row>
                    </Col>
                  </Sidebar>
                </Segment>
              </Sidebar.Pushable>
            </Suspense>
          </Col>
        </ContentWrapper >
      </>
    );
  }
}

export default withRouter(IntegratedDetailExpo);
