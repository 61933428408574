export const Frequency = [
  { label: 'Diario', value: 'Daily'},
  { label: 'Mensual', value: 'Monthly'}
];

export const Days = [
  { label: 'Lunes', value: 'Monday'},
  { label: 'Martes', value: 'Tuesday'},
  { label: 'Miércoles', value: 'Wednesday'},
  { label: 'Jueves', value: 'Thursday'},
  { label: 'Viernes', value: 'Friday'},
  { label: 'Sábado', value: 'Saturday'},
  { label: 'Domingo', value: 'Sunday'}
];

export const Months = [
  { label: 'Enero' , value: 'January'},
  { label: 'Febreo' , value: 'February'},
  { label: 'Marzo' , value: 'March'},
  { label: 'Abril' , value: 'April'},
  { label: 'Mayo' , value: 'May'},
  { label: 'Junio' , value: 'June'},
  { label: 'Julio' , value: 'July'},
  { label: 'Agosto' , value: 'August'},
  { label: 'Septiembre' , value: 'September'},
  { label: 'Octubre' , value: 'October'},
  { label: 'Noviembre' , value: 'November'},
  { label: 'Diciembre' , value: 'December'}
];

export const AllDays = () => {
  let limit = 1;
  let days = [];

  while (limit <= 31) {
    days.push({ key: `${limit}`, text: `${limit}`, value: `${limit}`});
    limit++;
  }
  return days;
};