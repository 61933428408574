import { notification } from 'antd';

const OpenNotification = (message, description, type, direction='bottomRight') => {
  notification[type]({
    message: message,
    description: description,
    placement: direction,
  });
};

export default OpenNotification;