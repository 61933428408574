import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { Services } from '../Services';
import Authentication from '../../../../services/Authentication';
const auth = new Authentication();

const StyledRating = withStyles({
  iconFilled: {
    color: '#388e3c',
  },
  iconHover: {
    color: '#388e3c',
  },
})(Rating);

export default function CustomizedRatings(props) {
  const sendData = async (val) => {
    try {
      const chatHistory = window.localStorage.getItem('chatHistory');

      const data = {
        userId: auth.getAuthentication('dataUser'),
        flujo: chatHistory,
        calificacion: val,
        fecha: new Date(),
      };

      const url = `${Services.OPERATION_CHATBOT.path}/quiz`;
      const sendData = await axios.post(url, data);

      window.localStorage.setItem('chatHistory', 'mainMenu');

      props.triggerNextStep({ trigger: 'Despedida' });
    } catch (error) {
      console.error(error);
    }
  };

  const customIcons = {
    1: {
      icon: (
        <SentimentVeryDissatisfiedIcon
          style={{ fontSize: 40, color: '#d32f2f' }}
        />
      ),
      label: 'Very Dissatisfied',
    },
    2: {
      icon: (
        <SentimentDissatisfiedIcon style={{ fontSize: 40, color: '#f44336' }} />
      ),
      label: 'Dissatisfied',
    },
    3: {
      icon: (
        <SentimentSatisfiedIcon style={{ fontSize: 40, color: '#f57c00' }} />
      ),
      label: 'Neutral',
    },
    4: {
      icon: (
        <SentimentSatisfiedAltIcon style={{ fontSize: 40, color: '#4caf50' }} />
      ),
      label: 'Satisfied',
    },
    5: {
      icon: (
        <SentimentVerySatisfiedIcon
          style={{ fontSize: 40, color: '#388e3c' }}
          name="Satisfecho"
          onClick={() => sendData(5)}
        />
      ),
      label: 'Very Satisfied',
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  return (
    <div style={{ height: '40px' }}>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <StyledRating
          name="customized-icons"
          defaultValue={1}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          onChange={(event, newValue) => sendData(newValue)}
        />
      </Box>
    </div>
  );
}
