import React, { Component, Suspense } from "react";
import axios from "axios";
import { Row, Card, CardBody, CardText, Col } from "reactstrap";
import { Pagination } from "semantic-ui-react";
import { connect } from "react-redux";

import ContentWrapper from "../../../template/Layout/ContentWrapper";
import { MyResponsivePieCanvas } from "../../Components/Graphics/DonutGraph";
import { MyResponsiveBarCanvas } from "../../Components/Graphics/BarChart";
// import Map from './../../Components/Maps/map';
import Loader from "../../../Global/Loader";
import { withRouter } from "react-router-dom";
import Cards from "../../Components/Cards";
import "../Components/styles/cards.scss";
import { Services } from "../Services";
import "./styles.scss";
// import ClientesList from '../../../../services/storageClient.js';
const Map = React.lazy(() => import("../../Components/Maps/map"));

// const clientSave = new ClientesList();
let coloresAzules = [
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
];
let coloresVerdes = [
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
  "#3B831E",
  "#4EAA29",
  "#61CF35",
  "#78F340",
  "#9EF571",
  "#98F56C",
  "#B9F79B",
  "#CFF9BD",
];
let coloresAmarillos = [
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
  "#FAEA4E",
  "#FDEE4F",
  "#F5E663",
  "#FCF07B",
  "#FBF1A0",
  "#FAF2B1",
  "#F9F3C1",
  "#F8F4CF",
];

const statusInter = [
  { Estatus: "Instruccionado", EstatusId: 61, count: 0, key: 1 },
  { Estatus: "Reservado", EstatusId: 62, count: 0, key: 2 },
  { Estatus: "Carga Lista", EstatusId: 63, count: 0, key: 3 },
  { Estatus: "Recolectado", EstatusId: 64, count: 0, key: 4 },
  { Estatus: "Zarpó", EstatusId: 65, count: 0, key: 5 },
  { Estatus: "Arribó", EstatusId: 66, count: 0, key: 6 },
  { Estatus: "En transito a Entregar", EstatusId: 72, count: 0, key: 7 },
  { Estatus: "Entregado", EstatusId: 73, count: 0, key: 8 },
  { Estatus: "Facturado", EstatusId: 71, count: 0, key: 9 },
];

class Shipments extends Component {
  state = {
    map: (
      <Suspense fallback={<div>Loading...</div>}>
        <Map
          polylineData={[]}
          heidIcon={30}
          style={{ maxHeight: "300px" }}
          marker={[]}
          visible={true}
          type={"polylineDrawer"}
          data={[]}
          dataDrawer={""}
        // zoom={3}
        />
      </Suspense>
    ),
    // clientesSelect: clientSave.getAuthentication('client'),
    clientesSelect: this.props.clients
      ? JSON.stringify(this.props.clients)
      : "[0]",
    activePage: 1,
    limit: 10,
    paramsxml: {},
    numberPage: 1,
    totalPages: 1,
    search: "",
    isSearch: false,
    totalCard: [],
    dataDrawer: "",
    visible: false,
    selected: "",
    polyline: [],
    data: [],
    // dataMap: mapJson.dataMap,
    dataAux: [],
    dataOut: [],
    labelStatus: "closed",
    loading: true,
    graphs: [],
    markers: [],
    dataMap: [],
    donutChart: [],
    donutChartTrafico: [
      { id: "Expo", label: "Expo", value: 30, color: "#0bc637" },
      { id: "Triangulado", label: "Trinagulado", value: 40, color: "#149e16" },
      { id: "Impo", label: "Inpo", value: 30, color: "#39ef64" },
    ],
    barTable: [
      { status: "Arribo", Cancelled: 2, CancelledColor: "#efbc04" },
      { status: "Zarpo", Cancelled: 5, CancelledColor: "#efbc04" },
      { status: "Carga Lista", Cancelled: 5, CancelledColor: "#efbc04" },
    ],
    config: [
      {
        title: "Estatus",
        idExcel: "Estatus",
        xls: true,
        header: { link: false, hide: true, icon: false },
        data: {
          link: false,
          progress: false,
          icon: false,
          status: false,
          hide: true,
        },
        id: "null",
        className: "hidden",
      },
      {
        title: "",
        xls: true,
        first: true,
        foot: { show: false, style: { fontSize: "12px", fontWeight: "bold" } },
        header: { link: false, hide: true, icon: false },
        data: { link: false, progress: false, icon: false, status: false },
        id: "tiempo",
        link: "/shipmentsDetal/",
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "Cliente",
        xls: true,
        foot: {
          show: false,
          style: { fontSize: "12px", fontWeight: "bold", color: "green" },
        },
        data: { link: false, progress: false, icon: false, status: false },
        header: { link: false },
        id: "Cliente",
        link: "/shipmentsDetal/",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Proveedor",
        xls: true,
        foot: {
          show: false,
          style: { fontSize: "12px", fontWeight: "bold", color: "green" },
        },
        data: { link: false, progress: false, icon: false, status: false },
        header: { link: false },
        id: "Proveedor",
        link: "/shipmentsDetal/",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Consignatario",
        xls: true,
        icon: "fa-2x mr-2 fas fa-arrow-alt-circle-left",
        colorLogo: "#643296c2",
        id: "Consignatario",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Proyecto",
        xls: true,
        type: 7,
        id: "Proyecto",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Referencia del Cliente",
        xls: true,
        link: "/shipmentsDetal/",
        id: "RefCliente",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Origen",
        xls: true,
        link: "/shipmentsDetal/",
        id: "POL",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Destino",
        xls: true,
        data: { link: false, progress: false, icon: false, status: false },
        link: "/shipmentsDetal/",
        id: "POD",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Tráfico",
        xls: true,
        data: { link: false, progress: false, icon: false, status: false },
        id: "TipoNegocio",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "ETD",
        xls: true,
        data: { link: false, progress: false, icon: false, status: false },
        id: "ETD",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "ETA",
        xls: true,
        data: { link: false, progress: false, icon: false, status: false },
        link: "/shipmentsDetal/",
        id: "ETA",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      // {title: '',id:'null',link:'/shipmentsDetal/',className: ''},
    ],
    advancedSearch: [
      {
        title: "Información del Embarque",
        data: [
          [
            {
              title: "Cliente",
              type: "select",
              id: "Cliente",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "No DO",
              type: "text",
              id: "DoEmbarque",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Origen",
              type: "select",
              id: "POL",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Destino",
              type: "select",
              id: "POD",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
          ],
          [
            {
              title: "Tipo de Transporte",
              type: "select",
              id: "ModoTransporte",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "HBL",
              type: "text",
              id: "HBL_HAWB",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "MBL",
              type: "text",
              id: "MBL_MAWB",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Proyecto",
              type: "text",
              id: "Proyecto",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
          ],
          [
            {
              title: "Carrier",
              type: "select",
              id: "Carrier",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Agente Aduanal",
              type: "text",
              id: "AgenteAduanal",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Referencia del Cliente",
              type: "text",
              id: "RefCliente",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            // {
            //   title: 'Estatus', type: 'select', id: 'Estatus', class: 'col-3', values: [], value: ''
            // },
            {
              title: "Estatus",
              UseIN: true,
              type: "multipleSelect",
              id: "Estatus",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
          ],
          [
            {
              title: "Proveedor",
              type: "select",
              id: "Proveedor",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Consignatario",
              type: "select",
              id: "Consignatario",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Entrega",
              type: "select",
              id: "RecoleccionEntrega",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Tráfico",
              type: "select",
              id: "TipoNegocio",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
            },
          ],
        ],
      },
      {
        title: "Fechas",
        data: [
          [
            {
              title: "Recolección / Recepción",
              type: "dateRange",
              id: "FechaRecoleccion",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },
            {
              title: "Carga Lista",
              type: "dateRange",
              id: "FechaCargaLista",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },
          ],
          [
            {
              title: "ATD",
              type: "dateRange",
              id: "ATD",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },
            {
              title: "ATA",
              type: "dateRange",
              id: "ATA",
              class: "col-xs-12 col-md-6 col-lg-4",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },
          ],
        ],
      },
    ],
    advancedObj: [],
    url: "",
  };

  componentDidMount = () => {
    this.getListShipments();
    this.getCatalogs();
    this.getMapShipments();

    window.addEventListener("resize", () => {
      let newGraphs = this.state.graphs;
      newGraphs.map((e) => {
        e.width = this.calculateWidth();
        return e;
      });
      this.setState({ graphs: newGraphs });
    });
  };

  getCatalogs = () => {
    let data = {
      client: this.state.clientesSelect,
    };
    // axios.get(`${Services.Get_Traceability_Imports.Path}customs/allCustomImports`, {params:{EsDTA:0, search: this.state.search, allData: true}} ).then(success => {
    // axios.get(`${Services.Get_Traceability_Shipments.Path}shipments/catalogShipment`).then(success => {
    axios
      .post(
        `${Services.Get_Traceability_Shipments.path}/shipments/catalogShipment`,
        data
      )
      .then((success) => {
        let POL = success.data.data.POL.map((e) => {
          return { value: e.POL, label: e.POL ? e.POL : "Sin Valor" };
        });
        let POD = success.data.data.POD.map((e) => {
          return { value: e.POD, label: e.POD ? e.POD : "Sin Valor" };
        });
        let ModoTransporte = success.data.data.ModoTransporte.map((e) => {
          return {
            value: e.ModoTransporte,
            label: e.ModoTransporte ? e.ModoTransporte : "Sin Valor",
          };
        });
        let Carrier = success.data.data.Carrier.map((e) => {
          return {
            value: e.Carrier,
            label: e.Carrier ? e.Carrier : "Sin Valor",
          };
        });
        // let Estatus = success.data.data.Estatus.map(e =>{return { value: e.Estatus, label: e.Estatus ? e.Estatus : 'Sin Valor' };});
        let Estatus = success.data.data.Estatus.map((e, index) => {
          return {
            key: e.Estatus,
            text: e.Estatus,
            value: e.Estatus ? e.Estatus : "Sin Valor",
          };
        });
        let Proveedor = success.data.data.Proveedor.map((e) => {
          return {
            value: e.Proveedor,
            label: e.Proveedor ? e.Proveedor : "Sin Valor",
          };
        });
        let Consignatario = success.data.data.Consignatario.map((e) => {
          return {
            value: e.Consignatario,
            label: e.Consignatario ? e.Consignatario : "Sin Valor",
          };
        });
        let RecoleccionEntrega = success.data.data.RecoleccionEntrega.map(
          (e) => {
            return {
              value: e.RecoleccionEntrega,
              label: e.RecoleccionEntrega ? e.RecoleccionEntrega : "Sin Valor",
            };
          }
        );
        let TipoNegocio = success.data.data.TipoNegocio.map((e) => {
          return {
            value: e.TipoNegocio,
            label: e.TipoNegocio ? e.TipoNegocio : "Sin Valor",
          };
        });
        let Cliente = success.data.data.Cliente.map((e) => {
          return {
            value: e.Cliente,
            label: e.Cliente ? e.Cliente : "Sin Valor",
          };
        });

        let newAdvanced = this.state.advancedSearch;
        newAdvanced.map((e) => {
          e.data.map((f) => {
            f.map((g) => {
              if (g.id === "POL") {
                g.values = POL;
              }
              if (g.id === "POD") {
                g.values = POD;
              }
              if (g.id === "ModoTransporte") {
                g.values = ModoTransporte;
              }
              if (g.id === "Carrier") {
                g.values = Carrier;
              }
              if (g.id === "Estatus") {
                g.values = Estatus;
              }
              if (g.id === "Proveedor") {
                g.values = Proveedor;
              }
              if (g.id === "Consignatario") {
                g.values = Consignatario;
              }
              if (g.id === "RecoleccionEntrega") {
                g.values = RecoleccionEntrega;
              }
              if (g.id === "TipoNegocio") {
                g.values = TipoNegocio;
              }
              if (g.id === "Cliente") {
                g.values = Cliente;
              }
              return g;
            });
            return f;
          });
          return e;
        });
        this.setState({
          advancedSearch: newAdvanced,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getListShipments = () => {
    let data = {
      client: this.state.clientesSelect,
      pag: this.state.numberPage,
      limit: this.state.limit,
      EsDTA: 0,
      search: this.state.search,
      advanced: this.state.advancedObj,
    };
    this.setState({
      url: encodeURI(
        `${Services.Get_Traceability_Shipments.path}/shipments/listShipments?search=${this.state.search}&allData=true`
      ),
      paramsxml: data,
    });
    // axios.post(`${Services.Get_Traceability_Shipments.path}shipments/listShipments`, data, {params:{pag:this.state.numberPage,limit:this.state.limit, EsDTA:0, search: this.state.search}} ).then(success => {
    axios
      .post(
        `${Services.Get_Traceability_Shipments.path}/shipments/listShipments`,
        data
      )
      .then((success) => {
        let data = success.data.data.map((e) => {
          let TraspormodeId = 1;
          e.TraspormodeId = TraspormodeId;
          return e;
        });
        this.setState({
          totalPages: success.data.totalPage,
          data: data,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  setAdvanced = (val) => {
    this.setState({ advancedObj: val }, () => {
      this.getListShipments();
    });
  };

  getMapShipments = () => {
    statusInter.map((e) => {
      e.count = 0;
    });

    let data = {
      client: this.state.clientesSelect,
    };
    axios
      .post(
        `${Services.Get_Traceability_MapsShipments.path}/shipments/MapsShipments`,
        data
      )
      .then((success) => {
        let markers = [];
        let polylineData = [];
        let totalEmbarques = Number(success.data.data.length).toLocaleString(
          "En-us"
        );
        let newGraphTransport = [];
        let newGraphTrafico = [];

        let newGraphSatatus = [];
        success.data.data.map((e, index) => {
          let findGraph = false;
          let findGraphstatus = false;
          let findGraphtrafico = false;

          newGraphTransport.map((f) => {
            if (e.ModoTransporte === f.id) {
              findGraph = true;
              f.value = f.value + 1;
            }
            return f;
          });
          if (!findGraph) {
            let ModoTraspor = {
              id: e.ModoTransporte,
              label: e.ModoTransporte,
              value: 1,
            };
            newGraphTransport.push(ModoTraspor);
          }
          newGraphTransport.map((f, index) => {
            f.color = coloresAzules[index];
            return f;
          });
          newGraphTrafico.map((f) => {
            if (e.TipoNegocio === f.id) {
              findGraphtrafico = true;
              f.value = f.value + 1;
            }
            return f;
          });
          if (!findGraphtrafico) {
            let ModoTraspor = {
              id: e.TipoNegocio,
              label: e.TipoNegocio,
              value: 1,
            };
            newGraphTrafico.push(ModoTraspor);
          }

          newGraphTrafico.map((f, index) => {
            f.color = coloresVerdes[index];
            return f;
          });

          newGraphSatatus.map((f) => {
            if (e.Estatus === f.status) {
              findGraphstatus = true;
              f.Cancelled = f.Cancelled + 1;
            }
            return f;
          });
          if (!findGraphstatus) {
            let status = {
              status: e.Estatus,
              Cancelled: 1,
              id: e.Estatus,
              EstatusId: e.EstatusId,
            };
            newGraphSatatus.push(status);
          }

          if (
            e.OrigenLatitude &&
            e.OrigenLongitude &&
            e.DestinoLatitude &&
            e.DestinoLongitude
          ) {
            if (e.OrigenLatitude && e.OrigenLongitude) {
              let obj = {
                anchor: 15,
                key: `${index}From`,
                title: "Origen",
                name: e.EmbarqueId,
                data: e,
                position: {
                  lat: e.OrigenLatitude,
                  lng: e.OrigenLongitude,
                },
                icon: {
                  url:
                    e.ModoTransporte === "AEREA"
                      ? "https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png"
                      : e.ModoTransporte === "MARITIMA"
                        ? "https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png"
                        : "https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png",
                },
              };

              let position = {
                lat: Number(e.OrigenLatitude),
                lng: Number(e.OrigenLongitude),
              };
              polylineData.push(position);
              markers.push(obj);
            }
            if (e.DestinoLatitude && e.DestinoLongitude) {
              let obj = {
                key: `${index}To`,
                title: "Destino",
                name: e.EmbarqueId,
                data: e,
                position: {
                  lat: e.DestinoLatitude,
                  lng: e.DestinoLongitude,
                },
                icon: {
                  url:
                    e.ModoTransporte === "AEREA"
                      ? "https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png"
                      : e.ModoTransporte === "MARITIMA"
                        ? "https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png"
                        : "https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png",
                },
              };
              let position = {
                lat: Number(e.DestinoLatitude),
                lng: Number(e.DestinoLongitude),
              };
              polylineData.push(position);
              markers.push(obj);
            }
          }
          return e;
        });
        if (newGraphSatatus.length <= 0) {
          statusInter.map((e) => {
            e.count = 0;
            return e;
          });
        } else {
          newGraphSatatus.map((w) => {
            statusInter.map((e) => {
              if (w.EstatusId === e.EstatusId) {
                e.count = w.Cancelled;
              }
              return e;
            });
            return w;
          });
        }
        let newGraphSatatus00 = [];
        statusInter.map((e, index) => {
          let objt = {
            status: e.Estatus,
            Cancelled: e.count,
            id: e.Estatus,
            CancelledColor: coloresAmarillos[index],
            key: e.key,
          };
          newGraphSatatus00.push(objt);

          return e;
        });
        newGraphSatatus00.sort(function (a, b) {
          return b.key - a.key;
        });
        // newGraphSatatus00.map((e) => {
        //   e.status = e.status ? (e.status.length > 13 ? `${e.status.substring(0, (16 - 2))}` : e.status) : 'null';
        //   return e;
        // });

        let totalCard = [
          {
            title: "Total de Embarques",
            value: totalEmbarques,
            icon: "fa-2x mr-2 fas fa-map-marker-alt",
            firstColor: {
              backgroundColor: "#2f80e7",
              color: "#fff",
            },
            secondColor: {
              backgroundColor: "#7eb3f6 ",
              color: "#fff",
            },
          },
        ];

        this.setState(
          {
            markers: markers,
            dataMap: polylineData,
            polyline: polylineData,
            donutChart: newGraphTransport,
            donutChartTrafico: newGraphTrafico,
            barTable: newGraphSatatus00,
            totalCard: totalCard,
          },
          () => {
            this.setState({
              map: (
                <Suspense fallback={<div>Loading...</div>}>
                  <Map
                    polylineData={this.state.polylineData}
                    heidIcon={30}
                    style={{ maxHeight: "300px" }}
                    marker={this.state.markers}
                    visible={true}
                    type={"polylineDrawer"}
                    data={this.state.polylineData}
                    dataDrawer={this.state.dataDrawer}
                    zoom={3}
                  />
                </Suspense>
              ),
              graphs: [
                {
                  title: "Operaciones por Modo de Transporte",
                  type: "pie",
                  data: this.state.donutChart,
                  width: this.calculateWidth(),
                  height: "280",
                },
                {
                  title: "Operaciones por Tráfico",
                  type: "pie",
                  data: this.state.donutChartTrafico,
                  width: this.calculateWidth(),
                  height: "280",
                },
                {
                  title: "Operaciones por Estatus",
                  type: "bar",
                  data: this.state.barTable,
                  width: this.calculateWidth(),
                  height: "280",
                  left: 120,
                },
              ],
              fullLoader: true,
            });
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  changePage = (page) => {
    this.setState(
      {
        numberPage: page,
        activePage: page,
      },
      () => {
        this.getListShipments();
      }
    );
  };

  handleChange = () => (event) => {
    let val = event.target.value;
    if (!this.state.isSearch) {
      this.setState({ isSearch: true }, () => {
        this.setState(
          {
            search: val,
            activePage: 1,
            limit: 10,
            numberPage: 1,
            totalPages: 1,
          },
          () => {
            this.getListShipments();
          }
        );
      });
    }
  };

  newSearch = (val) => {
    if (val.length < 1) {
      this.setState(
        {
          search: val,
          isSearch: false,
          activePage: 1,
          limit: 10,
          numberPage: 1,
          totalPages: 1,
        },
        () => {
          this.getListShipments();
        }
      );
    } else {
      if (val.length < this.state.search.length) {
        this.setState(
          {
            search: val,
            activePage: 1,
            limit: 10,
            numberPage: 1,
            totalPages: 1,
          },
          () => {
            this.getListShipments();
          }
        );
      } else {
        this.setState({ search: val }, () => {
          this.getListShipments();
        });
      }
    }
  };

  calculateWidth = () => {
    if (window.innerWidth >= 1756) {
      return (window.innerWidth / 4.8).toString();
    } else {
      return (window.innerWidth / 5.5).toString();
    }
  };

  handleSelectedFile = (id) => {
    let { selected } = this.state;
    try {
      if (selected) {
        let selectedItem = document.getElementById(selected);
        selectedItem.classList.remove("_cardSelected");
      }
      let out = this.searchSelected(id);
      this.setState({ selected: id, dataOut: [out], labelStatus: id });
    } catch (e) { }
  };

  toggleDrawerMap = (data) => (evt) => {
    this.setState({ visible: true, dataDrawer: data }, () => {
      this.setState({
        map: (
          <Suspense fallback={<div>Loading...</div>}>
            <Map
              polylineData={this.state.polylineData}
              heidIcon={30}
              style={{ maxHeight: "300px" }}
              marker={this.state.markers}
              visible={true}
              type={"polylineDrawer"}
              data={this.state.polylineData}
              dataDrawer={this.state.dataDrawer}
              zoom={3}
            />
          </Suspense>
        ),
      });
    });
  };

  redirectTo = (id) => (evt) => {
    // evt.preventDefault();
    // this.props.history.push('/tracking/'+id)
  };

  backres = (backResponse) => {
    this.setState({
      data: backResponse,
      dataAux: backResponse,
      dataOut: backResponse,
    });
  };

  render() {
    let {
      url,
      graphs,
      labelStatus,
      data,
      totalCard,
      totalPages,
      activePage,
      fullLoader,
    } = this.state;
    return (
      <ContentWrapper>
        <Loader hidden={fullLoader}></Loader>
        <div className="content-heading">
          <legend className="legendTitle">
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/icon_embarques.png"
              height="28"
              width="28"
            />
            Trazabilidad de Embarques
          </legend>
        </div>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              {totalCard.map((e, index) => {
                return (
                  <Col xs={12} sm={6} md={6} lg={3}>
                    <div
                      key={index}
                      className="card flex-row align-items-center align-items-stretch border-0 _card_colors"
                    >
                      <div
                        className="col-4 d-flex align-items-center justify-content-center rounded-left"
                        style={e.firstColor}
                      >
                        {!e.icon ? (
                          <center>{e.value}</center>
                        ) : (
                          <>
                            <em className={`fas fa-2x ${e.icon}`}></em>
                          </>
                        )}
                      </div>
                      <div
                        className="col-8 rounded-right totalCardInfo"
                        style={e.secondColor}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                            marginTop: !e.icon ? "16px" : "4px",
                          }}
                        >
                          {e.title}
                        </div>
                        {!e.icon ? (
                          ""
                        ) : (
                          <div style={{ fontSize: "18px" }}>
                            {" "}
                            <center
                              style={{ fontSize: "20px", marginTop: "6px" }}
                            >
                              <strong>{e.value}</strong>
                            </center>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          {graphs.map((e) => {
            let typeGraph =
              e.type === "pie" ? (
                <MyResponsivePieCanvas
                  width={e.width}
                  height={e.height}
                  data={e.data}
                />
              ) : (
                <MyResponsiveBarCanvas
                  position="horizontal"
                  left={e.left}
                  width={e.width}
                  height={e.height}
                  data={e.data}
                />
              );
            return (
              <Col md={e.type === "pie" ? 6 : 12} sm={12}>
                <Card className="graphcardsize">
                  <CardBody className="graphcardbody">
                    <CardText
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontFamily: "unset",
                        fontWeight: "bold",
                      }}
                    >
                      {e.title}
                    </CardText>
                    {typeGraph}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
          <Col xs={12} sm={12} md={12} lg={12}>
            {this.state.map}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className="mt-3">
            <Cards
              paramsxml={this.state.paramsxml}
              clientesSelect={this.state.clientesSelect}
              advancedSearch={(val) => {
                this.setAdvanced(val);
              }}
              advancedSearchData={this.state.advancedSearch}
              urlReport={url}
              newSearch={(value) => {
                this.newSearch(value);
              }}
              pagination={false}
              broswer={true}
              config={this.state.config}
              headerCard={this.state.headerCard}
              toggleDrawer={this.toggleDrawerMap}
              visible={this.state.visible}
              data={data}
              dataSearch={data}
              reset={this.resetTableCards}
              labelStatus={labelStatus}
              backRes={this.backres}
              nameXlsx={"Embarques.xlsx"}
            ></Cards>
            <center>
              <Pagination
                activePage={activePage}
                ellipsisItem={null}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) =>
                  this.changePage(activePage)
                }
                size='mini'
              />
            </center>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shipments));
