import React, { Component, Suspense } from 'react';
import { withRouter, Link } from 'react-router-dom';
import StepperEvent from '../../../../../Components/StepperEvent';
import axios from 'axios';
import { Services } from '../../../../Services';
import Table from '../../../../../Components/Table';
import { Card, CardBody, Col, Row } from 'reactstrap';
import './styles.css';

const CardInfo = React.lazy(() => import('../../../../../Components/Cards'));
class Summary extends Component {
  state = {
    FechaActualizacion: '',
    DOAduanaExpo: '',
    DocTransporte: '',
    HBL_HAWB: '',
    id: this.props.match.params.id,
    dataExport: {},
    CardIndoHeaders: {
      title: 'Aduana',
      subTitle: '',
      Icon: 'fa-3x mr-2 fas fa-arrow-alt-circle-left',
      style: { color: '#582C83' },
    },
    Info: [
      {
        Referencia: 'EJHAS78',
        Aduana: 'ROLDAN',
        Proyecto: 'KJHASKJH-15-2019',
        Totales: 'KJHSKDJ78',
        Iniciales: 'ROLDAN',
        Anticipadas: 'ITALIA',
        Ordinarias: 'EXW',
        Temporales: 'Carlos Ruiz',
        TotalIVA: 'INTCOMEX',
        TotalAraceles: '$200,000',
        TotalTributos: '$400,000',
      },
    ],
    config: [
      {
        title: 'No de DO',
        xls: true,
        id: 'DOAduanaExpo',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      {
        title: 'Proyecto',
        xls: true,
        id: 'Proyecto',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      {
        title: 'Referencia de Cliente',
        xls: true,
        id: 'RefCliente',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
      {
        title: 'Documento de Transporte',
        xls: true,
        id: 'DocTransporte',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
      {
        title: 'Documento de Transporte Hija',
        xls: true,
        id: 'DocumentoTransporteHija',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
      {
        title: 'País de Destino',
        xls: true,
        id: 'PaisDestino',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      // { title: 'Incoterm', xls: true, id: 'Incoterm',className: 'col-md-3 col-lg-1 col-sm-6'},
      {
        title: 'Agente de Carga',
        xls: true,
        id: 'AgenteCarga',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      {
        title: 'Transportador Internacional',
        xls: true,
        id: 'TransportadorInt',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
    ],
    declaraciones: [],
    certificados: [],
    steps: [
      {
        label: 'Instrucción Cliente AA (Reserva)',
        value: '',
        id: 2,
        completed: false,
        order: 1,
        middleStep: '',
      },
      {
        label: 'Autorización de Embarque',
        value: '',
        id: 8,
        completed: false,
        order: 2,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Cierre Físico',
        value: '',
        id: 13,
        completed: false,
        order: 3,
        middle: false,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Manifiesto de Carga',
        value: '',
        id: 16,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: '',
      },
      {
        label: 'Facturación',
        value: '',
        id: 18,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: '',
      },
      {
        label: 'Radicación Factura',
        value: '',
        id: 19,
        completed: false,
        order: 6,
        idStep: 'step5',
        middleStep: '',
      },
    ],
    dataRoadTransport: [],
    columnsTerrestre: [
      {
        label: 'No. Solicitud', key: 'NoSolicitud', idTh: 'firstTh', idTd: 'firstTd', custom: (value) => (
          <Link
            to={
              "/transportation-detail-view/" + value.TmsId
            }
          >{value.NoSolicitud}</Link>)
      },
      { label: 'Estatus', key: 'Estatus', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Transportista', key: 'EmpresaTransportadora', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Tipo servicio', key: 'TipoServicio', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Origen', key: 'CiudadOrigen', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Destino', key: 'CiudadDestino', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Fecha salida', key: 'FechaSalidaRuta', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Fecha descargue', key: 'FechaDescargue', idTh: 'firstTh', idTd: 'firstTd' },
    ]
  };

  componentDidMount = () => {
    this.getDataForId();
    this.setState({
      steps: this.props.steps,
      HBL_HAWB: this.props.HBL_HAWB,
      DocTransporte: this.props.DocTransporte,
      DOAduanaExpo: this.props.DOAduanaExpo,
      Estatus: this.props.Estatus,
      cliente: this.props.cliente,
      NIT: this.props.NIT,
      Grupo: this.props.Grupo,
      FechaActualizacion: this.props.FechaActualizacion,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    this.getDataForId();
    this.setState({
      steps: nextProps.steps,
      HBL_HAWB: nextProps.HBL_HAWB,
      DocTransporte: nextProps.DocTransporte,
      DOAduanaExpo: nextProps.DOAduanaExpo,
      Estatus: nextProps.Estatus,
      cliente: nextProps.cliente,
      NIT: nextProps.NIT,
      Grupo: nextProps.Grupo,
      FechaActualizacion: nextProps.FechaActualizacion,
    });
  };

  getDataForId = () => {
    axios
      .get(
        `${Services.Get_Traceability_Export_ForId.path}/customs/dataCustomExport/${this.state.id}`
      )
      .then((success) => {
        this.setState({
          dataExport: success.data.data.infoExport[0],
          Info: success.data.data.infoExport,
          declaraciones: success.data.data.DeclaracionesExpo,
          certificados: success.data.data.Certificados,
          dataRoadTransport: success.data.data.dataRoadTrasnport,
          // Estatus: success.data.data.Estatus ? success.data.data.infoImport[0].Estatus :'',
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  toggleDrawerMap = (data) => (evt) => {
    // this.setState({ visible: true, dataDrawer: data });
  };

  render() {
    let {
      certificados,
      declaraciones,
      cliente,
      NIT,
      Grupo,
      FechaActualizacion,
      dataRoadTransport,
      columnsTerrestre
    } = this.state;
    let title = 'Declaración Exportación';
    let columns = [
      { label: 'Número SAE', key: 'NoSAE', idTh: 'firstTh', idTd: 'firstTd' },
      {
        label: 'Consignatario',
        key: 'Consignatario',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
      { label: 'Incoterm', key: 'Incoterm', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Número AE', key: 'NoAE', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Número DEX', key: 'NoDEX', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Fecha DEX', key: 'FechaDEX', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Régimen', key: 'Regimen', idTh: 'firstTh', idTd: 'firstTd' },
      {
        label: 'No. de Factura',
        key: 'NoFacturaComercial',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
    ];
    let columns2 = [
      {
        label: 'ID',
        key: 'CertificadoOrigenId',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
      {
        label: 'Número Certificado de Origen',
        key: 'NoCertificadoOrigen',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
      {
        label: 'Instrucción Certificado Origen',
        key: 'FechaInstruccion',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
      {
        label: 'Entrega Certificado Origen',
        key: 'FechaEntrega',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
    ];
    let title2 = 'Certificado Origen Expo';
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <Row>
              <div className='col-xs-2 col-md-1 col-lg-1 d-flex justify-content-center d-flex align-items-center'>
                <i style={{ color: '#582c83' }} className="fa-3x fas fa-arrow-alt-circle-left"></i>
              </div>
              <div className='col-xs-10 col-md-6 col-lg-6'>
                <p className='sizeTextComents' >{cliente} ({NIT}) / {Grupo}</p>
                <p className='sizeTextComents' style={{ color: '#0099ff' }}>{this.state.DOAduanaExpo}</p>
                <p className='sizeTextComents' > {this.state.DocTransporte}</p>
              </div>
              <div className='col-xs-12 col-md-5 col-lg-5 d-flex justify-content-end'>
                <span className='sizeTextComents' > <i>Última Actualización:</i>  {FechaActualizacion}</span>
              </div>
              <div className='col-12 mt-1'>
                {this.state.Estatus}
              </div>
            </Row >
            <div className="col-12">
              <StepperEvent steps={this.state.steps} />
            </div>
          </CardBody>
        </Card>
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <CardInfo
            CardIndoHeaders={this.state.CardIndoHeaders}
            config={this.state.config}
            toggleDrawer={this.toggleDrawerMap}
            data={this.state.Info}
            cardInfon={true}
          ></CardInfo>
        </Suspense>{' '}
        <Card className="myCard">
          <CardBody>
            <span className='titleCard'>{title}</span>
            <Table
              noCenter={true}
              columns={columns}
              data={declaraciones}
              options={{
                large: false,
                downloads: {
                  pdf: false,
                  xlsx: false,
                },
              }}
            />
          </CardBody>
        </Card>
        <Card className="myCard">
          <CardBody>
            <span className='titleCard'>{title2}</span>
            <Table
              noCenter={true}
              columns={columns2}
              data={certificados}
              options={{
                position: 'center',
                large: false,
                downloads: {
                  pdf: false,
                  xlsx: false,
                },
              }}
            />
          </CardBody>
        </Card>
        <Card className="myCard">
          <CardBody>
            <span className='titleCard'>{'Transporte Terrestre'}</span>
            <Table
              noCenter={true}
              columns={columnsTerrestre}
              data={dataRoadTransport}
              options={{
                position: 'center',
                large: false,
                downloads: {
                  pdf: false,
                  xlsx: false,
                },
              }}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(Summary);
