import React from 'react';
import DialogList from '../SecComponents/dialogList';

const availableReferences = {
  message: 'Por favor, indique alguna de las referencias:',
  optionList: [
    'Referencia',
    'HBL/HAWB (Documento de Transporte)',
    'Número de Contenedor',
    'Referencia de Cliente',
    'DO (Número de Embarque)',
  ],
};

function HandleType() {
  return (
    <div className="row" style={{ width: '100%' }}>
      <DialogList references={availableReferences} />
    </div>
  );
}

export default HandleType;
