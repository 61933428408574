class Authentication {
  test = () => { };
  setAuthentication = (token, dataUser, customerSelect, email) => {
    // console.log("token -->",token);
    // console.log(" dataUser --<", dataUser);
    // console.log("customerSelect --<",customerSelect);
    // console.log("email--->",email);
    // localStorage.setItem('EmailUser', dataUser[0].Email);
    localStorage.setItem('EmailUser', dataUser[0].Email ? dataUser[0].Email : email);
    localStorage.setItem('token', token);
    localStorage.setItem('dataUser', dataUser[0].UsuarioId);
    localStorage.setItem('UserName', dataUser[0].Nombre);
    localStorage.setItem('UserNameLast', dataUser[0].Apellido);
    localStorage.setItem('RolId', dataUser[0].RolId);
    localStorage.setItem('Rol', dataUser[0].Rol);
    localStorage.setItem('DATAUSER', dataUser.toString());
    localStorage.setItem('customerSelect', JSON.stringify(customerSelect)); //FIXME: quitar
  };
  getAuthentication = (value) => {
    return localStorage.getItem(value);
  };
  deleteAuthentication = (value) => {
    localStorage.removeItem(value);
  };
}

export default Authentication;
