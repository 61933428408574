import React, { Component, Suspense } from 'react';
import { Row, Card, CardBody, CardText, Col } from 'reactstrap';
import { Tab, Loader as Load, Icon } from 'semantic-ui-react';
import Chart from 'react-google-charts';
import axios from 'axios';
import moment from 'moment';

import ContentWrapper from '../../template/Layout/ContentWrapper';
// import { MyResponsiveBarCanvas } from "../Components/Graphics/BarChart";
import FlotChart from '../../template/Common/Flot';
import Loader from '../../Global/Loader';
import Summary from './components/Summary';
import Details from './components/Details';
import { Cards } from './components/Cards';

import { Services } from './Services';
import Values from './Values';
import { FullLoader } from './components/Loader';

class Statistics extends Component {
  state = {
    graphs: [],
    data: [],
    donutChartTrafico: [],
    accesosPorMes: [],
    accesosPorDia: [],
    barTable: [],
    totalCards: [],
    loadingGraphs: true,
  };

  componentDidMount = () => {
    this.getDataCharts();
    this.getCardsInfo();
    window.addEventListener('resize', () => {
      let newGraphs = this.state.graphs;
      newGraphs.map((e) => {
        e.width = this.calculateWidth();
        return e;
      });
      this.setState({ graphs: newGraphs });
    });
  };

  getLocationCustom = () => {
    this.setState({
      graphs: [
        {
          title: 'Acceso a Módulos Acumulado',
          type: 'bar',
          data: this.state.donutChartTrafico,
          width: this.calculateWidth(),
          height: '280',
          size: 4,
          theme: true,
        },
        {
          title: 'Tráfico por Mes',
          type: 'pie',
          data: this.state.accesosPorMes,
          options: {
            height: '208',
            grid: {
              borderColor: '#eee',
              borderWidth: 1,
              hoverable: true,
              backgroundColor: '#fcfcfc',
            },
            series: {
              lines: { show: false },
              points: { show: true, radius: 4 },
              splines: { show: true, tension: 0.4, lineWidth: 1, fill: 0.5 },
              shadowSize: 0,
              tooltip: true,
            },
            tooltip: true,
            tooltipOpts: {
              content: (label, x, y) => x + ' : ' + y,
            },
            xaxis: { tickColor: '#fcfcfc', mode: 'categories' },
            yaxis: {
              min: 0,
              tickColor: '#eee',
              autoscaleMargin: 0.5,
              tickDecimals: 0,
            },
            height: '208',
          },
          size: 4,
          height: '200',
        },
        {
          title: 'Tráfico por Hora Promedio',
          type: 'pie',
          data: this.state.accesosPorDia,
          options: {
            series: {
              lines: {
                show: false,
                fillColor: '#fcfcfc',
              },
              points: {
                show: true,
                radius: 4,
              },
              splines: {
                show: true,
                tension: 0.4,
                lineWidth: 1,
                fill: 0.5,
              },
            },
            grid: {
              borderColor: '#eee',
              borderWidth: 1,
              hoverable: true,
              backgroundColor: '#fcfcfc',
            },
            tooltip: true,
            tooltipOpts: {
              content: (label, x, y) =>
                moment(x)
                  .utc()
                  .format('HH:mm') +
                ' hrs : ' +
                y,
            },
            xaxis: {
              mode: 'time',
              tickLength: 5,
              timeformat: '%H:%M %p',
              tickSize: [5, 'hour'],
              tickColor: '#fcfcfc',
            },
            yaxis: {
              min: 0,
              tickColor: '#eee',
              autoscaleMargin: 0.5,
              tickDecimals: 0,
              tickFormatter: (v) => v /* + ' visitors'*/,
            },
            shadowSize: 0,
          },
          size: 4,
          height: '298',
        },
      ],
    });
  };

  getDataCharts = () => {
    axios
      .get(`${Services.GetStatistics.path}/statistics/tmpgraphs`)
      .then((success) => {
        this.setState(
          {
            barTable: [],
            donutChartTrafico: success.data.data.modules,
            accesosPorMes: success.data.data.traficByMonth,
            accesosPorDia: success.data.data.traficByHour,
            loadingGraphs: false,
            fullLoader: true,
          },
          () => {
            this.getLocationCustom();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getCardsInfo = async () => {
    let cardsInfo = await axios.get(
      `${Services.GetStatistics.path}/statistics/cards`
    );
    Values.Cards.map((e) => {
      if (cardsInfo.data.data[e.aka]) {
        e.value = cardsInfo.data.data[e.aka];
      }
      return e;
    });

    this.setState({ totalCards: Values.Cards });
  };

  calculateWidth = () => {
    if (window.innerWidth >= 1756) {
      return (window.innerWidth / 4.8).toString();
    } else {
      return (window.innerWidth / 5.5).toString();
    }
  };

  render() {
    let { totalCards, graphs, fullLoader, loadingGraphs } = this.state;
    const faketables = [1, 2, 3];

    return (
      <ContentWrapper>
        <Loader hidden={fullLoader}></Loader>
        <div className="content-heading">
          <div className="row">
            <Icon name="users" />
            Estadísticas de Uso
          </div>
        </div>
        <Col>
          <div className="col-md-12 col-lg-12 col-sm-12">
            <div className="row">
              <Col md={12}>
                <Row>
                  {loadingGraphs
                    ? faketables.map((e) => {
                        return (
                          <Col md={4} xl={4} sm={12} xs={12}>
                            <Card className="graphcardsize">
                              <CardBody className="graphcardbody">
                                <CardText
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '10px',
                                    fontFamily: 'unset',
                                    fontWeight: 'bold',
                                  }}
                                ></CardText>
                                <FullLoader />
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })
                    : graphs.map((e) => {
                        let typeGraph =
                          e.type === 'pie' ? (
                            <FlotChart data={e.data} options={e.options} />
                          ) : (
                            <Chart
                              className={'_table_content'}
                              width={this.calculateWidth()}
                              height={'208px'}
                              chartType="BarChart"
                              loader={<div>Loading Chart</div>}
                              data={this.state.donutChartTrafico}
                              options={{
                                height: 200,
                                bar: { groupWidth: '80%' },
                                //legend: { position: 'none' },
                                colors: ['#B7D968', '#FDE47F'],
                                animation: {
                                  startup: true,
                                  easing: 'linear',
                                  duration: 1000,
                                },
                              }}
                              rootProps={{ 'data-testid': '6' }}
                            />
                          );
                        return (
                          <Col md={e.size} xl={e.size} sm={12} xs={12}>
                            <Card className="graphcardsize">
                              <CardBody className="graphcardbody">
                                <CardText
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '10px',
                                    fontFamily: 'unset',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {e.title}
                                </CardText>
                                {typeGraph}
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                </Row>
              </Col>

              <Col md={12}>
                <Row style={{ height: '70px' }}>
                  {totalCards.map((e, index) => {
                    return (
                      <Col
                        md={4}
                        xl={4}
                        sm={4}
                        xs={4}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Cards data={e} element={index} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </div>
          </div>
        </Col>
        <br />
        <br />

        <Tab
          menu={{ secondary: true, className: 'myWrapped' }}
          menuPosition="right"
          panes={[
            {
              menuItem: 'Resumen',
              render: () => (
                <Suspense fallback={<Load />}>
                  <Summary />
                </Suspense>
              ),
            },
            {
              menuItem: 'Detalle',
              render: () => (
                <Suspense fallback={<Load />}>
                  <Details />
                </Suspense>
              ),
            },
          ]}
        />
      </ContentWrapper>
    );
  }
}

export default Statistics;
