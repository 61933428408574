import React from 'react';
import DialogList from '../SecComponents/dialogList';

const availableReferences = {
  message: 'Por favor, indique alguna de las referencias:',
  optionList: ['No. de Ingreso', 'No. de Despacho', 'Referencia de cliente', 'Documento de Transporte'],
};

function Almacen() {
  return (
    <div className="row" style={{ width: '100%' }}>
      <DialogList references={availableReferences} />
    </div>
  );
}

export default Almacen;
