import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Col, Row } from 'reactstrap';
const theme = ['#80b1d3', '#b3de69', '#8dd3c7', '#fdb462','#bebada', '#e0e0e0', '#ffffb3'];
/**
 * Responsive line chart
 */
const MyResponsiveLine = ({ data }) => {
  return (
    <div style={{ height: '20em'}}>
      <ResponsiveLine
        data={data}
        colors={theme}
        margin={{ top: 20, right: 140, bottom: 25, left: 140 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat="1>-$01,.2~f"
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          format: function numberWithCommas(x) {
            return '$' + x.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
          }
        }}
        tooltip={ (val)=> {
        // {val.data.id}  : ${(Number(val.y).toLocaleString('En-us'))}
          const point = val.point;
          return (
            <Row>
              <Col md={12} style={{backgroundColor: '#ffff', textAlign: 'center', padding:'.5em', borderRadius: '.2em'}}>
                <h5><span style={{height:'12px',width:'12px',background:point.serieColor, borderRadius:'2%',display:'inline-block', marginRight: '.2em', marginTop:'.3em'}}></span>
                  {point.serieId}:
                  <span style={{marginBottom:'.1em', fontWeight: 'bold'}}>
                    {point.data.yFormatted}
                  </span>
                </h5>
              </Col>
            </Row>
          );
        }}
        lineWidth={4}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.2}
        useMesh={true}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};

export default MyResponsiveLine;