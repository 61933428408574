import React from 'react';
import './styles.scss';

const CardComponent = (props) => {
  return (
    <div class="card shadow-sm p-3 mb-5 bg-white rounded card-wrapper">
      <div class="card-body">
        <h4 class="card-title">{props.title}</h4>
        {props.children}
      </div>
    </div>
  );
};

export default CardComponent;