import React from 'react';
import ErrorType from './erroresType.json';
import Select from 'react-virtualized-select';

function TroubleType(props) {
  const [typeErr, setTypeErr] = React.useState([{}]);
  const [opError, setOpError] = React.useState('');
  const [isOptionSelect, setIsOptionSelect] = React.useState(false);

  const { steps, triggerNextStep } = props;
  const { problemaOperacionMenu } = steps;

  const getOperation = React.useCallback(async () => {
    try {
      let troubleType = problemaOperacionMenu.value[0];


      setTypeErr(ErrorType[troubleType]);
    } catch (error) {
      console.error('GET OPERATION ==>', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getOperation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErrorType = (data) => {
    setOpError(data);
    setIsOptionSelect(true);
    triggerNextStep({ value: data.label, trigger: 'DialogoProblemaOperacion' });
  };

  return (
    <div style={{ width: '100%' }}>
      <Select
        options={typeErr}
        disabled={isOptionSelect}
        onChange={(selectValue) => handleErrorType(selectValue)}
        value={opError}
      />
    </div>
  );
}

export default TroubleType;
