import React, { Component } from "react";
import { Card, CardBody, CardText, Col, Row, NavbarText } from "reactstrap";
import Loader from "../../../../Global/Loader";
import { MyResponsivePieCanvas } from "../../../Components/Graphics/DonutGraph";
import { MyResponsiveBarCanvas } from "../../../Components/Graphics/BarChart";
import Cards from "../../../Components/Cards";
import { Pagination } from "semantic-ui-react";
import axios from "axios";
import { connect } from 'react-redux';

import { Services } from "../../Services";

// import ClientesList from "../../../../../services/storageClient.js";
// const clientSave = new ClientesList();
class Exportaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCard: [
        {
          title: "Operaciones Expo",
          value: "0",
          icon: "fa-2x mr-2 fas fa-map-marker-alt",
          firstColor: {
            backgroundColor: "#2f80e7",
            color: "#fff",
          },
          secondColor: {
            backgroundColor: "#7eb3f6 ",
            color: "#fff",
          },
        },
      ],
      graphs: [
        {
          title: "Operaciones por Proceso",
          type: "pie",
          data: [
            {
              id: "INTERNACIONAL",
              label: "INTERNACIONAL",
              value: "1",
              color: "#2196F3",
            },
          ],
          height: "280",
        },
        {
          title: "Operaciones por Modo de Transporte",
          type: "pie",
          data: [
            {
              id: "Maritima",
              label: "Maritima",
              value: "1",
              color: "#3B831E",
            },
          ],
          height: "280",
        },
        {
          title: "Operaciones por Estatus",
          type: "bar",
          data: [
            {
              status: "Facturado",
              Cancelled: 1,
              id: "Facturado",
              CancelledColor: "#FDD835",
              key: 10,
            },
            {
              status: "En Deposito",
              Cancelled: 1,
              CancelledColor: "#FDD835",
            },
            {
              status: "En Inspacción",
              Cancelled: 1,
              CancelledColor: "#FDD835",
            },
            {
              status: "Despachado",
              Cancelled: 1,
              CancelledColor: "#FDD835",
            },
            {
              status: "Salida",
              Cancelled: 1,
              CancelledColor: "#FDD835",
            },
          ],
          height: "280",
        },
      ],
      config1: [
        {
          title: "",
          xls: true,
          first: true,
          firstExpo: true,
          foot: {
            show: false,
            style: { fontSize: "12px", fontWeight: "bold" },
          },
          header: { link: false, hide: true, icon: false },
          data: { link: false, progress: false, icon: false, status: false },
          id: "Estatus",
          idExpo: "ExportacionId",
          link: "/integrated-detail-expo/",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        // {title: 'jhgasjh',titleId: "dosId",foot:{show: true, style: { fontSize: '12px', fontWeight: 'bold', color:'green' }},data: {link: false, progress: false, icon: false, status: false},header: { link: true }, id:'HBL', id2:"status",link:"/integrated-detail/",className: 'col-md-3 col-lg-2 col-sm-6'},
        {
          title: "Estatus",
          idExcel: "Estatus",
          xls: true,
          id: "",
          className: "hidden",
          header: { hide: true },
        },
        {
          title: "Cliente",
          xls: true,
          id: "Cliente",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "Referencia del Cliente",
          xls: true,
          id: "RefCliente",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "Origen",
          xls: true,
          id: "Origen",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "Destino",
          xls: true,
          id: "Destino",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "ETD",
          xls: true,
          id: "ETD",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "ETA",
          xls: true,
          data: { link: false, progress: false, icon: false, status: false },
          id: "ETA",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "ATD",
          xls: true,
          data: { link: false, progress: false, icon: false, status: false },
          id: "ATD",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "Depósito Habilitado",
          xls: true,
          data: {
            link: false,
            progress: false,
            icon: false,
            button: false,
            status: true,
          },
          header: { link: false, hide: false, icon: false },
          id: "DepositoHabilitadoId",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "Aduana",
          xls: true,
          data: {
            link: false,
            progress: false,
            icon: false,
            button: false,
            status: true,
          },
          header: { link: false, hide: false, icon: false },
          id: "ExportacionId",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "Internacional",
          xls: true,
          data: {
            link: false,
            progress: false,
            icon: false,
            button: false,
            status: true,
          },
          header: { link: false, hide: false, icon: false },
          id: "EmbarqueId",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        {
          title: "",
          id: "",
          className: "col-md-3 col-lg-2 col-sm-6",
        },
        // {
        //   title: "Almacén",
        //   xls: true,
        //   data: {
        //     link: false,
        //     progress: false,
        //     icon: false,
        //     button: false,
        //     status: true,
        //   },
        //   header: { link: false, hide: false, icon: false },
        //   id: "IngresoIds",
        //   className: "col-md-3 col-lg-2 col-sm-6",
        // },
        // {title: '' , xls: true,data: {link: false, progress: false, icon: false, button: false},header: { link: false, hide: false, icon:false }, colorProgress : '',id:'null',className: 'hidden'},
        // {title: 'Progreso' , xls: true,data: {link: false, progress: true, icon: false, button: false},header: { link: false, hide: false, icon:false }, colorProgress : 'grayColor',id:'Progreso',className: 'col-md-3 col-lg-2 col-sm-6'},
        // {title: 'Ver Detalles' ,data: {link: false, progress: false, icon: false, button: true, }, link:'/integrated-detail/', idLink:'dosId', header: { link: false, hide: true, icon:false },id:'dosId',className: 'col-md-3 col-lg-2 col-sm-6'},
      ],
      advancedSearch: [
        {
          title: 'Información de Integrada',
          data: [
            [
              {
                title: 'No de DO', type: 'text', id: 'DoEmbarque', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
              },
              {
                title: 'Cliente', type: 'select', id: 'Cliente', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
              },
              {
                title: 'Referencia del Cliente', type: 'text', id: 'RefCliente', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
              },
              {
                title: 'Documento de Transporte', type: 'text', id: 'DocTransporte', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
              },
            ],
            [
              {
                title: 'Origen', type: 'select', id: 'Origen', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
              },
              {
                title: 'Destino', type: 'select', id: 'Destino', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
              },
              // {
              //   title: 'Estatus', type: 'select', id: 'Estatus', class: 'col-3', values: [], value: ''
              // }
              {
                title: 'Estatus', UseIN: true, type: 'multipleSelect', id: 'Estatus', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
              }
            ]
          ]
        },
        {
          title: 'Fechas',
          data: [
            [
              {
                title: 'ETD', type: 'dateRange', id: 'ETD', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
              },
              {
                title: 'ETA', type: 'dateRange', id: 'ETA', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
              },
            ],
            [
              {
                title: 'ATD', type: 'dateRange', id: 'ATD', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
              },
              {
                title: 'Fecha de Levante', type: 'dateRange', id: 'FechaLevante', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
              },
              // {
              //   title: 'Llegada a Almacén', type: 'dateRange', id: 'FechaLlegadaAlmacen', class: 'col-6', values: [], value: '', date: { init: '', end: '' }
              // }
            ],
          ]
        },
      ],
      data1: [],
      TotalPage: 1,
      activePage: 1,
      search: "",
      clientesSelect: this.props.clients
        ? JSON.stringify(this.props.clients)
        : '[0]',
      numberPage: 1,
      limit: 10,
      search: "",
      advancedObj: [],
      fullLoader: false,
      paramsxml: {},

    };
  }
  componentDidMount = () => {
    this.getListIntegerExpo();
    this.getDataChartsExpo();
    this.getCatalogosAdvancedSearch()
  };

  getCatalogosAdvancedSearch = () => {
    let data = {
      client: this.state.clientesSelect,
    };
    axios
      .post(
        `${Services.Get_list_integrada_Expo.path}/integrated/expo/advanced`,
        data
      )
      .then(success => {
        let POL = success.data.data.POL.map(e => {
          return {
            value: e.Origen,
            label: e.Origen
          };
        });
        let POD = success.data.data.POD.map(e => {
          return {
            value: e.Destino,
            label: e.Destino
          };
        });
        // let Estatus = success.data.data.Estatus.map(e => {
        //   return {
        //     value: e.Estatus,
        //     label: e.Estatus
        //   };
        // });
        let Estatus = success.data.data.Estatus.map((e, index) => { return { key: e.Estatus, text: e.Estatus, value: e.Estatus ? e.Estatus : 'Sin Valor' }; });
        let Cliente = success.data.data.Cliente.map(e => {
          return {
            value: e.Cliente,
            label: e.Cliente
          };
        });
        let newAdvanced = this.state.advancedSearch;
        newAdvanced.map(e => {
          e.data.map(f => {
            f.map(g => {
              if (g.id === 'Estatus') {
                g.values = Estatus;
              }
              if (g.id === 'Origen') {
                g.values = POL;
              }
              if (g.id === 'Destino') {
                g.values = POD;
              }
              if (g.id === 'Cliente') {
                g.values = Cliente;
              }
              return g;
            });
            return f;
          });
          return e;
        });

        this.setState({
          advancedSearch: newAdvanced
        });
      }).catch(error => {
        console.warn(error);
      });
  }

  setAdvanced = (val) => {
    this.setState({
      advancedObj: val
    }, () => { this.getListIntegerExpo() });
  }

  // componentWillReceiveProps = (nexprops) => {
  //   this.setState({
  //     totalCard: nexprops.totalCard,
  //     graphs: nexprops.graphs,
  //   });
  // };

  getListIntegerExpo = () => {
    let { data1, TotalPage } = this.state;
    let data = {
      pag: this.state.numberPage,
      limit: this.state.limit,
      EsDTA: 0,
      search: this.state.search,
      advanced: this.state.advancedObj,
      client: this.state.clientesSelect
    };

    this.setState({
      url: encodeURI(
        `${Services.Get_list_integrada_Expo.path}/integrated/expo/list?allData=true`
      ),
      paramsxml: data,
    });
    axios
      .post(
        `${Services.Get_list_integrada_Expo.path}/integrated/expo/list`,
        data
      )
      .then((success) => {
        data1 = success.data.data.map((e) => {
          let TraspormodeId = 1;
          e.TraspormodeId = TraspormodeId;
          // e.EmbarqueId = e.EmbarqueId ? 'SI' : null;
          return e;
        });

        TotalPage = success.data.totalPage;
        this.setState({
          TotalPage,
          data1,
          fullLoader: true
        });
      })
      .catch((error) => {
        this.setState({
          fullLoader: true,
        });
        console.warn(error);
      });
  };

  getDataChartsExpo = () => {
    let data = {
      client: this.state.clientesSelect ? this.state.clientesSelect : '[0]'
    };

    axios
      .post(
        `${Services.Post_IntegradaExpo_Charts.path}/integrated/expo/charts`,
        data
      )
      .then((success) => {
        let totalCarddd = 0;
        success.data.data.firstCharts.map((e) => {
          if (e.id === "ADUANA") {
            totalCarddd = e.value;
          }
          return e;
        });

        let newtotalCarddd = Number(totalCarddd).toLocaleString("En-us");

        let totalCard = [
          {
            title: "Operaciones Expo",
            value: newtotalCarddd,
            icon: "fa-2x mr-2 fas fa-map-marker-alt",
            firstColor: {
              backgroundColor: "#2f80e7",
              color: "#fff",
            },
            secondColor: {
              backgroundColor: "#7eb3f6 ",
              color: "#fff",
            },
          },
        ];

        this.setState({
          fullLoader: true,
          totalCard: totalCard,
          graphs: [
            {
              title: "Operaciones por Proceso",
              type: "pie",
              data: success.data.data.firstCharts,
              height: "280",
            },
            {
              title: "Operaciones por Modo de Transporte",
              type: "pie",
              data: success.data.data.secondCharts,
              height: "280",
            },
            {
              title: "Operaciones por Estatus",
              type: "bar",
              data: success.data.data.thirdCharts,
              height: "280",
            },
          ],
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  toggleDrawerMap = (data) => (evt) => {
    this.setState({ visible: true, dataDrawer: data });
  };

  changePage = (page) => {
    this.setState(
      {
        numberPage: page,
        activePage: page,
      },
      () => {
        this.getListIntegerExpo();
      }
    );
  };

  newSearch = (val) => {
    if (val.length < 1) {
      this.setState(
        {
          search: val,
          isSearch: false,
          activePage: 1,
          limit: 10,
          numberPage: 1,
          totalPages: 1,
        },
        () => {
          this.getListIntegerExpo();
        }
      );
    } else {
      if (val.length < this.state.search.length) {
        this.setState(
          {
            search: val,
            activePage: 1,
            limit: 10,
            numberPage: 1,
            totalPages: 1,
          },
          () => {
            this.getListIntegerExpo();
          }
        );
      } else {
        this.setState({ search: val }, () => {
          this.getListIntegerExpo();
        });
      }
    }
  };

  render() {
    let { totalCard, graphs, TotalPage, data1, activePage, fullLoader, advancedSearch, url } = this.state;
    return (
      <>
        <Loader hidden={fullLoader}></Loader>
        <div className="content">
          <Row>
            <Col lg={12} md={12} >
              <Row>
                <Col xs={12} sm={6} md={6} lg={3}>
                  <Row>
                    {totalCard.map((e, index) => {
                      return (
                        <Col md={12}>
                          <div
                            key={index}
                            className="card flex-row align-items-center align-items-stretch border-0 _card_colors"
                          >
                            <div
                              className="col-4 d-flex align-items-center justify-content-center rounded-left"
                              style={e.firstColor}
                            >
                              {!e.icon ? (
                                <center>{e.value}</center>
                              ) : (
                                <>
                                  <em className={`fas fa-2x ${e.icon}`}></em>
                                </>
                              )}
                            </div>
                            <div
                              className="col-8 rounded-right totalCardInfo"
                              style={e.secondColor}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  textAlign: "center",
                                  marginTop: !e.icon ? "16px" : "4px",
                                }}
                              >
                                {e.title}
                              </div>
                              {!e.icon ? (
                                ""
                              ) : (
                                <div style={{ fontSize: "18px" }}>
                                  {" "}
                                  <center
                                    style={{ fontSize: "20px", marginTop: "6px" }}
                                  >
                                    <strong>{e.value}</strong>
                                  </center>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col lg={10} md={10}></Col>
                <Col lg={12} md={12}>
                  <Row>
                    {graphs.map((e, index) => {
                      let typeGraph =
                        e.type === "pie" ? (
                          <MyResponsivePieCanvas data={e.data} />
                        ) : (
                          <MyResponsiveBarCanvas
                            position="horizontal"
                            data={e.data}
                          />
                        );
                      return (
                        <Col
                          md={e.type === "pie" ? 6 : 12}
                          sm={12}
                          className="_card_container"
                          key={index}
                        >
                          {/* <CardDeck style={{marginLeft:"13px"}} className="graphcarddeck"> */}
                          <Card className="graphcardsize">
                            <CardBody className="graphcardbody">
                              <CardText
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                  fontFamily: "unset",
                                  fontWeight: "bold",
                                }}
                              >
                                {e.title}
                              </CardText>
                              {typeGraph}
                            </CardBody>
                          </Card>
                          {/* </CardDeck> */}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ height: 25 }} />
          <Col lg={12} md={12}>
            <Cards
              advancedSearchData={advancedSearch}
              advancedSearch={(val) => {
                this.setAdvanced(val);
              }}
              paramsxml={this.state.paramsxml}
              // clientesSelect={this.state.clientesSelect}
              pagination={false}
              urlReport={url}
              newSearch={(value) => {
                this.newSearch(value);
              }}
              config={this.state.config1}
              visible={true}
              toggleDrawer={(value) => {
                this.toggleDrawerMap(value);
              }}
              data={data1}
              dataSearch={data1}
              labelStatus={"closed"}
              nameXlsx={'Integrada_Exportacion.xlsx'}
            />
            <center>
              <Pagination
                activePage={activePage}
                ellipsisItem={null}
                totalPages={TotalPage}
                onPageChange={(e, { activePage }) =>
                  this.changePage(activePage)
                }
                size={'mini'}
              />
            </center>
          </Col>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Exportaciones);