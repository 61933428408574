export const Services = {
   "PostOauth": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "POST",
      "descrp": " method for user authentication"
   },
   "GetCustomerForUserId": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain customer list for userId"
   },
   "Post_Data_Recovery": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "POST",
      "descrp": "method to obtain correo y  generar token"
   }
}