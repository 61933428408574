import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import FormModel from './models/form';
import Fade from 'react-reveal/Fade';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import 'react-datetime/css/react-datetime.css';
// import Datetime from 'react-datetime';
import { DatePicker } from 'antd';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
const { RangePicker } = DatePicker;


class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogCombo: props.catalogCombo ? props.catalogCombo : [],
      Form: FormModel,
      BODY: '',
      HEADER: '',
      selects: {
        CurrentPortLoad: [],
        DestinationPort: [],
        MoveType: [],
        EquipSize: [],
        EquipType: []
      },
      Filter: {},
      Inputs: '',
      actionFiltersText: 'SHOW FILTERS',
      open: false
    };
  }


  componentDidMount = () => {
    // this.buildForm('BODY')
    this.buildForm('HEADER');
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      catalogCombo: nextProps.catalogCombo ? nextProps.catalogCombo : [],
    }, () => {
      // this.buildForm('BODY')
      this.buildForm('HEADER');
    });
  }

  handleChangeSelect = (selected) => event => {
    
    let { Filter } = this.state;
    if (event && event.value) {
      Filter[selected] = event.value;
      this.setState({ Filter }, () => {

        if (selected=== 'Proceso'){
          this.props.sendQuery(this.state.Filter,true);
        }
        else this.props.sendQuery(this.state.Filter);
        // this.buildForm('BODY')
        this.buildForm('HEADER');
      });
    } else {
      Filter[selected] = '';
      this.setState({ Filter }, () => {
        this.props.sendQuery(this.state.Filter);
        // this.buildForm('BODY')
        this.buildForm('HEADER');
      });
    }
  };

  handleChangeDate = name => event => {
    let { Filter } = this.state;
    try {
      let range = `${event[0].format('YYYY-MM-DD')}TO${event[1].format('YYYY-MM-DD')}`;

      Filter[name] = range;
      this.setState({ Filter }, () => {
        this.props.sendQuery(this.state.Filter);
        // this.buildForm('BODY')
        this.buildForm('HEADER');
      });
    }
    catch (ex) {
      Filter[name] = '';
      this.setState({ Filter }, () => {
        this.props.sendQuery(this.state.Filter);
        // this.buildForm('BODY')
        this.buildForm('HEADER');
      });
    }
  }

  buildForm = (key) => {
    // let style={fontSize:'9px'};
    const { Filter, catalogCombo, Form } = this.state;
    // let styleData= {height:'2.5em',borderRadius:'.3em'};
    let Inputs = Form[key].map((e) => {
      if (e.type === 'Select') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          <Select
            // style={style}
            id={e.id}
            required
            placeholder='Seleccionar'
            place
            type={e.datatype}
            name={e.name}
            value={Filter[e.id]}
            onChange={this.handleChangeSelect(e.id)}
            options={catalogCombo[e.options]}
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>);
      else if (e.type === 'Datetime') return (
        <div className={e.class} key={e.id} >
          <Fade left opposite>
            <span id={e.label} style={e.style}> <label  >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          <RangePicker
            plac
            locale={locale}
            onChange={this.handleChangeDate(e.id)}
            style={{height:'2.6em',borderRadius:'.3em',borderColor:'#CCCCCC'}}
            size='large'
            className='col-md-12'
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>
      );
    });
    this.setState({
      [key]: Inputs
    });
  }

  actionFilters = () => {
    this.setState(prevState => ({    // prevState?
      open: !prevState.open
    }));
  };

  render() {
    // const { open } = this.state;
    return (
      <Card className="myCard">
        <CardBody>
          <div>
            <div className="row">
              {/* <div className="col-md-2 col-lg-2 col-sm-12">
                <InputGroup style={{ paddingTop: '10%' }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText onClick={this.actionFilters} style={{ 'cursor': 'pointer' }}>{!open ? 'SHOW FILTERS' : 'HIDE FILTERS'}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </div> */}
              {this.state.HEADER}
            </div>
            {/* <Collapse isOpen={this.state.open}>
              <div className="row">
                {this.state.BODY}
              </div>
            </Collapse> */}
          </div>
        </CardBody>
      </Card>
    );
  }
}
export default Filters;


