import React from 'react';
import { Col} from 'reactstrap';
import PropTypes from 'prop-types';

/*=============================================
=            Card Toptals Conponent           =
=============================================*/
function CardTotals(props){
  return (
    <Col xl={12} md={12} sm={12}>
      {/* <Card className="myCard2"> */}
      {/* <CardBody style= {{marginBottom:'-1em'}}> */}
      <div className="card flex-row align-items-center align-items-stretch border-0">
        <div className={props.propertiesIcon}>
          {props.image}
        </div>
        <div className={props.propertiesTitle}>
          <div  className="">{props.title}</div>
          <div className="h4 mt-0" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{props.total}</div>
        </div>
      </div>
      {/* </CardBody> */}
      {/* </Card> */}
    </Col>
  );
}

// Default values for props component
CardTotals.defaultProps = {
  xl: 3,
  md: 6,
  sm: 12
};

CardTotals.propTypes = {
  xl: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  propertiesIcon: PropTypes.string,
  image: PropTypes.element,
  propertiesTitle: PropTypes.string,
  total: PropTypes.number,
  title: PropTypes.string
};

export default CardTotals;