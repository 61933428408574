import React from 'react';

import moment from 'moment';
import { Label, Input, Container, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// import imagenLogin from './../../../resources/1A18.png';
import imagenLogin from '../../../resources/splashLogin.jpg';
import nameRol4ll from '../../../resources/Login_Logo.png';
import { Services } from './Services.js';
import axios from 'axios';
import Authentication from '../../../services/Authentication.js';
import SessionStorage from '../../../services/SessionStorage.js';
import { SetNewListGroups } from '../../../state/actions/Header/index.js';
import imagenOne from '../../../resources/Login_Icon_User.png';
import imagenTwo from '../../../resources/Login_Icon_Password.png';
import imagenRoldan from '../../../resources/logoWhite_rLogistics.png';



import './styles.scss';

const auth = new Authentication();
const BackgroundHead = {
  // height: '100%',
  backgroundImage: 'url(' + imagenLogin + ')',
  backgroundSize: 'cover',
  // backgroundPosition: 'center',
  // width: 'calc(100vw + 48px)',
  // margin: -24,
  // padding: 24,
};
/*=============================================
=            Login Component                  =
=============================================*/
function Login(props) {
  const [redirectlogin, setRedirectlogin] = React.useState({
    login: {
      email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
      valid: false,
      password: localStorage.getItem('password')
        ? localStorage.getItem('password')
        : '',
      check: localStorage.getItem('email') ? true : false,
    },
  });
  let [password, setPassword] = React.useState(false);
  const [recoverPassword, setrecoverPassword] = React.useState('');
  const [response, setresponse] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  /*=============================================
  =            Handle Login Function            =
  =============================================*/
  function change(event) {
    document.getElementById('email_sin').setAttribute('hidden', 'hidden');
    document.getElementById('pass_sin').setAttribute('hidden', 'hidden');
    document.getElementById('error_user').setAttribute('hidden', 'hidden');
    if (event.target.name === 'email') {
      let value = event.target.value;
      const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
      if (emailRegex.test(value)) {
        redirectlogin.login.valid = true;
        document.getElementById('input_email').className = 'input100';
        document.getElementById('email_alert').setAttribute('hidden', 'hidden');
      } else {
        redirectlogin.login.valid = false;
        document.getElementById('input_email').className = 'invalidInput';
        document.getElementById('email_alert').removeAttribute('hidden');
      }
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    } else {
      let value = event.target.value;
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    }
  }

  function changeCorreo(event) {
    document.getElementById('correo_alert').removeAttribute('hidden');
    document
      .getElementById('correo_alert_base')
      .setAttribute('hidden', 'hidden');
    document.getElementById('correo_sin').setAttribute('hidden', 'hidden');
    let value = event.target.value;
    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    if (emailRegex.test(value)) {
      document.getElementById('input_correo').className = 'input100';
      document.getElementById('correo_alert').setAttribute('hidden', 'hidden');
      document.getElementById('correo_aler_button').removeAttribute('hidden');
    } else {
      document.getElementById('input_correo').className = 'invalidInput';
      document.getElementById('correo_alert').removeAttribute('hidden');
      document
        .getElementById('correo_aler_button')
        .setAttribute('hidden', 'hidden');
    }
    setrecoverPassword(value);
  }

  // function validateOnChange(event){
  //   if (event && event.value) {
  //     let id = event.value;
  //     setRedirectUserCustomers(id);
  //     auth.setAuthentication(redirectToken,redirectUserData.redirectUserData,id);
  //     props.history.push('/dashboard');
  //   }
  //   else {
  //     let id = '';
  //     setRedirectUserCustomers(id);
  //   }
  // }

  async function handleLoginId() {
    try {
      setLoading(true);
      if (!redirectlogin.login.email) {
        document.getElementById('email_sin').removeAttribute('hidden');
        setLoading(false);
      } else if (!redirectlogin.login.password) {
        document.getElementById('pass_sin').removeAttribute('hidden');
        setLoading(false);
      } else {
        document.getElementById('email_sin').setAttribute('hidden', 'hidden');
        document.getElementById('pass_sin').setAttribute('hidden', 'hidden');
        let data = {
          email: redirectlogin.login.email,
          password: redirectlogin.login.password,
        };
        const response = await axios.post(
          `${Services.PostOauth.path}/oauth/setAuthentication`,
          data
        );
        let token = response.data.token;
        let redirectUserData = response.data.data;
        if (redirectlogin.login.check) {
          localStorage.setItem('email', redirectlogin.login.email);
          localStorage.setItem('password', redirectlogin.login.password);
        }
        let id = response.data.data[0].UsuarioId;
        let rolId = redirectUserData[0].RolId;

        // let groups = await axios.get(`http://localhost:4219/admin/customerForId/v2/${id}`);
        const groups = await axios.get(
          `${Services.GetCustomerForUserId.path}/admin/customerForId/v2/${id}`
        );
        const createOffsetValue = (date) => {
          let sign = date.getTimezoneOffset() > 0 ? '-' : '+';
          let offset = Math.abs(date.getTimezoneOffset());
          let hours = Math.floor(offset / 60);
          let minutes = ((offset % 60) / 60) * 10;
          return sign + hours + '.' + minutes;
        };
        let userAudit = {
          UsuarioId: redirectUserData[0].UsuarioId,
          SessionId: token,
          StartConnection: new Date(),
          timezone: createOffsetValue(new Date()),
          Origin: 'web',
        };

        //navigator.sendBeacon("http://localhost:3019/user/login", JSON.stringify(userAudit))
        navigator.sendBeacon(
          'https://roldanapiaudit.azurewebsites.net/user/login',
          JSON.stringify(userAudit)
        );
        await props.SetNewListGroups({
          group_list: groups.data.resp,
          clients: groups.data.clients,
          nit: groups.data.NIT,
        });
        const session = new SessionStorage();
        session.addItem('group_list', JSON.stringify(groups.data.resp));
        session.addItem('clients', JSON.stringify(groups.data.clients));
        session.addItem('nit', JSON.stringify(groups.data.NIT));

        auth.setAuthentication(
          token,
          redirectUserData,
          groups.data.resp,
          redirectlogin.login.email
        );
        rolId === 5
          ? await props.history.push('/document-manager-warehouse')
          : await props.history.push('/dashboard');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      document.getElementById('error_user').removeAttribute('hidden');

      console.error('handleLoginId', error);
    }
  }

  function handleAccount() {
    if (!recoverPassword) {
      document.getElementById('correo_sin').removeAttribute('hidden');
    } else {
      document.getElementById('correo_sin').setAttribute('hidden', 'hidden');
      let data = {
        correo: recoverPassword,
      };
      axios
        .post(`${Services.Post_Data_Recovery.path}/admin/recover`, data)
        .then(() => {
          document.getElementById('correo_exitoso').removeAttribute('hidden');
          setrecoverPassword('');
        })
        .catch((error) => {
          if (error.response) {
            setresponse(error.response.data.message);
            document
              .getElementById('correo_alert_base')
              .removeAttribute('hidden');
            console.error('error', error.response.data.message);
          }
        });
    }
  }

  function funtionRegresa() {
    setPassword(!password);
    setrecoverPassword('');
    // document.getElementById('input_email').className = 'input100';
    document.getElementById('input_correo').className = 'input100';
    document.getElementById('correo_sin').setAttribute('hidden', 'hidden');
    document.getElementById('correo_alert').setAttribute('hidden', 'hidden');
    document
      .getElementById('correo_alert_base')
      .setAttribute('hidden', 'hidden');
    document
      .getElementById('correo_aler_button')
      .setAttribute('hidden', 'hidden');
    document.getElementById('correo_exitoso').setAttribute('hidden', 'hidden');

    // document.getElementById('error_user').setAttribute('hidden', 'hidden');
  }

  if (redirectlogin.login.check) {
    localStorage.setItem('email', redirectlogin.login.email);
    localStorage.setItem('password', redirectlogin.login.password);
  } else {
    localStorage.removeItem('email', 'email');
    localStorage.removeItem('password', 'password');
  }

  return (
    <div style={BackgroundHead}>
      <div className='login-page'>
        <Container>
          {!password ? (
            <Col xs={12} md={6} lg={4} className="ml-auto mr-auto">
              {/* <div className="wrap-login100"> */}
              {/* <div className="col-12" style={{ height: '26px' }}></div> */}
              {/* <div> */}
              {/* <div className="col-12"> */}
              {/* <center> */}
              <Card className="loginContainer">
                <center>
                  <img
                    className="pt-3 marginSpace"
                    src={nameRol4ll}
                    alt="App Logo"
                    width={'60%'}
                  // height={'120px'}
                  />
                </center>
                {/* </center> */}
                {/* </div> */}
                {/* <div className="col-12" style={{ height: '30px' }}></div> */}
                {/* </div> */}
                <div className="col-12 marginSpace1em">
                  <label style={{ color: '#d51c29', fontSize: '22px' }}>
                    Inicia Sesión
                  </label>

                </div>
                {/* <div style={{ height: 10 }} /> */}
                {/* {redirectUserFlat ? <></> : <> */}
                <div className="col-12" style={{ textAlign: 'center' }}>
                  <div
                    style={{ marginLeft: '-1px' }}
                    className="wrap-input100 row validate-input m-b-12 "
                    data-validate="Usuario es Requerido"
                  >
                    {/* <div className="row"> */}
                    <input
                      className="input100"
                      id="input_email"
                      type="text"
                      value={redirectlogin.login.email}
                      name="email"
                      onChange={change}
                      placeholder="Usuario"
                    />
                    <img
                      style={{
                        marginLeft: '-32px',
                        marginTop: '4px',
                        zIndex: '9999',
                      }}
                      height={25}
                      width={25}
                      src={imagenOne}
                      alt="check"
                    ></img>

                    {/* </div> */}

                    <span className="focus-input100"></span>
                  </div>
                  <div
                    style={{
                      padding: '3px',
                      textAlign: 'start',
                      marginTop: '-14px',
                      color: '#BA2B30',
                      fontSize: '12px',
                    }}
                    className="_alert"
                    id="email_alert"
                    hidden
                  >
                    Introduce un correo electrónico válida
                  </div>
                  <div
                    style={{ marginLeft: '-1px' }}
                    className="wrap-input100 row validate-input m-b-12"
                    data-validate="Contraseña es Requerida"
                  >
                    <input
                      className="input100"
                      type="password"
                      value={redirectlogin.login.password}
                      name="password"
                      onChange={change}
                      placeholder="Contraseña"
                    />
                    <img
                      style={{
                        marginLeft: '-32px',
                        marginTop: '8px',
                        zIndex: '9999',
                      }}
                      height={15}
                      width={25}
                      src={imagenTwo}
                      alt="check"
                    ></img>

                    <span className="focus-input100"></span>
                  </div>
                  <div
                    style={{
                      padding: '3px',
                      textAlign: 'start',
                      marginTop: '-14px',
                      color: '#BA2B30',
                      fontSize: '12px',
                    }}
                    className="_alert"
                    id="email_sin"
                    hidden
                  >
                    Correo electrónico es obligatorio
                  </div>
                  <div
                    style={{
                      textAlign: 'start',
                      padding: '3px',
                      marginTop: '-14px',
                      color: '#BA2B30',
                      fontSize: '12px',
                    }}
                    className="_alert"
                    id="pass_sin"
                    hidden
                  >
                    Contraseña es Requerida
                  </div>
                  <div
                    style={{
                      textAlign: 'start',
                      padding: '3px',
                      marginTop: '-14px',
                      color: '#BA2B30',
                      fontSize: '12px',
                    }}
                    className="_alert"
                    id="error_user"
                    hidden
                  >
                    Parece que tienes el correo electrónico o la contraseña
                    incorrectos
                  </div>
                </div>
                <div className=" col-6 marginSpace1em">
                  <div
                    style={{ fontSize: '15px', marginLeft: '8px', color: '#000' }}
                    className="col-12"
                  >
                    <Label>
                      <Input
                        type="checkbox"
                        onChange={() => {
                          redirectlogin.login.check = !redirectlogin.login.check;
                          setRedirectlogin({ redirectlogin, ...redirectlogin });
                        }}
                        checked={redirectlogin.login.check}
                      />
                      Recuérdame
                    </Label>
                  </div>
                </div>

                {/* <div style={{ height: 10 }} /> */}
                <div
                  style={{ marginBottom: '1em' }}
                  className="container-login100-form-btn"
                >
                  {/* <div className="col-6"></div> */}
                  <div
                    className="col-12" /* style={{ textAlign: '-webkit-right' }} */
                  >
                    <button
                      className="login100-form-btn"
                      onClick={handleLoginId}
                      disabled={loading}
                    >
                      {loading ? (
                        <strong>Ingresando...</strong>
                      ) : (
                        <strong>Ingresar</strong>
                      )}
                    </button>
                  </div>
                </div>

                <div style={{ marginBottom: '1em' }} className="col-6">
                  <button
                    onClick={() => {
                      setPassword(!password);
                    }}
                    style={{ color: 'blue', fontSize: '11px', width: '16em' }}
                  >
                    <h3
                      className="decorationHover"
                      style={{
                        color: '#470054',
                        fontSize: '15px',
                        fontWeight: 'unset',
                      }}
                    >
                      {' '}
                      ¿Olvidaste tu contraseña?{' '}
                    </h3>
                  </button>
                </div>
                {/* </div> */}

              </Card>
            </Col>
          ) : (
            <Col xs={12} md={6} lg={4} className="ml-auto mr-auto mb-2">
              <Card className="loginContainer">
                <CardHeader>
                  <center>
                    <img
                      // className="img-fluid"
                      src={nameRol4ll}
                      alt="App Logo"
                      width={'60%'}
                    // height={'120px'}
                    />
                  </center>
                </CardHeader>
                {/* <div>
                    <div style={{ height: 20 }} />
                    <div className="col-12">
                      <center>
                        <img
                          // className="img-fluid"
                          src={nameRol4ll}
                          alt="App Logo"
                          width={'240px'}
                          height={'120px'}
                        />
                      </center>
                    </div>
                    <div className="col-12" style={{ height: '30px' }}></div>
                  </div> */}
                <CardBody>
                  <div className="col-12">
                    <label style={{ color: '#003F70', fontSize: '24px' }}>
                      Recuperar Contraseña
                    </label>
                  </div>
                  <div className="col-12">
                    <label style={{ color: '#003F70', fontSize: '12px' }}>
                      Ingresa tu correo electrónico con el que te registraste por
                      primera vez y se te enviará un correo de confirmación a tu
                      cuenta.
                    </label>
                  </div>

                  {/* <div style={{ height: 10 }} /> */}
                  {/* {redirectUserFlat ? <></> : <> */}
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    <div
                      className="wrap-input100 validate-input m-b-12 "
                      data-validate="Correo es requerio"
                    >
                      <input
                        className="input100"
                        id="input_correo"
                        type="text"
                        value={recoverPassword}
                        name="correo"
                        onChange={changeCorreo}
                        placeholder="Correo a recuperar contraseña"
                      />
                      <span className="focus-input100"></span>
                    </div>
                    {/* <div style={{padding:'3px',marginTop:'-14px', color:'#BA2B30', fontSize:'12px'}} className="_alert" id="correo_alert_recuperar" hidden>Introduce una dirección de correo electrónico válida</div> */}
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="correo_alert"
                      hidden
                    >
                      Introduce un correo electrónico válido
                    </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="correo_alert_base"
                      hidden
                    >
                      {response}
                    </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="correo_sin"
                      hidden
                    >
                      Correo electrónico es Requerido
                    </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#BA2B30',
                        fontSize: '12px',
                      }}
                      className="_alert"
                      id="error_correo"
                      hidden
                    >
                      Parece que tu correo electrónico no esta registado
                    </div>
                    <div
                      style={{
                        padding: '3px',
                        marginTop: '-14px',
                        color: '#052389',
                        fontSize: '14px',
                      }}
                      className="_alert"
                      id="correo_exitoso"
                      hidden
                    >
                      Correo electrónico enviado exitosamente
                    </div>
                  </div>
                  <div style={{ height: 30 }} />
                  <div className="container-login100-form-btn">
                    <div className="col-6" style={{ marginTop: '-1px' }}>
                      <button
                        id=""
                        className="login100-form-btn"
                        onClick={funtionRegresa}
                      >
                        <strong>Regresar</strong>
                      </button>
                    </div>
                    <div className="col-6" style={{ textAlign: '-webkit-right' }}>
                      <button
                        id="correo_aler_button"
                        className="login100-form-btn"
                        onClick={handleAccount}
                      >
                        <strong>Enviar</strong>
                      </button>
                    </div>
                  </div>
                  {/* <div style={{ height: 20 }} /> */}
                </CardBody>
              </Card>
            </Col>
          )}


          {/* <div
            className="col-12"
            style={{
              textAlign: 'center',
              color: '#fff',
              fontSize: '14px',
              fontWeight: '',
            }}
          > */}
          <Col xs={12} md={12} lg={12} className="ml-auto mr-auto mt-5 styleCopyRights">
            <div className="_copy_rigth">
              Copyright © {moment(new Date()).format('YYYY')}{' '}
              <a className="em-a" href="https://roldan-webapp.firebaseapp.com/">
                {' '}
              </a>
              Roldán. All rights reserved.{' '}
            </div>
            <div className=" _copy_rigth_term">
              {' '}
              <a
                id="login-terms-link"
                target="_blank1"
                href="https://roldan-webapp.firebaseapp.com/terminos-y-condiciones"
                className="em-a"
              >
                {' '}
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                target="_blank2"
                href="https://roldan-webapp.firebaseapp.com/politica-de-privacidad"
                id="login-termss-link"
                className="em-a"
              >
                {' '}
                Privacy Policies.
              </a>{' '}

            </div>
          </Col>
          {/* <div className="col-md-12 copyright-terms"> <a id="login-terms-link" target="_blank"  className="em-a"> Terms & Conditions</a> and <a target="_blank" id="login-termss-link" className="em-a"> Privacy Policies.</a> </div>  */}
          {/* </div> */}
        </Container>

      </div>
    </div>
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  SetNewListGroups: (new_group_list) =>
    dispatch(SetNewListGroups(new_group_list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
