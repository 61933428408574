import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Icon, Form, Input, Button, Segment, Dimmer, Loader} from 'semantic-ui-react';
import axios from 'axios';

import Spin from '../../Components/Spin';
// ? Transfer
import Notification from '../../Components/Alert';
import TableTransfer from './components/Transfer';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { leftTableColumns, rightTableColumns} from './config';
import { Services } from '../Services';

function CreateAndEditGroup(props){
  const [dataSource, setDataSource] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);
  // const [disabled, setDisabled] = React.useState(false);
  // const [showSearch , setShowSearch] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingSpin, setLoadingSpin] = React.useState(false);
  const [Group, setGroup] = React.useState({value: '', valid: true});
  const { _user, match } = props;
  const { id } = match.params;
  const queryString = new URLSearchParams(props.location.search);
  
  /**
   * Funciones de almacenamiento
   */
  const usersList = React.useCallback(async() => {
    try {
      const respUsers = await axios.get(`${Services.REPORTS.path}/groups/users/list`);
      setDataSource(respUsers.data.users);
    }catch(error){
      Notification(
        'Error', 
        'Ha ocurrido un error al intentar consultar la lista de usuarios',
        'error'
      );
    }
  }, []);
  
  const UsersSelected = React.useCallback(async() => {
    try{
      setLoadingSpin(true);
      const body = {
        user: _user,
        report: id
      };
      const respUsersSelected = await axios.post(`${Services.REPORTS.path}/groups/users/shared`, body);
      setDataSource(respUsersSelected.data.users);
      setTargetKeys(respUsersSelected.data.shared);
      setGroup({...{value: respUsersSelected.data.reportName, valid: true}});
      setLoadingSpin(false);
    }catch(error){
      setLoadingSpin(false);
      Notification(
        'Error', 
        'Ha ocurrido un error al intentar consultar datos del grupo!',
        'error'
      );
    }
  }, [_user, id]);

  const CreateGroup = React.useCallback(async(Group, targetKeys) => {
    try{
      const body = {
        groupName: Group.value,
        targetKeys: targetKeys
      };
      setLoading(true);
      await axios.post(`${Services.REPORTS.path}/groups/create`, body);
      Notification(
        'Creado', 
        'Grupo creado correctamente!',
        'success'
      );
      setGroup({...{value: '', valid: true}});
      setTargetKeys([]);
      setLoading(false);
    }catch(error){
      console.error(' -- CreateGroup -- the error --', error);
      Notification(
        'Error', 
        'Error al intentar crear grupo!',
        'error'
      );
      setLoading(false);
    }
  }, []);

  const UpdateGroup = React.useCallback(async({targetKeys, groupId, user, groupName}) => {
    try {
      setLoading(true);
      const body = {
        groupName,
        targetKeys,
        group: groupId,
        user
      };
      await axios.put(`${Services.REPORTS.path}/groups/update`, body);
      Notification(
        'Actualizado', 
        'Grupo actualizado exitosamente!',
        'success'
      );
      setLoading(false);
    }catch(error){
      console.error(' -- UpdateGroup -- the error --', error);
      Notification(
        'Error', 
        'Error al intentar actualizar grupo!',
        'error'
      );
      setLoading(false);
    }
  }, []);


  // ? Report
  function OnChangeGroupName(evt, data){
    if(data.value){
      setGroup({...{value: data.value, valid: true}});
    } else {
      setGroup({...{value: data.value, valid: false}});
    }
  };
  // ? Transfer Functions
  const onChange = nextTargetKeys => {
    setTargetKeys(nextTargetKeys);
  };

  // const triggerDisable = disabled => {
  //   setDisabled(disabled);
  // };

  // const triggerShowSearch = showSearch => {
  //   setShowSearch(showSearch);
  // };

  const handleActions = () => {
    if (Group.value && targetKeys.length){
      if(id && JSON.stringify(queryString.get('edit'))){
        UpdateGroup({targetKeys, groupId: id, user: _user, groupName: Group.value});
      } else {
        CreateGroup(Group, targetKeys);
      }
    } else {
      Notification(
        'Error', 
        'El nombre de grupo es requerido y debe de existir al menos un usario seleccionado ',
        'error'          
      );      
    }
  };

  React.useEffect(() => {
    if(id && JSON.parse(queryString.get('edit'))){
      UsersSelected();
    } else {
      usersList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList, UsersSelected]);

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div className="row">
          <em className="fas fa-1x mr-2 fas fa-table" />
          Creación de grupo
        </div>
      </div>      
      <div>
        <Header as='h3' className='_btnBack' onClick={() => props.history.push('/groups')}>
          <Icon.Group size='large'>
            <Icon name='angle left' link className='_btnBack' size='big' onClick={() => {}} />
          </Icon.Group>
          Grupos
        </Header>
      </div>
      <Header className='mb-3' as='h3'>Información del grupo</Header>
      <Spin spinning={loadingSpin}>
        <div className='col-md-12 mt-2'>
          <div className='row'>
            <div className='col-md-6'>
              <Form size='mini'>
                <Form.Field required width={6} error={Group.valid ? false: true}>
                  <label>Nombre</label>
                  <Input placeholder='Nombre' name='groupName' onChange={OnChangeGroupName} value={Group.value}/>
                </Form.Field>
              </Form>

            </div>
            <div className='col-md-6'>
              <div className='pt-4'>
                <Button icon labelPosition='right' size='mini' positive loading={loading} onClick={handleActions}>
                  <Icon name='save' />
                  Guardar
                </Button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <TableTransfer
                className='mt-3'
                dataSource={dataSource}
                targetKeys={targetKeys}
                // disabled={disabled}
                // showSearch={showSearch}
                showSearch
                onChange={onChange}
                filterOption={(inputValue, item) =>
                  item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 
                }
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
              />
              {/* <Switch
                unCheckedChildren="disabled"
                checkedChildren="disabled"
                checked={disabled}
                onChange={triggerDisable}
                style={{ marginTop: 16 }}
              />
              <Switch
                unCheckedChildren="showSearch"
                checkedChildren="showSearch"
                checked={showSearch}
                onChange={triggerShowSearch}
                style={{ marginTop: 16 }}
              />       */}
            </div>
          </div>
        </div>
      </Spin>
    </ContentWrapper>
  );
}
export default withRouter(CreateAndEditGroup);