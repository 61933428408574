import React, { Component, Suspense } from 'react';
import axios from 'axios';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';


import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { MyResponsivePieCanvas } from '../../Components/Graphics/DonutGraph';
import { MyResponsiveBarCanvas } from '../../Components/Graphics/BarChart';
import mapJson from '../../../Services/mapJson';
import { Services } from '../Services';
import { FullLoader } from './Components/Loader';
import Loader from '../../../Global/Loader';
import { connect } from 'react-redux';
// import ClientesList from '../../../../services/storageClient'
const Table = React.lazy(() => import('../../Components/Table'));

// const clientSave = new ClientesList();
let coloresAzules = ['#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD',];
let coloresVerdes = ['#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#3B831E', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD', '#3B831E', '#4EAA29', '#61CF35', '#78F340', '#9EF571', '#98F56C', '#B9F79B', '#CFF9BD',];
let coloresAmarillos = ['#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF', '#FAEA4E', '#FDEE4F', '#F5E663', '#FCF07B', '#FBF1A0', '#FAF2B1', '#F9F3C1', '#F8F4CF',];


class InventaryDetail extends Component {
  state = {
    // clientesSelect: clientSave.getAuthentication('nit'),
    clientesSelect: this.props.nit ? JSON.stringify(this.props.nit) : '[0]',
    dataOriginal: [],
    catalog: [],
    graphs: [],
    data: mapJson.dataTable,
    dataDrawer: '',
    dataMap: mapJson.dataMap,
    donutChart: [],
    donutChartTrafico: [],
    barTable: [],
    visible: false,
    columns: [
      { label: 'Almacén', key: 'punto' },
      { label: 'Referencia', key: 'articulo' },
      { label: 'Descripción', key: 'nombre_articulo' },
      { label: 'Lote', key: 'lote' },
      { label: 'Cantidad', key: 'cantidad' },
      { label: 'Unidad de Medida', key: 'presentacion' },
      { label: 'Categoría', key: 'nombre_tipo_producto' },
      { label: 'Estado de la Mercancía', key: 'nombre_estado_articulo' },],
    dataTable: [],
    fullLoader: false,
    loadingGraphs: true
  }

  componentDidMount = () => {
    this.getDataStorage();
    window.addEventListener('resize', () => {
      let newGraphs = this.state.graphs;
      newGraphs.map((e) => {
        e.width = this.calculateWidth();
        return e;
      });
      this.setState({ graphs: newGraphs });
    });
  }

  getDataStorage = () => {
    axios.post(`${Services.Post_Data_InventaryDetail.path}/storage/inventario`, {
      'proveedor': this.state.clientesSelect,
      'punto': '',
      'articulos': []
    }).then(success => {
      let dataNew = success.data.message.arreglo_respuestas.map((e) => {
        e.articulo = e.articulo[0];
        e.presentacion = e.presentacion[0];
        return e;
      });
      this.setState({
        dataOriginal: dataNew,
        loadingGraphs: false
      }, () => {
        this.findData(dataNew);
      });
    }).catch(error => {
      console.warn(error);
      this.setState({
        fullLoader: true
      });
    });
  }


  findData = (data, flat) => {
    let stateMercancia = [];
    let top5 = [];
    let Reftop10 = [];
    let cantalog = [];
    data.map((e) => {
      let findMercancia = false;
      let findtop5 = false;
      let findReftop10 = false;
      let findcantalog = false;
      cantalog.map((f) => {
        if (e.punto === f.value) {
          findcantalog = true;
        }
        return f;
      });
      if (!findcantalog) {
        let obj = {
          value: e.punto,
        };
        cantalog.push(obj);
      };
      Reftop10.map((j) => {
        if (e.articulo === j.status) {
          j.Cancelled = j.Cancelled + e.cantidad;
          findReftop10 = true;
        }
        return j;
      });
      if (!findReftop10) {
        let obj = {
          status: e.articulo,
          id: e.articulo,
          Cancelled: e.cantidad,
          CancelledColor: '#0bc637'
        };
        Reftop10.push(obj);
      };



      stateMercancia.map((u) => {
        if (e.nombre_estado_articulo === u.id) {
          u.value = u.value + e.cantidad;
          findMercancia = true;
        }
        return u;
      });
      if (!findMercancia) {
        let obj = {
          id: e.nombre_estado_articulo,
          label: e.nombre_estado_articulo,
          value: 1,
          color: '#00a9ff'
        };
        stateMercancia.push(obj);
      };


      stateMercancia.map((f, index) => {
        f.color = coloresAzules[index];
        return f;

      });
      top5.map((h) => {
        if (e.nombre_tipo_producto === h.status) {
          h.Cancelled = h.Cancelled + e.cantidad;
          findtop5 = true;
        }
        return h;
      });
      if (!findtop5) {
        let obj = {
          status: e.nombre_tipo_producto,
          id: e.nombre_tipo_producto,
          Cancelled: 1,
          CancelledColor: '#efbc04'
        };
        top5.push(obj);
      };

      top5.map((f, index) => {
        f.CancelledColor = coloresAmarillos[index];
        return f;
      });
      return e;
    });
    let newtop5 = [];
    top5.sort(function (a, b) {
      if (b.Cancelled > a.Cancelled) {
        return 1;
      }
      if (b.Cancelled < a.Cancelled) {
        return -1;
      }
      return 0;
    });
    top5.map((e, index) => {
      if (index < 10) { newtop5.push(e); }
      return e;
    });
    newtop5.map((e) => {
      e.status = e.status ? (e.status.length > 13 ? `${e.status.substring(0, (16 - 2))}` : e.status) : '';
      return e;
    });
    newtop5.sort(function (a, b) {
      if (a.Cancelled > b.Cancelled) {
        return 1;
      }
      if (a.Cancelled < b.Cancelled) {
        return -1;
      }
      return 0;
    });
    let newReftop10 = [];
    Reftop10.sort(function (a, b) {
      if (b.Cancelled > a.Cancelled) {
        return 1;
      }
      if (b.Cancelled < a.Cancelled) {
        return -1;
      }
      return 0;
    });
    Reftop10.map((e, index) => {
      if (index < 10) { newReftop10.push(e); }
      return e;
    });
    newReftop10.sort(function (a, b) {
      if (a.Cancelled > b.Cancelled) {
        return 1;
      }
      if (a.Cancelled < b.Cancelled) {
        return -1;
      }
      return 0;
    });

    newReftop10.map((f, index) => {
      f.CancelledColor = coloresVerdes[index];
      return f;
    });


    this.setState({
      donutChartTrafico: newReftop10,
      dataTable: data,
      donutChart: stateMercancia,
      barTable: newtop5,
    }, () => {
      if (!flat) { this.getCatalogStorage(cantalog); }
      this.setState({
        graphs: [
          {
            title: 'Estado de la Mercancía',
            type: 'pie',
            data: this.state.donutChart,
            width: this.calculateWidth(),
            height: '280',
            class: 6
          },
          {
            title: 'Top10 por Referencia',
            type: 'bar',
            data: this.state.donutChartTrafico,
            width: this.calculateWidth(),
            height: '280',
            class: 6,
            left: 75
          },
          {
            title: 'Top10 por Categoría',
            type: 'bar',
            data: this.state.barTable,
            width: this.calculateWidth(),
            height: '280',
            class: 12,
            left: 120
          }
        ]
      });
    });
  }

  newdata = (data) => {
    if (data) {
      this.findData(data, true);
    }
    else {
      this.findData(this.state.dataOriginal, true);
    }
  }

  getCatalogStorage = (cantalog) => {
    axios.get(`${Services.Get_CatalogStorage.path}/catalog/CatalogAlmacen`).then(success => {
      let NewCatalog = [];
      success.data.data.map((e) => {
        let finNewCatalog = false;
        cantalog.map((f) => {
          if (f.value === e.value) {
            finNewCatalog = true;
          }
          return f;
        });
        if (finNewCatalog) {
          let obj = {
            value: e.value,
            label: e.label,
          };
          NewCatalog.push(obj);
        }
        return e;
      }
      );
      this.setState({
        catalog: NewCatalog,
        fullLoader: true
      });
    }).catch(error => {
      console.warn(error);
    });
  }

  calculateWidth = () => {
    if (window.innerWidth >= 1756) {
      return (window.innerWidth / 3.9).toString();
    }
    else {
      return (window.innerWidth / 4.3).toString();
    }
  }

  toggleDrawerMap = data => evt => {
    this.setState({ visible: true, dataDrawer: data });
  };

  render() {
    let { graphs, fullLoader } = this.state;
    const faketables = [1, 2, 3];
    return (
      <>
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <div className="content-heading">
            <legend className="legendTitle">
              <img
                src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-15.png"
                height="28"
                width="28"
              />
              Detalle Inventario
            </legend>
          </div>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Row>
                {
                  this.state.loadingGraphs ?
                    faketables.map((e, index) => {
                      return (
                        <Col key={index} md={4} xl={4} lg={4} sm={12} xs={12}>
                          <Card className="graphcardsize">
                            <CardBody className="graphcardbody">
                              <CardText style={{ textAlign: 'center', fontFamily: 'unset', marginTop: '10px', fontWeight: 'bold' }}>{e.title}</CardText>
                              <FullLoader />
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })
                    :
                    graphs.map((e, index) => {
                      let typeGraph = e.type === 'pie' ? (<MyResponsivePieCanvas data={e.data} />) : <MyResponsiveBarCanvas position="horizontal" data={e.data} left={e.left} />;
                      return (
                        <Col key={index} md={e.class} xl={e.class} lg={e.class} sm={12} xs={12}>
                          <Card className="graphcardsize">
                            <CardBody className="graphcardbody">
                              <CardText style={{ textAlign: 'center', fontFamily: 'unset', marginTop: '10px', fontWeight: 'bold' }}>{e.title}</CardText>
                              {typeGraph}
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })
                }
              </Row>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Card className="myCard">
                <CardBody>
                  {
                    this.state.loadingGraphs ?
                      <FullLoader />
                      :
                      <Suspense fallback={<div>Loading...</div>}>
                        <Table
                          renderData={this.newdata}
                          columns={this.state.columns}
                          data={this.state.dataTable}
                          data2={this.state.dataOriginal}
                          itemsByPage={20}
                          nameXlsx={'Detalle_Inventario.xlsx'}
                          options={{
                            SelectZone: true,
                            catalog: this.state.catalog,
                            downloads: {
                              pdf: false,
                              xlsx: true,
                            },
                            pagination: true,
                            search: true,
                          }} /></Suspense>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ContentWrapper>
      </>
    );
  }
}
const mapStateToProps = state => ({
  nit: state.header.nit
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(InventaryDetail);
