import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Search } from 'semantic-ui-react';
import TableComponent from './table'; 
import Loader from '../../../../Global/Loader';
import { Services } from '../../Services';


function Despachos(props) {
  
  let [clients, setClients] = React.useState(`[${props.clients}]`);
  let [data, setData] = React.useState([]);
  let [cpyData, setCpyData] = React.useState([]);
  let [fullLoad, setFullLoad] = React.useState(false);
  
  const getCustomImports = React.useCallback(async () => {
    try {

      let objPost = {
        pag: 1,
        limit: 1000000,
        client: clients,
        advanced:[],
        sarch:''
      };
      
      let success = await Axios.post(`${Services.Almacen_manager.path}/storage/officesData`, objPost);

      setData(success.data.data);
      setCpyData(success.data.data);
      setFullLoad(true);
    } catch (error) {
      console.error('getCardList ==>', error);
    }
  }, [clients]);
  
  React.useEffect(() => {
    getCustomImports();
  }, [getCustomImports]);


  let mockColumns = [
    {CollapseTable: true, flatColor: false, key: '',  label: ''},
    {label: 'No. de Despacho', key: 'NoDespacho'},
    {label: 'Almacén', key: 'Almacen'},
    {label: 'Estatus', key: 'Estatus'},
    {label: 'Cliente', key: 'Cliente'},
    {label: 'Referencia', key: 'RefCliente'},
    {label: 'Doc. de Transporte', key: 'DocTransporte'},
    {label: 'Notificación Salida', key: 'FechaNotificacionSalida'},
  ];

  const handleChange = (event) => {
    
    let search = event.target.value;
    search = search.toUpperCase();

    let all = cpyData;  

    let results = [];
    all.forEach((e) => {
      let found = 0;
      let keys = ['NoDespacho', 'Almacen', 'Estatus', 'Cliente', 'RefCliente', 'FechaNotificacionSalida', 'DocTransporte'];
      keys.forEach((key) => {
        if ((e[key] ? (e[key] + '').toLowerCase().indexOf(search.toLowerCase()) : -1) >= 0) found += 1;
      });
      if (found !== 0) results.push(e);
    });

    setData(results);
  };

  let sbTable = [
    {label: 'Nombre del Documento', key: 'Name'},
    {label: 'Tipo de Documento', key: 'Type'},
    {label: 'Fecha de Creación', key: 'CreationDate'},
    {label: 'Actualizado por', key: 'CreateBy'},
    {label: '', key: 'Download', separator: true}
  ];

  return (
    <> 
      <Loader hidden={fullLoad}></Loader>
      <div className="col-xs-12 col-md-4 col-lg-3">
        <Search
          input={{ icon: 'search', iconPosition: 'left', fluid: true }}
          placeholder="..."
          //value={searchIn}
          name="search"
          id="search"
          onSearchChange={handleChange}
          showNoResults={false}
        />
        <br/>
      </div>
      <TableComponent columnsTable={mockColumns} data={data} subTable={sbTable}></TableComponent>
    </>
  );
}


const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});
    
export default connect(mapStateToProps, mapDispatchToProps)(Despachos);