import React from 'react';
import DialogList from '../SecComponents/dialogList';

const availableReferences = {
  message: 'Por favor, indique alguna de las referencias:',
  optionList: ['No. de DO', 'Documento de Transporte', 'Referencia de Cliente'],
};

function Integrada() {
  return (
    <div className="row" style={{ width: '100%' }}>
      <DialogList references={availableReferences} />
    </div>
  );
}

export default Integrada;
