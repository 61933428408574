/**
 * sortNumber - Implementation of sort function https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * @param {Object} - Current value and next value
 * @return 0, 1 or -1 depends of criterior
 */
export const sortNumber = (a, b) => {
  if (a.value < b.value) {
    return 1;
  }
  if (a.value > b.value) {
    return -1;
  }
  return 0;
};
/**
 * export const sortString = (a, b) => {
 - Implementation of sort function https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * @param {Object} - Current value and next value
 * @return 0, 1 or -1 depends of criterior
 */
export const sortString = (a, b) => {
  var stringA = a.id.toUpperCase();
  var stringB = b.id.toUpperCase();
  if (stringA < stringB) {
    return -1;
  }
  if (stringA > stringB) {
    return 1;
  }
  return 0;
};