export const Services = {
   "Api_Costos": {
      "path": "https://roldanapifacturacioncostos.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO SET FACTURACION"
   },
   "Dowload_Excel": {
      "path": "https://servicereportapi.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO GET EXCELL"
   }
}