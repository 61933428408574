import React from 'react';
import { Container, Label } from 'semantic-ui-react';

const defaultStyles = {
  fontSize: '12px',
  backgroundColor: '#FDFEFE',
};

function CurrentLabel(props) {

  const nextStep = React.useCallback(() => {
    const nextStep = Array.isArray(props.previousStep.value)
      ? props.previousStep.value[1]
      : props.previousStep.value ? props.previousStep.value : window.localStorage.currentChatbot;
    
    const defaultValue = window.localStorage.getItem('chatHistory');

    let rpl; 

    const updateStorage = defaultValue
      ? defaultValue.concat('; ', nextStep)
      : 'mainMenu';

    localStorage.setItem('chatHistory', updateStorage);

    let valIf = 'Lo hemos redireccionado al detalle de la operación donde encontrará la información específica de la referencia ingresada.';
    
    /** validar si viene de vista de detalle de la card  */
    if(Array.isArray(props.previousStep.value)){
      if(props.previousStep.value[0] === valIf){
        let newValues = JSON.parse(props.previousStep.value[1]);
        props.triggerNextStep({
          value: newValues.value,
          trigger: newValues.trigger,
        });
      }else{
        rpl = window.localStorage.currentChatbot.split(',');
        props.triggerNextStep({
          value: props.previousStep.avatar ? rpl[0] : props.previousStep.value ,
          trigger: nextStep,
        });
      }
    }
    else{
      rpl = window.localStorage.currentChatbot.split(',');

      let nextStepWS = nextStep;
      let currentStep; 
      if(nextStep.includes(',') ){
        currentStep = nextStep.split(',');
        nextStepWS = currentStep[0];
      }

      props.triggerNextStep({
        value: props.previousStep.avatar ? rpl[0] : props.previousStep.value ,
        trigger: nextStepWS,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    nextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let stepRepl = window.localStorage.currentChatbot;
  stepRepl = stepRepl.split(',');

  return (
    <div className="row" style={{ width: '100%', backgroundColor: '#FDFEFE' }}>
      <Container>
        <center>
          <Label style={defaultStyles}>
            {Array.isArray(props.previousStep.value)
              ? props.previousStep.value[0]
              : props.previousStep.value ? props.previousStep.value : stepRepl[0]}
          </Label>
        </center>
      </Container>
    </div>
  );
}

export default CurrentLabel;
