/*eslint-disable*/
import React, { Suspense } from "react";
import { withRouter } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardFooter,
  Label,
  CardTitle,
} from "reactstrap";
// import axios from "axios";
import { connect } from 'react-redux';

import ImpoContainer from "./Impo/Importaciones";
import ExpoContainer from "./Expo/Exportaciones";
import ContentWrapper from "../../../template/Layout/ContentWrapper";
import axios from "axios";
import Services from "../Services";
// import ClientesList from "../../../../services/storageClient.js";
// const clientSave = new ClientesList();

class Integrada extends React.Component {


  render() {
    return (
      <>
        <ContentWrapper>
          <div className="content-heading">
            <legend className="legendTitle">
              <img
                alt=""
                src="https://fsposeidon.blob.core.windows.net/roldan/icon_trazabilidad.png"
                height="28"
                width="28"
              />
              Trazabilidad Integrada
            </legend>
          </div>
          <div>
            <Tab
              menu={{ secondary: true, className:'wrapped' }}
              panes={[
                {
                  menuItem: "Importación",
                  render: () =>
                    <ImpoContainer />,
                },
                {
                  menuItem: "Exportación",
                  render: () =>
                    <ExpoContainer />,
                },
              ]}
            />
          </div>
        </ContentWrapper>
      </>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Integrada));
