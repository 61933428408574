import React, { Component, Suspense } from 'react';
import ContentWrapper from '../../../../template/Layout/ContentWrapper';
import { Row } from 'reactstrap';
import { Tab } from 'semantic-ui-react';
import dataResumen from '../../../../Model/resumenCard';
// import Documents from '../../../Components/Documents';
// import Comments from '../../../Components/Comments';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Services } from '../../Services';
const Events = React.lazy(() => import('./Events'));
const Summary = React.lazy(() => import('./Summary'));

class ContainerDetails extends Component {
  state = {
    allSteps: [],
    id:this.props.match.params.id,
    dataResumen: dataResumen,
    dataContainer:{},
    commentsData: [{
      ComType: 'Tipo 1',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-06-20',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    },
    {
      ComType: 'Tipo 2',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-07-02',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    }],
    catalogsData:
      [
        { ComTypeId: 1, ModuleId: 1, ComType: 'Supplier Follow Up', value: 1, label: 'Supplier Follow Up' },
        { ComTypeId: 2, ModuleId: 1, ComType: 'Order Quality', value: 2, label: 'Order Quality' },
        { ComTypeId: 3, ModuleId: 1, ComType: 'Order Quantities', value: 3, label: 'Order Quantities' },
        { ComTypeId: 10, ModuleId: 1, ComType: 'Other', value: 10, label: 'Other' },
      ],
    steps:[
      {
        label: 'ATA',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        idStep: 'step1',
        middleStep: ''
      },
      {
        label: 'Cita para Cargue',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Salida de puerto',
        value: '',
        id: 5,
        completed: false,
        order: 3,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Llegada de Destino',
        value: '',
        id: 6,
        completed: false,
        order: 4,
        idStep: 'step5',
        middleStep: ''
      },
      {
        label: 'Finalización de OTM',
        value: '',
        id: 7,
        completed: false,
        order: 5,
        idStep: 'step6',
        middleStep: ''
  
      },
      {
        label: 'Devolución de Contenedor',
        value: '',
        id: 8,
        completed: false,
        order: 6,
        idStep: 'step8',
        middleStep: ''
      },
    ]

  }

  componentDidMount = () =>{
    this.getContainerForId();
    this.getEvents();
  }

  getEvents = ()=>{
    const {steps, id } = this.state;
    let moduleId = 4;
    // let idContainer = 1816;
    axios.get(`${Services.Get_Events_General.path}/events/all/${moduleId}/${id}`)
      .then((response) => {
        let update = false;
        for (let index = response.data.steps.length; index >= 0; index--) {
          const element = response.data.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = steps;
        let dataEvents = response.data.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {
            if(e.EventDescriptionId === f.id){
              findNext = true;
              f.value = e.EventDate;
              if(e.InTimeLine === '1'){
                f.completed = true;
              }
              else {
                findNext = false;
              }
            }
            else {
              findNext = false;
            }
            if(findNext){
              f.completed = true;
            }
            return e;
          });
          return f;
        });
        newSteps.sort(function(a, b) {return b.order - a.order});
        let findTrue = false;
        newSteps.map(e =>{
          if(e.completed){
            findTrue = true;
          }
          if(findTrue){
            e.completed = true;
          }
          return e;
        });
        this.setState({ steps: newSteps, allSteps: dataEvents },()=>{
        });
      }).catch((error) => {
      });
  }

  getContainerForId = () => {
    axios.get(`${Services.Get_Traceability_ContainerDetail.path}/shipments/ContainerDetail/${this.state.id}`).then(success => {
      let newResume = dataResumen.map((f)=>{
        f.data.map(g =>{
          g.value = success.data.data[0][g.key];
          g.value2 = success.data.data[0][g.idlink];
          return g;
        });  
        return f;
      });      
      this.setState({
        dataResumen:newResume,
        doEmbarque:success.data.data[0].DoEmbarque,
        noContenedor:success.data.data[0].NoContenedor
      });
    }).catch(error => {
      console.warn(error); 
    });
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <Row>
            <legend><em className="fa-1x mr-2 fas fa-shopping-basket"></em>Detalle del Contenedor</legend>
          </Row>
        </div>
        <Tab
          menu={{ secondary: true, className: 'myWrapped' }}
          menuPosition='right'
          panes={[
            { menuItem: 'Resumen', render: () => <Suspense fallback={<div>Loading...</div>}> <Summary steps={this.state.steps} data={this.state.dataResumen} ></Summary></Suspense> },
            { menuItem: 'Eventos', render: () => <Suspense fallback={<div>Loading...</div>}> <Events noContenedor={this.state.noContenedor} doEmbarque={this.state.doEmbarque}  steps={this.state.steps} allSteps={this.state.allSteps} ></Events></Suspense> },
            // { menuItem: 'Comentarios', render: () => <div><Comments data={this.state.commentsData} catalogs={this.state.catalogsData}> </Comments></div> },
            // { menuItem: 'Documentos', render: () => <div><Documents rolId={12} shipment="" shipmentDataPlane=""></Documents></div> },
          ]} />
      </ContentWrapper>
    );
  }
}
export default withRouter(ContainerDetails);
