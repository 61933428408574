import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { red } from '@material-ui/core/colors/';

const color='#3b4c7f';
// const color='#001489';
const useStyles = makeStyles((theme) => ({
  danger: {
    padding: '8px',
    '&:hover': {
      backgroundColor: red[500],
      borderColor: red[500],
      boxShadow: 'none',
      color: '#ffff',
    },
  },
  primary: {
    padding: '8px',
    '&:hover': {
      backgroundColor: color,
      borderColor: color,
      boxShadow: 'none',
      color: '#ffff',
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function CircleButton(props){
  const classes = useStyles();
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <IconButton aria-label="actions" className={props.color === 'danger' ? classes.danger: classes.primary} onClick={props.onClick} disabled={props.disabled}>
        <Icon fontSize="small">{props.icon}</Icon>
      </IconButton>
    </div>
  );
}

CircleButton.propTypes = {
  color: propTypes.string,
  disabled: propTypes.bool,
  icon: propTypes.string,
  onClick: propTypes.func,
};

CircleButton.defaultProps = {
  color: 'primary',
  disabled: true,
  icon: 'delete',
  onClick: () => {},
};

export default CircleButton;