import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import moment from 'moment';
import { Row} from 'reactstrap';

/*=============================================
=            Catalogs Trends By Year          =
=============================================*/
function CatYears(props){
  const [CatalogYears, setCatalogYears] = React.useState();
  const [date, setDate] = React.useState(moment(new Date()).format('YYYY'));
  const GetCatalog = React.useCallback( async ()=> {
    try{
      setCatalogYears(props.yearCat);
      let defaultyear = props.yearCat.length >0 ? props.yearCat[0].value : '';
      setDate(defaultyear);
    } catch(error){
      console.error(error);
    }
  },[props.yearCat]);

  function onChangeDate(evt, data){
    // setDate(data.value);
    props.onChangeYear(data.value);
  };

  React.useEffect(() => {
    GetCatalog();
  }, [GetCatalog]);
    
  return(
    <>
      <div >
        <Row>
          <Dropdown
            className="catalog-year-container"
            text={props.yearselected === '' ? date : props.yearselected}
            scrolling
            id='changeYear'
            onChange={onChangeDate}
            defaultValue={props.yearselected === '' ? date : props.yearselected }
            options={props.yearCat}/>
          {/* <i style={{ fontSize: '9px', marginLeft: '-14px', fontStyle: 'normal',marginTop:'5px' }}>▼</i> */}
        </Row>
      </div>
    </>
  );
}

export default CatYears;