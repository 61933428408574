import React from 'react';
import { Redirect } from 'react-router';

function RoldanProblemMenu(props) {
  const [option, setOption] = React.useState(-1);

  const selectRedirect = () => {

    const op = props.previousStep.value[0];

    if (op === 'Necesito cambiar mi contraseña') setOption(0);
    if (op === 'No encuentro mi operación') setOption(1);
    if (op === 'No puedo ingresar desde la aplicación móvil') setOption(2);
  };

  React.useEffect(() => {
    selectRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {option !== -1 ? (
        <Redirect
          to={{
            pathname: '/faqs',
            state: { errorType: option },
          }}
        />
      ) : (
        ''
      )}
      Le hemos redireccionado al módulo “Soporte” en el apartado “Preguntas Frecuentes”, donde encontrará la información detallada sobre el problema que presenta.
    </>
  );
}

export default RoldanProblemMenu;
