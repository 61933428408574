export const leftTableColumns = [
  {
    dataIndex: 'label',
    title: 'Nombre',
  },
];
export const rightTableColumns = [
  {
    dataIndex: 'label',
    title: 'Nombre'
  },
];