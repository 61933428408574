import React from 'react';
import { Grid } from 'semantic-ui-react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import ColFeeds from './components/ScrollFeeds';
import { Col, Row } from 'reactstrap';

function RSSFeeds() {
  // ? Scroll with ref hook
  // ---------------------------------------------------------
  // const observer = useRef();
  // const lastBookElementRef = useCallback(node => {
  //   if (loading) return;
  //   if (observer.current) observer.current.disconnect();
  //   observer.current = new IntersectionObserver(entries => {
  //     // if (entries[0].isIntersecting && hasMore) {
  //     if (entries[0].isIntersecting && hasMore) {
  //       setPageNumber(prevPageNumber => prevPageNumber + 1);
  //     }

  //   });
  //   if (node) observer.current.observe(node);
  // }, [loading, hasMore]);
  // ---------------------------------------------------------

  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend className="legendTitle">
          <i className='fas fa-1x mr-2 icon-feed' />
          RSS Feeds
        </legend>
      </div>
      <Col xs={12} md={12} lg={12}>
        <Row>
          <Col xs={12} md={12} lg={6} >
            <ColFeeds apiPath='https://roldanapirss.azurewebsites.net/api/v1/rss-feed/colombia' title='Colombia' />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <ColFeeds apiPath='https://roldanapirss.azurewebsites.net/api/v1/rss-feed/internacional' title='Internacional' />
          </Col>
        </Row>
      </Col>
      {/* <Grid conatiner divided padded='horizontally'>
        <Grid.Row>
          <Grid.Column width={8}>
            <ColFeeds apiPath='https://roldanapirss.azurewebsites.net/api/v1/rss-feed/colombia' title='Colombia' />
          </Grid.Column>
          <Grid.Column width={8}>
            <ColFeeds apiPath='https://roldanapirss.azurewebsites.net/api/v1/rss-feed/internacional' title='Internacional' />
          </Grid.Column>
        </Grid.Row>
      </Grid> */}
    </ContentWrapper>
  );
}

export default RSSFeeds;