import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import ContentWrapper from '../../template/Layout/ContentWrapper';
import Table from '../Components/Table';
import CircleButton from '../Components/CircleButton';
import Notification from '../Components/Alert';
import { columns } from './config';
import { Services } from './Services';

function Groups(props){
  const [Groups, setGroups] = React.useState([]);
  const { _user } = props;

  const GetGroups = React.useCallback(async() => {
    try {
      const GroupsResp = await axios.get(`${Services.REPORTS.path}/groups/list`);
      setGroups(GroupsResp.data.groups);
    }catch(error){
      console.error('GetGroups -- error ', error);
      Notification(
        'Error',
        'Ha ocurrido un error al intentar consultar el listado de grupos',
        'error'
      );
    }
  }, []);

  const OnDeleteGroup = React.useCallback(async(id) => {
    try{
      const body = {
        user: _user,
        group: id
      };
      // const delGroup = 
      await axios.post(`${Services.REPORTS.path}/groups/delete/`, body);
      Notification(
        'Eliminado',
        'El grupo ha sido eliminado exitosamente!',
        'success'
      );
      GetGroups();
    }catch(error){
      console.error('OnDeleteGroup -- error -- ', error);
      Notification(
        'Error',
        'Ha ocurrido un error al intentar eliminar el grupo',
        'error'
      );
    }
  }, [_user, GetGroups]);

  React.useEffect(() => {
    GetGroups();
  }, [GetGroups]);

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div className="row">
          <em className="fas fa-1x mr-2 fas fa-table" />
          Grupos
        </div>
      </div>
      <div className='col-md-12 d-flex justify-content-start align-items-center mb-4'>
        <CircleButton onClick={() => props.history.push('/group/create')}/>
        <p className='ml-2'>Nuevo grupo</p>
      </div>
      <div className='col-md-12 mt-2'>
        <Table
          data={Groups}
          columns={columns(props.history, OnDeleteGroup)}
          options={{
            SelectZone: false,
            // catalog:this.state.catalog,
            downloads: {
              pdf: false,
              xlsx: true,
            },
            pagination: true,
            search: true           
          }}
        />
      </div>
    </ContentWrapper>
  );
}

export default withRouter(Groups);