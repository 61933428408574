import React from "react";
import "./styles.scss";

function BiOperativo() {
  const ref = React.useRef(null);

  React.useEffect(() => {
  }, []);

  return (
    <React.Fragment>
      <div className="embed-container">
        <iframe
          src="https://bilogistico.roldanlogistica.com/jasperserver-pro/login.html"
          frameBorder="0"
          allowFullScreen
          onLoad={() => {}}
          onError={() => {}}
        ></iframe>
      </div>
    </React.Fragment>
  );
}

export default BiOperativo;
