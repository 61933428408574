export const Services = {
   "SetAutenticationrecover": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "changePassword": {
      "path": "https://roldanapiadmin.azurewebsites.net",
      "method": "",
      "descrp": ""
   }
}