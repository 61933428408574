export const init = () => {
  return {
    report_type: 'ondemand',
    report_name: { value: '' , valid: true},
    report_description: { value: '', valid: true},
    share_to: [],
    // ? Filters
    filters: [],
    filters_data: [],
    // ? Fields
    module_selected: null,
    modules: [],
    level_info: [],
    selected_fields: [],
    // ? Order
    order_fields: [],
    // ? Scheduling
    config: {
      frequency: 'Daily',
      Daily: Daily(),
      Monthly: Montly(),
      startDate: {value: null, dateString: undefined},
      endDate: {value: null, dateString: undefined},
      hour: {value: null, timeString: undefined},
      day: 0,
      timeZone: createOffset(new Date()),
      timeZoneValue: createOffsetValue(new Date()),
      timeZoneStr: DetectTimeZone()
    },
    emailList: [],
    onEdit: false
  };
};

export const Daily = () => {
  return {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false    
  };
};

export const Montly = () => {
  return  {
    January: false,
    February: false,
    March: false,
    April: false,
    May: false,
    June: false,
    July: false,
    August: false,
    September: false,
    October: false,
    November: false,
    December: false    
  };
};

function pad(value) {
  return value < 10 ? '0' + value : value;
}

function createOffset(date) {
  var sign = (date.getTimezoneOffset() > 0) ? '-' : '+';
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = pad(Math.floor(offset / 60));
  var minutes = pad(offset % 60);
  return sign + hours + ':' + minutes;
}

function createOffsetValue(date) {
  var sign = (date.getTimezoneOffset() > 0) ? '-' : '+';
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = Math.floor(offset / 60);
  var minutes = (offset % 60) / 60 * 10;
  return sign + hours + '.' + minutes;
}
/**
 * Timezone detection
 */
function DetectTimeZone(){
  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
  return resolvedOptions.timeZone;
}