const cardResumen = [
    {
      label: 'Información del Contenedor',
      data: [
        {
        //   value: 'NoAduana',
        //   key: '',
        //   label: 'Número de Do Aduana IMPO/EXPO:',
        //   bold: false,
        //   info: false
        // },
        // {
        //   value: 'RefForwarder',
        //   key: '',
        //   primary: true,
        //   label: 'Número de Do Referencia Forwarder',
        //   bold: false,
        //   info: false
        // },
        // {
          value: '',
          key: 'NoContenedor',
          label: 'No de Contenedor',
          bold: false,
          info: false
        },
        {
          value: '',
          key: 'DoEmbarque',
          label: 'DO de Embarque',
          bold: false,
          info: false,
          link:'/shipmentsDetal/',
          idlink:'EmbarqueId',
          value2:''
        },
        {
          value: '',
          key: 'Precinto',
          label: 'Precinto',
          bold: false,
          info: false
        },
        {
          value: '',
          key: 'Temperatura',
          label: 'Temperatura',
          bold: false,
          info: false
        },
        {
          value: '',
          key: 'Ventilación',
          label: 'Ventilación',
          bold: false
        },
        {
          value: '',
          key: 'Peso',
          label: 'Peso',
          bold: false
        },
        {
          value: '',
          key: 'TipoContenedor',
          label: 'Tipo de Contenedor',
          bold: false
        },
        {
          value: '',
          key: 'Volumen',
          label: 'Volumen',
          bold: false
        },
      ]
    },
    {
      label: 'General',
      data: [
        {
          value: '',
          key: 'DGR',
          label: 'DGR',
          bold: false
        },
        {
          value: '',
          key: 'IMPO',
          label: 'IMPO',
          bold: false
        },
        {
          value: '',
          key: 'Commodity',
          label: 'Commodity',
          bold: false
        },
        {
          value: '',
          key: 'ATA',
          label: 'ATA',
          bold: false
        },
        {
          value: '',
          key: 'ETA',
          label: 'ETA',
          bold: false
        },
        // {
        //   value: '',
        //   key: 'Vessel',
        //   label: 'Vessel',
        //   bold: false
        // },
        {
          value: '',
          key: 'Consignatario',
          label: 'Consignatario',
          bold: false
        }]
    },
    {
      label: 'Características de la Carga',
      data: [
        {
          value: '',
          key: 'Piezas',
          label: 'Piezas',
          primary: false,
          otro: false
        },
        {
          value: '',
          key: 'Dimensiones',
          label: 'Dimensiones',
          primary: false,
          otro: false
        },
        {
          value: '',
          key: 'grade',
          label: 'Grado del Contenedor',
          primary: false,
          otro: false
        },
        {
          value: '',
          key: 'DescripcionCarga',
          label: 'Descripción de la carga',
          primary: false,
          otro: false
        },
        // {
        //   value: '',
        //   key: 'Observaciones',
        //   label: 'Observación del Contenedor',
        //   primary: false,
        //   otro: true,
        // },
      ]
    }  ]

export default cardResumen