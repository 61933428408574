
export const UnidadesMetricas = {
    masa: [
        { key: "oz", value: "oz", label: "Onza", um: 'Masa' },
        { key: "g", value: "g", label: "Gramo", um: 'Masa' },
        { key: "kg", value: "kg", label: "Kilogramo", um: 'Masa' },
        { key: "lb", value: "lb", label: "Libra", um: 'Masa' },
        { key: "shortton", value: "short ton", label: "Tonelada Corta (EEUU)", um: 'Masa' },
        { key: "T", value: "T", label: "Tonelada Métrica", um: 'Masa' },
    ],
    longitud: [
        { key: "m", value: "m", label: "Metro", um: 'Longitud'  },
        { key: "mm", value: "mm", label: "Milímetro", um: 'Longitud'  },
        { key: "km", value: "km", label: "Kilometro", um: 'Longitud'  },
        { key: "in", value: "in", label: "Pulgada", um: 'Longitud' },
        { key: "ft", value: "ft", label: "Pie", um: 'Longitud' },
        { key: "yd", value: "yd", label: "Yarda", um: 'Longitud' },
        { key: "mi", value: "mi", label: "Milla de Estatuto", um: 'Longitud' },
        { key: "min", value: "min", label: "Milla Náutica", um: 'Longitud' },
    ],
    area: [
        { key: "km2", value: "km2", label: "Kilometro Cuadrado", um: 'Area' },
        { key: "m2", value: "m2", label: "Metro Cuadrado", um: 'Area' },
        { key: "yd2", value: "yd2", label: "Yarda Cuadrada", um: 'Area' },
        { key: "ft2", value: "ft2", label: "Pie Cuadrado", um: 'Area' },
        { key: "in2", value: "in2", label: "Pulgada Cuadrada", um: 'Area' },
        { key: "ha", value: "ha", label: "Héctarea", um: 'Area' },
        { key: "acre", value: "acre", label: "Acre", um: 'Area' },
    ],
    volumen: [
        { key: "l", value: "l", label: "Litro", um: 'Volumen' },
        { key: "ml", value: "ml", label: "Milílitro", um: 'Volumen' },
        { key: "m3", value: "m3", label: "Metro Cubico", um: 'Volumen' },
        { key: "in3", value: "in3", label: "Pulgada Cubica", um: 'Volumen' },
        { key: "ft3", value: "ft3", label: "Pie Cubico", um: 'Volumen' },
        //{ key: "yd3", value: "yd3", label: "Yarda Cubica", um: 'Volumen' },
        { key: "room", value: "room", label: "Cuarto (EEUU)", um: 'Volumen' },
        { key: "TT", value: "TT", label: "Galón (EEUU)", um: 'Volumen' },
        { key: "T", value: "T", label: "Galón", um: 'Volumen' },
    ],
};
  