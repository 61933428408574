import React, { Component, Suspense } from 'react';
import ContentWrapper from '../../../../../template/Layout/ContentWrapper';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import '../../../../../styles/customStyle.scss';
import Comments from '../../../../Components/CommenInteger';
import '../styles.scss';
import { CloseIcon } from './config';
import axios from 'axios';
import { Services } from '../../../Services';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { validateIdParams } from '../../../../../utils/regularExpressionValidation';
import Errors from '../../../../Components/Errors';

const StepperContainer = React.lazy(() =>
  import('../../../../Components/StepperContainer')
);

const status_ = {
  AEREA: 'https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png',
  MARITIMA: 'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png',
  OTM: 'https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png',
  TERRESTRE: 'https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png',
  AEREO: 'https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png',
  MARITIMO: 'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png',
};

class IntegratedDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customWidth: window.innerWidth,
      stepsEmbarque: [
        {
          label: 'Destino Final',
          value: '',
          id: 23,
          completed: false,
          order: 6,
        },
        {
          label: 'Salida de Puerto',
          value: '',
          id: 22,
          completed: false,
          order: 5,
        },
        {
          label: 'ATA',
          value: '',
          id: 11,
          completed: false,
          order: 4,
        },
        {
          label: 'ATD',
          value: '',
          id: 9,
          completed: false,
          order: 3,
        },
        {
          label: 'Recolección / Recepción',
          value: '',
          id: 4,
          completed: false,
          order: 2,
        },
        {
          label: 'Carga Lista',
          value: '',
          id: 3,
          completed: false,
          order: 1,
        },
      ],
      stepsImport: [
        {
          label: 'Instrucción Cliente AA',
          value: '',
          id: 3,
          completed: false,
          order: 1,
          middleStep: '',
        },
        {
          label: 'ATA (Manifiesto)',
          value: '',
          id: 5,
          completed: false,
          order: 2,
          idStep: 'step2',
          middleStep: '',
        },
        {
          label: 'Ingreso Depósito',
          value: '',
          id: 7,
          completed: false,
          order: 3,
          middle: false,
          idStep: 'step3',
          middleStep: '',
        },
        {
          label: 'Levante',
          value: '',
          id: 12,
          completed: false,
          order: 4,
          idStep: 'step4',
          middleStep: '',
        },
        {
          label: 'Despacho',
          value: '',
          id: 14,
          completed: false,
          order: 5,
          idStep: 'step5',
          middleStep: '',
        },
        {
          label: 'Radicación de Factura',
          value: '',
          id: 16,
          completed: false,
          order: 6,
          idStep: 'step6',
          middleStep: '',
        },
      ],
      stepsDtas: [
        {
          label: 'Instrucción Cliente AA',
          value: '',
          id: 3,
          completed: false,
          order: 1,
          middleStep: '',
        },
        {
          label: 'ATA (Manifiesto)',
          value: '',
          id: 5,
          completed: false,
          order: 2,
          idStep: '',
          middleStep: '',
        },
        {
          label: 'Aceptación Solicitud DTA',
          value: '',
          id: 19,
          completed: false,
          order: 3,
          middle: false,
          idStep: '',
          middleStep: '',
        },
        {
          label: 'Autorización Inspección',
          value: '',
          id: 20,
          completed: false,
          order: 4,
          idStep: 'step4',
          middleStep: '',
        },
        {
          label: 'Salida DTA',
          value: '',
          id: 21,
          completed: false,
          order: 5,
          idStep: 'step5',
          middleStep: '',
        },
        {
          label: 'Finalización DTA',
          value: '',
          id: 22,
          completed: false,
          order: 6,
          idStep: 'step6',
          middleStep: '',
        },
        {
          label: 'Radicación de Factura',
          value: '',
          id: 16,
          completed: false,
          order: 7,
          idStep: 'step7',
          middleStep: '',
        },
      ],
      steps: [
        {
          icon:
            'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png',
          classes: 'class1',
          card: {
            title: 'Embarque',
            progress: {
              // title: '25 días - Finalizado',
              progress: 'finish',
            },
            steps: [
              {
                label: 'Destino Final',
                value: '',
                id: 23,
                completed: false,
                order: 1,
              },
              {
                label: 'Salida de Puerto',
                value: '',
                id: 22,
                completed: false,
                order: 2,
              },
              {
                label: 'ATA',
                value: '',
                id: 11,
                completed: false,
                order: 3,
              },
              {
                label: 'ATD',
                value: '',
                id: 9,
                completed: false,
                order: 4,
              },
              {
                label: 'Recolección / Recepción',
                value: '',
                id: 4,
                completed: false,
                order: 5,
              },
              {
                label: 'Carga Lista',
                value: '',
                id: 1,
                completed: false,
                order: 6,
              },
            ],
            data: [
              {
                title: 'DO de Embarque',
                value: '',
                primary: true,
              },
              {
                title: 'Proveedor',
                value: '',
                primary: false,
              },
              {
                title: 'Modo de Transporte',
                value: '',
                primary: false,
              },
              {
                title: 'Agente de Carga',
                value: '',
                primary: false,
              },
              {
                title: 'Carrier',
                value: '',
                primary: false,
              },
              {
                title: 'ETD',
                value: '',
                primary: false,
              },
              {
                title: 'ETA',
                value: '',
                primary: false,
              },
              {
                title: 'POL',
                value: '',
                primary: false,
              },
              {
                title: 'POD',
                value: '',
                primary: false,
              },
            ],
            comments: [],
          },
        },
        {
          icon:
            'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13.png',
          classes: 'class1',
          card: {
            title: 'Depósito Habilitado',
            progress: {
              // title: '0 días - No iniciado',
              progress: 'notStarted',
            },
            steps: [
              {
                label: 'Disponibilidad de Inventarios',
                value: '',
                id: 31,
                completed: false,
                order: 1,
              },
              {
                label: 'Vencimiento de Manifiesto',
                value: '',
                id: 34,
                completed: false,
                order: 2,
              },
              {
                label: 'Manifiesto (Público)',
                value: '',
                id: 62,
                completed: false,
                order: 3,
              },
              {
                label: 'Fin de Descargue',
                value: '',
                id: 91,
                completed: false,
                order: 4,
              },
              {
                label: 'Inicio de Descargue',
                value: '',
                id: 97,
                completed: false,
                order: 5,
              },
              {
                label: 'Llegada a Almacén',
                value: '',
                id: 98,
                completed: false,
                order: 6,
              },
              {
                label: 'Notificación de Ingreso',
                value: '',
                id: 98,
                completed: false,
                order: 6,
              },
            ],
            data: [
              {
                title: 'No de Ingreso',
                value: '',
                data: [
                  {
                    label: 'N098',
                    value: 'N098',
                  },
                  {
                    label: 'N099',
                    value: 'N099',
                  },
                  {
                    label: 'N100',
                    value: 'N100',
                  },
                ],
                primary: false,
                select: true,
              },
              {
                title: 'Nombre del Almacén',
                value: '',
                primary: false,
              },
              {
                title: 'Tipo de Vehículo',
                value: '',
                primary: false,
              },
              {
                title: 'Fecha de Llegada',
                value: '',
                primary: false,
              },
              {
                title: 'Cantidades Recibidas',
                value: '',
                primary: false,
              },
            ],
            comments: [],
          },
        },
        {
          icon:
            'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png',
          classes: 'class2',
          card: {
            title: 'Aduana',
            progress: {
              // title: '10 días - En Proceso',
              progress: 'process',
            },
            steps: [
              {
                label: 'Instrucción Cliente AA',
                value: '',
                id: 3,
                completed: false,
                order: 1,
                middleStep: '',
              },
              {
                label: 'ATA (Manifiesto)',
                value: '',
                id: 5,
                completed: false,
                order: 2,
                idStep: 'step2',
                middleStep: '',
              },
              {
                label: 'Ingreso Depósito',
                value: '',
                id: 7,
                completed: false,
                order: 3,
                middle: false,
                idStep: 'step3',
                middleStep: '',
              },
              {
                label: 'Levante',
                value: '',
                id: 12,
                completed: false,
                order: 4,
                idStep: 'step4',
                middleStep: '',
              },
              {
                label: 'Despacho',
                value: '',
                id: 14,
                completed: false,
                order: 5,
                idStep: 'step5',
                middleStep: '',
              },
              {
                label: 'Radicación de Factura',
                value: '',
                id: 16,
                completed: false,
                order: 6,
                idStep: 'step6',
                middleStep: '',
              },
            ],
            data: [
              {
                title: 'DO de Aduana',
                value: '',
                primary: true,
              },
              {
                title: 'Aduana',
                value: '',
                primary: false,
              },
              {
                title: 'Agente Aduanal',
                value: '',
                primary: false,
              },
              {
                title: 'Fecha de Ingreso',
                value: '',
                primary: false,
              },
              {
                title: 'Fecha Estimada de Levante',
                value: '',
                primary: false,
              },
              {
                title: 'ATA (Manifiesto)',
                value: '',
                primary: false,
              },
              {
                title: 'Total de Declaraciones',
                value: '',
                primary: false,
              },
            ],
            comments: [],
          },
        },
        {
          icon:
            'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13.png',
          classes: 'class1',
          card: {
            title: 'Almacén',
            progress: {
              // title: '0 días - No iniciado',
              progress: 'notStarted',
            },
            steps: [
              {
                label: 'Fecha de facturación y entrega de documentos',
                value: '29/06/2017',
                id: 31,
                completed: false,
                order: 1,
              },
              {
                label: 'Fecha de manifiesto de carga',
                value: '22/06/2017',
                id: 34,
                completed: false,
                order: 2,
              },
              {
                label: 'Fecha de cierre físico',
                value: '13/06/2017',
                id: 62,
                completed: false,
                order: 3,
              },
              {
                label: 'Fecha de autorización de Embarque',
                value: '29/05/2017',
                id: 91,
                completed: false,
                order: 4,
              },
              {
                label: 'Fecha de Instrucción Cliente AA (Reserva)',
                value: '',
                id: 97,
                completed: false,
                order: 5,
              },
            ],
            data: [
              {
                title: 'No de Ingreso',
                value: '',
                data: [
                  {
                    label: 'N098',
                    value: 'N098',
                  },
                  {
                    label: 'N099',
                    value: 'N099',
                  },
                  {
                    label: 'N100',
                    value: 'N100',
                  },
                ],
                primary: false,
                select: true,
              },
              {
                title: 'Nombre del Almacén',
                value: '',
                primary: false,
              },
              {
                title: 'Tipo de Vehículo',
                value: '',
                primary: false,
              },
              {
                title: 'Fecha de Llegada',
                value: '',
                primary: false,
              },
              {
                title: 'Cantidades Recibidas',
                value: '',
                primary: false,
              },
            ],
            comments: [],
          },
        },
      ],
      stepsDepoHab: [
        {
          label: 'Notificación de Ingreso',
          value: '',
          id: 1,
          completed: false,
          order: 1,
          middleStep: '',
        },
        {
          label: 'Llegada a Almacén',
          value: '',
          id: 2,
          completed: false,
          order: 2,
          idStep: 'step2',
          middleStep: '',
        },
        {
          label: 'Inicio de Descargue',
          value: '',
          id: 3,
          completed: false,
          order: 3,
          idStep: 'step3',
          middleStep: '',
        },
        {
          label: 'Fin de Descargue',
          value: '',
          id: 4,
          completed: false,
          order: 4,
          idStep: 'step4',
          middleStep: '',
        },
        {
          label: 'Manifiesto (Público)',
          value: '',
          id: 6,
          completed: false,
          order: 5,
          idStep: 'step5',
          middleStep: '',
        },
        {
          label: 'Vencimiento de Manifiesto',
          value: '',
          id: 7,
          completed: false,
          order: 6,
          idStep: 'step6',
          middleStep: '',
        },
        {
          label: 'Disponibilidad de Inventario',
          value: '',
          id: 8,
          completed: false,
          order: 7,
          idStep: 'step6',
          middleStep: '',
        },
      ],
      stepsBodNac: [
        {
          label: 'Notificación de Ingreso',
          value: '',
          id: 1,
          completed: false,
          order: 1,
          middleStep: '',
        },
        {
          label: 'Llegada a Almacén',
          value: '',
          id: 2,
          completed: false,
          order: 2,
          idStep: 'step2',
          middleStep: '',
        },
        {
          label: 'Inicio de Descargue',
          value: '',
          id: 3,
          completed: false,
          order: 3,
          idStep: 'step3',
          middleStep: '',
        },
        {
          label: 'Fin de Descargue',
          value: '',
          id: 4,
          completed: false,
          order: 4,
          idStep: 'step4',
          middleStep: '',
        },
        {
          label: 'Disponibilidad de Inventario',
          value: '',
          id: 8,
          completed: false,
          order: 5,
          idStep: 'step6',
          middleStep: '',
        },
      ],
      stepsZonFra: [
        {
          label: 'Notificación de Ingreso',
          value: '',
          id: 1,
          completed: false,
          order: 1,
          middleStep: '',
        },
        {
          label: 'Llegada a Almacén',
          value: '',
          id: 2,
          completed: false,
          order: 2,
          idStep: 'step2',
          middleStep: '',
        },
        {
          label: 'Inicio de Descargue',
          value: '',
          id: 3,
          completed: false,
          order: 3,
          idStep: 'step3',
          middleStep: '',
        },
        {
          label: 'Fin de Descargue',
          value: '',
          id: 4,
          completed: false,
          order: 4,
          idStep: 'step4',
          middleStep: '',
        },
        {
          label: 'Devolución de Contenedor',
          value: '',
          id: 5,
          completed: false,
          order: 5,
          idStep: 'step6',
          middleStep: '',
        },
        {
          label: 'Disponibilidad de Inventario',
          value: '',
          id: 8,
          completed: false,
          order: 6,
          idStep: 'step6',
          middleStep: '',
        },
      ],
      id: props.match.params.id,
      openComments: false,
      catalogsData: [
        {
          ComTypeId: 1,
          ModuleId: 1,
          ComType: 'Supplier Follow Up',
          value: 1,
          label: 'Supplier Follow Up',
        },
        {
          ComTypeId: 2,
          ModuleId: 1,
          ComType: 'Order Quality',
          value: 2,
          label: 'Order Quality',
        },
        {
          ComTypeId: 3,
          ModuleId: 1,
          ComType: 'Order Quantities',
          value: 3,
          label: 'Order Quantities',
        },
        {
          ComTypeId: 10,
          ModuleId: 1,
          ComType: 'Other',
          value: 10,
          label: 'Other',
        },
      ],
      commentsData: [
        {
          ComType: 'Tipo 1',
          ComTypeId: 1,
          Comment: 'Comentario de prueba',
          CommentId: 73,
          Date: '2019-06-20',
          Module: 'Comentario',
          ModuleId: 1,
          RowId: 861,
          UpdateBy: 'API',
        },
        {
          ComType: 'Tipo 2',
          ComTypeId: 1,
          Comment: 'Comentario de prueba',
          CommentId: 73,
          Date: '2019-07-02',
          Module: 'Comentario',
          ModuleId: 1,
          RowId: 861,
          UpdateBy: 'API',
        },
      ],
      embarque: '',
      integrado: {},
      error: {
        open: false,
        message: "",
        type: "admin",
      },
    };
  }

  handleResize = () => {
    this.setState({ customWidth: window.innerWidth });
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  componentDidMount = () => {
    this.getIntegredForId();
  };

  handleClose = () => {
    this.setState({ openComments: false });
  };

  openCommt = (value, id) => {
    this.setState({ openComments: true, commentsData: value, id });
  };

  getIntegredForId = () => {
    let { id } = this.state
    let idValidate = validateIdParams(id);

    if (idValidate) {
      axios
        .get(
          `${Services.Get_Traceability_Integred_ForId.path}/integrated/IntegratedForId/${this.state.id}`
        )
        .then((success) => {
          let commentsDataEbarque = [];
          let commentsDataDepHab = [];
          let commentsDataAduna = [];
          let commentsDataAlmacen = [];
          let ElementData = success.data.data;
          let dataSelectAlmacen = [];
          if (Object.entries(ElementData.dataAlmace).length === 0) {
          }

          let dataSelectDepositoHabilitado = [];
          if (ElementData.DataDepositoHabilitado.events) {
            if (ElementData.DataDepositoHabilitado.events.length > 0) {
              let update = false;
              for (
                let index = ElementData.DataDepositoHabilitado.events.length;
                index >= 0;
                index--
              ) {
                const element = ElementData.DataDepositoHabilitado.events[index];
                if (element && element.completed) update = true;
                if (update) element.completed = true;
              }
              var newStepsDepHab =
                ElementData.DataDepositoHabilitado.typeEvents === 1
                  ? this.state.stepsBodNac
                  : ElementData.DataDepositoHabilitado.typeEvents === 2
                    ? this.state.stepsZonFra
                    : this.state.stepsDepoHab;
              let dataEvents = ElementData.DataDepositoHabilitado.events;
              var findNext = false;
              newStepsDepHab.map((f) => {
                dataEvents.map((e) => {
                  if (e.EventDescriptionId === f.id) {
                    findNext = true;
                    f.value = e.EventDate;
                    if (e.InTimeLine === '1') {
                      f.completed = true;
                    } else {
                      findNext = false;
                    }
                  } else {
                    findNext = false;
                  }
                  if (findNext) {
                    f.completed = true;
                  }
                  return e;
                });
                return f;
              });
              newStepsDepHab.sort(function (a, b) {
                return b.order - a.order;
              });
              let findTrue = false;
              newStepsDepHab.map((e) => {
                if (e.completed) {
                  findTrue = true;
                }
                if (findTrue) {
                  e.completed = true;
                }
                return e;
              });
              // newStepsEmbarque.sort(function(a, b) {return b.order - a.order});
            }
          }

          if (ElementData.dataAlmace.events) {
            if (ElementData.dataAlmace.events.length > 0) {
              let update = false;
              for (
                let index = ElementData.dataAlmace.events.length;
                index >= 0;
                index--
              ) {
                const element = ElementData.dataAlmace.events[index];
                if (element && element.completed) update = true;
                if (update) element.completed = true;
              }
              var newStepsAlmacen = (ElementData.dataAlmace.typeEvents = 1
                ? this.state.stepsBodNac
                : (ElementData.DataDepositoHabilitado.typeEvents = 2
                  ? this.state.stepsZonFra
                  : this.state.stepsDepoHab));
              let dataEvents = ElementData.dataAlmace.events;
              let findNext = false;
              newStepsAlmacen.map((f) => {
                dataEvents.map((e) => {
                  if (e.EventDescriptionId === f.id) {
                    findNext = true;
                    f.value = e.EventDate;
                    if (e.InTimeLine === '1') {
                      f.completed = true;
                    } else {
                      findNext = false;
                    }
                  } else {
                    findNext = false;
                  }
                  if (findNext) {
                    f.completed = true;
                  }
                  return e;
                });
                return f;
              });
              newStepsAlmacen.sort(function (a, b) {
                return b.order - a.order;
              });
              let findTrue = false;
              newStepsAlmacen.map((e) => {
                if (e.completed) {
                  findTrue = true;
                }
                if (findTrue) {
                  e.completed = true;
                }
                return e;
              });
              // newStepsEmbarque.sort(function(a, b) {return b.order - a.order});
            }
          }

          if (ElementData.Aduana.events) {
            if (ElementData.Aduana.events.length > 0) {
              let update = false;
              for (
                let index = ElementData.Aduana.events.length;
                index >= 0;
                index--
              ) {
                const element = ElementData.Aduana.events[index];
                if (element && element.completed) update = true;
                if (update) element.completed = true;
              }
              var newStepsAduana = ElementData.Aduana.dta
                ? this.state.stepsDtas
                : this.state.stepsImport;
              let dataEvents = ElementData.Aduana.events;
              let findNext = false;
              newStepsAduana.map((f) => {
                dataEvents.map((e) => {
                  if (e.EventDescriptionId === f.id) {
                    findNext = true;
                    f.value = e.EventDate;
                    if (e.InTimeLine === '1') {
                      f.completed = true;
                    } else {
                      findNext = false;
                    }
                  } else {
                    findNext = false;
                  }
                  if (findNext) {
                    f.completed = true;
                  }
                  return e;
                });
                return f;
              });
              newStepsAduana.sort(function (a, b) {
                return b.order - a.order;
              });
              let findTrue = false;
              newStepsAduana.map((e) => {
                if (e.completed) {
                  findTrue = true;
                }
                if (findTrue) {
                  e.completed = true;
                }
                return e;
              });
            }
          }

          if (ElementData.infoEmbarque.events) {
            if (ElementData.infoEmbarque.events.length > 0) {
              let update = false;
              for (
                let index = ElementData.infoEmbarque.events.length;
                index >= 0;
                index--
              ) {
                const element = ElementData.infoEmbarque.events[index];
                if (element && element.completed) update = true;
                if (update) element.completed = true;
              }
              var newStepsEmbarque = this.state.stepsEmbarque;
              let dataEvents = ElementData.infoEmbarque.events;
              let findNext = false;
              newStepsEmbarque.map((f) => {
                dataEvents.map((e) => {
                  if (e.EventDescriptionId === f.id) {
                    findNext = true;
                    f.value = e.EventDate;
                    if (e.InTimeLine === '1') {
                      f.completed = true;
                    } else {
                      findNext = false;
                    }
                  } else {
                    findNext = false;
                  }
                  if (findNext) {
                    f.completed = true;
                  }
                  return e;
                });
                return f;
              });
              newStepsEmbarque.sort(function (a, b) {
                return b.order - a.order;
              });
              let findTrue = false;
              newStepsEmbarque.map((e) => {
                if (e.completed) {
                  findTrue = true;
                }
                if (findTrue) {
                  e.completed = true;
                }
                return e;
              });
            }
          }

          if (ElementData.infoEmbarque.infoEmbarque) {
            let commetEmbarque = {
              ComType: '',
              ComTypeId: 1,
              Comment: ElementData.infoEmbarque.infoEmbarque.Observaciones,
              CommentId: '',
              Date: ElementData.infoEmbarque.infoEmbarque.ETA,
              Module: 'Comentario',
              ModuleId: 1,
              RowId: '',
              UpdateBy: 'API',
            };
            commentsDataEbarque.push(commetEmbarque);
          }

          if (ElementData.DataDepositoHabilitado.DepositoHabi) {
            if (
              ElementData.DataDepositoHabilitado.DepositoHabi
                .ObservacionesRecepcion
            ) {
              let commetEmbarque = {
                ComType: '',
                ComTypeId: 1,
                Comment:
                  ElementData.DataDepositoHabilitado.DepositoHabi
                    .ObservacionesRecepcion,
                CommentId: '',
                Date:
                  ElementData.DataDepositoHabilitado.DepositoHabi
                    .FechaNotificacionIngreso,
                Module: 'Comentario',
                ModuleId: 1,
                RowId: '',
                UpdateBy: 'API',
              };
              commentsDataDepHab.push(commetEmbarque);
            }
          }

          if (ElementData.Aduana.Aduana) {
            if (ElementData.Aduana.Aduana.Comentarios) {
              let commetEmbarque = {
                ComType: '',
                ComTypeId: 1,
                Comment: ElementData.Aduana.Aduana.Comentarios,
                CommentId: '',
                Date: ElementData.Aduana.Aduana.FechaLevante,
                Module: 'Comentario',
                ModuleId: 1,
                RowId: '',
                UpdateBy: 'API',
              };
              commentsDataAduna.push(commetEmbarque);
            }
          }

          if (ElementData.dataAlmace.Almacen) {
            if (ElementData.dataAlmace.Almacen.ObservacionesRecepcion) {
              let commetEmbarque = {
                ComType: '',
                ComTypeId: 1,
                Comment: ElementData.dataAlmace.Almacen.ObservacionesRecepcion,
                CommentId: '',
                Date: ElementData.dataAlmace.Almacen.FechaNotificacionIngreso,
                Module: 'Comentario',
                ModuleId: 1,
                RowId: '',
                UpdateBy: 'API',
              };
              commentsDataAlmacen.push(commetEmbarque);
            }
          }
          if (ElementData.Almacen.length > 1) {
            ElementData.Almacen.map((e) => {
              dataSelectAlmacen.push({
                label: e,
                value: e,
              });
              return e;
            });
          } else {
            dataSelectAlmacen = [];
          }
          if (ElementData.DepositoHabilitado.length > 1) {
            ElementData.DepositoHabilitado.map((e) => {
              dataSelectDepositoHabilitado.push({
                label: e,
                value: e,
              });
              return e;
            });
          } else {
            dataSelectDepositoHabilitado = [];
          }

          let steps = [
            {
              icon:
                Object.entries(ElementData.infoEmbarque.infoEmbarque).length === 0
                  ? 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-01gris.png'
                  : status_[ElementData.infoEmbarque.infoEmbarque.ModoTransporte]
                    ? status_[ElementData.infoEmbarque.infoEmbarque.ModoTransporte]
                    : 'https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png',
              classes: 'class2',
              completed:
                Object.entries(ElementData.infoEmbarque.infoEmbarque).length === 0
                  ? false
                  : true,
              card: {
                link: '/shipmentsDetal/',
                title: 'Embarque',
                id:
                  Object.entries(ElementData.infoEmbarque.infoEmbarque).length ===
                    0
                    ? ''
                    : ElementData.infoEmbarque.infoEmbarque.EmbarqueId,
                progress: {
                  // title: '25 días - Finalizado',
                  progress: ElementData.infoEmbarque ? 'finish' : 'notStarted',
                },
                steps:
                  ElementData.infoEmbarque.events.length > 0
                    ? newStepsEmbarque
                    : this.state.stepsEmbarque,
                data: [
                  {
                    title: 'DO de Embarque',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.DoEmbarque
                      : '',
                    primary: true,
                  },
                  {
                    title: 'Proveedor',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.Proveedor
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Modo de Transporte',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.ModoTransporte
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Agente de Carga',
                    value: ElementData.infoEmbarque ? 'GLODEX' : '',
                    primary: false,
                  },
                  {
                    title: 'Carrier',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.Carrier
                      : '',
                    primary: false,
                  },
                  {
                    title: 'ETD',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.ETD
                      : '',
                    primary: false,
                  },
                  {
                    title: 'ETA',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.ETA
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Origen',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.POL
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Destino',
                    value: ElementData.infoEmbarque
                      ? ElementData.infoEmbarque.infoEmbarque.POD
                      : '',
                    primary: false,
                  },
                ],
                comments: commentsDataEbarque ? commentsDataEbarque : [],
                // noSolicitud: "12345678,234343334,56565,232122,0676076, DRT676"
                noSolicitud: ElementData.infoEmbarque.infoEmbarque.NoSolicitud
              },
            },
            {
              icon:
                Object.entries(ElementData.DataDepositoHabilitado).length === 0
                  ? 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13gris.png'
                  : 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13.png',
              classes: 'class2',
              completed:
                Object.entries(ElementData.DataDepositoHabilitado).length === 0
                  ? false
                  : true,
              card: {
                link: '/storage-entries-detail/',
                id:
                  Object.entries(ElementData.DepositoHabilitado).length === 0
                    ? ''
                    : ElementData.DataDepositoHabilitado.DepositoHabi.IngresoId,
                title: 'Depósito Habilitado',
                progress: {
                  // title: '0 días - No iniciado',
                  progress: ElementData.DepositoHabilitado
                    ? 'finish'
                    : 'notStarted',
                },
                steps:
                  ElementData.DataDepositoHabilitado.events &&
                    ElementData.DataDepositoHabilitado.events.length > 0
                    ? newStepsDepHab
                    : this.state.stepsDepoHab.sort(function (a, b) {
                      return b.order - a.order;
                    }),
                data: [
                  {
                    title: 'No de Ingreso',
                    value: ElementData.DepositoHabilitado[0],
                    data: dataSelectDepositoHabilitado,
                    primary: false,
                    select:
                      dataSelectDepositoHabilitado.length > 1 ? true : false,
                  },
                  {
                    title: 'Nombre del Almacén',
                    value: ElementData.DepositoHabilitado
                      ? ElementData.DataDepositoHabilitado.DepositoHabi.Almacen
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Tipo de Vehículo',
                    value: ElementData.DepositoHabilitado
                      ? ElementData.DataDepositoHabilitado.DepositoHabi
                        .TipoVehiculo
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Fecha de Llegada',
                    value: ElementData.DepositoHabilitado
                      ? ElementData.DataDepositoHabilitado.DepositoHabi
                        .FechaLlegadaAlmacen
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Cantidades Recibidas',
                    value: ElementData.DepositoHabilitado
                      ? ElementData.DataDepositoHabilitado.DepositoHabi
                        .CantidadesRecibidas
                      : '',
                    primary: false,
                  },
                  {
                    title: ` ${'\u00A0'}`,
                    value: '\u00A0',
                  },
                  {
                    title: ` ${'\u00A0'}`,
                    value: '\u00A0',
                  },
                ],
                comments: commentsDataDepHab ? commentsDataDepHab : [],
              },
            },
            {
              icon: ElementData.Aduana
                ? 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png'
                : 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19gris.png',
              classes: 'class2',
              completed: ElementData.Aduana ? true : false,
              card: {
                link: ElementData.Aduana.dta
                  ? '/dta-detail-view/'
                  : '/custom-detail-view/',
                id:
                  Object.entries(ElementData.Aduana).length === 0
                    ? ''
                    : ElementData.Aduana.Aduana.ImportacionId,
                title: 'Aduana',
                progress: {
                  // title: '10 días - En Proceso',
                  progress: ElementData.Aduana ? 'finish' : 'notStarted',
                },
                steps:
                  ElementData.Aduana.events.length > 0
                    ? newStepsAduana
                    : this.state.stepsImport,
                data: [
                  {
                    title: 'DO de Aduana',
                    value: ElementData.Aduana
                      ? ElementData.Aduana.Aduana.DOAduanaImpo
                      : '',
                    primary: true,
                  },
                  {
                    title: 'Aduana',
                    value: ElementData.Aduana
                      ? ElementData.Aduana.Aduana.Aduana
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Agente Aduanal',
                    value: 'ROLDÁN ADUANA',
                    primary: false,
                  },
                  {
                    title: 'Fecha de Ingreso',
                    value: ElementData.Aduana
                      ? ElementData.Aduana.Aduana.FechaIngresoDeposito
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Fecha Estimada de Levante',
                    value: ElementData.Aduana
                      ? ElementData.Aduana.Aduana.FechaLevante
                      : '',
                    primary: false,
                  },
                  {
                    title: 'ATA (Manifiesto)',
                    value: ElementData.Aduana
                      ? ElementData.Aduana.Aduana.ATA
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Total de Declaraciones',
                    value: ElementData.Aduana
                      ? ElementData.Aduana.Aduana.TotalDeclaraciones
                      : '',
                    primary: false,
                  },
                ],
                comments: commentsDataAduna ? commentsDataAduna : [],
                // noSolicitud: "34348398"
                noSolicitud: ElementData.Aduana.Aduana.NoSolicitud
              },
            },
            {
              icon: ElementData.dataAlmace.Almacen
                ? 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13.png'
                : 'https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-13gris.png',
              classes: 'class2',
              completed: ElementData.dataAlmace.Almacen ? true : false,
              card: {
                link: '/storage-entries-detail/',
                id:
                  Object.entries(ElementData.dataAlmace).length === 0
                    ? ''
                    : ElementData.dataAlmace.Almacen.IngresoId,
                title: 'Almacén',
                progress: {
                  // title: '0 días - No iniciado',
                  progress: ElementData.Aduana ? 'finish' : 'notStarted',
                },
                steps:
                  ElementData.dataAlmace.events &&
                    ElementData.dataAlmace.events.length > 0
                    ? newStepsAlmacen
                    : this.state.stepsZonFra.sort(function (a, b) {
                      return b.order - a.order;
                    }),
                data: [
                  {
                    title: 'No de Ingreso',
                    value: ElementData.Almacen[0],
                    data: dataSelectAlmacen,
                    primary: true,
                    select: dataSelectAlmacen.length > 1 ? true : false,
                  },
                  {
                    title: 'Nombre del Almacén',
                    value: ElementData.dataAlmace.Almacen
                      ? ElementData.dataAlmace.Almacen.Almacen
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Tipo de Vehículo',
                    value: ElementData.dataAlmace.Almacen
                      ? ElementData.dataAlmace.Almacen.TipoVehiculo
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Fecha de Llegada',
                    value: ElementData.dataAlmace.Almacen
                      ? ElementData.dataAlmace.Almacen.FechaLlegadaAlmacen
                      : '',
                    primary: false,
                  },
                  {
                    title: 'Cantidades Recibidas',
                    value: ElementData.dataAlmace.Almacen
                      ? ElementData.dataAlmace.Almacen.CantidadesRecibidas
                      : '',
                    primary: false,
                  },
                ],
                comments: commentsDataAlmacen ? commentsDataAlmacen : [],
              },
            },
          ];
          this.setState({
            steps,
            embarque: ElementData.infoEmbarque,
            integrado: ElementData.infoIntegrado,
          });
        })
        .catch((error) => {
          console.warn(error);
        });
    } else {
      this.setState({
        error: {
          message: "Dato incorrecto, favor de verificar",
          open: true,
          type: "error",
        }
      }, () => {
        setTimeout(() => {
          this.props.history.push("/integrada");
        }, 2000);
      })
    }
  };

  changeSelectData = (index) => { };

  render() {
    let { integrado, customWidth, error } = this.state
    return (
      <>
        <Errors open={error.open} type={error.type} message={error.message} setProps={this.recieveProps} />
        <ContentWrapper>
          <div className="content-heading">
            <legend className='legendTitle'>
              <img
                alt=""
                src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-18.png"
                height="28"
                width="28"
              />
              Trazabilidad Integrada
            </legend>
          </div>

          <Col xs={12}>
            <div>
              <p className='sizeTextComents' style={{ fontWeight: 'bold' }}>
                {integrado ? integrado.Cliente : ''} (
                {integrado ? integrado.NIT : ''}) /{' '}
                {integrado ? integrado.Grupo : ''}
              </p>
              <p className='sizeTextComents' style={{ color: '#458CC9', fontWeight: 'bold' }}>
                {integrado ? integrado.DoEmbarque : ''}
              </p>
              <p className='sizeTextComents' >Referencia del Cliente: {integrado ? integrado.NoPedido : ''}
              </p>
              <p className='sizeTextComents'>
                Doc. de Transporte: {integrado ? integrado.HBL_HAWB : ''}
              </p>
              <p className='sizeTextComents'>Proyecto:</p>
              <p className='sizeTextComents' style={{ fontWeight: 'bold' }}>Estatus: {integrado ? integrado.Estatus : ''}
              </p>
            </div>
          </Col>
          <Suspense fallback={<div>Loading...</div>}>
            <StepperContainer
              openCommt={this.openCommt}
              steps={this.state.steps}
            />
            <Sidebar.Pushable as="Segment">
              <Segment>
                <Sidebar
                  as={Menu}
                  animation="overlay"
                  //  icon='labeled'
                  className="_comment"
                  direction="right"
                  // onHide={() => setVisible(false)}
                  vertical
                  visible={this.state.openComments}
                  // width="very wide"
                  style={{ width: customWidth < 758 ? customWidth : 500, }}
                >
                  <Col className="margintopp" md={12}>
                    <Row>
                      <Col
                        md={12}
                        style={{ marginTop: '-25px!important' }}
                        className="close_container"
                      >
                        <div className="header_comments">{this.state.id}</div>
                        <div className="close_icon">
                          <Menu.Item
                            as="a"
                            onClick={() => {
                              this.setState({ openComments: false });
                            }}
                          >
                            <CloseIcon />
                          </Menu.Item>
                        </div>
                      </Col>
                      <Comments
                        style={{ marginTop: '38px!important' }}
                        data={this.state.commentsData}
                        id={this.state.id}
                        catalogs={this.state.catalogsData}
                      />
                    </Row>
                  </Col>
                </Sidebar>
              </Segment>
            </Sidebar.Pushable>
          </Suspense>
        </ContentWrapper>
      </>
    );
  }
}

export default withRouter(IntegratedDetail);
