export const Services = {
   "GET_DASHBOARD_SERVICE": {
      "path": "https://roldanapidashboard.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO SET DASHBOARD"
   },
   "GET_DASHBOARD_CATYEARS": {
      "path": "https://roldanapidashboard.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO SET DASHBOARD"
   },
   "GET_DASHBOARD_TOPERACIONES": {
      "path": "https://roldanapidashboard.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO SET DASHBOARD"
   },
   "RSS": {
      "path": "https://roldanapirss.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO SET DASHBOARD"
   }
}