import React from 'react';
import propTypes from 'prop-types';
import { Empty } from 'antd';

/**
 * Empty Component
 * @param {*} props
 */
function EmptyComponent(props){
  return (
    <Empty
      imageStyle={{
        width: '100%',
        height: 'auto',
        textAlign: 'center'
      }}
      description={
        <span>
          {props.title}
        </span>
      }
    >
    </Empty>
  );
}

EmptyComponent.propTypes = {
  title: propTypes.string
};
EmptyComponent.defaultProps = {
  title: ''
};

export default EmptyComponent;