const adminForm = {
  CreateCustomer: [
    {
      type: 'Input',
      label: 'Nombre del Cliente',
      class: 'col-md-3',
      placeHolder: '',
      name: 'CustomerName',
      id: 'CustomerName',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Teléfono',
      name: 'Telephone',
      class: 'col-md-3',
      id: 'Telephone',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'NIT',
      name: 'NIT',
      class: 'col-md-3',
      id: 'NIT',
      style: {},
      required: true
    },
    {
      type: 'Select',
      options: 'CountryId',
      label: 'País',
      name: 'Country',
      placeHolder: 'Elegir',
      class: 'col-md-3',
      id: 'Country',
      style: {},
      required: false
    },
    // {
    //   type: 'Input',
    //   label: 'Customer code',
    //   class: 'col-md-3',
    //   placeHolder: '',
    //   name: 'CustomerCode',
    //   id: 'CustomerCode',
    //   style: {},
    // },
    {
      type: 'multiCombo',
      label: 'Módulo',
      class: 'col-md-4',
      placeHolder: 'Selección',
      name: 'multiCombo',
      id: 'multiCombo',
      required: true,

    },

  ],
  AddContact: [
    {
      type: 'Input',
      label: 'Nombre',
      class: 'col-md-3',
      placeHolder: '',
      name: 'name',
      id: 'Nombre',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Apellido',
      name: 'Lastname',
      class: 'col-md-2',
      id: 'Apellido',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Posición',
      name: 'Position',
      class: 'col-md-2',
      id: 'Posicion',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Teléfono',
      class: 'col-md-2',
      placeHolder: '',
      name: 'Telephone',
      id: 'Telefono',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },
  ],
  CreateUser: [
    {
      type: 'Input',
      label: 'Nombre',
      class: 'col-md-3',
      placeHolder: '',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Apellidos',
      name: 'Lastname',
      class: 'col-md-3',
      id: 'LastName',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Posición',
      name: 'Position',
      class: 'col-md-3',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Contraseña',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Password',
      id: 'Password',
      style: {},
      required: true
    },
    {
      type: 'Select',
      options: 'RoleId',
      label: 'Rol',
      name: 'RoleId',
      class: 'col-md-3',
      id: 'RoleId',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Teléfono',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
  ],
  // editUser: [
  //   {
  //     type: 'Input',
  //     label: 'Name',
  //     class: 'col-md-3',
  //     placeHolder: '',
  //     name: 'name',
  //     id: 'Name',
  //     style: {},
  //     required: true
  //   },
  //   {
  //     type: 'Input',
  //     label: 'Last Name',
  //     name: 'Lastname',
  //     class: 'col-md-3',
  //     id: 'LastName',
  //     style: {},
  //     required: true
  //   },
  //   {
  //     type: 'Input',
  //     label: 'Position',
  //     name: 'Position',
  //     class: 'col-md-3',
  //     id: 'Position',
  //     style: {},
  //     required: false
  //   },
  //   {
  //     type: 'Input',
  //     label: 'Email',
  //     class: 'col-md-3',
  //     placeHolder: '',
  //     name: 'Email',
  //     id: 'Email',
  //     style: {},
  //     required: true
  //   },
  //   {
  //     type: 'Input',
  //     label: 'Password',
  //     class: 'col-md-3',
  //     placeHolder: '',
  //     name: 'Password',
  //     id: 'Password',
  //     style: {},
  //     required: false
  //   },
  //   {
  //     type: 'Select',
  //     options: 'RoleId',
  //     label: 'Role',
  //     name: 'RoleId',
  //     class: 'col-md-3',
  //     id: 'RoleId',
  //     style: {},
  //     required: true
  //   },
  //   {
  //     type: 'Input',
  //     label: 'Telephone',
  //     class: 'col-md-3',
  //     placeHolder: '',
  //     name: 'Telephone',
  //     id: 'Telephone',
  //     style: {},
  //     required: false
  //   },
  // ],
    editUser: [
    {
      type: 'Input',
      label: 'Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Last Name',
      name: 'Lastname',
      class: 'col-md-3',
      id: 'LastName',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Position',
      name: 'Position',
      class: 'col-md-3',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },
    // {
    //   type: 'Input',
    //   label: 'Password',
    //   class: 'col-md-3',
    //   placeHolder: '',
    //   name: 'Password',
    //   id: 'Password',
    //   id2: 'Passwordedit',
    //   style: {},
    //   required: false
    // },
    {
      type: 'Select',
      options: 'RoleId',
      label: 'Role',
      name: 'RoleId',
      class: 'col-md-3',
      id: 'RoleId',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Telephone',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
  ],
  createGroup: [
    {
      type: 'Input',
      label: 'Nombre',
      class: 'col-md-3',
      placeHolder: '',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    }
   ],
}
export default adminForm