import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { Pagination } from 'semantic-ui-react';
import axios from 'axios';
import { connect } from 'react-redux';

import { MyResponsivePieCanvas } from '../../../Components/Graphics/DonutGraph';
import { MyResponsiveBarCanvas } from '../../../Components/Graphics/BarChart';
import Cards from '../../../Components/Cards';
import '../../../../styles/customStyle.scss';
import { Services } from '../../Services';
import Loader from '../../../../Global/Loader';
import './styles.scss';
// import ClientesList from '../../../../../services/storageClient.js';

// const clientSave = new ClientesList();

class Integrada extends Component {
  state = {
    clientesSelect: this.props.clients
      ? JSON.stringify(this.props.clients)
      : '[0]',
    data1: [],
    totalCard: [],
    activePage: 1,
    limit: 10,
    numberPage: 1,
    search: '',
    totalPages: 1,
    graphs: [],
    paramsxml: {},
    config1: [
      { title: '', xls: true, first: true, foot: { show: false, style: { fontSize: '12px', fontWeight: 'bold' } }, header: { link: false, hide: true, icon: false }, data: { link: false, progress: false, icon: false, status: false, }, id: 'Estatus', idImpo: 'ImportacionId', link: '/integrated-detail/', className: 'col-md-3 col-lg-2 col-sm-6' },
      // {title: 'jhgasjh',titleId: "dosId",foot:{show: true, style: { fontSize: '12px', fontWeight: 'bold', color:'green' }},data: {link: false, progress: false, icon: false, status: false},header: { link: true }, id:'HBL', id2:"status",link:"/integrated-detail/",className: 'col-md-3 col-lg-2 col-sm-6'},
      { title: 'Estatus', idExcel: 'Estatus', xls: true, id: '', className: 'hidden', header: { hide: true } },
      { title: 'Cliente', xls: true, id: 'Cliente', className: 'col-md-3 col-lg-2 col-sm-6', },
      { title: 'Referencia del Cliente', xls: true, id: 'NoPedido', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Origen', xls: true, id: 'POL', className: 'col-md-3 col-lg-2 col-sm-6', },
      { title: 'Destino', xls: true, id: 'POD', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'ETD', xls: true, id: 'ETD', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'ETA', xls: true, data: { link: false, progress: false, icon: false, status: false }, id: 'ETA', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Fecha de Levante', xls: true, id: 'FechaLevante', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Llegada Almacén', xls: true, data: { link: false, progress: false, icon: false, status: false }, id: 'FechaLlegadaAlmacen', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Internacional', xls: true, data: { link: false, progress: false, icon: false, button: false, status: true, }, header: { link: false, hide: false, icon: false }, id: 'EmbarqueId', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Depósito Habilitado', xls: true, data: { link: false, progress: false, icon: false, button: false, status: true, }, header: { link: false, hide: false, icon: false }, id: 'DepositoHabilitadoId', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Aduana', xls: true, data: { link: false, progress: false, icon: false, button: false, status: true, }, header: { link: false, hide: false, icon: false }, id: 'ImportacionId', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Almacén', xls: true, data: { link: false, progress: false, icon: false, button: false, status: true, }, header: { link: false, hide: false, icon: false }, id: 'IngresoIds', className: 'col-md-3 col-lg-2 col-sm-6' },
      // {title: '' , xls: true,data: {link: false, progress: false, icon: false, button: false},header: { link: false, hide: false, icon:false }, colorProgress : '',id:'null',className: 'hidden'},
      // {title: 'Progreso' , xls: true,data: {link: false, progress: true, icon: false, button: false},header: { link: false, hide: false, icon:false }, colorProgress : 'grayColor',id:'Progreso',className: 'col-md-3 col-lg-2 col-sm-6'},
      // {title: 'Ver Detalles' ,data: {link: false, progress: false, icon: false, button: true, }, link:'/integrated-detail/', idLink:'dosId', header: { link: false, hide: true, icon:false },id:'dosId',className: 'col-md-3 col-lg-2 col-sm-6'},
    ],
    donutChart: [
      { id: 'Aduana', label: 'Terrestre', value: 35, color: '#00a9ff' },
      { id: 'Internacional', label: 'Aereo', value: 30, color: '#67c7f7' },
      { id: 'Almacén', label: 'FCL', value: 25, color: '#33adea' },
      { id: 'Depósito Habilitado', label: 'LCL', value: 10, color: '#33c6ea' },
    ],
    donutChartTrafico: [
      { id: 'Expo', label: 'Expo', value: 30, color: '#0bc637' },
      { id: 'DTA', label: 'Trinagulado', value: 40, color: '#149e16' },
      { id: 'Impo', label: 'Impo', value: 30, color: '#39ef64' },
    ],
    barTable: [
      { status: 'Sin Iniciar', Cancelled: 2, CancelledColor: '#efbc04' },
      { status: 'En Proceso', Cancelled: 5, CancelledColor: '#efbc04' },
      { status: 'Finalizado', Cancelled: 5, CancelledColor: '#efbc04' },
    ],
    advancedSearch: [
      {
        title: 'Información de Integrada',
        data: [
          [
            {
              title: 'No de DO', type: 'text', id: 'DoEmbarque', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
            },
            {
              title: 'Cliente', type: 'select', id: 'Cliente', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
            },
            {
              title: 'Referencia del Cliente', type: 'text', id: 'NoPedido', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
            },
            {
              title: 'Documento de Transporte', type: 'text', id: 'HBL_HAWB', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
            },
          ],
          [
            {
              title: 'Origen', type: 'select', id: 'POL', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
            },
            {
              title: 'Destino', type: 'select', id: 'POD', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
            },
            // {
            //   title: 'Estatus', UseIN:false, type: 'select', id: 'Estatus', class: 'col-3', values: [], value: ''
            // },
            {
              title: 'Estatus', UseIN: true, type: 'multipleSelect', id: 'Estatus', class: 'col-xs-12 col-md-6 col-lg-4', values: [], value: ''
            }
          ]
        ]
      },
      {
        title: 'Fechas',
        data: [
          [
            {
              title: 'ETD', type: 'dateRange', id: 'ETD', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
            },
            {
              title: 'ETA', type: 'dateRange', id: 'ETA', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
            }
          ],
          [
            {
              title: 'Fecha de Levante', type: 'dateRange', id: 'FechaLevante', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
            },
            {
              title: 'Llegada a Almacén', type: 'dateRange', id: 'FechaLlegadaAlmacen', class: 'col-xs-12 col-md-6 col-lg-6', values: [], value: '', date: { init: '', end: '' }
            }
          ]
        ]
      },
    ],
    advancedObj: [],
    url: ''
  }

  componentDidMount = () => {
    this.getListInteger();
    this.getDataCharts();
    this.getCatalogs();
  }

  getCatalogs = () => {
    let data = {
      client: this.state.clientesSelect,
    };
    // axios.get(`${Services.Get_Traceability_Imports.path}customs/allCustomImports`, {params:{EsDTA:0, search: this.state.search, allData: true}} ).then(success => {
    axios.post(`${Services.Get_list_integrated.path}/integrated/catalogIntegrated`, data).then(success => {
      let POL = success.data.data.POL.map(e => { return { value: e.POL, label: e.POL ? e.POL : 'Sin Valor' }; });
      let POD = success.data.data.POD.map(e => { return { value: e.POD, label: e.POD ? e.POD : 'Sin Valor' }; });
      // let Estatus = success.data.data.Estatus.map(e => { return { value: e.Estatus, label: e.Estatus ? e.Estatus : 'Sin Valor' }; });
      let Estatus = success.data.data.Estatus.map((e, index) => { return { key: e.Estatus, text: e.Estatus, value: e.Estatus ? e.Estatus : 'Sin Valor' }; });
      let Cliente = success.data.data.Cliente.map(e => { return { value: e.Cliente, label: e.Cliente ? e.Cliente : 'Sin Valor' }; });

      let newAdvanced = this.state.advancedSearch;
      newAdvanced.map(e => {
        e.data.map(f => {
          f.map(g => {
            if (g.id === 'Estatus') {
              g.values = Estatus;
            }
            // if (g.id === 'Estatus2') {
            //   g.values = Estatus2;
            // }
            if (g.id === 'POL') {
              g.values = POL;
            }
            if (g.id === 'POD') {
              g.values = POD;
            }
            if (g.id === 'Cliente') {
              g.values = Cliente;
            }
            return g;
          });
          return f;
        });
        return e;
      });
      this.setState({
        advancedSearch: newAdvanced
      });
    }).catch(error => {
      console.warn(error);
    });
  }

  getDataCharts = () => {
    let data = {
      client: this.state.clientesSelect ? this.state.clientesSelect : '[0]'
    };
    axios.post(`${Services.Post_Integrada_Charts.path}/integrated/chartsintegred`, data).then(success => {

      let totalCarddd = 0;
      success.data.data.firstCharts.map((e) => {
        if (e.id === 'ADUANA') {
          totalCarddd = e.value;
        }
        return e;
      });


      let newtotalCarddd = (Number(totalCarddd).toLocaleString('En-us'));


      let totalCard = [
        {
          title: 'Operaciones Integradas',
          value: newtotalCarddd,
          icon: 'fa-2x mr-2 fas fa-map-marker-alt',
          firstColor: {
            backgroundColor: '#2f80e7',
            color: '#fff'
          },
          secondColor: {
            backgroundColor: '#7eb3f6 ',
            color: '#fff'
          }
        }];


      this.setState({
        fullLoader: true,
        totalCard: totalCard,
        graphs: [
          {
            title: 'Operaciones por Proceso',
            type: 'pie',
            data: success.data.data.firstCharts,
            height: '280'
          },
          {
            title: 'Operaciones por Modo de Transporte',
            type: 'pie',
            data: success.data.data.secondCharts,
            height: '280'
          },
          {
            title: 'Operaciones por Estatus',
            type: 'bar',
            data: success.data.data.thirdCharts,
            height: '280'
          }
        ]
      });
    }).catch(error => {
      console.warn(error);
    });
  };

  componentWillReceiveProps = (nextProps) => {
  }

  setAdvanced = (val) => {
    this.setState({ advancedObj: val }, () => { this.getListInteger(); });
  }

  getListInteger = () => {
    this.setState({
      fullLoader: false
    });
    let data = {
      pag: this.state.numberPage,
      limit: this.state.limit,
      EsDTA: 0,
      search: this.state.search,
      advanced: this.state.advancedObj,
      client: this.state.clientesSelect
    };

    this.setState({ url: encodeURI(`${Services.Get_list_integrated.path}/integrated/listIntegrated?allData=true`), paramsxml: data });
    axios.post(`${Services.Get_list_integrated.path}/integrated/listIntegrated`, data).then(success => {
      let data1 = success.data.data.map((e) => {
        let TraspormodeId = 1;
        e.TraspormodeId = TraspormodeId;
        // e.EmbarqueId = e.EmbarqueId ? 'SI' : null;
        return e;
      });
      this.setState({
        fullLoader: true,
        totalPages: success.data.totalPage,
        data1: data1,
      });
    }).catch(error => {
      this.setState({
        fullLoader: true
      });
      console.warn(error);
    });
  }

  toggleDrawerMap = data => evt => {
    this.setState({ visible: true, dataDrawer: data });
  };

  newSearch = (val) => {
    if (val.length < 1) {
      this.setState({
        search: val, isSearch: false, activePage: 1, limit: 10,
        numberPage: 1,
        totalPages: 1
      }, () => { this.getListInteger(); });
    }
    else {
      if (val.length < this.state.search.length) {
        this.setState({
          search: val, activePage: 1, limit: 10,
          numberPage: 1,
          totalPages: 1
        }, () => { this.getListInteger(); });
      }
      else {
        this.setState({ search: val }, () => { this.getListInteger(); });
      }
    }
  }

  changePage = page => {
    this.setState({
      numberPage: page,
      activePage: page
    }, () => {
      this.getListInteger();
    });
  }

  render() {
    let {
      advancedSearch,
      totalCard,
      graphs,
      url,
      totalPages,
      activePage,
      fullLoader,
    } = this.state;
    return (
      <>
        <Loader hidden={fullLoader}></Loader>
        {/* <div className="content-heading">
            <Row>
              <legend><img style = {{ margin : '6px', marginTop :'-1px'}} alt ="" src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-18.png" height="30" width="30"></img>
              Trazabilidad Integrada</legend>
            </Row>
          </div> */}
        <div className="content">
          <Row>
            <Col lg={12} md={12}>
              <Row>
                <Col xs={12} sm={6} md={6} lg={3}>
                  <Row>
                    {totalCard.map((e, index) => {
                      return (
                        <Col md={12}>
                          <div
                            key={index}
                            className="card flex-row align-items-center align-items-stretch border-0 _card_colors"
                          >
                            <div
                              className="col-4 d-flex align-items-center justify-content-center rounded-left"
                              style={e.firstColor}
                            >
                              {!e.icon ? (
                                <center>{e.value}</center>
                              ) : (
                                <>
                                  <em className={`fas fa-2x ${e.icon}`}></em>
                                </>
                              )}
                            </div>
                            <div
                              className="col-8 rounded-right totalCardInfo"
                              style={e.secondColor}
                            >
                              <div
                                style={{
                                  fontSize: '14px',
                                  textAlign: 'center',
                                  marginTop: !e.icon ? '16px' : '4px',
                                }}
                              >
                                {e.title}
                              </div>
                              {!e.icon ? (
                                ''
                              ) : (
                                <div style={{ fontSize: '18px' }}>
                                  {' '}
                                  <center
                                    style={{ fontSize: '20px', marginTop: '6px' }}
                                  >
                                    <strong>{e.value}</strong>
                                  </center>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col lg={10} md={10} ></Col>
                <Col lg={12} md={12} >
                  <Row>
                    {graphs.map((e, index) => {
                      let typeGraph =
                        e.type === 'pie' ? (
                          <MyResponsivePieCanvas data={e.data} />
                        ) : (
                          <MyResponsiveBarCanvas
                            position="horizontal"
                            data={e.data}
                            left={100}
                          />
                        );
                      return (
                        <Col
                          md={e.type === 'pie' ? 6 : 12}
                          sm={12}
                          className="_card_container"
                          key={index}
                        >
                          {/* <CardDeck style={{marginLeft:"13px"}} className="graphcarddeck"> */}
                          <Card className="graphcardsize">
                            <CardBody className="graphcardbody">
                              <CardText
                                style={{
                                  textAlign: 'center',
                                  marginTop: '10px',
                                  fontFamily: 'unset',
                                  fontWeight: 'bold',
                                }}
                              >
                                {e.title}
                              </CardText>
                              {typeGraph}
                            </CardBody>
                          </Card>
                          {/* </CardDeck> */}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Cards
            advancedSearchData={advancedSearch}
            advancedSearch={(val) => {
              this.setAdvanced(val);
            }}
            paramsxml={this.state.paramsxml}
            clientesSelect={this.state.clientesSelect}
            pagination={false}
            urlReport={url}
            newSearch={(value) => {
              this.newSearch(value);
            }}
            config={this.state.config1}
            visible={true}
            toggleDrawer={(value) => {
              this.toggleDrawerMap(value);
            }}
            data={this.state.data1}
            dataSearch={this.state.data1}
            labelStatus={'closed'}
            nameXlsx={'Integrada_Importacion.xlsx'}
          />
          <center className='pt-4'>
            <Pagination
              activePage={activePage}
              ellipsisItem={null}
              totalPages={totalPages}
              onPageChange={(e, { activePage }) =>
                this.changePage(activePage)
              }
              size={'mini'}
              siblingRange={0}
            />
          </center>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Integrada);
