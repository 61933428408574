import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core/';
import { Card, CardBody } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { Button } from 'reactstrap';
import Table from '../../../../Components/Table';
import axios from 'axios';
import { Services } from '../../../Services';
import ListUser from './listUsers';
import ReportAsing from './reportAssig';
import Tooltip from '@material-ui/core/Tooltip';
import Errors from '../../../../Components/Errors';

class createUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passcorrect: false,
      selectcheck: true,
      modal: false,
      dataDocuments: [],
      dataReport: {},
      AsociadosClient: [],
      flatNext: false,
      reset: false,
      UserList: [],
      newSelected: [],
      mainColumns: [],
      dataSelect: [],
      roles: [],
      CustomerId: '',
      CreateFormData: [],
      CreateForAdd: [],
      Form: this.props.form,
      selected: [],
      data: this.props.customerList,
      datall: this.props.customerList,
      error: {
        open: false,
        message: '',
        type: 'admin',
      },
      user: {
        Name: '',
        LastName: '',
        Position: '',
        Email: '',
        Password: '',
        RoleId: '',
        Telephone: '',
      },
      columns: [
        {
          label: 'ID',
          key: 'ClienteId',
          custom: (value) => (
            <em
              onClick={() =>
                this.setState({ active: !this.state.active }, () => { })
              }
            >
              {' '}
              <label style={{}}>{value.ClienteId}</label>{' '}
            </em>
          ),
        },
        { label: 'Nombre del Cliente', key: 'Nombre' },
        // { label: 'Customer Code', key: 'CustomerCode' },
        { label: 'NIT', key: 'NIT' },
        { label: 'Grupo', key: 'Grupo' },
        { label: 'Teléfono', key: 'Telefono' },
        { label: 'País', key: 'Pais' },
        { label: 'Logo', key: '' },
        // { label: 'Usuarios Asociados', key: 'UsuariosAsociados' },
        {
          label: 'Acción',
          key: 'addAll',
          custom: (value) => this.custom1(value),
        },
      ],
      columnsAsociados: [
        {
          label: 'ID',
          key: 'ClienteId',
          custom: (value) => (
            <em
              onClick={() =>
                this.setState({ active: !this.state.active }, () => { })
              }
            >
              {' '}
              <label style={{}}>{value.ClienteId}</label>{' '}
            </em>
          ),
        },
        { label: 'Nombre del Cliente', key: 'Nombre' },
        // { label: 'Customer Code', key: 'CustomerCode' },
        { label: 'NIT', key: 'NIT' },
        { label: 'Grupo', key: 'Grupo' },
        { label: 'Teléfono', key: 'Telefono' },
        { label: 'País', key: 'Pais' },
        { label: 'Logo', key: '' },
        // { label: 'Usuarios Asociados', key: 'UsuariosAsociados' },
        { label: '', key: '', custom: (value) => this.custom2(value) },
      ],
      errorValidate: false,
      guardarDisabled: false,
      siguienteEnabled: false,
      showClientes: true
    };
  }

  custom1 = (value) => (
    <em
      class="fa-2x mr-2 fas fa-plus-circle"
      onClick={this.addClientGroup(value)}
      style={{ color: 'rgb(0, 121, 184)', marginTop: '5px' }}
    ></em>
  );
  custom2 = (value) => (
    <em
      class="fa-2x mr-2 fas fa-minus-circle"
      onClick={this.deleteClient(value)}
      style={{ color: 'rgb(0, 121, 184)', marginTop: '5px' }}
    ></em>
  );
  addClientGroup = (index) => (event) => {
    let { newSelected } = this.state;
    let newClietAsociados = newSelected;
    newClietAsociados.push(index);
    let deleteValue = index;
    let itemNew = this.state.data.filter((e) => {
      if (e.ClienteId !== deleteValue.ClienteId) {
        return e;
      }
      return 0;
    });
    this.setState({
      newSelected: newClietAsociados,
      data: itemNew,
    });
  };
  deleteClient = (index) => (event) => {
    let deleteValue = index;
    let itemNew = this.state.newSelected.filter((e) => {
      if (e.ClienteId !== deleteValue.ClienteId) {
        return e;
      }
      return 0;
    });
    let newClietAsociados = this.state.data;
    newClietAsociados.push(index);
    newClietAsociados.sort(function (a, b) {
      return a.ClienteId - b.ClienteId;
    });
    this.setState({
      newSelected: itemNew,
      data: newClietAsociados,
    });
  };
  componentDidMount = () => {
    this.getRoles();
    this.getUser();
  };
  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        newSelected: [],
        selectcheck: false,
        Form: nextProps.form,
        reset: true,
      },
      () => {
        this.getRoles();
        this.getUser();
      }
    );
  };

  getUser = (save) => {
    axios
      .get(`${Services.GetUser.path}/admin/GetUser`, {})
      .then((response) => {
        if (save) {
          this.setState({
            UserList: response.data.data,
            reset: true,
          });
        } else {
          this.setState({
            UserList: response.data.data,
            reset: false,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getRoles = () => {
    axios
      .get(`${Services.GetRole.path}/admin/roles`, {})
      .then((response) => {
        this.setState({ roles: response.data.data }, () => {
          this.buildFormCreate();
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  validateSaveUser = (e) => (evt) => {
    let validation = [];
    let errors = '';
    let Form = this.props.form.CreateUser;

    if (this.state.passcorrect) {
      Form.map((e) => {
        if (e.required === true) {
          validation.push(e);
        }
        return e;
      });
      validation.forEach((e) => {
        if (this.state.user[e.id] === '') {
          errors += e.label + ', ';
          document.getElementById(e.id).style.color = 'red';
        }
      });
      if (errors.length > 0) {
        this.setState(
          {
            submitt: true,
          },
          () => {
            this.buildFormCreate();
          }
        );
        this.setState({
          guardarDisabled: false,
          siguienteEnabled: true,
          error: {

            message: 'Campos requeridos: ' + errors + '',
            open: true,
            type: 'error',
          },
        });
      } else {
        this.saveUser();
      }
    } else {
      this.setState({
        guardarDisabled: false,
        siguienteEnabled: true,
        error: {

          message: 'la contraseña no cumple con el formato especificado',
          open: true,
          type: 'error',
        },
      });
    }
  };
  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  saveUser = async () => {
    let { user } = this.state;
    user["Email"] = user["Email"].toLowerCase();

    let data = {
      data: user,
      items: this.state.newSelected,
      report: this.state.dataReport,
      documents: this.state.dataDocuments,
    };
    /**
         1-Admin
         3-Roldan
         4-Roldan SU
       */
    if ([1, 3, 4].indexOf(user["RoleId"]) >= 0) {
      data.items = [];
    }
    await axios
      .post(`${Services.PostUser.path}/admin/createUser`, data)
      .then((response) => {
        this.props.onError({
          error: {
            message: 'El Usuario se ha guardado correctamente!',
            open: true,
            type: 'admin',
          },
        });
        this.sleep(1000).then(() => {
          this.setState(
            {
              newSelected: [],
              guardarDisabled: false,
              user: {
                Name: '',
                LastName: '',
                Position: '',
                Email: '',
                Password: '',
                RoleId: '',
                Telephone: '',
              },
              reset: true,
              data: this.state.datall,
              columns: this.state.columns,
              flatNext: false,
              showClientes: true,
              siguienteEnabled: false
            },
            () => {
              this.getUser(true);
              this.buildFormCreate();

            }
          );
        });

      })
      .catch((error) => {
        if (
          error.response.data.message ===
          'Correo electrónico existente, probar con otro diferente'
        ) {
          this.setState({
            guardarDisabled: false,
            siguienteEnabled: true,
            error: {
              message: error.response.data.message,
              open: true,
              type: 'error',
            },
          });
        } else {
          this.setState({
            guardarDisabled: false,
            siguienteEnabled: true,
            error: {
              message: 'Dato incorrecto, favor de verificar',
              open: true,
              type: 'error',
            },
          });
        }
      });
  };

  PutUser = (dataUserPut) => {
    axios
      .put(`${Services.PutUser.path}/admin/updateUser`, dataUserPut)
      .then((response) => {
        this.setState(
          {
            error: {
              message: 'El usuario se ha guardado correctamente!',
              open: true,
              type: 'admin',
            },
            modal: false,
            errorValidate: false,
          },
          () => {
            this.getUser();
            this.buildFormCreate();
          }
        );
      })
      .catch((error) => {
        if (error.response.data.message === 'Existing email, try again') {
          this.setState({
            error: {
              message: error.response.data.message,
              open: true,
              type: 'error',
            },
          });
        } else {
          this.setState({
            error: {
              message: 'Dato incorrecto, favor de verificar',
              open: true,
              type: 'error',
            },
          });
        }
        this.setState({
          errorValidate: true,
        });
      });
  };

  handleChange = (name) => (event) => {
    let { user } = this.state;
    var value = "";
    if (name === 'Password') {
      value = event.target.value;

      const emailRegex = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])([A-Za-z\d$@$!%*?&_-]|[^ ]){8,15}$/
      );
      if (emailRegex.test(value)) {
        this.setState({
          passcorrect: true,
        });
        document
          .getElementById('pass_diferentes')
          .setAttribute('hidden', 'hidden');
      } else {
        this.setState({
          passcorrect: false,
        });
        document.getElementById('pass_diferentes').removeAttribute('hidden');
      }
    } else if (name === "Email") {
      value = event.target.value;
    } else {
      value = event.target.value;
    }

    document.getElementById(name).style.color = 'black';

    user[name] = value;
    this.setState(
      {
        user,
      },
      () => {
        this.buildFormCreate();
      }
    );
  };

  handleChangeSelect = (selected) => (event) => {
    /**
      1-Admin
      3-Roldan
      4-Roldan SU
    */
    let { showClientes, siguienteEnabled, flatNext, newSelected } = this.state;
    showClientes = true;
    siguienteEnabled = false;
    if (selected === "RoleId") {
      if (event && [1, 3, 4].indexOf(event.value) >= 0) {
        showClientes = false;
        siguienteEnabled = true

      } else if (newSelected.length <= 0 && flatNext) {
        flatNext = false;
      }

    }
    document.getElementById(selected).style.color = 'black';
    if (event && event.value) {
      let { user } = this.state;
      user[selected] = event.value;
      this.setState({ selected, showClientes, siguienteEnabled, flatNext }, () => {
        this.buildFormCreate();
      });
    } else {
      let { user } = this.state;
      user[selected] = '';
      this.setState({ selected, showClientes, siguienteEnabled, flatNext }, () => {
        this.buildFormCreate();
      });
    }
  };

  buildFormCreate = () => {
    const { Form, user, roles } = this.state;
    let CreateFormData = Form.CreateUser.map((e, i) => {
      if (e.type === 'Input')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {' '}
              <span id={e.id} style={e.style}>
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}
                </label>
              </span>
            </Fade>
            <Input
              id={e.id === "Email" ? "email-lower" : ""}
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={user[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
            {e.id === 'Password' ? (
              <Fade left opposite>
                <div
                  style={{
                    padding: '',
                    marginTop: '',
                    color: '#BA2B30',
                    fontSize: '12px',
                  }}
                  className="_alert"
                  id="pass_diferentes"
                  hidden
                >
                  <label>La contraseña debe de estar compuesta por:</label>
                  <label>* Mínimo 8 digitos y hasta máximo 15 digitos</label>
                  <label>* Al menos una letra mayúscula y una minúscula</label>
                  <label>* Un carácter especial (*!$%&?).</label>
                </div>
              </Fade>
            ) : (
              <></>
            )}
          </div>
        );
      else if (e.type === 'Select')
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.id} style={e.style}>
                {' '}
                <label>
                  {e.label} {e.required === true ? <span>*</span> : ''}{' '}
                </label>
              </span>
            </Fade>
            <Select
              // id={e.id}
              required
              type={e.datatype}
              name={e.name}
              value={user[e.id]}
              placeholder="Elegir..."
              onChange={this.handleChangeSelect(e.id)}
              options={roles}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      return e;
    });
    this.setState({
      CreateFormData,
    });
  };
  recieve = (array) => {
    this.setState({
      newSelected: array,
    });
  };

  dataReport = (data, edit) => {

    let dataReport = { data: data, userId: this.props._user };

    this.setState({
      dataReport,
    });
  };

  newdata = (data) => { };

  dataDocuments = (dataPo, dataship, edit) => {
    let array = [];
    dataPo.map((e) => {
      if (e.check) {
        let data = {
          DocCategoryId: e.DocCategoryId,
          ModuleId: e.ModuleId,
          UserId: this.props._user,
        };
        array.push(data);
      }
      return e;
    });
    dataship.map((e) => {
      if (e.check) {
        let data = {
          DocCategoryId: e.DocCategoryId,
          ModuleId: e.ModuleId,
          UserId: this.props._user,
        };
        array.push(data);
      }
      return e;
    });
    this.setState({
      dataDocuments: array,
    });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    return (
      <>
        <Errors
          open={this.state.error.open}
          type={this.state.error.type}
          message={this.state.error.message}
          setProps={this.recieveProps}
        ></Errors>
        <Card>
          <CardBody className="myCard">
            <ExpansionPanel elevation={0} defaultExpanded>
              <div className="col-md-12">
                <strong style={{ marginLeft: '-12px' }}>
                  Información del Usuario
                </strong>
                <br></br>
              </div>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col-md-12">
                    <div className="row">{this.state.CreateFormData}</div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {this.state.flatNext ? (
              <div className="col-md-12">
                <ReportAsing
                  dataDocuments={this.dataDocuments}
                  dataReport={this.dataReport}
                  columns={this.state.columns}
                  newSelected={this.state.newSelected}
                ></ReportAsing>
              </div>
            ) : (this.state.showClientes ?
              <>
                <div className="col-md-12">
                  <strong style={{ marginLeft: '-12px', color: 'black' }}>
                    Seleccionar Cliente
                  </strong>
                  <br></br>
                  <br></br>
                </div>
                <Table
                  renderData={this.newdata}
                  columns={this.state.columns}
                  data={this.state.data}
                  mainColumns={this.state.mainColumns}
                  bordered={true}
                  itemsByPage={10}
                  options={{
                    // pagination: true,
                    large: true,
                    selectGroup: true,
                    search: false,
                    pagination: true,
                    downloads: {
                      pdf: false,
                      xlsx: false,
                    },
                    // selectItems: true,
                    reset: this.state.reset,
                  }}
                // onSelect={this.recieve}
                />
                <div style={{ height: '60px' }}></div>
                <div className="col-md-12">
                  <strong style={{ marginLeft: '-12px', color: 'black' }}>
                    Clientes Asociados
                  </strong>
                  <br></br>
                  <br></br>
                </div>
                <Table
                  columns={this.state.columnsAsociados}
                  data={this.state.newSelected}
                  bordered={true}
                  itemsByPage={10}
                  options={{
                    // pagination: true,
                    large: true,
                    selectGroup: true,
                    search: false,
                    pagination: true,
                    downloads: {
                      pdf: false,
                      xlsx: false,
                    },
                    // selectItems: true,
                    reset: this.state.reset,
                  }}
                // onSelect={this.recieve}
                />
              </> : ""
            )}
            <br></br>
            <br></br>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: 'center' }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="danger"
                  className="button"
                  onClick={() => {
                    this.setState(
                      {
                        reset: true,
                        showClientes: true,
                        flatNext: false,
                        user: {
                          Name: '',
                          LastName: '',
                          Position: '',
                          Email: '',
                          Password: '',
                          RoleId: '',
                          Telephone: '',
                        },
                        newSelected: [],
                        siguienteEnabled: false
                      },
                      () => {
                        let Form = this.props.form.CreateUser;
                        Form.map((e) => {
                          document.getElementById(e.id).style.color = 'black';
                          return e;
                        });
                        this.buildFormCreate();
                        this.setState({ reset: false });
                      }
                    );
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                {this.state.flatNext ? (
                  <Button
                    variant="contained"
                    disabled={!this.state.siguienteEnabled ? (this.state.newSelected.length <= 0 || this.state.guardarDisabled) : false}
                    color="primary"
                    className="button"
                    onClick={() => { this.setState({ guardarDisabled: true, siguienteEnabled: false }, this.validateSaveUser()) }}
                  >
                    Guardar
                  </Button>
                ) : (
                  <>
                    {' '}
                    <Tooltip
                      open={false}
                      interactive={true}
                      title="selecting a customer is required"
                    //  placement="placement"
                    >
                      <span>
                        <Button
                          variant="contained"
                          disabled={
                            !this.state.siguienteEnabled ? this.state.newSelected.length <= 0 : false
                          }
                          color="primary"
                          className="button"
                          onClick={() => {
                            this.setState({ flatNext: true });
                          }}
                        >
                          Siguiente
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
        <ListUser
          errorValidate={this.state.errorValidate}
          userId={this.props._user}
          modal={this.state.modal}
          dataDocuments={this.dataDocuments}
          dataReport={this.dataReport}
          saveUser={this.PutUser}
          onError={this.onError}
          deleteUser={this.props.deleteUser}
          customerList={this.props.customerList}
          Roles={this.state.roles}
          Form={this.state.Form}
          UserList={this.state.UserList}
        ></ListUser>
      </>
    );
  }
}

export default createUsers;
