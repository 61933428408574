import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { Card, Button, Grid } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import NoResultsFound from '../SecComponents/noResultsFound';
import CardContent from '../SecComponents/cardContent';
import Table from '../../DocumentManager/Custom/components/dataTablev1';
import { Services } from '../Services';

const noResultsNextSteps = {
  newSearch: 'Ver documentos de operación',
  mainMenu: 'SecondaryMenu',
  rateService: 'CalificarServicio',
};

const avilableOptions = {
  Aduana: {
    Icon: 'Aduana',
    Header: 'AduanaDo',
    SubHeader: 'Estatus',
    Action: { path: 'externalPath', id: 'externalId' },
    LabelButton: 'Visualizar documento',
    val1: { title: 'Referencia de Cliente:', id: 'RefCliente' },
    val2: { title: 'Origen:', id: 'PuertoOrigen' },
    val3: { title: 'Destino:', id: 'PuertoDestino' },
  },
  Almacén: {
    Icon: 'AlmacenIcon',
    Header: 'AlmacenDO',
    SubHeader: 'Estatus',
    Action: { path: 'externalPath', id: 'externalId' },
    LabelButton: 'Visualizar documento',
    val1: { title: 'Referencia de Cliente:', id: 'RefCliente' },
    val2: { title: 'Almacen:', id: 'Almacen' },
    val3: { title: 'cardTitle', id: '' },
  },
};

const defaultStyles = {
  fontSize: '13px',
};

function DocumentsResults(props) {
  
  const { steps, previousStep, step } = props;
  const { value } = previousStep;
  const { menuDocumentosDeOperacion } = steps;

  const qSearch = previousStep.value;
  let operationType = menuDocumentosDeOperacion.value
    ? menuDocumentosDeOperacion.value[0]
    : step.id === 'DocumentResult'
    ? step.value[0]
    : '';

  const [loader, setLoader] = React.useState(true);
  const [thereAreResults, setThereAreResults] = React.useState(false);
  const [results, setResults] = React.useState('');

  const [singleResult, setSingleResult] = React.useState('');
  const [newCardResult, setNewCardResult] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handlerResponse = (value) => {

    setNewCardResult([...newCardResult, value]);

    setSingleResult(value);
    setOpen((prevState) => !prevState);
  };

  const colData = {
    Almacén: [
      { label: 'Tipo de operación', key: 'AlmacenType' },
      { label: 'No. DO', key: 'AlmacenDO' },
      { label: 'Almacen', key: 'Almacen' },
      { label: 'Referencia de Cliente', key: 'RefCliente' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ],
    Aduana: [
      { label: 'Tipo de operación', key: 'Aduana' },
      { label: 'Aduana Do', key: 'AduanaDo' },
      { label: 'Referencia de Cliente', key: 'RefCliente' },
      { label: 'Doc. de Transporte', key: 'DocTransporte' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ],
  };

  const getDocuments = React.useCallback(async () => {

    if (operationType === 'Almacén') operationType = 'Almacen';


    const data = {
      cliente: JSON.stringify(props.clients),
      tipoOperacion: operationType,
    };

    const urlDoocs = `${Services.OPERATION_CHATBOT.path}/opdocs?q=${qSearch}&op=${operationType}`;
    // const urlDoocs = `http://localhost:3020/opdocs?q=${qSearch}&op=${operationType}`;

    try {
      const documentResults = await axios.post(urlDoocs, data);

      if (!documentResults.data.data.length) {
        setLoader(false);
        return setThereAreResults(true);
      }

      let opType;
      if (documentResults.data.data.length === 1) {
        opType = documentResults.data.data[0];
        setSingleResult(opType);
        setNewCardResult([...newCardResult, opType]);
      }

      if (documentResults.data.data.length > 1) {
        opType = documentResults.data.data;
        setResults(opType);
      }
      
      setLoader(false);
      
      props.triggerNextStep({
        value: ['Ver documentos de operación', 'CalificarServicio'],
        trigger: 'RealizarOtraConsulta',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('GET DOCUMENTS() ERROR: ', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOption = {
    Aduana: (
      <CardContent
        data={results}
        option={operationType}
        avilableOptions={avilableOptions[operationType]}
      />
    ),
    Almacén: (
      <CardContent
        data={results}
        option={'Almacen'}
        avilableOptions={avilableOptions[operationType]}
      />
    ),
  };

  const actionButton = () => {
    setOpen((prevState) => !prevState);
  };

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  const multipleresults = () => {
    return (
      <Card fluid>
        <Card.Content style={defaultStyles}>
          <Grid divided="vertically">
            <Grid.Column>
              <Grid.Row
                columns={1}
                style={{ fontSize: '12px', marginLeft: '10px' }}
              >
                Se encontrarón los siguientes resultados para &nbsp;
                <b> {` ${value}`}</b>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Card.Content>

        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              basic
              fluid
              compact
              color="grey"
              onClick={() => actionButton()}
              style={defaultStyles}
            >
              Ver lista de resultados
            </Button>
          </div>
        </Card.Content>


        <Modal isOpen={open} toggle={toggle} size="lg">
          <ModalHeader
            className="advancedSearchModal"
            style={{ background: '#003F70', color: 'white' }}
            toggle={toggle}
          >
            <b>Lista de resultados {` ${value}`}</b>
          </ModalHeader>
          <ModalBody>
            <Table columnsTable={colData[operationType]} data={results}></Table> 
          </ModalBody>
        </Modal> 


      </Card>
    );
  };

  const singleResults = () => {
    if (operationType === 'Ver documentos de operación')showData();
    else{
      const listResults = newCardResult.map( element => 
        <>
          <CardContent
            data={element}
            option={'Almacen'}
            avilableOptions={(operationType  === 'Almacén' || operationType  === 'Aduana') ? avilableOptions[operationType] : colData[operationType]}
            isDocument={true}
            next={props}
          />
        </>
      );
      return listResults;
    }
  };

  const showData = () => {
    return thereAreResults ? (
      <NoResultsFound botSteps={props} nextSteps={noResultsNextSteps} />
    ) : (
      ''
    );
  };

  return (
    <div style={{ width: '100%' }}>
      {loader ? <Loader active inline="centered" /> : showData()}
      {results.length > 1 ? multipleresults() : ''}
      { singleResult ? singleResults() : '' }
    </div>
  );
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsResults);
