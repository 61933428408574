import React, { Suspense } from 'react';
import { Tab, Loader as Load, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import Importaciones from './components/importaciones';
import Exportaciones from './components/exportaciones';

function DocumentManagerCustoms() {

  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend className="legendTitle">
          <Icon name='file alternate outline' />
          Gestor Documental Aduanas
        </legend>
      </div>
      <Tab
        menu={{ secondary: true, className: 'wrapped' }}
        menuPosition="right"
        panes={[
          {
            menuItem: 'Importaciones',
            render: () => (
              <Suspense fallback={<Load />}><Importaciones></Importaciones></Suspense>
            )
          },
          {
            menuItem: 'Exportaciones',
            render: () => (
              <Suspense fallback={<Load />}><Exportaciones></Exportaciones></Suspense>
            )
          }
        ]}
      />

    </ContentWrapper>
  );
}

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagerCustoms);

