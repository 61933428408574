import React, { useState, useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import Card from './CardWrapper/index.js';
import Feed from '../../RSSFeeds/components/RSSCard/index.js';
import './styles.scss';
import { Services } from '../Services.js';

const { Text, Link } = Typography;
/**
 * RSS Feeds
 * @param {*} param0
 */
function RSS({type, history}){
  const [feeds, setFeeds] = useState([]);

  const GetFeeds = useCallback(async() => {
    try{
      const response = await axios.get(`${Services.RSS.path}/api/v1/rss-feed/col-int`);
      setFeeds([...response.data.feeds]);
    }catch(error){
      console.error('Error: ', error);
    }
  }, []);

  useEffect(() => {
    GetFeeds();
  }, [GetFeeds]);

  return (
    <>
      <div className='col-md-12 rss-container d-flex flex-row justify-content-between'>
        <div className='d-flex flex-row justify-content-start'>
          <em className="fas fa-1x mr-2 icon-feed" /><Text type='secondary'>Top 3 noticias logísticas</Text>
        </div>
        <div onClick={() => history.push('/rss-feeds')}>
          <Link>
            Ver más resultados
          </Link>
        </div>
      </div>
      <div className='feed-container'>
        {
          feeds.map((feed, key) => {
            return (
              <Card key={key}>
                <Feed feed={feed} type={type}></Feed>
              </Card>
            );
          })
        }
      </div>
    </>
  );
}
RSS.propTypes = {
  type: propTypes.string
};
RSS.defaultProps = {
  type: 'rss'
};

export default withRouter(RSS);