import React, { Component, Suspense } from 'react';
import ContentWrapper from '../../../../../../template/Layout/ContentWrapper';
import { Row } from 'reactstrap';
import Comments from '../../../../../Components/Comments';
import Documents from './Documents';
import Events from './Events';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Services } from '../../../../Services';
import axios from 'axios';
const Summary = React.lazy(() => import('./Summary'));

class CustomDetail extends Component {
  state = {
    allSteps: [],
    id: this.props.match.params.id,
    commentsData: [
      {
        ComType: 'Tipo 1',
        ComTypeId: 1,
        Comment: 'Comentario de prueba',
        CommentId: 73,
        Date: '2019-06-20',
        Module: 'Comentario',
        ModuleId: 1,
        RowId: 861,
        UpdateBy: 'API',
      },
      {
        ComType: 'Tipo 2',
        ComTypeId: 1,
        Comment: 'Comentario de prueba',
        CommentId: 73,
        Date: '2019-07-02',
        Module: 'Comentario',
        ModuleId: 1,
        RowId: 861,
        UpdateBy: 'API',
      },
    ],
    catalogsData: [
      {
        ComTypeId: 1,
        ModuleId: 1,
        ComType: 'Supplier Follow Up',
        value: 1,
        label: 'Supplier Follow Up',
      },
      {
        ComTypeId: 2,
        ModuleId: 1,
        ComType: 'Order Quality',
        value: 2,
        label: 'Order Quality',
      },
      {
        ComTypeId: 3,
        ModuleId: 1,
        ComType: 'Order Quantities',
        value: 3,
        label: 'Order Quantities',
      },
      {
        ComTypeId: 10,
        ModuleId: 1,
        ComType: 'Other',
        value: 10,
        label: 'Other',
      },
    ],
    steps: [
      {
        label: 'Instrucción Cliente AA',
        value: '',
        id: 3,
        completed: false,
        order: 1,
        middleStep: '',
      },
      {
        label: 'ATA (Manifiesto)',
        value: '',
        id: 5,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: '',
      },
      {
        label: 'Ingreso Depósito',
        value: '',
        id: 7,
        completed: false,
        order: 3,
        middle: false,
        idStep: 'step3',
        middleStep: '',
      },
      {
        label: 'Levante',
        value: '',
        id: 12,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: '',
      },
      {
        label: 'Despacho',
        value: '',
        id: 14,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: '',
      },
      {
        label: 'Radicación de Factura',
        value: '',
        id: 16,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: '',
      },
    ],
  };

  componentDidMount = () => {
    this.getEvents();
    this.getDataForId();
  };

  getDataForId = () => {
    axios
      .get(
        `${Services.Get_Traceability_Imports_ForId.path}/customs/dataCustomImports/${this.state.id}`
      )
      .then((success) => {
        let commentsData = [];
        let data = {
          ComType: '',
          ComTypeId: 1,
          Comment: success.data.data.infoImport[0].Comentarios,
          CommentId: '',
          Date: success.data.data.infoImport[0].Comentarios
            ? success.data.data.infoImport[0].ETA
            : '',
          Module: 'Comentario',
          ModuleId: 1,
          RowId: '',
          UpdateBy: 'API',
        };
        commentsData.push(data);
        let Info = [];
        Info.push(success.data.data.infoImport[0]);
        this.setState({
          commentsData,
          HBL_HAWB: success.data.data.HBL_HAWB
            ? success.data.data.infoImport[0].HBL_HAWB
            : '',
          Estatus: success.data.data.infoImport
            ? success.data.data.infoImport[0].Estatus
            : '',
          DocTransporte: success.data.data.infoImport
            ? success.data.data.infoImport[0].DocTransporte
            : '',
          doImportImpo: success.data.data.infoImport
            ? success.data.data.infoImport[0].DOAduanaImpo
            : '',
          cliente: success.data.data.infoImport ? Info[0].Cliente : '',
          NIT: success.data.data.infoImport ? Info[0].NIT : '',
          Grupo: success.data.data.infoImport ? Info[0].Grupo : '',
          FechaActualizacion: success.data.data.infoImport
            ? Info[0].FechaActualizacion
            : '',
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getEvents = () => {
    const { steps, id } = this.state;
    let moduleId = 1;
    // let idContainer = 1816;
    axios
      .get(`${Services.Get_Events_General.path}/events/all/${moduleId}/${id}`)
      .then((response) => {
        let update = false;
        for (let index = response.data.steps.length; index >= 0; index--) {
          const element = response.data.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = steps;
        let dataEvents = response.data.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {
            if (e.EventDescriptionId === f.id) {
              findNext = true;
              f.value = e.EventDate;
              if (e.InTimeLine === '1') {
                f.completed = true;
              } else {
                findNext = false;
              }
            } else {
              findNext = false;
            }
            if (findNext) {
              f.completed = true;
            }
            return e;
          });
          return f;
        });
        newSteps.sort(function (a, b) {
          return b.order - a.order;
        });
        let findTrue = false;
        newSteps.map((e) => {
          if (e.completed) {
            findTrue = true;
          }
          if (findTrue) {
            e.completed = true;
          }
          return e;
        });
        newSteps.sort(function (a, b) {
          return a.order - b.order;
        });
        this.setState({ steps: newSteps, allSteps: dataEvents }, () => { });
      })
      .catch((error) => { });
  };

  render() {
    const { commentsData, catalogsData } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <legend className='legendTitle'>
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/ICONOGRAFIA_ROL4LL_TRANSPORT_RGB-19.png"
              height="28"
              width="28"
            />
            Detalle Aduana Importación
          </legend>
        </div>
        <Tab
          menu={{ secondary: true, className: 'wrapped' }}
          menuPosition="right"
          panes={[
            {
              menuItem: 'Resumen',
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Summary
                    FechaActualizacion={this.state.FechaActualizacion}
                    cliente={this.state.cliente}
                    NIT={this.state.NIT}
                    Grupo={this.state.Grupo}
                    doImportImpo={this.state.doImportImpo}
                    HBL_HAWB={this.state.HBL_HAWB}
                    Estatus={this.state.Estatus}
                    DocTransporte={this.state.DocTransporte}
                    steps={this.state.steps}
                  />
                </Suspense>
              ),
            },
            {
              menuItem: 'Eventos',
              render: () => (
                <Events
                  cliente={this.state.cliente}
                  NIT={this.state.NIT}
                  Grupo={this.state.Grupo}
                  Estatus={this.state.Estatus}
                  doImportImpo={this.state.doImportImpo}
                  HBL_HAWB={this.state.HBL_HAWB}
                  DocTransporte={this.state.DocTransporte}
                  steps={this.state.steps}
                  allSteps={this.state.allSteps}
                />
              ),
            },
            {
              menuItem: 'Comentarios',
              render: () => (
                <Comments data={commentsData} catalogs={catalogsData} />
              ),
            },
            {
              menuItem: 'Documentos',
              render: () => (
                <Documents rolId={12} shipment="" shipmentDataPlane="" />
              ),
            },
          ]}
        />
      </ContentWrapper>
    );
  }
}

export default withRouter(CustomDetail);
