import React, { Component, Suspense } from "react";
import { Row, Card, CardBody, CardText, Col } from "reactstrap";
import { Tab } from "semantic-ui-react";
import { connect } from "react-redux";

import ContentWrapper from "../../../template/Layout/ContentWrapper";
import { MyResponsivePieCanvas } from "../../Components/Graphics/DonutGraph";
import { MyResponsiveBarCanvas } from "../../Components/Graphics/BarChart";

//import Map from "./../../Components/Maps/map";
import Loader from "../../../Global/Loader";
import axios from "axios";
import { Services } from "../Services";
import "./styles.scss";
// import ClientesList from '../../../../services/storageClient.js';

// const clientSave = new ClientesList();
let coloresAzules = [
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
  "#2196F3",
  "#42A5F5",
  "#64B5F6",
  "#90CAF9",
  "#BBDEFB",
  "#E3F2FD",
];
const CustomImports = React.lazy(() => import("./components/CustomImports"));
const Map = React.lazy(() => import("../../Components/Maps/map"));
//const CustomDta = React.lazy(() => import("./components/CustomDta"));
//const CustomExports = React.lazy(() => import("./components/CustomsExports"));

class GroundTransportation extends Component {
  state = {
    // clientesSelect: clientSave.getAuthentication('client'),
    map: (
      <Suspense fallback={<div>Loading...</div>}>
        <Map
          polylineData={[]}
          heidIcon={30}
          style={{ maxHeight: "300px" }}
          marker={[]}
          visible={true}
          type={"polyline"}
          data={[]}
          dataDrawer={""}
          zoom={5}
        />
      </Suspense>
    ),
    clientesSelect: this.props.clients
      ? JSON.stringify(this.props.clients)
      : "[0]",
    zoom: 5,
    position: {
      lat: 4.0,
      lng: -72.0,
    },
    CustomLocation: [],
    markers: [],
    dataMap: [],
    graphs: [],
    dataDrawer: "",
    polyline: [],
    visible: false,
    data: [],
    dataImports: [],
    dataOut: [],
    donutChart: [
      {
        status: "Contenedor",
        value: 5366,
        id: "Contenedor",
        color: "#4EAA29",
        key: 10,
      },
      {
        status: "Mercancia suelta",
        value: 39883,
        id: "Mercancia suelta",
        color: "#3B831E",
        key: 9,
      },
    ],
    donutChartTrafico: [
      {
        status: "Solicitado",
        Cancelled: 985,
        id: "Solicitado",
        CancelledColor: "#F9F3C1",
        key: 3,
      },
      {
        status: "Mercancia Cargada",
        Cancelled: 4,
        id: "Mercancia Cargada",
        CancelledColor: "#FAF2B1",
        key: 4,
      },
      {
        status: "En trancito",
        Cancelled: 290,
        id: "En trancito",
        CancelledColor: "#FBF1A0",
        key: 5,
      },
      {
        status: "Mercancia descargada",
        Cancelled: 180,
        id: "Mercancia descargada",
        CancelledColor: "#FCF07B",
        key: 6,
      },
      {
        status: "Contenedor devuelto",
        Cancelled: 80,
        id: "Contenedor devuelto",
        CancelledColor: "#F5E663",
        key: 7,
      },
    ],
    barTable: [
      {
        status: "Radicado",
        Cancelled: 9853,
        id: "Radicado",
        CancelledColor: "#F9F3C1",
        key: 7,
      },
      {
        status: "Facturado",
        Cancelled: 4,
        id: "Facturado",
        CancelledColor: "#FAF2B1",
        key: 6,
      },
      {
        status: "Manifestado",
        Cancelled: 290,
        id: "Manifestado",
        CancelledColor: "#FBF1A0",
        key: 5,
      },
      {
        status: "Autorizado",
        Cancelled: 0,
        id: "Autorizado",
        CancelledColor: "#FCF07B",
        key: 4,
      },
      {
        status: "En Zona Primaria",
        Cancelled: 0,
        id: "En Zona Primaria",
        CancelledColor: "#F5E663",
        key: 3,
      },
      {
        status: "Recolectado",
        Cancelled: 0,
        id: "Recolectado",
        CancelledColor: "#FDEE4F",
        key: 2,
      },
      {
        status: "Instruccionado",
        Cancelled: 290,
        id: "Instruccionado",
        CancelledColor: "#FAEA4E",
        key: 1,
      },
    ],
    totalCards: [],
  };

  componentDidMount = () => {
    this.getDataCharts();
    window.addEventListener("resize", () => {
      let newGraphs = this.state.graphs;
      newGraphs.map((e) => {
        e.width = this.calculateWidth();
        return e;
      });
      this.setState({ graphs: newGraphs });
    });
  };

  getDataCharts = () => {
    let data = {
      cliente: this.state.clientesSelect,
    };
    axios
      .post(`${Services.Get_Chars_StatusTranspor.path}/customs/charts`, data)
      .then((success) => {
        //this.getLocationCustom(); data.sort((a, b) => a.EventId - b.EventId)
        this.setState(
          {
            donutChart: success.data.data.chartImpr,
            donutChartTrafico: success.data.data.charExpo.sort(
              (a, b) => b.key - a.key
            ),
          },
          () => {
            this.getLocationCustom();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getLocationCustom = () => {
    let data = {
      cliente: this.state.clientesSelect,
    };

    axios
      .post(
        `${Services.Get_Location_Transpor.path}/customs/locationAduana`,
        data
      )
      .then((success) => {
        let totalOp = success.data.data.length;
        let totalImport = 0;
        let totalExp = 0;
        let totalOt = 0;

        let markers = [];
        let polylineData = [];
        let totalEmbarques = Number(success.data.data.length).toLocaleString(
          "En-us"
        );
        //let donutChart = [];
        success.data.data.forEach((element) => {
          switch (element.Modalidad) {
            case "EXPO":
              totalExp += 1;
              break;
            case "IMPO":
              totalImport += 1;
              break;
            default:
              totalOt += 1;
              break;
          }
        });
        success.data.data.map((e, index) => {
          if (
            e.LatitudOrigen &&
            e.LongitudOrigen &&
            e.LatitudDestino &&
            e.LongitudDestino
          ) {
            if (e.LatitudOrigen && e.LongitudOrigen) {
              let obj = {
                anchor: 15,
                key: `${index}From`,
                title: "Origen",
                name: e.TmsId,
                data: e,
                position: {
                  lat: e.LatitudOrigen,
                  lng: e.LongitudOrigen,
                  //lat: e.LongitudOrigen,
                  //lng: e.LatitudOrigen,
                },
                icon: {
                  url:
                    "https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png",
                },
              };

              let position = {
                lat: Number(e.LatitudOrigen),
                lng: Number(e.LongitudOrigen),
                //lat: Number(e.LongitudOrigen),
                //lng: Number(e.LatitudOrigen),
              };
              polylineData.push(position);
              markers.push(obj);
            }
            if (e.LatitudDestino && e.LongitudDestino) {
              let obj = {
                key: `${index}To`,
                title: "Destino",
                name: e.TmsId,
                data: e,
                position: {
                  lat: e.LatitudDestino,
                  lng: e.LongitudDestino,
                  //lat: e.LongitudDestino,
                  //lng: e.LatitudDestino,
                },
                icon: {
                  url:
                    "https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png",
                },
              };
              let position = {
                lat: Number(e.LatitudDestino),
                lng: Number(e.LongitudDestino),
                //lat: Number(e.LongitudDestino),
                //lng: Number(e.LatitudDestino),
              };
              polylineData.push(position);
              markers.push(obj);
            }
          }
          return e;
        });

        let totalCards = [
          {
            title: "Total de Operaciones",
            value: Number(totalOp).toLocaleString("En-us"),
            icon: "fa-2x mr-2 fas fa-map-marker-alt",
            firstColor: {
              backgroundColor: "#2f80e7",
              color: "#fff",
            },
            secondColor: {
              backgroundColor: "#7eb3f6 ",
              color: "#fff",
            },
          },
          {
            title: "Total de Importaciones",
            value: Number(totalImport).toLocaleString("En-us"),
            icon: "fa-arrow-alt-circle-right",
            firstColor: {
              backgroundColor: "#57b65b",
              color: "#fff",
            },
            secondColor: {
              backgroundColor: "#83db86",
              color: "#fff",
            },
          },
          {
            title: "Total de Exportaciones",
            value: Number(totalExp).toLocaleString("En-us"),
            icon: "fa-arrow-alt-circle-left",
            firstColor: {
              backgroundColor: "#9e9e9e",
              color: "#fff",
            },
            secondColor: {
              backgroundColor: "#C0C0C0",
              color: "#fff",
            },
          },
          {
            title: "Otros",
            value: Number(totalOt).toLocaleString("En-us"),
            icon: "fa-arrow-alt-circle-right",
            firstColor: {
              backgroundColor: "#5E3E62",
              color: "#fff",
            },
            secondColor: {
              backgroundColor: "#917999",
              color: "#fff",
            },
          },
        ];
        /*
         <Map
                polylineData={this.state.dataMap}
                heidIcon={30}
                style={{ maxHeight: "300px" }}
                marker={this.state.markers}
                visible={true}
                type={"polylineDrawer"}
                data={this.state.dataMap}
                dataDrawer={this.state.markers}
                zoom={zoom}
              />
        */
        this.setState(
          {
            CustomLocation: success.data.data,
            markers: markers,
            dataMap: polylineData,
            polyline: polylineData,
            totalCards: totalCards,
            //donutChart: donutChart,
            fullLoader: true,
          },
          () => {
            this.setState({
              graphs: [
                {
                  title: "Operaciones por Estatus",
                  type: "bar",
                  data: this.state.donutChartTrafico,
                  width: this.calculateWidth(),
                  height: "280",
                  class: 6,
                },
                {
                  title: "Operaciones por Tipo de Transporte",
                  type: "pie",
                  data: this.state.donutChart,
                  width: this.calculateWidth(),
                  height: "280",
                  theme: true,
                  class: 6,
                },
              ],
              map: (
                <Suspense fallback={<div>Loading...</div>}>
                  <Map
                    polylineData={this.state.polylineData}
                    heidIcon={30}
                    style={{ maxHeight: "300px" }}
                    marker={this.state.markers}
                    visible={true}
                    type={"polyline"}
                    data={this.state.polylineData}
                    dataDrawer={this.state.dataDrawer}
                    zoom={5}
                  //hiddenDrawerInfo={true}
                  />
                </Suspense>
              ),
            });
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  toggleDrawerMap = (data) => (evt) => {
    this.setState({ visible: true, dataDrawer: data }, () => {
      this.setState({
        map: (
          <Suspense fallback={<div>Loading...</div>}>
            <Map
              polylineData={this.state.polylineData}
              heidIcon={30}
              style={{ maxHeight: "300px" }}
              marker={this.state.markers}
              visible={true}
              type={"polyline"}
              data={this.state.polylineData}
              dataDrawer={this.state.dataDrawer}
              zoom={5}
            />
          </Suspense>
        ),
      });
    });
  };

  calculateWidth = () => {
    if (window.innerWidth >= 1756) {
      return (window.innerWidth / 4.8).toString();
    } else {
      return (window.innerWidth / 5.5).toString();
    }
  };

  formatPolyLine = (from, to) => {
    let out = [
      {
        lat: parseFloat(from.Location.Latitude),
        lng: parseFloat(from.Location.Longitude),
      },
      {
        lat: parseFloat(to.Location.Latitude),
        lng: parseFloat(to.Location.Longitude),
      },
    ];
    return out;
  };

  render() {
    let {
      markers,
      totalCards,
      graphs,
      position,
      zoom,
      fullLoader,
    } = this.state;
    return (
      <ContentWrapper>
        <Loader hidden={fullLoader}></Loader>
        <div className="content-heading">
          <legend className="legendTitle">
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/icon_camion.png"
              height="28"
              width="28"
            />
            Transporte Terrestre
          </legend>
        </div>

        <Row>
          <Col xs={12} md={12} lg={12}>
            <div class="row">
              {totalCards.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="card flex-row align-items-center align-items-stretch border-0 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div
                      className="col-4 d-flex align-items-center justify-content-center rounded-left"
                      style={e.firstColor}
                    >
                      <em className={`fas fa-2x ${e.icon}`}></em>{" "}
                    </div>
                    <div
                      className="col-8 rounded-right totalCardInfo"
                      style={e.secondColor}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          textAlign: "center",
                          marginTop: "4px",
                        }}
                      >
                        {e.title}
                      </div>
                      <div style={{ fontSize: "18px" }}>
                        <center style={{ fontSize: "20px", marginTop: "6px" }}>
                          <strong>{e.value}</strong>
                        </center>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Row>
              {graphs.map((e) => {
                let typeGraph =
                  e.type === "pie" ? (
                    <MyResponsivePieCanvas data={e.data} theme={e.theme} />
                  ) : (
                    <MyResponsiveBarCanvas
                      left={150}
                      position="horizontal"
                      data={e.data}
                    />
                  );
                return (
                  <Col md={e.class} lg={e.class} sm={10} xs={10}>
                    <Card className="graphcardsize">
                      <CardBody className="graphcardbody">
                        <CardText
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                            fontFamily: "unset",
                            fontWeight: "bold",
                          }}
                        >
                          {e.title}
                        </CardText>
                        {typeGraph}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col xs={12} md={12} lg={12}>
            {this.state.map}
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Suspense fallback={<div>Loading...</div>}>
              <CustomImports
                _user={this.props}
                clientesSelect={this.state.clientesSelect}
                toggleDrawerMap={this.toggleDrawerMap}
              />
            </Suspense>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroundTransportation);
