export const Services = {
   "GET_REPORTS_ALL": {
      "path": "https://roldanapireport.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain contacts and users related to the customer, for the modality of view and edition ******ROLDAN********"
   },
   "GET_VISTA_REPORTES": {
      "path": "https://roldanapireport.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain contacts and users related to the customer, for the modality of view and edition ******ROLDAN********"
   },
   "DOWNLOAD_FILES": {
      "path": "https://roldanapireport.azurewebsites.net",
      "method": "GET",
      "descrp": "method to obtain contacts and users related to the customer, for the modality of view and edition ******ROLDAN********"
   },
   "REPORTS": {
      "path": "https://roldanapireport.azurewebsites.net",
      "method": "GET",
      "descrp": "METHOD TO SET REPORT"
   }
}