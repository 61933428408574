import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Popup } from "semantic-ui-react";
import "./styles/cards.scss";

const limit = 12;
const highLimit = 20;

export const CardDespacho = (props) => {
  let { element } = props;
  return (
    <>
      <Card className="_clickCard mySearchCard" id={Math.random()}>
        <CardBody>
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                {/* Icono & Booking Number & Status */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================ICONO===================================== */}
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                Despacho
                              </span>
                            </div>
                            <div className="mb-mail-subject">
                              <em
                                className="fa-2x mr-2 fas fa-sort-amount-up"
                                style={{ color: "#582C83", marginLeft: "11px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ==========================CLIENTE ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Cliente ? (
                                element.Cliente.length > limit ? (
                                  <Popup
                                    content={element.Cliente}
                                    key={element.Cliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Cliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Cliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Numero de despacho===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              No. de Despacho
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              <Link
                                to={
                                  "/storage-offices-detail/" +
                                  element.DespachoId
                                }
                              >
                                {element.NoDespacho ? (
                                  element.NoDespacho.length > limit ? (
                                    <Popup
                                      content={element.NoDespacho}
                                      key={element.NoDespacho}
                                      position=""
                                      trigger={
                                        <span>
                                          {element.NoDespacho.substring(
                                            0,
                                            limit - 3
                                          )}
                                          ...
                                        </span>
                                      }
                                    />
                                  ) : (
                                    element.NoDespacho
                                  )
                                ) : (
                                  ""
                                )}
                              </Link>
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Estatus, REF. Cliente & Doc. de transporte */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================Estatus================================================ */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Estatus
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Estatus ? (
                                element.Estatus.length > limit ? (
                                  <Popup
                                    content={element.Estatus}
                                    key={element.Estatus}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Estatus.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Estatus
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================REF. Cliente=========================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Ref. de Cliente
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.RefCliente ? (
                                element.RefCliente.length > limit ? (
                                  <Popup
                                    content={element.RefCliente}
                                    key={element.RefCliente}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.RefCliente.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.RefCliente
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ==========================Doc. de transporte===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Doc. de Transporte
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.DocTransporte ? (
                                element.DocTransporte.length > limit ? (
                                  <Popup
                                    content={element.DocTransporte}
                                    key={element.DocTransporte}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.DocTransporte.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.DocTransporte
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Almacen & Placas de vehiculo*/}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ==========================Almacen ===================================== */}
                    <div className="col-md-5">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Almacén
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Almacen ? (
                                element.Almacen.length > limit ? (
                                  <Popup
                                    content={element.Almacen}
                                    key={element.Almacen}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Almacen.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Almacen
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Placas de vehiculo===================================== */}
                    <div className="col-md-7">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Placas de Vehículo
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.PlacaVehiculo ? (
                                element.PlacaVehiculo.length > limit ? (
                                  <Popup
                                    content={element.PlacaVehiculo}
                                    key={element.PlacaVehiculo}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.PlacaVehiculo.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.PlacaVehiculo
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Destinatario & Notificacion de salida */}
                <div className="col-md-3">
                  <div className="row text-center">
                    {/* ========================== Destinatario===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Destinatario
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.Destinatario ? (
                                element.Destinatario.length > limit ? (
                                  <Popup
                                    content={element.Destinatario}
                                    key={element.Destinatario}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.Destinatario.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.Destinatario
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Notificacion de salida ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Notificación de Salida
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaNotificacionSalida ? (
                                element.FechaNotificacionSalida.length >
                                limit ? (
                                  <Popup
                                    content={element.FechaNotificacionSalida}
                                    key={element.FechaNotificacionSalida}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaNotificacionSalida.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaNotificacionSalida
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                    {/* ========================== Fecha salida ===================================== */}
                    <div className="col-md-4">
                      <div className="mb-mail-meta">
                        <div className="mb-mail-subject">
                          <center>
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Fecha de Salida
                            </span>
                          </center>
                        </div>
                        <div className="mb-mail-from">
                          <center>
                            <span style={{ fontSize: "12px" }}>
                              {element.FechaNotificacionSalida ? (
                                element.FechaNotificacionSalida.length >
                                limit ? (
                                  <Popup
                                    content={element.FechaNotificacionSalida}
                                    key={element.FechaNotificacionSalida}
                                    position=""
                                    trigger={
                                      <span>
                                        {element.FechaNotificacionSalida.substring(
                                          0,
                                          limit - 3
                                        )}
                                        ...
                                      </span>
                                    }
                                  />
                                ) : (
                                  element.FechaNotificacionSalida
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* END OF MAIN DIV */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
