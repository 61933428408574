import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { Card, Input, Grid, Button, Popup } from "semantic-ui-react";
import { Col, Row, Label } from "reactstrap";
import Loader from "../../../../Global/Loader";
import { IncotermsInfo } from "./data";
import "../../style.scss";

function Incoterms() {
  let [valueGrupoC, setValueGrupoC] = React.useState("cfr");
  let [valueGrupoD, setValueGrupoD] = React.useState("dpu");
  let [valueGrupoF, setValueGrupoF] = React.useState("fca");
  let [activeGC, setActiveGC] = React.useState("cfr");
  let [activeGD, setActiveGD] = React.useState("dpu");
  let [activeGF, setActiveGF] = React.useState("fca");

  function handleClickGC(inpt) {
    setValueGrupoC(inpt);
    setActiveGC(inpt);
  }

  function handleClickGD(inpt) {
    setValueGrupoD(inpt);
    setActiveGD(inpt);
  }

  function handleClickGF(inpt) {
    setValueGrupoF(inpt);
    setActiveGF(inpt);
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <img
            src={
              "https://fsposeidon.blob.core.windows.net/roldan/ROLDAN_INCOTERMS_2022.jpg"
            }
            alt=""
            width={"100%"}
            height={"auto"} /* style={{marginLeft:'18px'}} */
          />
        </div>
      </div>

      <br />

      <div className="row">
        <div
          className="col-12"
          style={{ /* backgroundColor: 'pink', */ paddingLeft: "30px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <Label>Grupo C - Entrega Indirecta</Label>
                    <Popup
                      content={IncotermsInfo.grupoC.grupoData}
                      style={{ textAlign: "justify" }}
                      position="top center"
                      wide="very"
                      trigger={
                        <em
                          class="fa-1x icon-info mr-1"
                          style={{ marginLeft: "5px" }}
                        ></em>
                      }
                    />
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-2" style={{ padding: "0" }}>
                      <Button
                        active={activeGC === "cfr"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cfr");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CFR
                      </Button>
                      <Button
                        active={activeGC === "cif"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cif");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CIF
                      </Button>
                      <Button
                        active={activeGC === "cpt"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cpt");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CPT
                      </Button>
                      <Button
                        active={activeGC === "cip"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cip");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CIP
                      </Button>
                    </div>
                    <div className="col-10" style={{ paddingLeft: "0" }}>
                      <Card
                        style={{ height: "182px" }}
                        Card
                        fluid
                        header={IncotermsInfo.grupoC[valueGrupoC].title}
                        description={IncotermsInfo.grupoC[valueGrupoC].data}
                        className="CARD UI REACT STRAP BORDER"
                      ></Card>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row">
                    <Label>Grupo D - Entrega Directa</Label>
                    <Popup
                      content={IncotermsInfo.grupoD.grupoData}
                      style={{ textAlign: "justify" }}
                      position="top center"
                      wide="very"
                      trigger={
                        <em
                          class="fa-1x icon-info mr-1"
                          style={{ marginLeft: "5px" }}
                        ></em>
                      }
                    />
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-2" style={{ padding: "0" }}>
                      <Button
                        active={activeGD === "dpu"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGD("dpu");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        DPU
                      </Button>
                      <Button
                        active={activeGD === "dap"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGD("dap");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        DAP
                      </Button>
                      <Button
                        active={activeGD === "ddp"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGD("ddp");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        DDP
                      </Button>
                    </div>
                    <div className="col-10" style={{ paddingLeft: "0" }}>
                      <Card
                        Card
                        fluid
                        header={IncotermsInfo.grupoD[valueGrupoD].title}
                        description={IncotermsInfo.grupoD[valueGrupoD].data}
                        className="CARD UI REACT STRAP BORDER"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          <div className="row">
            <div className="col-6">
              <div className="row">
                <Label>Grupo E - Entrega Directa a la Salida</Label>
                <Popup
                  content={IncotermsInfo.grupoE.grupoData}
                  style={{ textAlign: "justify" }}
                  position="top center"
                  wide="very"
                  trigger={
                    <em
                      class="fa-1x icon-info mr-1"
                      style={{ marginLeft: "5px" }}
                    ></em>
                  }
                />
              </div>
              <br />
              <div className="row">
                <div className="col-2" style={{ padding: "0" }}>
                  <Button
                    className="BUTTON UI REACT STRAP BORDER EG"
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    EXW
                  </Button>
                </div>
                <div className="col-10" style={{ paddingLeft: "0" }}>
                  <Card
                    Card
                    fluid
                    header={IncotermsInfo.grupoE.exw.title}
                    description={IncotermsInfo.grupoE.exw.data}
                    className="CARD UI REACT STRAP BORDER"
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="row">
                <Label>Grupo F - Entrega Indirecta</Label>
                <Popup
                  content={IncotermsInfo.grupoF.grupoData}
                  style={{ textAlign: "justify" }}
                  position="top center"
                  wide="very"
                  trigger={
                    <em
                      class="fa-1x icon-info mr-1"
                      style={{ marginLeft: "5px" }}
                    ></em>
                  }
                />
              </div>
              <br />
              <div className="row">
                <div className="col-2" style={{ padding: "0" }}>
                  <Button
                    active={activeGF === "fca"}
                    className="BUTTON UI REACT STRAP BORDER"
                    onClick={() => {
                      handleClickGF("fca");
                    }}
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    FCA
                  </Button>
                  <Button
                    active={activeGF === "fas"}
                    className="BUTTON UI REACT STRAP BORDER"
                    onClick={() => {
                      handleClickGF("fas");
                    }}
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    FAS
                  </Button>
                  <Button
                    active={activeGF === "fob"}
                    className="BUTTON UI REACT STRAP BORDER"
                    onClick={() => {
                      handleClickGF("fob");
                    }}
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    FOB
                  </Button>
                </div>
                <div className="col-10" style={{ paddingLeft: "0" }}>
                  <Card
                    style={{ height: "220px" }}
                    Card
                    fluid
                    header={IncotermsInfo.grupoF[valueGrupoF].title}
                    description={IncotermsInfo.grupoF[valueGrupoF].data}
                    className="CARD UI REACT STRAP BORDER"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Incoterms;
