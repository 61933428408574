import React,{useEffect,useCallback}  from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import MainMenu from './SecComponents/chatBotButtons';
import CurrentLabel from './SecComponents/currentLabel';
import OnlyLabel from './SecComponents/label';
import RateService from './SecComponents/ranking';
import AnotherQuery from './SecComponents/anotherQuery';
import OtraConsulta from './SecComponents/realizarOtraConsulta';

/** Consultar Estatus de Operación */
import OperationStatusMenu from './OperationStatus/operationStatusMenu';
import International from './OperationStatus/international';
import Aduana from './OperationStatus/aduana';
import Integrada from './OperationStatus/integrada';
import Almacen from './OperationStatus/almacen';
import ResultsOperationStatus from './OperationStatus/resultsOperationStatus';

/** Ver Documentos de Operación */
import VerDocumentosOperacionMenu from './DocsOperation/docsOperationMenu';
import AlmacenVerDocumentos from './DocsOperation/almacen';
import AduanaVerDocumentos from './DocsOperation/aduana';
import ResultadosVisializarDocumentos from './DocsOperation/resultadosDocumentos';

/** Tengo una novedad con Rol4ll */
import RoldanProblemMenu from './ProblemRoldan/menu';
import ErrorType from './ProblemRoldan/handleError';
import RoldanSendMail from './ProblemRoldan/enviarCorreo';

/** Tengo una novedad con mi Operacion */
import ProblemaOperacionMenu from './ProblemOperation/menu';
import TipoProblemaOperacion from './ProblemOperation/selectProblemType';
import HandleType from './ProblemOperation/proporcionarDatos';
import SendMail from './ProblemOperation/enviarCorreo';

/** Cotizar un Servicio*/
import CotizarServicioMenu from './QuoteService/menu';
import Cotizador from './QuoteService/handleQuoteService';

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#003F70',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#003F70',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const mainMenu = [
  {
    label: 'Consultar estatus de operación',
    value: 'currentLabel',
  },
  {
    label: 'Ver documentos de operación',
    value: 'currentLabel',
  },
  {
    label: 'Tengo una novedad con mi operación',
    value: 'currentLabel',
  },
  {
    label: 'Tengo una novedad con Rol4ll',
    value: 'currentLabel',
  },
  { label: 'Cotizar un servicio', value: 'currentLabel' },
];

const steps = [
  {
    id: 'Bienvenida',
    message:
      // '¡Bienvenido a Roldan Logística! Por favor, seleccione la opción que necesite.',
      '¡Bienvenido a Roldán Logistics! ¿Te puedo ayudar en algo?',
    trigger: 'MainMenu',
    
  },
  {
    id: 'MainMenu',
    component: <MainMenu buttons={mainMenu} />,
    waitAction: true,
  },
  {
    id: 'SecondaryMenu',
    message: 'Por favor, seleccione la opción que necesite.',
    trigger: 'MainMenu',
  },
  {
    id: 'currentLabel',
    component: <CurrentLabel />,
  },
  {
    id: 'CalificarServicio',
    message: 'Por favor ayúdanos a evaluar el servicio',
    trigger: 'Ranking',
  },
  {
    id: 'Ranking',
    component: <RateService />,
  },
  {
    id: 'RealizarOtraConsulta',
    component: <OtraConsulta />,
    asMessage: true,
  },
  {
    id: 'AnotherQueryButtons',
    component: <AnotherQuery />,
  },
  {
    id: 'Despedida',
    message:
      '¡Agradecemos que utilice Chat Bot de Roldan Logistica, hasta pronto!',
    trigger: 'SecondaryMenu',
  },
  {
    id: 'endProcessLabel',
    component: <OnlyLabel />,
  },
  /** CONSULTAR ESTATUS DE OPERACION */
  {
    id: 'Consultar estatus de operación',
    message: 'Que tipo de operacion necesita consultar',
    trigger: 'selectOperationStatus',
  },
  {
    id: 'selectOperationStatus',
    component: <OperationStatusMenu />,
  },
  {
    id: 'Internacional',
    component: <International />,
    asMessage: true,
    trigger: 'BusquedaInternacional',
  },
  {
    id: 'Aduana',
    component: <Aduana />,
    asMessage: true,
    trigger: 'BusquedaAduana',
  },
  {
    id: 'Integrada',
    component: <Integrada />,
    asMessage: true,
    trigger: 'BusquedaIntegrada',
  },
  {
    id: 'Almacén',
    component: <Almacen />,
    asMessage: true,
    trigger: 'BusquedaAlmacen',
  },
  {
    id: 'BusquedaInternacional',
    user: true,
    trigger: 'Resultados Estatus de Operacion',
  },
  {
    id: 'BusquedaAduana',
    user: true,
    trigger: 'Resultados Estatus de Operacion',
  },
  {
    id: 'BusquedaIntegrada',
    user: true,
    trigger: 'Resultados Estatus de Operacion',
  },
  {
    id: 'BusquedaAlmacen',
    user: true,
    trigger: 'Resultados Estatus de Operacion',
  },
  {
    id: 'Resultados Estatus de Operacion',
    component: <ResultsOperationStatus />,
    waitAction: true,
  },
  /**
   * VER DOCUMENTOS DE OPERACION
   * */
  {
    id: 'Ver documentos de operación',
    message: 'Que tipo de operación necesita consultar',
    trigger: 'menuDocumentosDeOperacion',
  },
  {
    id: 'menuDocumentosDeOperacion',
    component: <VerDocumentosOperacionMenu />,
  },
  {
    id: 'almacenVerDocumentos',
    component: <AlmacenVerDocumentos />,
    asMessage: true,
    trigger: 'DocumentInput',
  },
  {
    id: 'aduanaVerDocumentos',
    component: <AduanaVerDocumentos />,
    asMessage: true,
    trigger: 'DocumentInput',
  },
  {
    id: 'DocumentInput',
    user: true,
    trigger: 'DocumentResult',
  },
  {
    id: 'DocumentResult',
    component: <ResultadosVisializarDocumentos />,
    waitAction: true,
  },

  /**
   * PROBLEMA CON ROL4LL
   */
  {
    id: 'Tengo una novedad con Rol4ll',
    message:
      '¿Cual es la novedad con Rol4ll? Por favor, indique alguna de las siguientes opciones:',
    trigger: 'Rol4llMenu',
  },
  {
    id: 'Rol4llMenu',
    component: <RoldanProblemMenu />,
  },
  {
    id: 'tipoError',
    component: <ErrorType />,
    asMessage: true,
    trigger: 'CalificarServicio',
  },
  {
    id: 'DescripcionProblemaRoldan',
    message: 'Por favor describa el problema que presenta',
    trigger: 'InputProblemRoldan',
  },
  {
    id: 'InputProblemRoldan',
    user: true,
    trigger: 'SendCorreo',
  },
  {
    id: 'SendCorreo',
    component: <RoldanSendMail />,
    asMessage: true,
  },
  /**
   * Tengo una novedad con mi Operacion
   */
  {
    id: 'Tengo una novedad con mi operación',
    message: '¿A qué proceso pertenece su operación?',
    trigger: 'problemaOperacionMenu',
  },
  {
    id: 'problemaOperacionMenu',
    component: <ProblemaOperacionMenu />,
  },
  {
    id: 'ProblemaOperacionMensaje',
    message:
      'Por favor Seleccione el error que presenta con la opcion mencionada.',
    trigger: 'SeleccionaProblemaOperacion',
  },
  {
    id: 'SeleccionaProblemaOperacion',
    component: <TipoProblemaOperacion />,
  },
  {
    id: 'DialogoProblemaOperacion',
    component: <HandleType />,
    asMessage: true,
    trigger: 'DescripcionProblemaOperacion',
  },
  {
    id: 'DescripcionProblemaOperacion',
    user: true,
    trigger: 'DescripcionDada',
  },
  {
    id: 'DescripcionDada',
    message: 'Por favor describa el problema que presenta',
    trigger: 'problemDescription',
  },
  {
    id: 'problemDescription',
    user: true,
    trigger: 'SendErrorOperacion',
  },
  {
    id: 'SendErrorOperacion',
    component: <SendMail />,
    asMessage: true,
  },

  /**
   * Cotizar un servicio
   */
  {
    id: 'Cotizar un servicio',
    message: 'Por favor indiquenos ¿Qué tipo de servicio requiere cotizar?',
    trigger: 'MenuCotizarServicio',
  },
  {
    id: 'MenuCotizarServicio',
    component: <CotizarServicioMenu />,
    waitAction: true,
  },
  {
    id: 'Cotizador',
    component: <Cotizador />,
    asMessage: true,
    waitAction: true,
  },
];

function ChatTemplate() {
  const [opened, setopened] = React.useState(false);

  useEffect(() => {
    if (opened === false){
      setTimeout(() => {
        changeFlat()
      }, 2000);
    }
  }, []);

  const changeFlat = useCallback(async() => {
    try{
      setopened(true);
    }catch(error){
      console.error('Error: ', error);
    }
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ChatBot
          headerTitle={'Rol4ll Chat Bot'}
          steps={steps}
          floating={true}
          opened={opened}
          toggleFloating={() => {
            setopened(!opened);
          } }
          floatingIcon={
            <img
              src="https://fsposeidon.blob.core.windows.net/roldan/ROL4LL_CHATBOTICON.png"
              width={'65px'}
              alt="roldan chatbot"
            />
          }
          floatingStyle={{ width: '40px', height: '40px' }}
          width={'400px'}
          placeholder={'Escribe tu mensaje'}
        />
      </ThemeProvider>
    </div>
  );
}

export default ChatTemplate;
