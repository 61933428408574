import React from 'react';
import { Container, Label } from 'semantic-ui-react';

const defaultStyles = {
  fontSize: '12px',
  backgroundColor: '#FDFEFE',
};

function OnlyLabel() {
  return (
    <div className="row" style={{ width: '100%', backgroundColor: '#FDFEFE' }}>
      <Container>
        <center>
          <Label style={defaultStyles}>
            Lo hemos redireccionado al detalle de la operación donde encontrará la información específica de la referencia ingresada.
          </Label>
        </center>
      </Container>
    </div>
  );
}

export default OnlyLabel;
