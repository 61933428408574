import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const opStatusMenu = [
  {
    label: ['Almacén', 'almacenVerDocumentos'],
    value: 'currentLabel',
  },
  { label: ['Aduana', 'aduanaVerDocumentos'], value: 'currentLabel' },
];

function ProblemOperationMenu(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <ChatbotButtons buttons={opStatusMenu} botSteps={props} />
    </div>
  );
}

export default ProblemOperationMenu;
