import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Grid } from "semantic-ui-react";
import { Divider } from "antd";
import Chart from "react-google-charts";
import { connect } from "react-redux";
import { Typography, Image } from 'antd';
import ContentWrapper from "../../template/Layout/ContentWrapper";
import FlotChart from "../../template/Common/Flot";
import VectorMap from "./components/Maps/VectorMap";
import EventsTable from "./components/EventsTable";
import CardsTotals from "./components/CardTotals";

// ? Local Imports
import { TableLoader, FullLoader } from "./components/Loader";
import RSS from "./RSS";
import YearsSelect from "./components/CatYears";
import Loader from "../../Global/Loader";

import Axios from "axios";
import { Services } from "./Services";
import { isMovil } from "../../utils/device";
import "./styles.scss";

const defaultColors = {
  markerColor: "#23b7e5", // the marker points
  bgColor: "transparent", // the background
  scaleColors: ["#878c9a"], // the color of the region in the serie
  regionFill: "#bbbec6", // the base region color
};

const flotOptions = {
  series: {
    lines: {
      show: false,
    },
    points: {
      show: true,
      radius: 4,
    },
    splines: {
      show: true,
      tension: 0.4,
      lineWidth: 1,
      fill: 0.5,
    },
  },
  grid: {
    borderColor: "#eee",
    borderWidth: 1,
    hoverable: true,
    backgroundColor: "#fcfcfc",
  },
  tooltip: true,
  tooltipOpts: {
    content: (label, x, y) => x + " : " + y,
  },
  xaxis: {
    tickColor: "#fcfcfc",
    mode: "categories",
  },
  yaxis: {
    min: 0,
    tickColor: "#eee",
    autoscaleMargin: 0.5,
    tickDecimals: 0,
    tickFormatter: (v) => v,
  },
  shadowSize: 0,
};

/*=============================================
=            Dashboard Component Functions    =
=============================================*/
async function calculateLogistics(data) {
  let Logistics = [
    [
      "Element",
      "Total",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
  ];
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    Logistics.push([
      element.label,
      element.value,
      element.color,
      element.extra,
    ]);
  }
  return Logistics;
}
/*=============================================
=            Dashboard Component              =
=============================================*/
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customWidth: window.innerWidth,
      text: "",
      totals: {
        Bookings: "",
        Containers: "",
        CustomerId: "",
        OrderFullfillment: "",
        POs: "",
        Shipments: "",
        VendorFullfillment: "",
      },
      flat: false,
      // clientesSelect: clientSave.getAuthentication('client'),
      flotData: [],
      Attention: [],
      Logistics: [],
      Map: [],
      loadingTrends: true,
      loadingTotals: true,
      RegionValues: {},
      fullLoader: false,
    };
  }
  /*=============================================
  =            Get Info Dashboard Function      =
  =============================================*/
  getInfoDashBoard = async () => {
    const allowUsers = ["1", "2", "3", "4", "5"];

    let data = {
      customer: this.props.clients ? JSON.stringify(this.props.clients) : "[0]",
    };
    let response = await Axios.post(
      `${Services.GET_DASHBOARD_SERVICE.path}/dashboard/total`,
      data
    );

    let getAttention = allowUsers.includes(this.props._rolId)
      ? await Axios.post(
        `${Services.GET_DASHBOARD_SERVICE.path}/dashboard/events`,
        data
      )
      : "";

    let Attention = allowUsers.includes(this.props._rolId)
      ? getAttention.data.data
      : [];

    let Logistics = [];
    Logistics = await calculateLogistics(response.data.data.primeChart);
    this.setState({
      totals: response.data.data.totales,
      Logistics,
      Attention,
      flat: response.data.data.primeChart.length <= 0 ? true : false,
      Map: [],
      RegionValues: response.data.data.regionvalue,
      loadingTrends: false,
      fullLoader: true,
    });
  };
  /*=============================================
  =            Life Cicle Component             =
  =============================================*/

  componentDidMount = () => {

    this.getInfoDashBoard();
    document.getElementById("body").className = "layout-fixed"; // !changed for nothing

    this.setState({
      isMovilDevice: isMovil(),
    });
  };

  handleResize = () => {
    this.setState({ customWidth: window.innerWidth });
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  onChangeYear = async (year, modulo, text) => {
    // ? flotData
    let data2 = {
      cliente: this.props.clients ? JSON.stringify(this.props.clients) : "[0]",
      year: year,
      module: modulo,
    };
    try {
      let TrendShipmentByYear = await Axios.post(
        `${Services.GET_DASHBOARD_TOPERACIONES.path}/dashboard/toperacions`,
        data2
      );
      this.setState({ flotData: TrendShipmentByYear.data.data, text });
    } catch (error) {
      console.error(error);
    }
  };

  /*=============================================
  =            Render Method                    =
  =============================================*/

  render() {
    let height = { height: "298px" };
    const {
      totals,
      flotData,
      Logistics,
      Attention,
      Map,
      loadingTrends,
      loadingTotals,
      RegionValues,
      fullLoader,
      customWidth
    } = this.state;
    return (
      <>
        {/* <Loader hidden={fullLoader}/> */}
        <>
          <Loader hidden={fullLoader} />
          <ContentWrapper>
            <div className="content-heading">
              <legend className="legendTitle">
                <img
                  // style={{ margin: "6px", marginTop: "-1px" }}
                  src="https://fsposeidon.blob.core.windows.net/roldan/icon_controlToweraDhas.png"
                  height="28"
                  width="28"
                ></img>
                Mi Tablero de Control
              </legend>
            </div>
            {/* <Card> */}
            <div className="row">
              <div className="col-12">
                {/* <Row> */}
                {/* <div className="col-md-12"> */}

                <div className="wraped_totals">
                  <CardsTotals
                    image={
                      <em class="fas fa-2x fa-2x mr-2 fas fa-map-marker-alt" />
                    }
                    xl={3}
                    md={6}
                    loading={loadingTotals}
                    total={totals.T_Embar ? totals.T_Embar : "0"}
                    title={"Embarques"}
                    propertiesIcon={
                      "col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left"
                    }
                    propertiesTitle={
                      "col-8 py-3 bg-primary rounded-right"
                    }
                  />

                  <CardsTotals
                    image={
                      <em class="fas fa-2x fa-arrow-alt-circle-right" />
                    }
                    xl={3}
                    md={6}
                    loading={loadingTotals}
                    total={totals.T_Impo ? totals.T_Impo : "0"}
                    title={"Impo"}
                    propertiesIcon={
                      "col-4 d-flex align-items-center bgg-green-dark justify-content-center rounded-left"
                    }
                    propertiesTitle={
                      "col-8 py-3 bgg-green rounded-right"
                    }
                  />

                  <CardsTotals
                    image={
                      <em class="fas fa-2x fa-arrow-alt-circle-left" />
                    }
                    xl={3}
                    md={6}
                    loading={loadingTotals}
                    total={totals.T_Expo ? totals.T_Expo : "0"}
                    title={"Expo"}
                    propertiesIcon={
                      "col-4 d-flex align-items-center bgg-gray-dark justify-content-center rounded-left"
                    }
                    propertiesTitle={
                      "col-8 py-3 bgg-gray rounded-right"
                    }
                  />

                  <CardsTotals
                    image={
                      <em class="fa-2x mr-2 fas fa-sort-amount-down" />
                    }
                    xl={3}
                    md={6}
                    loading={loadingTotals}
                    total={totals.T_Ing ? totals.T_Ing : "0"}
                    title={"Ingresos"}
                    propertiesIcon={
                      "col-4 d-flex align-items-center bgg-green-dark justify-content-center rounded-left"
                    }
                    propertiesTitle={
                      "col-8 py-3 bgg-green rounded-right"
                    }
                  />

                  <CardsTotals
                    image={
                      <em class="fa-2x mr-2 fas fa-sort-amount-up" />
                    }
                    xl={3}
                    md={6}
                    loading={loadingTotals}
                    total={totals.T_Des ? totals.T_Des : "0"}
                    title={"Despachos"}
                    propertiesIcon={
                      "col-4 d-flex align-items-center bgg-gray-dark justify-content-center rounded-left"
                    }
                    propertiesTitle={
                      "col-8 py-3 bgg-gray rounded-right"
                    }
                  />
                  <CardsTotals
                    image={
                      <em class="fa-2x mr-2 fas fa-truck" />
                    }
                    xl={3}
                    md={6}
                    loading={loadingTotals}
                    total={totals.T_Tms ? totals.T_Tms : "0"}
                    title={"Transporte"}
                    propertiesIcon={
                      "col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left"
                    }
                    propertiesTitle={
                      "col-8 py-3 bg-primary rounded-right"
                    }
                    />
                </div>

                {/* </div> */}
                {/* </Row> */}
                <Divider />
                <Row>
                  <Col xs={12} md={9} lg={10} hidden={customWidth < 758 ? true : false}>
                    <RSS type="dashboard"></RSS>
                  </Col>
                  <Col xs={12} md={3} lg={2} className="">
                    <div className="row">
                        <div className="col-xs-6 col-md-12 col-lg-12 d-flex justify-content-center pt-2" >
                          <a href={'https://forms.office.com/r/SPTqKiAn9S'} target="_blank" rel="urlfeedRoldanPic">
                            <Image
                              className="hoverShadow"
                              preview={false}
                              width={'11em'}
                              src="https://magiclog.blob.core.windows.net/roldanpic/Bot%C3%B3n%20encuesta%20Rol4LL.jpg"
                              alt="alt_encuesta"
                            />
                          </a>
                        </div>
                        <div className="col-xs-6 col-md-12 col-lg-12 d-flex justify-content-center pt-2">
                          <a href={'https://www.roldanlogistics.com/blog'} target="_blank" rel="urlfeedRoldanPic">
                            <Image
                              className="hoverShadow"
                              preview={false}
                              width={'11em'}
                              src="https://magiclog.blob.core.windows.net/roldanpic/Bot%C3%B3n%20Rol4ll%20-%20enlace%20sitio%20web%20RL%202%20(1).png"
                              alt="feedRoldanPic"
                            />
                          </a>
                        </div>
                    </div>
                  </Col>
                  <Divider />
                  <Col xs={12} md={6} lg={6}>
                    <div className="card myCard equalsWidth">
                      <div className="card-header">
                        <div className="card-title">
                          {" "}
                          Operaciones Integradas por Módulo{" "}
                        </div>
                      </div>
                      <div
                        style={{ fontWeight: 100 }}
                        className="card-body"
                      >
                        {loadingTrends ? (
                          <FullLoader />
                        ) : (
                          <div>
                            {this.state.flat ? (
                              <div
                                style={{
                                  height: "348px",
                                  fontWeight: "100 !important",
                                }}
                              ></div>
                            ) : (
                              <Chart
                                className={"_table_content"}
                                width={"100%"}
                                height={"100%"}
                                chartType="BarChart"
                                loader={<div>Loading Chart</div>}
                                data={Logistics}
                                options={{
                                  height: 350,
                                  bar: { groupWidth: "80%" },
                                  legend: { position: "none" },
                                  animation: {
                                    startup: true,
                                    easing: "linear",
                                    duration: 1000,
                                  },
                                }}
                                rootProps={{ "data-testid": "6" }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    {/* START card */}
                    <div className="card myCard equalsWidth">
                      <div className="card-header">
                        <Col style={{ marginBottom: '28px' }} md={12}>
                          <Row>
                            <Col md={6}>
                              <div className="card-title">
                                {" "}
                                Tendencia de Operaciones{" "}
                              </div>
                              <div
                                className="card-title"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                Por {this.state.text}
                              </div>
                            </Col>
                            <Col className="" md={6}>
                              <YearsSelect
                                onChangeYear={this.onChangeYear}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </div>
                      <div className="card-body">
                        {loadingTrends ? (
                          <FullLoader />
                        ) : (
                          <FlotChart
                            data={flotData}
                            heightDash={height}
                            options={flotOptions}
                          />
                        )}
                      </div>
                    </div>
                    {/* END widget */}
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Card className="myCard equalsWidth">
                      <div className="card-header">
                        <div className="card-title">
                          {" "}
                          Eventos que Requieren Atención{" "}
                        </div>
                      </div>
                      <CardBody>
                        {loadingTrends ? (
                          <TableLoader />
                        ) : (
                          <EventsTable data={Attention} />
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Card className="myCard equalsWidth">
                      <div className="card-header">
                        <div className="card-title">
                          {" "}
                          Embarques por País de Origen{" "}
                        </div>
                      </div>
                      <CardBody>
                        {loadingTrends ? (
                          <FullLoader />
                        ) : (
                          <VectorMap
                            options={{
                              map: "world_mill_en",
                              backgroundColor: defaultColors.bgColor,
                              zoomMin: 1,
                              zoomMax: 8,
                              zoomOnScroll: false,
                              regionStyle: {
                                initial: {
                                  fill: defaultColors.regionFill,
                                  "fill-opacity": 1,
                                  stroke: "none",
                                  "stroke-width": 1.5,
                                  "stroke-opacity": 1,
                                },
                                hover: {
                                  "fill-opacity": 0.8,
                                },
                                selected: {
                                  fill: "blue",
                                },
                                selectedHover: {},
                              },
                              focusOn: {
                                x: 0.4,
                                y: 0.6,
                                scale: 1,
                              },
                              markerStyle: {
                                initial: {
                                  fill: defaultColors.markerColor,
                                  stroke: defaultColors.markerColor,
                                },
                              },
                              onRegionLabelShow: (e, el, code) => {
                                if (RegionValues[code])
                                  el.html(
                                    el.html() +
                                    ": " +
                                    RegionValues[code] +
                                    " Embarques"
                                  );
                              },
                            }}
                            series={{
                              regions: [
                                {
                                  values: RegionValues,
                                  scale: defaultColors.scaleColors,
                                  normalizeFunction: "polynomial",
                                },
                              ],
                            }}
                            markers={Map}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Divider />
              </div>
            </div>
          </ContentWrapper>
        </>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
