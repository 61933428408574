import React from 'react';
import propTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const LoaderIcon = <LoadingOutlined style={{ fontSize: 42, color: '#969696'}} spin />;


function SpinComponent(props){
  return (
    <Spin spinning={props.spinning} indicator={LoaderIcon} tip={props.tip}>
      {props.children}
    </Spin>
  );
}

SpinComponent.propTypes = {
  spinning: propTypes.bool,
  tip: propTypes.string
};

SpinComponent.defaultProps = {
  spinning: false,
  tip: ''
};


export default SpinComponent;