import React, { Component } from 'react';
import { Typography, Step, StepContent, StepLabel, Stepper} from '@material-ui/core';
import './styles/style.scss'

class StepperEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      steps: props.steps ? props.steps : [],
      isVertical: props.vertical ? props.vertical : false,
      pushDiv: false
    }
  }

  componentDidMount() {
    this.addMiddleStepper();
  }

  componentWillReceiveProps = (nextProps) => {
    
    this.setState({
      steps:nextProps.steps ? nextProps.steps : []
    });
  }


  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    window.addEventListener('resize', this.addMiddleStepper);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    window.addEventListener('resize', this.addMiddleStepper);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  addMiddleStepper = () =>{
    const { isVertical, steps, width } = this.state;
    const isMobile = (isVertical ? isVertical : (width <= 900));
    steps.map((e, i) =>{
      if(i !== 0 && e.middleStep){
        let circleId = `circle${e.idStep}`
        let findCircle = document.getElementById(circleId);
        if(findCircle){
          findCircle.remove();
        }
        let findStep = document.getElementById(e.idStep);
        if(findStep){
          var divPush = document.createElement("div");
          divPush.setAttribute("id", circleId);
          var className = "tooltipNew topNew";
          if(isMobile){
            className = "tooltipNewVert leftVert"
          }
          divPush.setAttribute("class", className)
          var circle = `<svg class="MuiSvgIcon-root MuiStepIcon-root circleMiddle" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><circle cx="12" cy="12" r="12"></circle></svg><span class="${isMobile ? 'tiptextVert' : 'tiptext'}">${e.middleStep}</span>`;
          divPush.innerHTML = circle;
          findStep.parentNode.insertBefore(divPush, findStep);
        }
      }
      return e
    })
  }

  render() {
    const { steps, width, isVertical } = this.state;
    const isMobile = (isVertical ? isVertical : (width <= 1051));
    return (
      <Stepper key={0} className={isMobile ? '' : 'disabledBorderLeft'} orientation={isMobile ? 'vertical' : 'horizontal'} style={{alignContent: 'center', flexWrap: 'wrap', width: '100%' }} alternativeLabel={isMobile ? false : true} nonLinear={isMobile ? false : true}>
        {steps.map((step, index) => {
          return (
            <Step id={step.idStep} active={true} key={step.label} className={step.middle ? 'circleGreen' : (step.completed ? '' : 'disabledCircle') } completed={step.completed} >
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography style={{color: 'grey', fontSize: '12px', textAlign: 'center'}}>{step.value ? step.value : `${'\u00A0'}`}</Typography>
                { isMobile ? <div style={{height: 20}}/> : <></>}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    )
  }
}

export default StepperEvent
