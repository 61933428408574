import React from 'react';
import { Card, Button, Grid } from 'semantic-ui-react';
import { Redirect } from 'react-router';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import Icons from './chatBotIcons';
import Table from '../../DocumentManager/Custom/components/dataTablev1';
import { Services } from '../Services';

const defaultStyles = {
  fontSize: '13px',
};

function CardContent(props) {

  const [redirect, setRedirect] = React.useState(false);
  const [pathRedirect, setPathRedirect] = React.useState('');

  const [expoDocs, setExpoDocs] = React.useState([{}]);
  const [open, setOpen] = React.useState(false);

  const { data, option, avilableOptions, isDocument } = props;
  const { Icon, Header, SubHeader, Action, val1, val2, val3 } = avilableOptions;

  const defaultLabelButton = 'Visualizar detalle';

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  const colExports = [
    { label: 'Nombre del Documento', key: 'Nombre' },
    { label: 'Tipo de Documento', key: 'ext' },
    { label: 'Fecha de Creación', key: 'Fecha' },
    {
      label: '',
      key: 'Download',
      separator: true,
      custom: (value) => (
        <a href={value.URL} target="_blank" rel="noopener noreferrer">
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
          ></button>
        </a>
      ),
    },
  ];

  const modalExportResults = () => {
    return (
      <>
        <Modal isOpen={open} toggle={toggle} size="lg">
          <ModalHeader
            className="advancedSearchModal"
            style={{ background: '#003F70', color: 'white' }}
            toggle={toggle}
          >
            <b>Lista de documentos {data.DOAduanaExpo}</b>
          </ModalHeader>
          <ModalBody>
            <Table columnsTable={colExports} data={expoDocs}></Table>
          </ModalBody>
        </Modal>
      </>
    );
  };

  const buttonAction = async () => {
    await setRedirect(false);
   
    const path = `${data[Action.path]}${data[Action.id]}`;
    const currentPath = window.location.pathname;

    if(path !== currentPath){
      if(props.next){
        let pathRedirect = 'Lo hemos redireccionado al detalle de la operación donde encontrará la información específica de la referencia ingresada.';
        props.next.triggerNextStep({
          value: pathRedirect,
          trigger: 'endProcessLabel'
        });
      }
      setPathRedirect(path);
      await setRedirect(true);
    } 
  };

  const viewDocumentImportacion = async (id) => {
    const url = `${Services.DOCS_CHATBOT.path}/document/documentHash/`;
    const response = await axios.get(url + id);
    window.open(response.data.data.url, '_blank', 'noopener,noreferrer');
  };

  const viewDocumentExportacion = async (id) => {
    setOpen(true);

    const url = `${Services.DOCS_CHATBOT.path}/document/Documents/${id}`;
    const rsp = await axios.get(url);

    setExpoDocs(rsp.data.data.recordset);
  };

  // const actionButton = 'default';
  const actionButton = () => {
    if (data.viewDocumentModule === 'ExportacionDocs') {
      viewDocumentExportacion(data.DOAduanaExpo);
    } else {
      if (data.viewDocumentModule === 'ImportacionDocs') {
        viewDocumentImportacion(data.ImportacionId);
      } else {
        return buttonAction();
      }
    }
  };

  const CardContent = () => {
    return (
      <Card fluid>
        <Card.Content style={defaultStyles}>
          <Grid divided="vertically">
            <Grid.Row columns={1} style={{ padding: '1rem 1em 0' }}>
              <Grid.Column width={10}>
                {Icons[option][data[Icon]]}
                <b style={{ color: 'red', marginLeft: '5px' }}>
                  {data[Header]}
                </b>
              </Grid.Column>
              <Grid.Column width={6} textAlign={'justified'}>
                <b>{data[SubHeader]}</b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>

        <Card.Content style={defaultStyles}>
          <Grid divided="vertically">
            <Grid.Row columns={1} style={{ padding: '1rem 1em 0' }}>
              <Grid.Column width={16}>
                {val1.title}
                <br />
                <b>{data[val1.id]}</b>
              </Grid.Column>
              <Grid.Column width={8}>
                {val2.title}
                <br />
                <b>{data[val2.id]}</b>
              </Grid.Column>
              <Grid.Column width={8}>
                {data.cardTitle ? data[val3.title] : val3.title}
                <br />
                <b>{data[val3.id]}</b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>

        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              basic
              fluid
              compact
              color="grey"
              onClick={() => actionButton()}
              style={defaultStyles}
            >
              {avilableOptions.LabelButton
                ? avilableOptions.LabelButton
                : defaultLabelButton}
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  };

  const change = () => {    
    if(isDocument === true){
      return <Redirect to={{ pathname: pathRedirect, search: '?doc=true' }} />;
    }else{
      return <Redirect to={pathRedirect} />;
    }    
  };

  return (
    <div style={{ width: '100%' }}>

      {redirect ?  change() : ''}  
      { CardContent() }
      { modalExportResults() }
      <div style={{ height: '5px'}}/>
    </div>
  );
}

export default CardContent;
