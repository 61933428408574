export const Services = {
   "GetStatistics": {
      "path": "https://roldanapiadmin-audit.azurewebsites.net",
      "method": "GET",
      "descrp": "Get data from user usage statistics"
   },
   "REPORT_SERVICE": {
      "path": "https://servicereportapi.azurewebsites.net",
      "descrp": "API Reportes"
   }
}