import React, { Component } from "react";
import "./App.scss";
import $ from "jquery";
import "./Global";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./state/store";
import "antd/dist/antd.css";

// import 'antd/dist/antd.css';
// import './antd.css';
// import 'semantic-ui-css/semantic.min.css';
import "./resources/semantic/theme/semantic-ui.css";
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import "./App.scss";
import "./Vendor";
import "./resources/Titillium_Web/TitilliumWeb-Black.ttf";
import "./resources/Titillium_Web/TitilliumWeb-Bold.ttf";
import LoginComponent from "./components/Roldan/Login";
import RecoverPasswors from "./components/Roldan/RecoverPassword";
import HomeComponent from "./components/Roldan/Home";
import WrapperRoute from "./components/Middlewares/WrapperRoute";
import traceabilityShipmentsComponent from "./components/Roldan/Traceability/Shipments";
import traceabilityCustomsComponent from "./components/Roldan/Traceability/Customs";
// import traceabilityIntegradaComponent from './components/Roldan/Traceability/Integrada/Impo/Importaciones';
import traceabilityIntegrada from "./components/Roldan/Traceability/Integrada/Integrada";
import shipmentsDetailComponent from "./components/Roldan/Traceability/Shipments/ShipmentsDetail";
import CustomViewDetail from "./components/Roldan/Traceability/Customs/components/CustomImports/Import-detail-view";
import DtaViewDetail from "./components/Roldan/Traceability/Customs/components/CustomDta/Dta-detail-view";
import ExportViewDetail from "./components/Roldan/Traceability/Customs/components/CustomsExports/Exports-detail-view";
import GroundTransportation from "./components/Roldan/Traceability/GroundTransportation";
import transportationDetailComponent from "./components/Roldan/Traceability/GroundTransportation/TransportationDetail";
// reporting
import Reporting from "./components/Roldan/Reporting&Analitics";
// import MyAnalitics from './components/Roldan/Reporting&Analitics/MyAnalitics';
import MyAnalitics from "./components/Roldan/Reporting&Analitics/MyAnalitics/gerencial";
import BiOperativo from "./components/Roldan/Reporting&Analitics/BiOperativo";

import Storage from "./components/Roldan/Storage";
import inventayDetail from "./components/Roldan/Storage/inventayDetail";
import StorageEntriesDetail from "./components/Roldan/Storage/Entries";
import StorageOfficesDetail from "./components/Roldan/Storage/Offices";
import ContainerDetailComponets from "./components/Roldan/Traceability/Shipments/Container";
import IntegratedDetail from "./components/Roldan/Traceability/Integrada/Impo/Detail";
import IntegratedDetailExpo from "./components/Roldan/Traceability/Integrada/Expo/Detail/DetailExpo";
import ContainerControl from "./components/Roldan/Traceability/ContainerControl";
import Admin from "./components/Roldan/Admin/Admin";
import NotFound from "./components/template/pages/notFound";
import Terminos from "./components/template/pages/termino-condiciones";
import Politicas from "./components/template/pages/politica-privacidad";
import Alerts from "./components/Roldan/Alerts";
import QuickSearch from "./components/Roldan/QuickSearch";

// ? Modulo de creación my reports
import MyReportsCreate from "./components/Roldan/Reporting&Analitics/MyReports/Create";
import Groups from "./components/Roldan/Groups";
import CreateAndEditGroup from "./components/Roldan/Groups/CreateAndEdit";

// ? Tablero de control
import ControlDashboard from "./components/Roldan/Reporting&Analitics/ControlDashboard";

// ? RSS Feeds
import RSSFedds from "./components/Roldan/RSSFeeds";

import Statistics from "./components/Roldan/Audit";
import DocManagerCustom from "./components/Roldan/DocumentManager/Custom";
import DocManagerwarehouse from "./components/Roldan/DocumentManager/warehouse";
import LogisticsTools from "./components/Roldan/LogisticsTools";
import FacturacioCostos from "./components/Roldan/Facturacion&Costos";

// ? Frequently Asked Questions (Faqs)
import Faqs from "./components/Roldan/Faqs";

// Service Desk Module
import ServiceDesk from "./components/Roldan/servicedeskmodule";
import TicketDetail from "./components/Roldan/servicedeskmodule/app/TicketDetails";

import history from "./history";

$.ajaxPrefilter((o) => (o.async = true));

/*=============================================
=            App Component With Router        =
=============================================*/

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter history={history} basename={"/"}>
          <Switch>
            <Route exact path="/" component={LoginComponent}></Route>
            <Route
              exact
              path="/dashboard"
              render={() => (
                <WrapperRoute
                  name={"dashboard"}
                  component={HomeComponent}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/shipments"
              render={() => (
                <WrapperRoute
                  name={"Embarques"}
                  component={traceabilityShipmentsComponent}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/customs"
              render={() => (
                <WrapperRoute
                  name={"Aduana"}
                  component={traceabilityCustomsComponent}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/transportation"
              render={() => (
                <WrapperRoute
                  name={"Transporte"}
                  component={GroundTransportation}
                ></WrapperRoute>
              )}
            ></Route>

            {/* <Route exact path="/integrada" render={() => <WrapperRoute name={'Integrado'} component={ traceabilityIntegradaComponent } ></WrapperRoute>}></Route> */}
            <Route
              exact
              path="/integrada"
              render={() => (
                <WrapperRoute
                  name={"Integrado"}
                  component={traceabilityIntegrada}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/storage"
              render={() => (
                <WrapperRoute
                  name={"Almacen"}
                  component={Storage}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/inventaryDetail"
              render={() => (
                <WrapperRoute
                  name={"Inventarios"}
                  component={inventayDetail}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/reporting"
              render={() => (
                <WrapperRoute
                  name={"reporting"}
                  component={Reporting}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/bi-gerencial"
              render={() => (
                <WrapperRoute
                  name={"misAnaliticos"}
                  component={MyAnalitics}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/bi-operativo"
              render={() => (
                <WrapperRoute
                  name={"BiOperativo"}
                  component={BiOperativo}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/ContainerControl"
              render={() => (
                <WrapperRoute
                  name={"ContainerControl"}
                  component={ContainerControl}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/Admin"
              render={() => (
                <WrapperRoute name={"Admin"} component={Admin}></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/Alerts"
              render={() => (
                <WrapperRoute name={"Alerts"} component={Alerts}></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/statistics"
              render={() => (
                <WrapperRoute
                  name={"statistics"}
                  component={Statistics}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/reportes-especiales/creacion"
              render={() => (
                <WrapperRoute
                  name={"myreports_create"}
                  component={MyReportsCreate}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/tablero-de-control/proyectos"
              render={() => (
                <WrapperRoute
                  name={"ControlDashboardProyects"}
                  component={ControlDashboard}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/groups"
              render={() => (
                <WrapperRoute name={"groups"} component={Groups}></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/group/create"
              render={() => (
                <WrapperRoute
                  name={"groupCreation"}
                  component={CreateAndEditGroup}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/group/edit/:id"
              render={() => (
                <WrapperRoute
                  name={"groupEdition"}
                  component={CreateAndEditGroup}
                ></WrapperRoute>
              )}
            />
            <Route exact path="/terminos-y-condiciones" component={Terminos} />
            <Route exact path="/politica-de-privacidad" component={Politicas} />
            <Route
              exact
              path="/document-manager-customs"
              render={() => (
                <WrapperRoute
                  name={"DocumentManagerCustomer"}
                  component={DocManagerCustom}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/document-manager-warehouse"
              render={() => (
                <WrapperRoute
                  name={"DocumentManagerWarehouse"}
                  component={DocManagerwarehouse}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/logistics-tools"
              render={() => (
                <WrapperRoute
                  name={"LogisticsTools"}
                  component={LogisticsTools}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/facturacion-costos"
              render={() => (
                <WrapperRoute
                  name={"FacturacioCostos"}
                  component={FacturacioCostos}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/rss-feeds"
              render={() => (
                <WrapperRoute
                  name={"RSSFeeds"}
                  component={RSSFedds}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/faqs"
              render={() => (
                <WrapperRoute name={"Faqs"} component={Faqs}></WrapperRoute>
              )}
            />

            {/* Routes with params */}
            <Route
              exact
              path="/recoverpassword/:token"
              component={RecoverPasswors}
            ></Route>
            <Route
              exact
              path="/shipmentsDetal/:id"
              render={() => (
                <WrapperRoute
                  name={"shipmentsDetal"}
                  component={shipmentsDetailComponent}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/custom-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"customDetail"}
                  component={CustomViewDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/dta-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"dtaDetail"}
                  component={DtaViewDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/exports-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"exportDetail"}
                  component={ExportViewDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/storage-entries-detail/:id"
              render={() => (
                <WrapperRoute
                  name={"storageEntriesDetail"}
                  component={StorageEntriesDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/storage-offices-detail/:id"
              render={() => (
                <WrapperRoute
                  name={"storageOfficesDetail"}
                  component={StorageOfficesDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/ContainerDetailComponets/:id"
              render={() => (
                <WrapperRoute
                  name={"ContainerDetailComponets"}
                  component={ContainerDetailComponets}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/integrated-detail/:id"
              render={() => (
                <WrapperRoute
                  name={"integratedDetail"}
                  component={IntegratedDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/integrated-detail-expo/:id"
              render={() => (
                <WrapperRoute
                  name={"IntegratedDetailExpo"}
                  component={IntegratedDetailExpo}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/search/:id*"
              render={() => (
                <WrapperRoute
                  name={"quick_search"}
                  component={QuickSearch}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/transportation-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"transportationDetal"}
                  component={transportationDetailComponent}
                ></WrapperRoute>
              )}
            />

            {/* Service Desk Mosule---> */}
            <Route
              exact
              path="/Service-Desk"
              render={() => (
                <WrapperRoute name={""} component={ServiceDesk}></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/Detail/:id"
              render={() => (
                <WrapperRoute
                  name={"TicketDetails"}
                  component={TicketDetail}
                ></WrapperRoute>
              )}
            />

            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
