import React from 'react';
import propTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Table } from 'antd';
import Search from './Search';
import Download from './Search/Download';
import ImgProvider from './assets/ImgComponent';
/**
   * Ant Table
   */
function AntTable(props){
  const [data, setData] = React.useState([...props.data]);
  const [data_aux] = React.useState([...props.data]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      
    },
    onSelect: (record, selected, selectedRows) => {
      
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      
    },
  };

  /**
   * Handle on search
   * @param {*} data
   */
  function handleOnSearch(data){
    setData(data);
  }

  return (
    <Col md={12}>
      <Row>
        <Col md={12} style={{ marginBottom: '1em'}}>
          <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'space-between' ,width: '100%'}}>
            <Col md={6}>
              <Search data={data_aux} handler={handleOnSearch} configSearch={{fieldsToFilter: props.FieldsToFilter}} width={'20em'}/>
            </Col>
            {
              props.imgKey ?
                <ImgProvider imgKey={props.imgKey}/>
                : ''
            }
            {
              props.download ?
                <div>
                  <Download data={data} fileName={props.fileName} keys={props.keys} headers={props.headers}/>
                </div> : ''
            }
          </div>
        </Col>
        <Col md={12}>
          <Table
            columns={props.columns}
            dataSource={data}
            rowSelection={ props.selection ? {...rowSelection}: undefined}
            // scroll={{ x: 1300 }}
            pagination={{
              responsive: true,
              showTitle: true,
              // itemRender: itemRender,
            }}
            {...props}
          />
        </Col>
      </Row>
    </Col>
  );
}

AntTable.propTypes = {
  columns: propTypes.array,
  data: propTypes.array,
  download: propTypes.bool,
  FieldsToFilter: propTypes.array,
  rowSelection: propTypes.func,
  selection: propTypes.bool,
  imgKey: propTypes.string,
};

AntTable.defaultProps = {
  columns: [],
  data: [],
  download: true,
  FieldsToFilter: [],
  imgKey: '',
  // rowSelection: () => {},
  // selection: false
};

export default AntTable;