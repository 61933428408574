import React from 'react';
import { Button } from 'semantic-ui-react';

function OptionsResponse(props) {
  const { triggerNextStep, previousStep } = props;
  const [option, setOption] = React.useState(false);

  const setOptions = React.useCallback(async () => {

    const optionValue = previousStep.value;
    
    if (optionValue) setOption(optionValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStep = (value) => {
    triggerNextStep({ trigger: value });
  };

  return (
    <>
      <div className="row" style={{ width: '100%' }}>
        <div style={{ height: '20px' }}></div>

        <div className="col-md-6">
          <Button
            fluid
            compact
            style={{ fontSize: '11px' }}
            onClick={() => nextStep(option[0])}
          >
            Si
          </Button>
        </div>
        <div className="col-md-6">
          <Button
            fluid
            compact
            style={{ fontSize: '11px' }}
            onClick={() => nextStep(option[1])}
          >
            No
          </Button>
        </div>
      </div>
    </>
  );
}

export default OptionsResponse;
