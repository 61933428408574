import { sortNumber, sortString } from '../../../../utils/Sorter';
/**
 * Logistic Cost Columns
 */
export const LogisticCost = [
  {
    title: 'Proyecto',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    sorter: sortString,
    showSorterTooltip: false
  },
  {
    title: 'Costo',
    dataIndex: 'value',
    key: 'value',
    ellipsis: true,
    sorter: sortNumber,
    showSorterTooltip: false,
    render: (text) => {
      return '$' + Number(text).toLocaleString('En-us');
    }
  },
];
