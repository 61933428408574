import React from 'react';
import propTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

// Consts definition
const theme = ['#80b1d3', '#b3de69', '#8dd3c7', '#fdb462','#bebada', '#e0e0e0', '#ffffb3'];
/**
 * Responsive Pie Chart
 * @param {*} props -
 */
export const PieChart = (props) => {
  return (
    <div style={{ height: '20em'}}>
      <ResponsivePie
        {...props.config}
        data={props.data}
        margin={{ top: 60, right: 20, bottom: 40, left: 0 }}
        padAngle={0.7}
        cornerRadius={3}
        colors={theme}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        enableRadialLabels={props.radialLabels}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#a1a1a1"
        radialLabelsTextXOffset={4}
        radialLabelsLinkOffset={-20}
        radialLabelsLinkDiagonalLength={15}
        radialLabelsLinkHorizontalLength={20}
        radialLabelsLinkColor="#a1a1a1"
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        // ? Fill configurations example
        // fill={[
        //   {
        //     match: {
        //       id: 'id'
        //     },
        //     id: 'dots'
        //   }
        // ]}
        legends={[
          {
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 0,
            itemsSpacing: 5,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};

PieChart.propTypes = {
  data: propTypes.array,
  radialLabels: propTypes.bool
};
PieChart.defaultProps = {
  data: [],
  radialLabels: true
};


/**
 * Pie Chart V2
 * @param {*} param0
 */
export const PieChartV2 = (props) => {
  return (
    <div style={{ height: '25em', width: '100%'}}>
      <ResponsivePie
        data={props.data}
        margin={{ top: 40, right: 10, bottom: 10, left: 10 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: 'nivo' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        enableRadialLabels={false}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{ from: 'color' }}

        enableSliceLabels={false}
        sliceLabel={(e) => { return '$'+(Number(e.value).toLocaleString('En-us'));}}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor={'#ffff'}
        tooltipFormat= {(e) => { return '$'+(Number(e).toLocaleString('En-us'));}}


        isInteractive={true}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
      />
    </div>
  );
};
// export default ResponsivePieComponent;