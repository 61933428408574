import React from 'react';
import CardsTotals from '../../../../Global/CardTotals';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { Select, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import { RightCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

// ? Chart Imports
import { PieChart, PieChartV2 } from '../../../../Global/Charts/Pie';
import LineChart from '../../../../Global/Charts/Line';
import BarChartAntd from '../../../../Global/Charts/BarAnt';
// import Notification from './../../../../Global/Alert';
import EmptyComponent from '../../../../Global/Empty';
import ConfigProvider from '../../../../Global/Empty/Provider';
import ProjectDetail from '../ProyectDetail';
import Context from '../store/CreateContext';
import CardWrapper from '../ProyectDetail/components/CardWrapper';
import TableAntV2 from '../../../../Global/table-componentv1';
import { LogisticCost } from './config';

import { Services } from '../../Services';
const { Option } = Select;
/**
 * Control Dashboard index
 */
function ControlDashboard(props){
  const context = React.useContext(Context);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [projectsList, setProjectsList] = React.useState([]);
  const [project, setProject] = React.useState(undefined);
  const [Graphics, setGraphics] = React.useState({
    ProjectsNo: 0,
    OperationsNo: 0,
    TotalCost: 0,
    Tables: {
      ProjectOperations: {
        data: [],
        keys: []
      },
      OperationsResume: {
        data: []
      },
      LogCostByProject: {
        donut:[],
        data: [],
        keys: []
      },
      LogisticCost: []
    }
  });
  const { clients } = props;
  /**
   * Get Project List and Graphics
   */
  const GetProjectList = React.useCallback(async() => {
    try{
      const projectsList = await axios.post(`${Services.GET_VISTA_REPORTES.path}/projects/list`, { customer: clients});
      if(projectsList.data.success){
        setProjectsList(projectsList.data.data);
      }
    }catch(error){
      // ! the client doesn´t want that the error will be showed in the interface
      // Notification(
      //   'Error',
      //   'Error al cosnsultar lista de proyectos',
      //   'error'
      // );
    }
  }, [clients]);

  /**
   * Get Pie and bar charts
   * */
  const GetGraphics = React.useCallback(async() => {
    try{
      setLoading(true);
      const GraphicsData = await axios.post(`${Services.GET_VISTA_REPORTES.path}/projects/project`, { project: project, customer: clients});

      if(GraphicsData.data.success){
        let data = GraphicsData.data;
        let Tables = data.Tables;
        if(firstLoad){
          setGraphics({
            ProjectsNo: data.ProjectsNo,
            OperationsNo: data.OperationsNo,
            TotalCost: data.TotalCost,
            Tables: {...Tables}
          });
          setFirstLoad(false);
        } else {
          // Graphics.Tables = {...Tables};
          // setGraphics({
          //   ...Graphics
          // });
          setGraphics({
            ProjectsNo: data.ProjectsNo,
            OperationsNo: data.OperationsNo,
            TotalCost: data.TotalCost,
            Tables: {...Tables}
          });
        }
        if(data.project){
          context.SetProjectInfo({name: data.project.name, client: data.project.client, LogisticCost: data.project.logisticCost });
        }
        // context
        setLoading(false);
      }
    }catch{
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, clients]);

  function OnRedirect(){
    if(context.state.project.name){
      context.IsProjectDetail(true);
    }
  }
  /**
   * React effect section
   * */
  React.useEffect(() => {
    GetGraphics();
    GetProjectList();
  },[GetGraphics, GetProjectList]);

  return (
    <React.Fragment>
      {
        context.state.projectDetail === false ?
          <React.Fragment>
            <div className='content-heading'>
              <Row>
                <legend><img style={{ margin: '6px', marginTop: '-1px' }} alt="" src="https://fsposeidon.blob.core.windows.net/roldan/icon_tablerodecontrol.png" height="35" width="35"></img>
                  Tablero de Control - Proyectos
                </legend>
             </Row>
            </div>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={12} sm={6}>
                      <div style={{ display: 'flex', alignItems: 'center'}}>
                        <ConfigProvider>
                          <Select
                            placeholder="Seleccione un proyecto"
                            allowClear
                            style={{ width: 200, marginTop: '.5em', marginBottom: '1em' }}
                            value={context.state.project.name ? context.state.project.name: undefined}
                            onChange={(value) => { context.SetProjectInfo({name: value}); setProject(value);}}
                          >
                            {
                              projectsList.map((project, key) => {
                                return (
                                  <Option key={key} style={{ fontSize: '1em' }} value={project.value}>{project.text}</Option>
                                );
                              })
                            }
                          </Select>
                        </ConfigProvider>
                        <RightCircleOutlined
                          className={` circle-icon-right ${project ? '__enabled':'__disabled'}`}
                          onClick={OnRedirect}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col md={4}>
                          <CardsTotals
                            image={<em class="fas fa-2x fa-2x mr-2 fas fa-briefcase" />}
                            xl={3}
                            md={6}
                            loading={false}
                            total={Graphics.ProjectsNo}
                            title={'No. de Proyectos'}
                            propertiesIcon={'col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left'}
                            propertiesTitle={'col-8 py-3 bg-primary rounded-right'}
                          />
                        </Col>
                        <Col md={4}>
                          <CardsTotals
                            image={<em class="fas fa-2x fa-2x mr-2 fas fa-list" />}
                            xl={3}
                            md={6}
                            loading={false}
                            total={Graphics.OperationsNo}
                            title={'No. de Operaciones'}
                            propertiesIcon={'col-4 d-flex align-items-center bg-success-dark justify-content-center rounded-left'}
                            propertiesTitle={'col-8 py-3 bg-success rounded-right'}
                          />
                        </Col>
                        <Col md={4}>
                          <CardsTotals
                            image={<em class="fas fa-2x fa-2x mr-2 fas fa-money-bill-alt" />}
                            xl={3}
                            md={6}
                            loading={false}
                            total={Graphics.TotalCost}
                            title={'Costo Total'}
                            propertiesIcon={'col-4 d-flex align-items-center bg-mg-gray-dark justify-content-center rounded-left'}
                            propertiesTitle={'col-8 py-3 bg-mg-gray rounded-right'}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <CardWrapper title="Resumen de Operaciones">
                        {
                          loading ?
                            <Skeleton active/>:
                            Graphics.Tables.OperationsResume.data ?
                              <PieChart
                                data={Graphics.Tables.OperationsResume.data}
                                config={
                                  {
                                    innerRadius: 0,
                                    sliceLabel: function(e){return `${e.value}%`;},
                                    sliceLabelsSkipAngle: 10,
                                    sliceLabelsTextColor: '#ffff',
                                    tooltipFormat: (e) => `${e}%`
                                  }
                                }
                              /> :
                              <EmptyComponent title='Sin datos' />
                        }
                      </CardWrapper>
                    </Col>
                    <Col md={6}>
                      <CardWrapper title="Operaciones por Proyecto">
                        {
                          loading ?
                            <Skeleton active/> :
                            Graphics.Tables.ProjectOperations.data.length > 0 ?
                              <BarChartAntd
                                config={{
                                  data: Graphics.Tables.ProjectOperations.data,
                                  xField: 'total',
                                  yField: 'project',
                                  yAxis: {label: { autoRotate: false }},
                                  seriesField: 'total',
                                  legend: {position: 'top-left'},
                                  renderer: 'svg',
                                  scrollbar: { type: 'vertical' },
                                  autoFit: true,
                                  height: 300,
                                  tooltip: {
                                    fields: ['label', 'total'],
                                    formatter: (datum) => {
                                      return { name: datum.label, value: datum.total };
                                    },
                                  }
                                }}
                              /> :
                              <EmptyComponent title='Sin datos' />
                        }
                      </CardWrapper>
                    </Col>
                    <Col md={12}>
                      <CardWrapper title="Costo Logístico (COP)">
                        {
                          loading ?
                            <Skeleton active/> :
                            Graphics.Tables.LogisticCost.length > 0 ?
                              <LineChart data={Graphics.Tables.LogisticCost}/>
                              :
                              <EmptyComponent title='Sin datos' />
                        }
                      </CardWrapper>
                    </Col>
                    <Col md={12}>
                      <CardWrapper title="Costo Logístico por Proyecto (COP)">
                        {
                          loading ?
                            <Skeleton active/> :
                            Graphics.Tables.LogCostByProject.data.length > 0 ?
                              <Row>
                                <Col md={6}>
                                  <PieChartV2
                                    data={Graphics.Tables.LogCostByProject.donut}
                                    config={
                                      {
                                        innerRadius: 0,
                                        enableSliceLabels: false,
                                        sliceLabel: function(e){return `${e.value}`;},
                                        sliceLabelsSkipAngle: 10,
                                        sliceLabelsTextColor: '#ffff',
                                        tooltipFormat: (e) => `$${e}`
                                      }
                                    }
                                  />
                                </Col>
                                <Col md={6}>
                                  <TableAntV2
                                    data={Graphics.Tables.LogCostByProject.data}
                                    columns={LogisticCost}
                                    FieldsToFilter={['id','value']}
                                    size='small'
                                    download={false}
                                    scroll={{ y: 240 }}
                                    // fileName={`${context.state.project.name}_${ReportsName[process]}_${moment(new Date()).format('YYYYMMDD')}`}
                                    // keys={ColumnsSetter(process, props.history).map(row => row.key)}
                                    // headers={ColumnsSetter(process, props.history).map(row => row.title)}
                                  />
                                </Col>
                              </Row>
                              :
                              <EmptyComponent title='Sin datos' />
                        }
                      </CardWrapper>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Col>
          </React.Fragment>
          :
          <ProjectDetail/>
      }
    </React.Fragment>
  );
}

ControlDashboard.propTypes = {};
ControlDashboard.defaultProps = {};

const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ControlDashboard));