const Services = {
  // ? Login Services
  SetAutentication: {
    // path: 'https://www.magicanalytics.app/roldan/api/admin/v1/',
    path: 'https://roldanapiadmin.azurewebsites.net/',
    // path: 'http://localhost:4219/',
    method: 'POST',
    descrp: ' method for user authentication'
  }
};
    
    
export default Services;
    