import React from 'react';
import { Loading } from 'react-simple-chatbot';
import axios from 'axios';
import { Services } from '../Services';
import Authentication from '../../../../services/Authentication';
const auth = new Authentication();

function SendMail(props) {
  const [loading, setLoading] = React.useState(true);

  const sendCorreo = React.useCallback(async () => {
    try {
      const valueToSend = props.previousStep.value;
      const tipoOperacion = props.steps.Rol4llMenu.value[0];

      const dataSend = {
        userName: auth.getAuthentication('UserName'),
        email: auth.getAuthentication('EmailUser'),
        tipoProblema: tipoOperacion,
        descripcion: valueToSend,
        userId:auth.getAuthentication('dataUser')
      };

      const urlMail = `${Services.SEND_MAIL_CHATBOT.path}/chatbot/problemaroldan`;

      const sendCorreo = await axios.post(urlMail, dataSend);

      setLoading(false);
      props.triggerNextStep({
        value: ['Tengo una novedad con Rol4ll', 'CalificarServicio'],
        trigger: 'RealizarOtraConsulta',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    sendCorreo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          La información proporcionada sera enviada a uno de nuestros agentes de
          servicio para solucionar su problema
        </div>
      )}
    </>
  );
}

export default SendMail;
