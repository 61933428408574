import React from "react";
import axios from "axios";
import { Loading } from "react-simple-chatbot";
import { Services } from "../Services";
import Authentication from "../../../../services/Authentication";
import SessionStorage from "../../../../services/SessionStorage";
const auth = new Authentication();
const session = new SessionStorage();

function SendOperationError(props) {
  const { steps, triggerNextStep } = props;
  const [loading, setLoading] = React.useState(true);
  const [allClients, setAllClients] = JSON.parse(session.getItem("group_list"));
  const {
    problemaOperacionMenu,
    SeleccionaProblemaOperacion,
    DescripcionProblemaOperacion,
    problemDescription,
  } = steps;

  const getData = React.useCallback(async () => {
    try {
      const dataSend = {
        userName: auth.getAuthentication("UserName"),
        email: auth.getAuthentication("EmailUser"),
        rolId: auth.getAuthentication("RolId"),
        procesName: problemaOperacionMenu.value[0],
        tipoProblema: SeleccionaProblemaOperacion.value,
        referencia: DescripcionProblemaOperacion.value,
        descripcion: problemDescription.value,
        userId: auth.getAuthentication("dataUser"),
        cc: [],
      };

      const crmBody = {
        service: problemaOperacionMenu.value[0],
        clients: allClients.data,
      };

      const urlMail = `${Services.SEND_MAIL_CHATBOT.path}/chatbot/problemaoperacion`;
      dataSend.sendEmails = [];

      try {
        const urlCrm = `${Services.CATALOGS_CHATBOT.path}/crm/getMailsNovedad`;


        if (auth.getAuthentication("RolId") === "2") {
          const getCrmEmails = await axios.post(urlCrm, crmBody);
          dataSend.sendEmails = getCrmEmails.data.data;
        }
      } catch (error) {
        console.warn("Error to send mail", error);
      }

      if (!dataSend.sendEmails.length) {
        console.log("No se encontraron correos en CRM");
        switch (dataSend.procesName) {
          case "Internacional":
            dataSend.sendEmails.push({
              email: "opscolombia@roldanlogistica.com",
              name: "roldanlogistica",
            },);
            break;
          case "Importación":
            dataSend.sendEmails.push(
              {
                email: "eaumbarila@roldanlogistica.com",
                name: "roldanlogistica",
              },
              {
                email: "gneuta@roldanlogistica.com",
                name: "roldanlogistica",
              },
              {
                email: "gte_operaciones@roldanlogistica.com",
                name: "roldanlogistica",
              }
            );
            break;
          case "Exportación":
            dataSend.sendEmails.push(
              {
                email: "gte_operaciones@roldanlogistica.com",
                name: "roldanlogistica",
              },
              {
                email: "slinares@roldanlogistica.com",
                name: "roldanlogistica",
              }
            );
            break;
          case "Ingresos":
            dataSend.sendEmails.push({
              email: "ocastaneda@roldanlogistica.com",
              name: "roldanlogistica",
            });
            break;
          case "Despachos":
            dataSend.sendEmails.push({
              email: "ocastaneda@roldanlogistica.com",
              name: "roldanlogistica",
            });
            break;
          default:
            console.warn(
              "Lo lamentamos, por el momento no disponemos de " +
                dataSend.procesName +
                "."
            );
        }
      }

      dataSend.sendEmails.push({
        email: "rene.mendoza@magic-log.com",
        name: "magic-log",
      });

      await axios.post(urlMail, dataSend);
      setLoading(false);

      triggerNextStep({
        value: ["Tengo una novedad con mi operación", "CalificarServicio"],
        trigger: "RealizarOtraConsulta",
      });
    } catch (error) {
      console.error("GET OPERATION ==>", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <Loading />
      ) : (
        <div>
          La información proporcionada sera enviada a uno de nuestros agentes de
          servicio para solucionar su problema
        </div>
      )}
    </div>
  );
}

export default SendOperationError;
