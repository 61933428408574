import React from 'react';
import './styles.scss';
/**
 * Img Provider
 * @param {*} props - {imgKey: impoImg | expoImg }
 */
function ImgProvider(props){
  switch (props.imgKey) {
  case 'impo':
    return (
      <div className='impoImg'>
        {/* <img height={60} width={200} src={IMPOIMG} alt='img-impo'></img> */}
      </div>
    );
  case 'expo':
    return (
      <div className='expoImg'>
        {/* <img height={60} width={200} src={EXPOIMG} alt='img-expo'></img> */}
      </div>
    );
  default:
    return (
      <div className='expoImg'>
        {/* <img height={60} width={200} src={EXPOIMG} alt='img-expo'></img> */}
      </div>
    );
  }
}

export default ImgProvider;