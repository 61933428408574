import React from 'react';
import propTypes from 'prop-types';
// import {Search} from 'semantic-ui-react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
// import { AudioOutlined } from '@ant-design/icons';
import Searcher from './Searcher';

const SearchOnTable = new Searcher();

/**
 * Init state
 * @param {*} param0 - { initialSearch, data }
 */
function init({initialSearch, data}){
  return { searchText: initialSearch, data: data};
}

/**
 * Reducer
 * @param {*} state - app state
 * @param {*} action - app action
 */
function reducer(state, action){
  switch(action.type){
  case 'search':
    if(action.payload.searchText !== ''){
      const dataFiltered = SearchOnTable.search(action.payload.data, action.payload.searchText, action.payload.config);
      action.payload.handler(dataFiltered);
      return {...{searcherText: action.payload.searchText, data: dataFiltered}};
    } else {
      action.payload.handler(action.payload.data);
      return {...{searcherText: '', data: action.payload.data}};
    }
  case 'reset':
    return init(...action.payload);
  default:
    return state;
  }
}

/**
 * Main component
 * @param {*} props - SearchComponent props
 */
function SearchComponent(props){
  const [state, dispatch] = React.useReducer(reducer, {initialSearch: '', data: props.data}, init);

  // ! Test Version
  // function onSearch(evt, data){
  //   dispatch({type: 'search', payload: {data: props.data, searchText: data.value, handler: props.handler, config: props.configSearch}});
  // }

  function onSearch(evt){
    dispatch({type: 'search', payload: {data: props.data, searchText: evt.target.value, handler: props.handler, config: props.configSearch}});
  }

  return (
    <div style={{ width: props.width }}>
      <Input
        placeholder="Buscar"
        onChange={onSearch}
        prefix={<SearchOutlined className="site-form-item-icon" />}
        // suffix={
        //   <Tooltip title="Extra information">
        //     <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        //   </Tooltip>
        // }
      />
      {/* Test version */}
      {/* <Search
        style={{marginBottom: '.5em', width: props.width}}
        input={{ icon: 'search', iconPosition: 'left', fluid: true }}
        placeholder="..."
        open={false}
        name="search-input-table"
        id="search-input-table"
        onSearchChange={onSearch}
      /> */}
    </div>
  );
}

SearchComponent.propTypes = {
  data: propTypes.array,
  handler: propTypes.func,
  width: propTypes.string
};

SearchComponent.defaultProps = {
  data: [],
  handler: () => {},
  width: '30%'
};

export default SearchComponent;
