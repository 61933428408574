import React from 'react';
import { withRouter } from 'react-router';
// ? components
// import Oauth from '../../Oauth';
import Base from '../template/Layout/Base.js';
// import NotFound from'./../template/pages/notFound';
import { MenuHandler, NameRole } from './MenuHandler.js';
import axios from 'axios';
import Services from './Services.js';

import Authentication from '../../services/Authentication.js';
// import ClientesList from '../../services/storageClient.js';

const auth = new Authentication();
// const clientSave = new ClientesList();

// import ContentWrapper from './../template/Layout/ContentWrapper';
// import Paper from '@material-ui/core/Paper';
// import {Loader} from 'semantic-ui-react';
// import './WrapperRoutes_styles.scss';


/*=============================================
=            Map Validation For Access        =
=============================================*/

// const map_validation = {
//   'salesleads': [1, 2, 3],
//   'user_create': [1],
//   'user_list':[1],
//   'search': [1, 2, 3, 4],
//   'fair_create': [1],
//   'fair_list': [1, 2, 3 , 4],
//   'fair_one': [1, 2, 3, 4],
//   'teamleads':[1, 3],
//   'fair_assignation': [1, 3],
//   'statistics': [1, 3],
//   'my_queries': [1,2,3],
//   'my_fair_queries':[1,2,3],
//   'op_create_op': [1,2,3],
//   'op_create_full': [1,2,3],
//   'op_create_list': [1,2,3],
//   'op_manage_report': [1,2,3]
// };


/*=============================================
=            Loader COmponent evvery Change   =
=============================================*/

// const LoaderComponent = ({}) => {
//   return (
//     <Paper elevation={0}>
//       <ContentWrapper>
//         <div className="col-md-12 __loader">
//           <div className="abs-center wd-xl">
//               <div className="text-center mb-4">
//                 <Loader active inline='centered' size='huge'/>
//               </div>
//           </div>
//         </div>
//       </ContentWrapper>
//     </Paper>
//   )
// }

/*=============================================
=            Component For WrapperRoute       =
=============================================*/
function WrapperRoute(props) {
  // let [loading, setLoading] = React.useState(true);
  // let [oauthInfo, setOauthInfo] = React.useState({});
  const MainComponent = props.component;
  let token = auth.getAuthentication('token');
  let RoleId = auth.getAuthentication('RolId');
  let UserId = auth.getAuthentication('dataUser');
  let UserNameLast = auth.getAuthentication('UserNameLast');
  let UserName = auth.getAuthentication('UserName');
  let Rol = auth.getAuthentication('Rol');
  // let customerId = auth.getAuthentication('customerSelect');
  // let  clientesSelect = clientSave.getAuthentication('client');

  //Props para service desk
  let ProjectId = 2;
  let Email = auth.getAuthentication("EmailUser");
  let Role = NameRole[RoleId];

  let moduleLog = {
    UsuarioId: UserId,
    ModuleId: props.name,
    Date: new Date(),
    SessionId: token
  };

  navigator.sendBeacon('https://roldanapiaudit.azurewebsites.net/modules/login', JSON.stringify(moduleLog));

  window.addEventListener('beforeunload', function (event) {
    const createOffsetValue = date => {
      var sign = (date.getTimezoneOffset() > 0) ? '-' : '+';
      var offset = Math.abs(date.getTimezoneOffset());
      var hours = Math.floor(offset / 60);
      var minutes = (offset % 60) / 60 * 10;
      return sign + hours + '.' + minutes;
    };

    let endSession = {
      SessionId: token,
      EndConnection: new Date(),
      timezone: createOffsetValue(new Date()),
      Logout: '1'
    };

    // navigator.sendBeacon("http://localhost:3019/user/logout", JSON.stringify(endSession))
    navigator.sendBeacon('https://roldanapiaudit.azurewebsites.net/user/logout', JSON.stringify(endSession));
  });
  const { history } = props;
  const GetAuthentication = React.useCallback(async () => {
    try {
      // const oauthData = 
      await axios.get(`${Services.SetAutentication.path}oauth/validate?token=${token}`);
    } catch (error) {
      auth.deleteAuthentication('token');
      auth.deleteAuthentication('dataUser');
      auth.deleteAuthentication('customerSelect');
      history.push('/');
    }
  }, [token, history]);

  React.useEffect(() => {
    GetAuthentication();
  }, [props.component, GetAuthentication]);

  return (
    <React.Fragment>
      <Base
        Menu={{ Menu: MenuHandler[RoleId] }}
        SideBarConfig={{
          nada: 'user nothing',
          UserNameLast: UserNameLast,
          UserName: UserName,
          RoleId: RoleId,
          Rol: Rol
        }}>
        <MainComponent
          // name={props.name}
          _user={UserId}
          _rolId={RoleId}
          ProjectId={ProjectId}
          UserNameLast={UserNameLast}
          Rol={Rol}
          UserName={UserName}
          Email={Email}
          _Role={Role} />
      </Base>
    </React.Fragment>
  );
};

// /*=============================================
// =            Component For WrapperRoute       =
// =============================================*/
// const WrapperRoute = props => {

//   const MainComponent = props.component;
//   let token = auth.getAuthentication('token');
//   let RoleId = auth.getAuthentication('RolId');
//   let UserId = auth.getAuthentication('dataUser');
//   let UserNameLast = auth.getAuthentication('UserNameLast');
//   let UserName = auth.getAuthentication('UserName');
//   let customerId = auth.getAuthentication('customerSelect');
//   let  clientesSelect = clientSave.getAuthentication('client');

//   // let moduleLog = {
//   //   UsuarioId: UserId,
//   //   ModuleId: props.name,
//   //   Date: new Date(),
//   //   SessionId: token
//   // }

//   // navigator.sendBeacon("https://roldanapiaudit.azurewebsites.net/modules/login", JSON.stringify(moduleLog)) 

//   // window.addEventListener("beforeunload", function(event) {

//   //   let endSession = {
// 	// 		SessionId: token,
// 	// 		EndConnection: new Date(),
// 	// 		Logout: '1'
//   //   }

//   //   //navigator.sendBeacon("http://localhost:3019/user/logout", JSON.stringify(endSession))
//   //   navigator.sendBeacon("https://roldanapiaudit.azurewebsites.net/user/logout", JSON.stringify(endSession))

//   // });


//   axios.get(`${Services.SetAutentication.path}oauth/validate?token=${token}`, {
//   }).then(response => {
//     document.getElementById('body').className = 'layout-fixed';
//     return (
//       <Base Menu={{ Menu: MenuHandler[RoleId] }} SideBarConfig={{ nada: 'user nothing',UserNameLast : UserNameLast, UserName:  UserName,RoleId:RoleId }}> <MainComponent name={props.name} _user={UserId} _rolId={RoleId} _customer={response.CustomerId} _clientesSelect={clientesSelect} /> </Base>
//     );
//   }).catch(error => {

//     // let endSession = {
// 		// 	SessionId: token,
// 		// 	EndConnection: new Date(),
// 		// 	Logout: '0'
//     // }

//     // navigator.sendBeacon("https://roldanapiaudit.azurewebsites.net/user/logout", JSON.stringify(endSession))

//     auth.deleteAuthentication('token');
//     auth.deleteAuthentication('dataUser');
//     auth.deleteAuthentication('customerSelect');
//     props.history.push('/');
//     return (
//       <Base Menu={{ Menu: MenuHandler[RoleId] }} SideBarConfig={{ nada: 'user nothing',UserNameLast : UserNameLast, UserName:  UserName ,RoleId:RoleId  }}> <MainComponent name="/" _user={UserId} clientesSelect={clientesSelect} /> </Base>
//     );
//   });
//   return (
//     <Base Menu={{ Menu: MenuHandler[RoleId] }} SideBarConfig={{ nada: 'user nothing',UserNameLast : UserNameLast, UserName:  UserName, RoleId:RoleId }}> <MainComponent name={props.name} _user={UserId} _rolId={RoleId} _customer={customerId} clientesSelect={clientesSelect}/> </Base>
//   );
// };

export default withRouter(WrapperRoute);
