import React, { Component, Suspense } from 'react';
import StepperEvent from '../../../../../Components/StepperEvent';
import Table from '../../../../../Components/Table';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { Services } from '../../../../Services';
import './styles.css';

const CardInfo = React.lazy(() => import('../../../../../Components/Cards'));

class Summary extends Component {
  state = {
    FechaActualizacion: '',
    doImportImpo: '',
    DocTransporte: '',
    HBL_HAWB: '',
    id: this.props.match.params.id,
    data: [],
    steps: [
      {
        label: 'Instrucción Cliente AA',
        value: '',
        id: 3,
        completed: false,
        order: 1,
        middleStep: '',
      },
      {
        label: 'ATA (Manifiesto)',
        value: '',
        id: 5,
        completed: false,
        order: 2,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Aceptación Solicitud DTA',
        value: '',
        id: 19,
        completed: false,
        order: 3,
        middle: false,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Autorización Inspección',
        value: '',
        id: 20,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: '',
      },
      {
        label: 'Salida DTA',
        value: '',
        id: 21,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: '',
      },
      {
        label: 'Finalización DTA',
        value: '',
        id: 22,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: '',
      },
      {
        label: 'Radicación de Factura',
        value: '',
        id: 16,
        completed: false,
        order: 7,
        idStep: 'step7',
        middleStep: '',
      },
    ],
    columns: [
      {
        label: 'Número de Remisión',
        key: 'NoRemision',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
      { label: 'Transportador', key: 'Transportador' },
      { label: 'Cantidad de Piezas', key: 'CantidadPiezas' },
      { label: 'Tipo de Embalaje', key: 'TipoEmbalaje' },
      { label: 'Dirección de Entrega', key: 'DireccionEntrega' },
      { label: 'Ciudad de Entrega', key: 'CiudadEntrega' },
    ],
    columnsTerrestre: [
      {
        label: 'No. Solicitud', key: 'NoSolicitud', custom: (value) => (
          <Link
            to={
              "/transportation-detail-view/" + value.TmsId
            }
          >{value.NoSolicitud}</Link>)
      },
      { label: 'Estatus', key: 'Estatus' },
      { label: 'Transportista', key: 'EmpresaTransportadora' },
      { label: 'Tipo servicio', key: 'TipoServicio' },
      { label: 'Origen', key: 'CiudadOrigen' },
      { label: 'Destino', key: 'CiudadDestino' },
      { label: 'Fecha salida', key: 'FechaSalidaRuta' },
      { label: 'Fecha descargue', key: 'FechaDescargue' },
    ],
    CardIndoHeaders: {
      title: 'Aduana DTA',
      subTitle: '',
      Icon: 'fa-3x mr-1 fas fa-arrow-alt-circle-right',
      style: { color: '#A7A8A9' },
    },
    Info: [
      {
        Referencia: 'ID8374',
        Aduana: 'Cruise 87',
        Proyecto: 'Roldan',
        Totales: 'KJH7687',
        Iniciales: '$2000,000',
        Anticipadas: '',
        Ordinarias: '',
        Temporales: '',
        TotalIVA: '$200,000',
        TotalAraceles: '$200,000',
        TotalTributos: '$400,000',
      },
    ],
    config: [
      {
        title: 'No. de Referencia del Cliente',
        xls: true,
        id: 'RefCliente',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
      {
        title: 'Proyecto ',
        xls: true,
        id: 'Proyecto',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      {
        title: 'Aduana',
        xls: true,
        id: 'Aduana',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      {
        title: 'Trasportador Internacional',
        xls: true,
        id: 'TransportadorInternacional',
        className: 'col-md-3 col-lg-3 col-sm-6',
      },
      {
        title: 'No. Autorización DTA',
        xls: true,
        id: 'NoAutorizacionDTA',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
      {
        title: 'FOB',
        xls: true,
        id: 'TotalFOB',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
    ],
    dataRoadTransport: [],
  };
  componentDidMount = () => {
    this.getDataForId();
    this.setState({
      steps: this.props.steps,
      HBL_HAWB: this.props.HBL_HAWB,
      DocTransporte: this.props.DocTransporte,
      doImportImpo: this.props.doImportImpo,
      Estatus: this.props.Estatus,
      cliente: this.props.cliente,
      NIT: this.props.NIT,
      Grupo: this.props.Grupo,
      FechaActualizacion: this.props.FechaActualizacion,
    });
  };
  componentWillReceiveProps = (nextProps) => {
    this.getDataForId();
    this.setState({
      steps: nextProps.steps,
      HBL_HAWB: nextProps.HBL_HAWB,
      DocTransporte: nextProps.DocTransporte,
      doImportImpo: nextProps.doImportImpo,
      Estatus: nextProps.Estatus,
      cliente: nextProps.cliente,
      NIT: nextProps.NIT,
      Grupo: nextProps.Grupo,
      FechaActualizacion: nextProps.FechaActualizacion,
    });
  };

  getDataForId = () => {
    axios
      .get(
        `${Services.Get_Traceability_Imports_ForId.path}/customs/dataCustomImports/${this.state.id}`,
        { params: { position: 'importDta' } }
      )
      .then((success) => {
        success.data.data.infoImport.map((e) => {
          e.TotalFOB = `$${Number(e.TotalFOB).toLocaleString('de-DE')}`;
          return e;
        });

        let Info = [];
        Info.push(success.data.data.infoImport[0]);

        this.setState({
          Info,
          data: success.data.data.Remisiones,
          doImportImpo: success.data.data.infoImport
            ? success.data.data.infoImport[0].DOAduanaImpo
            : '',
          dataRoadTransport: success.data.data.dataRoadTransport,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  toggleDrawerMap = (data) => (evt) => {
    // this.setState({ visible: true, dataDrawer: data });
  };

  render() {
    let { data, columns, cliente, NIT, Grupo, FechaActualizacion, columnsTerrestre, dataRoadTransport } = this.state;
    let title = 'Remisión';
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <Row>
              <div className='col-xs-2 col-md-1 col-lg-1 d-flex justify-content-center d-flex align-items-center'>
                <i style={{ color: '#a7a8a9' }} className="fa-3x fas fa-arrow-alt-circle-right"></i>
              </div>
              <div className='col-xs-10 col-md-6 col-lg-6'>
                <p className='sizeTextComents' >{cliente} ({NIT}) / {Grupo}</p>
                <p className='sizeTextComents' style={{ color: '#0099ff' }}>{this.state.doImportImpo}</p>
                <p className='sizeTextComents' > {this.state.DocTransporte}</p>
              </div>
              <div className='col-xs-12 col-md-5 col-lg-5 d-flex justify-content-end'>
                <span className='sizeTextComents' > <i>Última Actualización:</i>  {FechaActualizacion}</span>
              </div>
              <div className='col-12 mt-1'>
                {this.state.Estatus}
              </div>
            </Row >
            <div className="col-12">
              <StepperEvent steps={this.state.steps} />
            </div>
          </CardBody>
        </Card>
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <CardInfo
            toggleDrawer={this.toggleDrawerMap}
            CardIndoHeaders={this.state.CardIndoHeaders}
            config={this.state.config}
            data={this.state.Info}
            cardInfon={true}
          ></CardInfo>
        </Suspense>{' '}
        <br />
        {/* <br/> */}
        <Card className="myCard">
          <CardBody>
            <span className='titleCard'>{title}</span>
            <div className="col-12">
              <Table
                columns={columns}
                data={data}
                options={{
                  large: false,
                  downloads: {
                    pdf: false,
                    xlsx: false,
                  },
                }}
              />
            </div>
          </CardBody>
        </Card>
        <Card className="myCard">
          <CardBody>
            <span className='titleCard'>{'Transporte Terrestre'}</span>

            <Table
              columns={columnsTerrestre}
              data={dataRoadTransport}
              options={{
                large: false,
                downloads: {
                  pdf: false,
                  xlsx: false,
                },
              }}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(Summary);
