// Roles
// 1	"Administrador"
// 2	"Cliente"
// 3	"Roldan"
// 4	"Roldan SU"
// 5	"Gestor Documental"
export const MenuHandler = {
  // admin
  1: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-pie-chart",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-graph",
      submenu: [
        {
          name: "Integrada",
          path: "/integrada",
        },
        {
          name: "Internacional",
          path: "/shipments",
        },
        {
          name: "Aduana",
          path: "/customs",
        },
        {
          name: "Almacén",
          path: "/storage",
        },
        {
          name: "Transporte",
          path: "/transportation",
        },
        {
          name: "Detalle Inventario",
          path: "/inventaryDetail",
        },
        // {
        //   name: 'Control de Contenedores',
        //   path: '/ContainerControl'
        // }
      ],
    },
    // {
    //   name: 'Almacén',
    //   icon: 'icon-graph',
    //   submenu: [
    //     {
    //       name: 'Almacenamiento',
    //       path: '/storage',
    //     },{
    //       name: 'Detalle Inventario',
    //       path: '/inventaryDetail',
    //     }
    //   ]
    // },
    {
      name: "Reportes y Analíticos",
      icon: "icon-people",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/reporting",
        },
        {
          name: "BI Gerencial",
          path: "/bi-gerencial",
        },
        {
          name: "BI Operativo",
          path: "/bi-operativo",
        },
      ],
    },
    {
      name: "Gestor Documental",
      icon: "icon-doc",
      path: "document-manager",
      submenu: [
        {
          name: "Aduana",
          path: "/document-manager-customs",
        },
        {
          name: "Almacén",
          path: "/document-manager-warehouse",
        },
      ],
      //translate: 'sidebar.nav.DASHBOARD'
    },
    {
      name: "Facturación y Costos",
      icon: "fa fa-chart-line",
      path: "/facturacion-costos",
    },
    {
      name: "Proyectos",
      icon: "fa fa-industry",
      path: "/tablero-de-control/proyectos",
    },
    {
      name: "Herramientas Logísticas",
      icon: "fa fa-wrench",
      path: "/logistics-tools",
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
    // {
    //   name: 'Facturación y Costos',
    //   icon: 'fa fa-chart-line',
    //   path: '/facturacion-costos',
    // },
    {
      name: "Administración",
      icon: "icon-settings",
      path: "my-fairs",
      submenu: [
        {
          name: "Usuarios",
          path: "/Admin",
        },
        {
          name: "Alertas",
          path: "/alerts",
        },
        {
          name: "Estadísticas de Uso",
          path: "/statistics",
        },
        {
          name: "Grupos",
          path: "/groups",
        },
      ],
      translate: "sidebar.nav.DASHBOARD",
    },
    // {
    //   name: 'Servicio al cliente',
    //   icon: 'icon-settings',
    //   path: '/dashboard',
    //   translate: 'sidebar.nav.ADMIN'
    // },
    // {
    //   name: 'Preguntas Frecuentes',
    //   icon: 'icon-question',
    //   path: '/faqs',
    //   translate: 'sidebar.nav.DASHBOARD',
    // },
    {
      name: "Soporte",
      icon: "icon-question",
      path: "",
      submenu: [
        {
          name: "Preguntas Frecuentes",
          path: "/faqs",
        },
        {
          name: "Service Desk",
          path: "/Service-Desk",
        },
      ],
    },
  ],
  2: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-pie-chart",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-graph",
      submenu: [
        {
          name: "Integrada",
          path: "/integrada",
        },
        {
          name: "Internacional",
          path: "/shipments",
        },
        {
          name: "Aduana",
          path: "/customs",
        },
        {
          name: "Almacén",
          path: "/storage",
        },
        {
          name: "Transporte",
          path: "/transportation",
        },
        {
          name: "Detalle Inventario",
          path: "/inventaryDetail",
        },
        // {
        //   name: 'Control de Contenedores',
        //   path: '/ContainerControl'
        // }
      ],
    },
    // {
    //   name: 'Almacén',
    //   icon: 'icon-graph',
    //   submenu: [
    //     {
    //       name: 'Almacenamiento',
    //       path: '/storage',
    //     },{
    //       name: 'Detalle Inventario',
    //       path: '/inventaryDetail',
    //     }
    //   ]
    // },
    {
      name: "Reportes y Analíticos",
      icon: "icon-people",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/reporting",
        },
        {
          name: "BI Gerencial",
          path: "/bi-gerencial",
        },
        {
          name: "BI Operativo",
          path: "/bi-operativo",
        },
      ],
    },
    {
      name: "Gestor Documental",
      icon: "icon-doc",
      path: "document-manager",
      submenu: [
        {
          name: "Aduana",
          path: "/document-manager-customs",
        },
        {
          name: "Almacén",
          path: "/document-manager-warehouse",
        },
      ],
      //translate: 'sidebar.nav.DASHBOARD'
    },
    {
      name: "Facturación y Costos",
      icon: "fa fa-chart-line",
      path: "/facturacion-costos",
    },
    {
      name: "Proyectos",
      icon: "fa fa-industry",
      path: "/tablero-de-control/proyectos",
    },
    {
      name: "Herramientas Logísticas",
      icon: "fa fa-wrench",
      path: "/logistics-tools",
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },

    // {
    //   name: 'Administración',
    //   icon: 'icon-briefcase',
    //   path: 'my-fairs',
    //   submenu: [{
    //     name: 'Usuarios',
    //     path: '/Admin'
    //   },
    //   {
    //     name: 'Alertas',
    //     path: '/alerts'
    //   }],
    //   translate: 'sidebar.nav.DASHBOARD'
    // },
    // {
    //   name: 'Servicio al cliente',
    //   icon: 'icon-settings',
    //   path: '/dashboard',
    //   translate: 'sidebar.nav.ADMIN'
    // }
  ],
  3: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-pie-chart",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-graph",
      submenu: [
        {
          name: "Integrada",
          path: "/integrada",
        },
        {
          name: "Internacional",
          path: "/shipments",
        },
        {
          name: "Aduana",
          path: "/customs",
        },
        {
          name: "Almacén",
          path: "/storage",
        },
        {
          name: "Transporte",
          path: "/transportation",
        },
        {
          name: "Detalle Inventario",
          path: "/inventaryDetail",
        },
        // {
        //   name: 'Control de Contenedores',
        //   path: '/ContainerControl'
        // }
      ],
    },
    // {
    //   name: 'Almacén',
    //   icon: 'icon-graph',
    //   submenu: [
    //     {
    //       name: 'Almacenamiento',
    //       path: '/storage',
    //     },{
    //       name: 'Detalle Inventario',
    //       path: '/inventaryDetail',
    //     }
    //   ]
    // },
    {
      name: "Reportes y Analíticos",
      icon: "icon-people",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/reporting",
        },
        {
          name: "BI Gerencial",
          path: "/bi-gerencial",
        },
        {
          name: "BI Operativo",
          path: "/bi-operativo",
        },
        // {
        //   name: 'Mi Tablero de Control',
        //   path: '/dashboard'
        // }
      ],
    },
    {
      name: "Gestor Documental",
      icon: "icon-doc",
      path: "document-manager",
      submenu: [
        {
          name: "Aduana",
          path: "/document-manager-customs",
        },
        {
          name: "Almacén",
          path: "/document-manager-warehouse",
        },
      ],
      //translate: 'sidebar.nav.DASHBOARD'
    },
    {
      name: "Facturación y Costos",
      icon: "fa fa-chart-line",
      path: "/facturacion-costos",
    },
    {
      name: "Proyectos",
      icon: "fa fa-industry",
      path: "/tablero-de-control/proyectos",
    },
    {
      name: "Herramientas Logísticas",
      icon: "fa fa-wrench",
      path: "/logistics-tools",
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
    {
      name: "Soporte",
      icon: "icon-question",
      path: "",
      submenu: [
        {
          name: "Preguntas Frecuentes",
          path: "/faqs",
        },
        {
          name: "Service Desk",
          path: "/Service-Desk",
        },
      ],
    },

    // {
    //   name: 'Administración',
    //   icon: 'icon-briefcase',
    //   path: 'my-fairs',
    //   submenu: [{
    //     name: 'Usuarios',
    //     path: '/Admin'
    //   },
    //   {
    //     name: 'Alertas',
    //     path: '/alerts'
    //   }],
    //   translate: 'sidebar.nav.DASHBOARD'
    // },
    // {
    //   name: 'Servicio al cliente',
    //   icon: 'icon-settings',
    //   path: '/dashboard',
    //   translate: 'sidebar.nav.ADMIN'
    // }
  ],
  4: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-pie-chart",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-graph",
      submenu: [
        {
          name: "Integrada",
          path: "/integrada",
        },
        {
          name: "Internacional",
          path: "/shipments",
        },
        {
          name: "Aduana",
          path: "/customs",
        },
        {
          name: "Almacén",
          path: "/storage",
        },
        {
          name: "Transporte",
          path: "/transportation",
        },
        {
          name: "Detalle Inventario",
          path: "/inventaryDetail",
        },
        // {
        //   name: 'Control de Contenedores',
        //   path: '/ContainerControl'
        // }
      ],
    },
    // {
    //   name: 'Almacén',
    //   icon: 'icon-graph',
    //   submenu: [
    //     {
    //       name: 'Almacenamiento',
    //       path: '/storage',
    //     },{
    //       name: 'Detalle Inventario',
    //       path: '/inventaryDetail',
    //     }
    //   ]
    // },
    {
      name: "Reportes y Analíticos",
      icon: "icon-people",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/reporting",
        },
        {
          name: "BI Gerencial",
          path: "/bi-gerencial",
        },
        {
          name: "BI Operativo",
          path: "/bi-operativo",
        },
        // {
        //   name: 'Mi Tablero de Control',
        //   path: '/dashboard'
        // }
      ],
    },
    {
      name: "Gestor Documental",
      icon: "icon-doc",
      path: "document-manager",
      submenu: [
        {
          name: "Aduana",
          path: "/document-manager-customs",
        },
        {
          name: "Almacén",
          path: "/document-manager-warehouse",
        },
      ],
      //translate: 'sidebar.nav.DASHBOARD'
    },
    {
      name: "Facturación y Costos",
      icon: "fa fa-chart-line",
      path: "/facturacion-costos",
    },
    {
      name: "Proyectos",
      icon: "fa fa-industry",
      path: "/tablero-de-control/proyectos",
    },
    {
      name: "Herramientas Logísticas",
      icon: "fa fa-wrench",
      path: "/logistics-tools",
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },

    {
      name: "Administración",
      icon: "icon-briefcase",
      path: "my-fairs",
      submenu: [
        //   {
        //   name: 'Usuarios',
        //   path: '/Admin'
        // },
        // {
        //   name: 'Alertas',
        //   path: '/alerts'
        // },
        {
          name: "Grupos",
          path: "/groups",
        },
        {
          name: "Estadísticas de Uso",
          path: "/statistics",
        },
      ],
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Soporte",
      icon: "icon-question",
      path: "",
      submenu: [
        {
          name: "Preguntas Frecuentes",
          path: "/faqs",
        },
        {
          name: "Service Desk",
          path: "/Service-Desk",
        },
      ],
    },
  ],
  5: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Gestor Documental",
      icon: "icon-doc",
      path: "document-manager",
      submenu: [
        {
          name: "Aduana",
          path: "/document-manager-customs",
        },
        {
          name: "Almacén",
          path: "/document-manager-warehouse",
        },
        {
          name: "Transporte",
          path: "/transportation",
        },
      ],
      //translate: 'sidebar.nav.DASHBOARD'
    },
  ],
};

export const NameRole = {
  1: "Administrador",
  2: "Cliente",
  3: "Roldan",
  4: "Roldan SU",
  5: "Gestor Documental",
};
