import React from 'react';
import { Loading } from 'react-simple-chatbot';
import TransportInternacional from '../components/transporteInternacional';
import TransporteNacional from '../components/transporteNacionalModal';
import AgImportacion from '../components/agenciamientoImportacion';
import AgExportacion from '../components/agenciamientoExportacion';
import Almacenamiento from '../components/almacenamientoCOtizacion';
import Proyecto from '../components/proyectoCotizacion';

const messageLabel = `La información proporcionada será enviada a uno de nuestros agentes de
servicio para brindarle la información acerca de su cotización.`;

function Cotizador(props) {
  const { steps } = props;
  const { MenuCotizarServicio } = steps;
  const [service, setService] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [labelContent, setLabelContent] = React.useState(messageLabel);

  const handlerQuote = React.useCallback(async () => {
    const service = MenuCotizarServicio.value[0];
    // eslint-disable-next-line no-console
    setService(service);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    handlerQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResults = (results) => {
    if (!results.message) {
      setLabelContent(
        'El correo no fue enviado, esto debido a que no fue proporcionada la información necesaria para realizar una cotización'
      );
    }
    props.triggerNextStep({
      value: ['Cotizar un servicio', 'CalificarServicio'],
      trigger: 'RealizarOtraConsulta',
    });

    setLoading(false);
  };

  const selectmodal = () => {
    if (service) {
      if (service === 'Transporte Internacional')
        return <TransportInternacional onResults={handleResults} />;

      if (service === 'Transporte Nacional')
        return <TransporteNacional onResults={handleResults} />;

      if (service === 'Agenciamiento Importación')
        return <AgImportacion onResults={handleResults} />;

      if (service === 'Agenciamiento Exportación')
        return <AgExportacion onResults={handleResults} />;

      if (service === 'Almacenamiento')
        return <Almacenamiento onResults={handleResults} />;

      if (service === 'Proyecto') return <Proyecto onResults={handleResults} />;
    }
  };

  return (
    <>
      {loading ? <Loading /> : <div>{labelContent}</div>}
      {selectmodal()}
    </>
  );
}

export default Cotizador;
