import React, { Component } from "react";
import { Container, Row, Col, CardBody, CardText } from "reactstrap";
import FlotChart from "./Charts/Float.js";
import { ChartSpline, ChartBar } from "./ChartFlot.setup.js";
import "./styles.scss";
import YearsSelect from "./CatYears/index.js";
import { MyResponsiveBarCanvas } from "./Charts/BarChart.js";
import { MyResponsivePieCanvas } from "./Charts/DonutGraph.js";

class Graphic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearselected: "",
      yearCat: props.yearCat ? props.yearCat : [],
      tercerChart: props.tercerChart ? props.tercerChart : [],
      cuartaChar: props.cuartaChar ? props.cuartaChar : [],
      CupoCartera: props.CupoCartera ? props.CupoCartera : [],
      sparkOptions: {
        barColor: "#cfdbe2",
        height: 20,
        barWidth: 3,
        barSpacing: 2,
      },
      datos: [],
      keys: [],
      colors: ["#f9ed90", "#83db86", "#d3d3d3", "#2B98F0", "#f4ab35"],
      sparkValues: [1, 0, 4, 9, 5, 7, 8, 9, 5, 7, 8, 4, 7],
      data: this.props.data,
      Graphic1: [],
      enero: 0,
      chart1: "",
      chart2: "",
      ChartSpline: ChartSpline,
      ChartBar: ChartBar,
    };
  }
  componentDidMount = () => {
    this.buildMounths();
  };

  buildMounths = (year) => {
    let { ChartSpline, yearCat } = this.state;

    const InvoiceTrend = this.state.data;
    let yearselected = year ? year : yearCat.length > 0 ? yearCat[0].value : "";
    let dataInvoiceTrend = [];
    InvoiceTrend.map((e) => {
      if (e.Switche === yearselected) {
        dataInvoiceTrend.push(e);
      }
    });

    let json = {
      1: { val: 0, label: "Jan" },
      2: { val: 0, label: "Feb" },
      3: { val: 0, label: "Mar" },
      4: { val: 0, label: "Abr" },
      5: { val: 0, label: "May" },
      6: { val: 0, label: "Jun" },
      7: { val: 0, label: "Jul" },
      8: { val: 0, label: "Ago" },
      9: { val: 0, label: "Sep" },
      10: { val: 0, label: "Oct" },
      11: { val: 0, label: "Nov" },
      12: { val: 0, label: "Dic" },
    };

    dataInvoiceTrend.map(function(index) {
      let Mes = index.Order;
      let found = json[Mes];
      if (found) {
        json[Mes].val = index.Total;
      }
      return index;
    });
    let data = [];
    Object.keys(json).forEach((e) => {
      let array = [];
      array.push(json[e].label);
      array.push(json[e].val);
      data.push(array);
    });

    ChartSpline.data[0].data = data;
    this.setState(
      {
        ChartSpline,
        chart1: "",
      },
      () => {
        this.setState({
          chart1: (
            <>
              <Col md={12}>
                <div className="col-md-1"></div>
                <div className="flex-containerCatyear col-md-11">
                  {dataInvoiceTrend.length === 0 ? (
                    ""
                  ) : (
                    <YearsSelect
                      yearselected={this.state.yearselected}
                      yearCat={yearCat}
                      onChangeYear={this.onChangeYear}
                    />
                  )}
                </div>
              </Col>
              {dataInvoiceTrend.length === 0 ? (
                <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                  {" "}
                  Sin Resultados
                </h1>
              ) : (
                <FlotChart
                  options={ChartSpline.options}
                  data={ChartSpline.data}
                  className="flot-chart"
                  height="250px"
                />
              )}
            </>
          ),
        });
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        data: nextProps.data,
        yearCat: nextProps.yearCat,
        cuartaChar: nextProps.cuartaChar,
        tercerChart: nextProps.tercerChart,
        CupoCartera: nextProps.CupoCartera,
      },
      () => {
        this.buildMounths();
      }
    );
  };

  onChangeYear = async (data) => {
    this.setState(
      {
        yearselected: data,
      },
      () => this.buildMounths(data)
    );
  };

  render() {
    let {
      CupoCartera,
      cuartaChar,
      chart1,
      tercerChart,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col style={{ marginBottom: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Facturación
                </CardText>
                {chart1}
              </CardBody>
            </div>
          </Col>

          <Col style={{ marginBotton: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Cupo de Cartera
                </CardText>
                {CupoCartera.length === 0 ? (
                  <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                    {" "}
                    Sin Resultados
                  </h1>
                ) : (
                  <MyResponsiveBarCanvas
                    left={135}
                    indexBy={true}
                    position="vertical"
                    data={CupoCartera}
                  />
                )}
              </CardBody>
            </div>
          </Col>
          <Col style={{ marginBotton: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Conceptos
                </CardText>
                {tercerChart.length === 0 ? (
                  <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                    {" "}
                    Sin Resultados
                  </h1>
                ) : (
                  <MyResponsiveBarCanvas
                    position="vertical"
                    data={this.state.tercerChart}
                  />
                )}
              </CardBody>
            </div>
          </Col>
          <Col style={{ marginBotton: "20px" }} lg={6}>
            <div className=" equalsWidth2">
              <CardBody
                style={{ borderRadius: "22px" }}
                className="graphcardbody"
              >
                <CardText
                  style={{
                    textAlign: "center",
                    fontFamily: "unset",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Estatus
                </CardText>
                {cuartaChar.length === 0 ? (
                  <h1 style={{ textAlign: "center", marginTop: "2em" }}>
                    {" "}
                    Sin Resultados
                  </h1>
                ) : (
                  <MyResponsivePieCanvas data={cuartaChar} />
                )}
              </CardBody>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Graphic;
