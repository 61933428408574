import React from 'react';
import { Card, Input, Grid } from 'semantic-ui-react';
import { Col, Label } from 'reactstrap';
import Select from 'react-virtualized-select';
import { UnidadesMetricas } from './sistemaMetrico';
import { getValuesOp } from './operaciones';
import Loader from '../../../../Global/Loader';
import { set } from 'lodash';

function ConvertidorMedidas() {
  let [inputMasa, setInputMasa] = React.useState(1);
  let [inputLong, setInputLong] = React.useState(1);
  let [inputArea, setInputArea] = React.useState(1);
  let [inputVol, setInputVol] = React.useState(1);

  let [selectMasa, setSelectMasa] = React.useState('kg');
  let [selectMasaConv, setSelectMasaConv] = React.useState('lb');

  let [selectLong, setSelecLong] = React.useState('m');
  let [selectLongConv, setSelecLongConv] = React.useState('ft');

  let [selectArea, setSelectArea] = React.useState('m2');
  let [selectAreaConv, setSelectAreaConv] = React.useState('ft2');

  let [selectVol, setSelectVol] = React.useState('l');
  let [selectVolConv, setSelectVolConv] = React.useState('T');

  let [resultMasa, setResultMasa] = React.useState('');
  let [resultLongitud, setResultLongitud] = React.useState('');
  let [resultArea, setResultArea] = React.useState('');
  let [resultVol, setResultVol] = React.useState('');

  const inputHandler = (tableName) => {
    let rende;
    if (tableName === 'Masa') {
      rende = (
        <Input
          fluid
          type="number"
          value={inputMasa}
          onChange={(e, { value }) => (
            setInputMasa(value),
            operaciones(tableName, value, selectMasa, selectMasaConv)
          )}
        />
      );
    }

    if (tableName === 'Longitud') {
      rende = (
        <Input
          fluid
          type="number"
          value={inputLong}
          onChange={(e, { value }) => (
            setInputLong(value),
            operaciones(tableName, value, selectLong, selectLongConv)
          )}
        />
      );
    }

    if (tableName === 'Area') {
      rende = (
        <Input
          fluid
          type="number"
          value={inputArea}
          onChange={(e, { value }) => (
            setInputArea(value),
            operaciones(tableName, value, selectArea, selectAreaConv)
          )}
        />
      );
    }

    if (tableName === 'Volumen') {
      rende = (
        <Input
          fluid
          type="number"
          value={inputVol}
          onChange={(e, { value }) => (
            setInputVol(value),
            operaciones(tableName, value, selectVol, selectVolConv)
          )}
        />
      );
    }

    return rende;
  };

  const resultHandler = (tableName) => {
    let result;
    if (tableName === 'Masa') {
      result = resultMasa;
    }

    if (tableName === 'Longitud') {
      result = resultLongitud;
    }

    if (tableName === 'Area') {
      result = resultArea;
    }

    if (tableName === 'Volumen') {
      result = resultVol;
    }

    return result;
  };

  const unidadInicial = (tableName, event) => {
    if (tableName === 'Masa' && event) {
      setSelectMasa(event.value);
      operaciones(event.um, inputMasa, event.value, selectMasaConv);
    }
    if (tableName === 'Masa' && !event) {
      setSelectMasa('');
      setResultMasa('');
    }

    if (tableName === 'Longitud' && event) {
      setSelecLong(event.value);
      operaciones(event.um, inputLong, event.value, selectLongConv);
    }
    if (tableName === 'Longitud' && !event) {
      setSelecLong('');
      setResultLongitud('');
    }

    if (tableName === 'Area' && event) {
      setSelectArea(event.value);
      operaciones(event.um, inputArea, selectArea, event.value);
    }
    if (tableName === 'Area' && !event) {
      setSelectArea('');
      setResultArea('');
    }

    if (tableName === 'Volumen' && event) {
      setSelectVol(event.value);
      operaciones(event.um, inputVol, selectVol, event.value);
    }
    if (tableName === 'Volumen' && !event) {
      setSelectVol('');
      setResultVol('');
    }
  };

  const unidadAConvertir = (tableName, event) => {
    if (tableName === 'Masa' && event) {
      setSelectMasaConv(event.value);
      operaciones(event.um, inputMasa, selectMasa, event.value);
    }
    if (tableName === 'Masa' && !event) {
      setSelectMasaConv('');
      setResultMasa('');
    }

    if (tableName === 'Longitud' && event) {
      setSelecLongConv(event.value);
      operaciones(event.um, inputLong, selectLong, event.value);
    }
    if (tableName === 'Longitud' && !event) {
      setSelecLongConv('');
      setResultLongitud('');
    }

    if (tableName === 'Area' && event) {
      setSelectAreaConv(event.value);
      operaciones(event.um, inputArea, selectArea, event.value);
    }
    if (tableName === 'Area' && !event) {
      setSelectAreaConv('');
      setResultArea('');
    }

    if (tableName === 'Volumen' && event) {
      setSelectVolConv(event.value);
      operaciones(event.um, inputVol, selectVol, event.value);
    }
    if (tableName === 'Volumen' && !event) {
      setSelectVolConv('');
      setResultVol('');
    }
  };

  const operaciones = (tableName, value, fin, sin) => {
    if (tableName === 'Masa') {
      if (value && fin && sin) {
        setResultMasa(getValuesOp.Masa(value, fin, sin));
      } else {
        setResultMasa('');
      }
    }

    if (tableName === 'Longitud') {
      if (value && fin && sin) {
        setResultLongitud(getValuesOp.Longitud(value, fin, sin));
      } else {
        setResultLongitud('');
      }
    }

    if (tableName === 'Area') {
      if (value && fin && sin) {
        setResultArea(getValuesOp.Area(value, fin, sin));
      } else {
        setResultArea('');
      }
    }

    if (tableName === 'Volumen') {
      if (value && fin && sin) {
        setResultVol(getValuesOp.Volumen(value, fin, sin));
      } else {
        setResultVol('');
      }
    }
  };

  const cardContent = (unidades, valDef, valConvert, tableName) => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Card fluid className="CARD UI REACT STRAP BORDER">
              <Card.Content>
                <Grid celled="internally">
                  <Grid.Row>
                    <Grid.Column>
                      <Grid.Row>
                        <Col
                          lg={2}
                          style={{ textAlign: 'center', marginTop: '7px' }}
                        >
                          <Label>Convertir</Label>
                        </Col>
                        <Col lg={3}>{inputHandler(tableName)}</Col>
                        <Col lg={3}>
                          {tableName === 'Masa' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              //name={selectMasa}
                              placeholder=""
                              value={selectMasa}
                              onChange={(selectValue) =>
                                unidadInicial(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}
                          {tableName === 'Longitud' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              name={selectLong}
                              placeholder=""
                              value={selectLong}
                              onChange={(selectValue) =>
                                unidadInicial(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}
                          {tableName === 'Area' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              // name={selectArea}
                              placeholder=""
                              value={selectArea}
                              onChange={(selectValue) =>
                                unidadInicial(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}
                          {tableName === 'Volumen' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              // name={selectArea}
                              placeholder=""
                              value={selectVol}
                              onChange={(selectValue) =>
                                unidadInicial(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col
                          lg={1}
                          style={{ textAlign: 'center', marginTop: '7px' }}
                        >
                          <center>A</center>
                        </Col>
                        <Col lg={3}>
                          {tableName === 'Masa' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              //name={selectMasaConv}
                              placeholder=""
                              value={selectMasaConv}
                              onChange={(selectValue) =>
                                unidadAConvertir(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}

                          {tableName === 'Longitud' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              //name={selectMasaConv}
                              placeholder=""
                              value={selectLongConv}
                              onChange={(selectValue) =>
                                unidadAConvertir(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}

                          {tableName === 'Area' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              //name={selectMasaConv}
                              placeholder=""
                              value={selectAreaConv}
                              onChange={(selectValue) =>
                                unidadAConvertir(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}

                          {tableName === 'Volumen' ? (
                            <Select
                              // id={e.name}
                              // type={e.type}
                              //name={selectMasaConv}
                              placeholder=""
                              value={selectVolConv}
                              onChange={(selectValue) =>
                                unidadAConvertir(tableName, selectValue)
                              }
                              options={unidades}
                            />
                          ) : (
                            ''
                          )}
                        </Col>
                      </Grid.Row>
                      <br />
                      <br />
                      <Grid.Row>
                        <Col lg={1}>
                          <i
                            class="fas fa-equals"
                            style={{ marginLeft: '15px' }}
                          ></i>
                        </Col>
                        <Col lg={8}>
                          <Label style={{ marginBottom: '10px' }}>
                            {resultHandler(tableName)}
                          </Label>
                        </Col>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          </div>
        </div>
      </>
    );
  };

  const init = React.useCallback(async () => {
    setResultMasa(getValuesOp.Masa(1, 'kg', 'lb'));
    setResultLongitud(getValuesOp.Longitud(1, 'm', 'ft'));
    setResultArea(getValuesOp.Area(1, 'm2', 'ft2'));
    setResultVol(getValuesOp.Volumen(1, 'l', 'T'));
  });

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      <br />

      <div className="row">
        <div className="col-5">
          <div className="row">
            <div className="col-6">
              <Card fluid style={{ backgroundColor: '#003F70' }}>
                <Card.Content>
                  <Label style={{ font: 'bold', color: 'white' }}>Masa</Label>
                </Card.Content>
              </Card>
            </div>
          </div>
        </div>

        <div className="col-1"></div>

        <div className="col-5">
          <div className="row">
            <div className="col-6">
              <Card fluid style={{ backgroundColor: '#003F70' }}>
                <Card.Content>
                  <Label style={{ font: 'bold', color: 'white' }}>
                    Longitud
                  </Label>
                </Card.Content>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {cardContent(
            UnidadesMetricas.masa,
            selectMasa,
            selectMasaConv,
            'Masa'
          )}
        </div>

        <div className="col-6">
          {cardContent(
            UnidadesMetricas.longitud,
            selectLong,
            selectLongConv,
            'Longitud'
          )}
        </div>
      </div>

      <br />
      <br />
      {/* =========== NUEVAS CARDS =========== */}

      <div className="row">
        <div className="col-5">
          <div className="row">
            <div className="col-6">
              <Card fluid style={{ backgroundColor: '#003F70' }}>
                <Card.Content>
                  <Label style={{ font: 'bold', color: 'white' }}>Área</Label>
                </Card.Content>
              </Card>
            </div>
          </div>
        </div>

        <div className="col-1"></div>

        <div className="col-5">
          <div className="row">
            <div className="col-6">
              <Card fluid style={{ backgroundColor: '#003F70' }}>
                <Card.Content>
                  <Label style={{ font: 'bold', color: 'white' }}>
                    Volumen
                  </Label>
                </Card.Content>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {cardContent(
            UnidadesMetricas.area,
            selectArea,
            selectAreaConv,
            'Area'
          )}
        </div>

        <div className="col-6">
          {cardContent(
            UnidadesMetricas.volumen,
            selectVol,
            selectVolConv,
            'Volumen'
          )}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default ConvertidorMedidas;
