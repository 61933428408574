import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import { Label } from 'reactstrap';
import {connect} from 'react-redux';

import Cards from './Components/Cards';
import { Services } from './Services';
import ContentWrapper from '../../template/Layout/ContentWrapper';
// import ClientesList from '../../../services/storageClient';
// const clientSave = new ClientesList();
import Loader from '../../Global/Loader';

class QuickSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // clientesSelect: clientSave.getAuthentication('client'),
      results: [],
      path: '',
      fullLoader: false
    };
  }
  
  componentDidMount = () => {
    this.setState({ path: decodeURIComponent(this.props.match.params.id) }, () => this.getQuickSearch());
  };

  componentWillReceiveProps = nextProps => {
    this.setState({ path: decodeURIComponent(nextProps.match.params.id), fullLoader: false}, () => this.getQuickSearch());
  };

  getQuickSearch = () => {
    const { path } = this.state;
    let timeStart; 
    timeStart = Date.now();
    const data = {
      // cliente: this.state.clientesSelect
      cliente: this.props.clients ? JSON.stringify(this.props.clients): '[0]'
    };
    axios.post(`${Services.Get_QuickSearch.path}/quicksearch?q=${path}`, data).then((response) => {
    //axios.post(`http://localhost:3020/quicksearch?q=${path}`, data).then((response) => {
      this.setState({ 
        data: response.data.message, 
        sizeRes: response.data.message.Total, 
        fullLoader: true 
      });
    }).catch((error) => {
      this.setState({  
        fullLoader: true 
      });
      console.error('ERROR', error);
    });

    this.setState({timeStart});
  };

  render() {
    const { timeStart, data, path , sizeRes, fullLoader} = this.state;
    const timeEnddd = Date.now();
    const timeValue = (timeEnddd - timeStart) / 1000;

    return (
      <ContentWrapper>
        <Loader hidden={fullLoader}></Loader>
        <Label>Total de resultados: {sizeRes}  ({timeValue} segundos) para  <b> "{ path }" </b></Label>
        <Cards  data={data} /> 
      </ContentWrapper>
    );
  }
}
const mapStateToProps = state => ({
  clients: state.header.clients
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickSearch));
