import React, { Component } from 'react'
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { Card, CardBody, Row, Col } from 'reactstrap';


class Line extends Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            items: ''
        }
    }

    componentDidMount = () => {
        this.setState({
            comments: this.props.comments
        }, () => this.BuildComments())
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            comments: nextProps.comments
        }, () => this.BuildComments())
    }

    BuildComments = () => {
        let { comments } = this.state
        let items = comments.map((e, index) => {
            return (
                <Col xs={12}>
                    <TimelineItem
                        key={index}
                        dateText={e.Date}
                        dateInnerStyle={{ background: '#1D5E97', fontWeight: 'bold' }} >
                        <Card className="myCard">
                            <CardBody>
                                <div>
                                    <legend className='sizeTextComents'>{e.Module}</legend>
                                    <p className='sizeTextComents' style={{ fontWeight: 'bold' }}>{e.ComType}</p>
                                    <p className='sizeTextComents'>{e.Comment}</p>
                                </div>
                            </CardBody>
                        </Card>
                    </TimelineItem>
                </Col>
            )
        })
        this.setState({
            items
        })
    }

    render() {
        return (
            <Timeline lineColor={'#fff'}>
                <Row>
                    {this.state.items}
                </Row>
            </Timeline>
        )
    }
}

export default Line

