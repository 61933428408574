import React from 'react';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { Container, Row, Col, CardBody, CardText, } from 'reactstrap';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsiveBarCanvas = ({ position, data , width, height, left, indexBy /* see data tab */ }) =>{
  let colors = [];
  data.map((e) => {
    colors.push(e.CancelledColor);
    return e;
  });
  return((
    <ResponsiveBarCanvas
      data={data}
      keys={[
        'Prospecting',
        'Proposal Submitted',
        'Proposal Follow Up',
        'Negotiation',
        'Won',
        'Lost',
        'Cancelled',
        'status2'
      ]}
      axisTop={null}
      indexBy=
        {_index =>{

          if(indexBy){
            // let valor = _index.status.length>13 ?`${_index.status.substring(0,(15-2))}..` : `${_index.status}`;
            let valor = `${_index.status}`;
          // .padEnd(10, "foo")
          return valor;
          }
          else{
            let valor = `${_index.index}-.${_index.status.substring(0,(10-3))}..`;
            let valor2 = '';
  
            if (_index.index - 10){
              let flat = valor.search('I');
              valor2 =  valor.padEnd(flat >0 ?  14 :12,' ');
            }
            else{
              valor2 =valor;
            }
            // .padEnd(10, "foo")
            return valor2;
          }

         

        } }
      // "status"
      // isInteractive={false}
      margin={{ top: 3, right: 10, bottom: 80, left: left ? left : 90 }}
      pixelRatio={1}
      padding={0.3}
      innerPadding={0}
      minValue="auto"
      // minValue={-97}
      maxValue="auto"
      groupMode="stacked"
      layout={'horizontal'}
      reverse={false}
      colors={colors}
      // box-shadow={{0 8px 16px 0 rgba(0,0,0,0.2)}}
      colorBy="index"
      borderWidth={0}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisRight={null}
      tooltip={ (val)=> {
        return (
          <Row>
            <Col md={12}>
              <h5> <span style={{height:'15px',width:'15px',background:val.color,borderRadius:'50%',display:'inline-block'}}></span>  {val.data.id}  : ${(Number(val.value).toLocaleString('En-us'))} </h5>
            </Col>
          </Row>
        );
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 0,
        tickRotation: -35,
        // legend: 'country',
        legendPosition: 'middle',
        legendOffset: 36,

        format:
          function numberWithCommas(x) {
            return '$' + x.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
          }
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 6,
        tickRotation: 0,
        // legend: 'food',
        legendPosition: 'middle',
        legendOffset: -40,
      }}

      // tooltip={ (val)=> {
      //   return (
      //     <h5>{val.data.id ? val.data.id  : val.data.status} -- {val.data.Cancelled}</h5>
      //   );
      // }}
      enableGridX={false}
      enableGridY={false}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
    />
  ));
};
