import React, { Component } from 'react';
import Line from './lineComments';
import AddComment from './addComments';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      comments: [],
      id: ''
    };
  }

  componentDidMount = () => {
    this.setState({
      comments: this.props.data,
      id: this.props.id
    }, () => ({}));
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      comments: nextProps.data,
      id: nextProps.id
    }, () => ({}));
  }

  toggle = evt => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return (
      <>
        <Line id={this.state.id} comments={this.state.comments}></Line>
        <Modal isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle}><b>Crear nuevo comentario</b></ModalHeader>
          <ModalBody>
            <AddComment type="0" catalogs={this.props.catalogs}/* callback={this.callback} */ /* onError={this.onError} */ /* id={this.state.id} */ />
          </ModalBody>
        </Modal>
      </>

    );
  }
}

export default Comments;